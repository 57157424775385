<template>
  <v-container fluid style="background-color: #f9f9fb">
    <v-row>
      <v-col>
        <h1 class="cnx">
          {{ $t('web_server_side') }}
        </h1>
      </v-col>
    </v-row>
    <v-card style="margin-top: 20px; padding-top: 5px">
      <contentWeb @generateCode="Show_rtc_config" />
      <v-row>
        <v-col cols="12" style="padding-left: 30px">
          <p style="color: black; font-size: 15px">
            {{ $t('refer_to') }}
            <a
              href="https://developer.nexx360.io/web-server-side/activate-prebid-server"
              style="color: black"
              target="_blank"
              >{{ $t('documentation_link') }}</a
            >
            {{ $t('to_see_implementation') }}.
          </p>
        </v-col>
      </v-row>
    </v-card>
    <rtcConfig
      :code="codeGenerated"
      :parent-dialog="rtcConfigDialog"
      @sendToEmail="SendConfigToEmail"
      @hideDialog="rtcConfigDialog = false"
    />
  </v-container>
</template>

<script>
  import {
    GetSites,
    SaveSelectedStoredRequest,
    UpdateSelectedStoredRequest,
    GenerateTagWeb
  } from '@/store/action_types'
  import axios from 'axios'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import {
    SetSelectedSite,
    SetSelectedStoredRequest
  } from '@/store/mutation_types'
  import contentWeb from './content.vue'
  import rtcConfig from '@/pages/web/rtc-config.vue'

  export default {
    name: 'Cookieless',
    components: { contentWeb, rtcConfig },
    data() {
      return {
        codeGenerated: '',
        rtcConfigDialog: false,
        sspsEnabled: ''
      }
    },
    computed: {
      ...mapGetters(['sites', 'selectedSite', 'appCustomer'])
    },
    watch: {
      appCustomer() {}
    },
    async created() {
      this.codeGenerated = ''
    },
    methods: {
      ...mapActions({
        GetSites,
        SaveSelectedStoredRequest,
        UpdateSelectedStoredRequest,
        GenerateTagWeb
      }),
      ...mapMutations([SetSelectedSite, SetSelectedStoredRequest]),
      async Show_rtc_config(ssps) {
        this.sspsEnabled = ssps
        const generateTag = await axios.get(
          `/api/tagweb/${this.sspsEnabled}/${this.appCustomer.code}`
        )
        this.codeGenerated = generateTag.data
        this.rtcConfigDialog = true
      },

      async SendConfigToEmail(email) {
        const response = await axios.get(
          `/api/s2sconfig-email/${this.sspsEnabled}/${this.appCustomer.code}/${email}`
        )
      }
    }
  }
</script>

<style scoped>
  .cnx {
    letter-spacing: 2px;
    font-size: 30px;
    padding-left: 10px;
  }

  .combobox {
    padding-left: 50px;
    width: 370px;
  }
  .notcapital {
    text-transform: none !important;
  }
  .parameters {
    background-color: black;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
</style>
