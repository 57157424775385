<template>
  <v-dialog v-model="dialog" max-width="1000px" @click:outside="hideDialog">
    <v-card style="height: 100%">
      <v-col
        cols="12"
        style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
      >
        <v-icon color="black" @click="hideDialog">mdi-close</v-icon>
      </v-col>
      <v-card-title primary-title style="padding-top: 10px">
        <v-row>
          <v-col cols="12">
            <h3 style="color: black">Uploading your file of placements</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer />
      <br />
      <v-row>
        <v-col cols="12" align="left" justify="left">
          <span v-if="updated">
            <v-alert dense text type="success" color="green">
              Placements updated successfully</v-alert
            >
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11">
          <v-card-text
            :class="{ 'orange dark-3': dragover }"
            style="
              padding-top: 2%;
              margin-left: 5%;
              background-color: lightgray;
              border: dashed;
              border-color: #bababa;
              height: 400px;
            "
            align="center"
            justify="center"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
          >
            <v-row
              class="d-flex flex-column"
              dense
              align="center"
              justify="center"
            >
              <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                mdi-cloud-upload
              </v-icon>
              <p>Drop your file here</p>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-file-input
                  v-model="fileInput"
                  accept=".csv, .xlsx"
                  label="Click here to select a file"
                  outlined
                  dense
                  @change="onValidate"
                >
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :loading="submitLoading"
                  :disabled="fileInput == null || fileInput.length == 0"
                  width="110"
                  style="height: 40px"
                  class="white--text notcapital"
                  color="black"
                  @click.stop="submit"
                >
                  <v-icon left> mdi-check-circle</v-icon>
                  Upload
                </v-btn>
              </v-col>
            </v-row>
            <v-virtual-scroll
              v-if="uploadedFiles.length > 0"
              :items="uploadedFiles"
              height="70"
              item-height="50"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.name">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span class="ml-3 text--secondary">
                        {{ item.size }} bytes</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click.stop="removeFile(item.name)">
                      <v-icon> mdi-close-circle </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
            <v-row>
              <v-col cols="12" style="padding: 50px 10px 0px 10px">
                <v-progress-linear
                  v-if="progressionBar"
                  v-model="progressionBarValue"
                  color="light-blue"
                  height="20"
                  striped
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="1" />
      </v-row>
      <v-row style="padding: 5px">
        <v-col cols="11" align="left" justify="left" style="margin-left: 4%">
          <span v-if="errors.length > 0">
            <v-alert
              v-for="error in errors"
              :key="error"
              color="#db2727"
              type="error"
              dense
              >{{ error }}</v-alert
            >
          </span>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from "xlsx";
import axios from "axios";
import { GetSelectedCustomer, GetStoredRequests } from "@/store/action_types";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Upload",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dragover: false,
      uploadedFiles: [],
      submitLoading: false,
      fileInput: [],
      headersImported: [],
      headersExported: [
        "id",
        "name",
        "is_archived",
        "site_name",
        "pricefloor_is_active",
        "pricefloor_cpm",
      ],
      errors: [],
      dataImported: [],
      updated: false,
      progressionBarValue: "0",
      progressionBar: false,
      optionals: [
        "goodad_member",
        // 'ix_endpoint',
        "pubmatic_placement_id",
        "pubmatic_ad_slot",
        "rubicon_position",
        "smilewanted_position_type",
      ],
    };
  },
  computed: {
    ...mapGetters([
      "selectedSite",
      "appCustomer",
      "SspList",
      "SampleStoredRequest",
      "selectedCustomer",
      "SelectedStoredRequest",
    ]),
    formattedSspList() {
      const output = {};
      this.SspList.forEach((ssp) => {
        if (this.selectedCustomer[ssp + "_is_activated"] == true) {
          output[ssp] = {};
          Object.keys(this.SelectedStoredRequest).forEach((key) => {
            if (
              key.substr(0, this.getPosition(key, "_", 1)) == ssp &&
              key != "ix_endpoint"
            ) {
              output[ssp][key] = this.SelectedStoredRequest[key];
            }
          });
        }
      });
      return output;
    },
    headersExportedComputed() {
      let arr = [];
      this.headersExported.forEach((element) => {
        arr.push(element);
      });
      this.SspList.forEach((ssp) => {
        if (this.selectedCustomer[ssp + "_is_activated"] == true) {
          Object.keys(this.SampleStoredRequest).forEach((key) => {
            if (key.substr(0, this.getPosition(key, "_", 1)) == ssp) {
              arr.push(key);
            }
          });
        }
      });
      return arr;
    },
  },
  watch: {},
  async created() {
    await this.GetSelectedCustomer(this.$route.params.customer_id);
  },
  methods: {
    ...mapActions([GetSelectedCustomer, GetStoredRequests]),
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );

      // If file is in uploaded files remove it
      if (index > -1) {
        this.uploadedFiles.splice(index, 1);
        this.fileInput = null;
      }
    },

    onDrop(e) {
      this.dragover = false;
      this.errors = [];

      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];

      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        console.log("error multiple files");
      }
      if (
        e.dataTransfer.files[0].type != "text/csv" &&
        e.dataTransfer.files[0].type !=
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.errors.push("File extension not supported");
      }
      // Add each file to the array of uploaded files
      else {
        e.dataTransfer.files.forEach((element) =>
          this.uploadedFiles.push(element)
        );
        if (this.uploadedFiles.length == 0) this.fileInput = null;
        else this.fileInput = this.uploadedFiles[0];
        if (this.fileInput.type == "text/csv") this.readCsvFile(this.fileInput);
        if (
          this.fileInput.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
          this.readXLFile(this.fileInput);
      }
    },
    async onValidate(file) {
      this.errors = [];
      if (file == null) this.uploadedFiles = [];
      else if (
        file.type != "text/csv" &&
        file.type !=
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.errors.push("File extension not supported");
        this.fileInput = null;
      } else {
        this.uploadedFiles = [];
        this.uploadedFiles.push(file);
        if (file.type == "text/csv") this.readCsvFile(file);
        if (
          file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
          this.readXLFile(file);
      }
    },
    async submit() {
      if (this.fileInput != null) {
        this.submitLoading = true;
        let response = {};

        if (this.$route.name == "new_storedrequests")
          response = await axios.get(
            `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/${this.$route.params.site_id}`
          );
        else
          response = await axios.get(
            `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/0`
          );
        this.dataExported = response.data;
        this.verifyRowsNumber();
        if (this.errors.length == 0) await this.verifyHeaders();
        if (this.errors.length == 0) await this.verifyRequiredFields();
        if (this.errors.length == 0) await this.verifySSPFields();
        if (this.errors.length == 0) await this.updatePlacements();
        await this.activateProgressBar();
      }
    },
    async activateProgressBar() {
      if (this.errors.length == 0) {
        this.progressionBar = true;
        setTimeout(() => (this.progressionBarValue = "10"), 200);
        setTimeout(() => (this.progressionBarValue = "20"), 900);
        setTimeout(() => (this.progressionBarValue = "30"), 1100);
        setTimeout(() => (this.progressionBarValue = "40"), 1300);
        setTimeout(() => (this.progressionBarValue = "80"), 1500);
        setTimeout(() => (this.progressionBarValue = "90"), 1700);
        setTimeout(() => (this.progressionBarValue = "100"), 1800);
        setTimeout(() => (this.submitLoading = false), 1800);
        setTimeout(() => (this.progressionBar = false), 2500);
        setTimeout(() => (this.updated = true), 2500);
        this.progressionBarValue = "0";
        setTimeout(() => (this.updated = false), 4200);
        setTimeout(() => this.hideDialog(), 4000);
        setTimeout(() => (this.uploadedFiles = []), 4200);
        setTimeout(() => (this.fileInput = null), 4200);
      }
      setTimeout(() => (this.submitLoading = false), 2500);
      if (
        this.$route.params.site_id != "0" &&
        this.$route.params.site_id != undefined
      ) {
        await this.GetStoredRequests(this.$route.params.site_id);
      }
    },
    async updatePlacements() {
      console.log("imported -->", this.dataImported);
      for (let i = 0; i < this.dataImported.length; i++) {
        //delete this.dataImported[i]['site_id']
        delete this.dataImported[i]["site_name"];
        try {
          await axios.put(
            `/api/sr/${this.dataImported[i].id}`,
            this.dataImported[i]
          );
        } catch (error) {
          this.errors.push(error);
        }
      }
    },
    CleanSSPs() {
      for (let i = 0; i < this.dataImported.length; i++) {
        var storedRequest = this.dataImported[i];
        if (storedRequest.goodad_is_active == false) {
          storedRequest.galaxiemedia_tag_id = "";
        }
        if (storedRequest.galaxiemedia_is_active == false) {
          storedRequest.goodad_member = "";
          storedRequest.goodad_invcode = "";
        }
        if (storedRequest.adyoulike_is_active == false)
          storedRequest.adyoulike_placement = "";
        if (storedRequest.adform_is_active == false)
          storedRequest.adform_mid = "";
        if (storedRequest.traffective_is_active == false)
          storedRequest.traffictive_placement_id = "";
        if (storedRequest.firstid_is_active == false)
          storedRequest.firstid_tag_id = "";
        if (storedRequest.nexx360_is_active == false)
          storedRequest.nexx360_tag_id = "";
        if (storedRequest.cwire_is_active == false) {
          storedRequest.cwire_page_id = "";
          storedRequest.cwire_placement_id = "";
        }
        if (storedRequest.freewheel_is_active == false)
          storedRequest.freewheel_zone_id = "";
        if (storedRequest.adnuntius_is_active == false)
          storedRequest.adnuntius_au_id = "";
        if (storedRequest.revenuemaker_is_active == false) {
          //storedRequest.revenuemaker_account = ''
          storedRequest.revenuemaker_tag_id = "";
        }
        if (storedRequest.onetag_is_active == false)
          storedRequest.onetag_pub_id = "";
        if (storedRequest.richaudience_is_active == false)
          storedRequest.richaudience_pid = "";
        if (storedRequest.appnexus_is_active == false)
          storedRequest.appnexus_placement_id = "";
        if (storedRequest.criteo_is_active == false)
          storedRequest.criteo_network_id = "";
        if (storedRequest.bliink_is_active == false)
          storedRequest.bliink_tag_id = "";
        if (storedRequest.conversant_is_active == false)
          storedRequest.conversant_site_id = "";
        if (storedRequest.fueldigital_is_active == false) {
          storedRequest.fueldigital_site_id = "";
          storedRequest.fueldigital_page_id = "";
          storedRequest.fueldigital_format_id = "";
        }
        if (storedRequest.fueldigitalmedia_is_active == false) {
          storedRequest.fueldigitalmedia_tag_id = "";
        }
        if (storedRequest.fueldigitalix_is_active == false)
          storedRequest.fueldigitalix_site_id = "";

        if (storedRequest.gravity_is_active == false)
          storedRequest.gravity_placement_id = "";
        if (storedRequest.improve_is_active == false)
          storedRequest.improve_placement_id = "";
        if (storedRequest.ix_is_active == false) {
          storedRequest.ix_site_id = "";
          // storedRequest.ix_endpoint = ''
        }
        if (storedRequest.mediasquare_is_active == false) {
          storedRequest.mediasquare_zone = "";
          storedRequest.mediasquare_code = "";
        }
        if (storedRequest.mediagrid_is_active == false)
          storedRequest.mediagrid_uuid = "";
        if (storedRequest.moneytag_is_active == false)
          storedRequest.moneytag_placement_id = "";
        if (storedRequest.invibes_is_active == false)
          storedRequest.invibes_placement_id = "";
        if (storedRequest.openx_is_active == false) {
          storedRequest.openx_del_domain = "";
          storedRequest.openx_unit = "";
        }
        if (storedRequest.pubmatic_is_active == false) {
          storedRequest.pubmatic_placement_id = "";
          storedRequest.pubmatic_publisher_id = "";
          storedRequest.pubmatic_ad_slot = "";
        }
        if (storedRequest.rubicon_is_active == false) {
          storedRequest.rubicon_account_id = "";
          storedRequest.rubicon_site_id = "";
          storedRequest.rubicon_zone_id = "";
          storedRequest.rubicon_position = "";
        }
        if (storedRequest.smartadserver_is_active == false) {
          storedRequest.smartadserver_network_id = "";
          storedRequest.smartadserver_site_id = "";
          storedRequest.smartadserver_page_id = "";
          storedRequest.smartadserver_format_id = "";
        }
        if (storedRequest.smilewanted_is_active == false) {
          storedRequest.smartadserver_network_id = "";
          storedRequest.smilewanted_zone_id = "";
          storedRequest.smilewanted_position_type = "";
        }
        if (storedRequest.triplelift_is_active == false)
          storedRequest.triplelift_inventory_code = "";
        if (storedRequest.ttd_is_active == false)
          storedRequest.ttd_endpoint = "";
        if (storedRequest.verizon_is_active == false) {
          storedRequest.verizon_dcn = "";
          storedRequest.verizon_pos = "";
        }
        if (storedRequest.pricefloor_is_active == false) {
          storedRequest.pricefloor_cpm = "";
        }
      }
    },
    verifyRowsNumber() {
      if (this.dataImported.length > this.dataExported.length) {
        this.errors.push(
          "Only " +
            this.dataExported.length +
            " placements are created and you're trying to import " +
            this.dataImported.length +
            " placements."
        );
      }
    },
    verifyHeaders() {
      console.log("imported list ====>", this.headersImported);
      console.log("exported list ====>", this.headersExportedComputed);
      if (this.headersImported.length != this.headersExportedComputed.length) {
        this.errors.push("The number of columns in your file is not correct");
      } else
        for (let index = 0; index < this.headersImported.length; index++) {
          if (
            this.headersImported[index] != this.headersExportedComputed[index]
          ) {
            this.errors.push(
              "Problem header : Column '" +
                this.headersImported[index] +
                "' should be called '" +
                this.headersExportedComputed[index] +
                "'"
            );
          }
        }
    },

    async verifyRequiredFields() {
      await this.verifyPlacements();
    },
    async verifyPlacements() {
      for (let i = 0; i < this.dataImported.length; i++) {
        var nbrow = i + 2;
        const response = await axios.get(
          `/api/srexists/${this.dataImported[i].id}`
        );
        var placements = response.data;
        if (placements.length == 0) {
          this.errors.push(
            "Couldn't find the placement with id = '" +
              this.dataImported[i].id +
              "'"
          );
        } else {
          console.log("placements ==>", placements);
          console.log("data imported ==>", this.dataImported);
          console.log(
            "placement ==>",
            placements[0].name,
            "---------- ",
            this.dataImported[i].name,
            "----->>>>",
            placements[0].name != this.dataImported[i].name
          );
          if (placements[0].name != this.dataImported[i].name) {
            this.errors.push(
              "There is no existing placement with id = " +
                this.dataImported[i].id +
                " and name = " +
                this.dataImported[i].name +
                ", check row " +
                nbrow
            );
          }
        }
      }
    },
    async verifyPlacementsName() {
      for (let i = 0; i < this.dataImported.length; i++) {
        if (this.dataImported[i].name != this.dataExported[i].name) {
          let nrow = i + 2;

          this.errors.push(
            "Placement name in row number " +
              nrow +
              " should be " +
              this.dataExported[i].name
          );
        }
      }
    },
    async verifySitesIds() {
      for (let i = 0; i < this.dataImported.length; i++) {
        if (this.dataImported[i].site_id != this.dataExported[i].site_id) {
          let nrow = i + 1;
          this.errors.push(
            "Site id in row number " +
              nrow +
              " should be " +
              this.dataExported[i].site_id
          );
        }
      }
    },
    async verifySitesNames() {
      for (let i = 0; i < this.dataImported.length; i++) {
        if (this.dataImported[i].site_name != this.dataExported[i].site_name) {
          let nrow = i + 1;
          this.errors.push(
            "Site name in row number " +
              nrow +
              " should be " +
              this.dataExported[i].site_name
          );
        }
      }
    },
    verifySSPFields() {
      for (let i = 0; i < this.dataImported.length; i++) {
        var nbrow = i + 2;
        var row = this.dataImported[i];

        for (const property in row) {
          if (property.match("_is_active")) {
            if (row[property] == undefined) {
              row[property] = "0";
            }
            if (
              row[property] != 0 &&
              row[property] != 1 &&
              row[property] != 2 &&
              row[property] != ""
            ) {
              this.errors.push(
                "Invalid entry, " +
                  property +
                  " should be 0 or 1 in the row " +
                  nbrow
              );
            }
          }
        }

        for (const property in this.formattedSspList) {
          let keys = Object.keys(this.formattedSspList[property]);
          if (
            row[keys[0]] == 1 ||
            row[keys[0]].toLowerCase() == true ||
            row[keys[0]].toLowerCase() == "vrai"
          ) {
            let err = 0;
            for (let j = 1; j < keys.length; j++) {
              if (this.optionals.includes(keys[j]) == false) {
                if (row[keys[j]] == "" || row[keys[j]] == undefined)
                  err = err + 1;
              }
            }
            if (err > 0)
              this.errors.push(
                "You have chosen to activate " +
                  property +
                  " without providing its parameters for placement " +
                  row.id +
                  " in row " +
                  nbrow
              );
          }
        }

        if (
          row.pricefloor_is_active != 0 &&
          row.pricefloor_is_active != 1 &&
          row.pricefloor_is_active != ""
        ) {
          this.errors.push(
            "Invalid entry, pricefloor_is_active should be 0 or 1 in the row " +
              nbrow
          );
        }

        if (
          row.pricefloor_is_active == 1 ||
          row.pricefloor_is_active.toLowerCase() == true ||
          row.pricefloor_is_active.toLowerCase() == "vrai"
        ) {
          if (row.pricefloor_cpm == "" || row.pricefloor_cpm == undefined)
            this.errors.push(
              "You have chosen to set a pricefloor without providing Pricefloor CPM for placement " +
                row.id +
                " in row " +
                nbrow
            );
          if (isNaN(row.pricefloor_cpm)) {
            if (row.pricefloor_cpm.toString().indexOf(",") != -1) {
              var foo = row.pricefloor_cpm.replace(",", ".");
              if (isNaN(foo)) {
                this.errors.push(
                  "Format error : cpm_pricefloor should be a number for placement " +
                    row.id +
                    " in row " +
                    nbrow
                );
              }
            } else {
              this.errors.push(
                "Format error : cpm_pricefloor should be a number for placement " +
                  row.id +
                  " in row " +
                  nbrow
              );
            }
          }
        }
      }
    },

    async readCsvFile(file) {
      const reader = new FileReader();
      var text = "";
      var that = this;
      reader.onload = await function (e) {
        text = e.target.result;
        var data = that.csvToArray(text, ",");
        that.dataImported = data;
      };
      reader.readAsText(file);
    },
    csvToArray(str, delimiter = ",") {
      var headers = str.slice(0, str.indexOf("\n") - 1).split(delimiter);
      console.log("headers->", headers);
      console.log("headers length->", headers.length);
      if (headers.length == 1) {
        delimiter = ";";
        headers = str.slice(0, str.indexOf("\n") - 1).split(";");
      }
      this.headersImported = headers;
      const rows = str.split(/\r?\n/);
      console.log("rows ==>", rows);
      rows.shift();
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        var object = {};
        for (let index = 0; index < headers.length; index++) {
          object[headers[index]] = values[index];
        }

        return object;
      });
      console.log("before ==>", arr);
      console.log("id ==>", arr[arr.length - 1].id);
      if (arr[arr.length - 1].id == "") arr.pop();
      console.log("after ==>", arr);
      // return the array
      return arr;
    },
    readXLFile(file) {
      const reader = new FileReader();
      var that = this;
      var data = [];
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        try {
          ws["!ref"] = ws["!autofilter"]["ref"];
        } catch (error) {}

        /* Convert array of arrays */
        const elements = XLSX.utils.sheet_to_json(ws, { header: 1 });

        data = that.XLSXToArray(elements);

        for (let i = 0; i < data.length; i++) {
          for (const key in data[i]) {
            if (data[i][key] == undefined) data[i][key] = "";
          }
        }
        that.dataImported = data;
      };
      reader.readAsBinaryString(file);
      return data;
    },
    XLSXToArray(data) {
      const headers = [];
      var arr = [];
      data[0].forEach((header) => {
        headers.push(header);
      });
      this.headersImported = headers;
      for (let i = 1; i < data.length; i++) {
        var object = {};
        for (let index = 0; index < headers.length; index++) {
          object[headers[index]] = data[i][index];
        }
        arr.push(object);
      }
      console.log("rows ==>", arr);
      return arr;
    },
    back() {
      this.fileInput = null;
      this.uploadedFiles = [];
      this.headersImported = [];
      this.$emit("back");
    },
    hideDialog() {
      this.fileInput = null;
      this.uploadedFiles = [];
      this.headersImported = [];
      this.errors = [];
      this.$emit("hideDialog");
    },
    getPosition(text, subString, index) {
      return text.split(subString, index).join(subString).length;
    },
  },
};
</script>
