<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="$emit('hideDialog')"
  >
    <template>
      <v-card v-if="actionType == 'add'">
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h2 style="color: black">{{ TitleDialog }}</h2>
                </v-col>
                <br />
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p style="color: black">{{ $t('gam_new_description') }}</p>
                </v-col>
                <br />
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p>{{ $t('network_name') }}</p>
                  <v-text-field
                    v-model="gamSetup.network_name"
                    dense
                    solo
                    :rules="nameRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <p>{{ $t('network_id') }}</p>
                  <v-text-field
                    v-model="gamSetup.network_id"
                    dense
                    solo
                    :rules="nameRules"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions align="center" class="justify-end">
          <v-col cols="12" align="center">
            <v-btn
              width="320"
              style="height: 40px"
              class="white--text"
              color="black"
              @click="$emit('submitted', gamSetup)"
            >
              {{ btnText }}
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      },
      actionType: {
        default: '',
        type: String
      }
    },
    data() {
      return {
        dialog: false,
        gamSetup: {
          network_name: ''
        },
        TitleDialog: this.$t('update_gamSetup'),
        nameRules: [(v) => !!v || this.$t('name is required')],
        submitLoading: false,
        errors: [],
        btnText: this.$t('update')
      }
    },
    computed: {
      ...mapGetters(['appCustomer'])
    },
    watch: {
      parentDialog() {
        this.dialog = this.parentDialog
        if (this.actionType == 'add') {
          this.AddActions()
        }
      }
    },
    async created() {
      this.gamSetup.customer_id = this.appCustomer.id
    },
    methods: {
      DeleteActions() {
        this.gamSetup = Object.assign({}, this.$store.state.selectedGamSetup)
      },
      AddActions() {
        this.TitleDialog = this.$t('add_gamSetup')
        this.btnText = this.$t('add')
        if (this.gamSetup.network_name != '') this.$refs.form.reset()
        this.gamSetup = {
          network_name: '',
          customer_id: this.appCustomer.id
        }
      }
    }
  }
</script>
