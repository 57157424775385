<template>
  <v-toolbar flat style="background-color: #fafafa">
    <v-toolbar-title>
      <h2>{{ typeReporting }}</h2>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <app-timeselector
      :dateonefromurl="dateonefromurl"
      :datetwofromurl="datetwofromurl"
      @datesUpdate="datesUpdate"
    />
  </v-toolbar>
</template>

<script>
  import Timeselector from '@/components/reporting/timeselector.vue'
  export default {
    components: {
      AppTimeselector: Timeselector
    },
    props: ['dateonefromurl', 'datetwofromurl'],
    data() {
      return {
        typeReporting: ''
      }
    },
    watch: {},
    created() {
      if (this.$route.name == 'billing_reporting') {
        this.typeReporting = this.$t('facturation_reporting')
      }
      if (this.$route.name == 'pbs_reporting') {
        this.typeReporting = this.$t('analytics')
      }
    },
    methods: {
      datesUpdate(dates) {
        this.$emit('datesUpdate', dates)
      }
    }
  }
</script>
