var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"background-color":"#f9f9fb"},attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"padding-top":"20px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticStyle:{"padding-left":"90px"}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[(
              _vm.$store.getters.is_superadmin ||
              _vm.$store.state.appCustomer.adapter_is_active
            )?_c('v-col',{staticStyle:{"margin-left":"auto","margin-right":"auto"},attrs:{"cols":"3"}},[_c('v-card',{attrs:{"width":"280","height":"200"}},[_c('v-card-title',[_c('span',{staticStyle:{"color":"black","font-size":"17px","text-indent":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("setup_prebid_booster"))+" ")])]),_c('v-card-text',{staticStyle:{"height":"30px","width":"270px"}},[_c('v-container',[_c('v-row',[_c('v-col')],1)],1)],1),_c('v-card-actions',{staticStyle:{"padding-top":"50px","text-align":"center"}},[_c('v-row',[_c('v-col',{attrs:{"align-center":""}},[_c('v-btn',{staticClass:"ma-2 notcapital",attrs:{"width":"150","tile":"","outlined":"","color":"black"},on:{"click":function($event){return _vm.$router.push({
                          name: 'new_sites',
                          params: {
                            customer_id: _vm.$store.state.appCustomer.id,
                            type: 'adapter',
                          },
                        })}}},[_vm._v(" "+_vm._s(_vm.$t("access"))+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1):_vm._e(),(
              _vm.$store.getters.is_superadmin ||
              _vm.$store.state.appCustomer.web_is_active
            )?_c('v-col',{staticStyle:{"margin-left":"auto","margin-right":"auto"},attrs:{"cols":"3"}},[_c('v-card',{attrs:{"width":"280","height":"200"}},[_c('v-card-title',[_c('span',{staticStyle:{"color":"black","font-size":"17px","text-indent":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("setup_Web_stack_nexx360"))+" ")])]),_c('v-card-text',{staticStyle:{"height":"30px","width":"270px"}},[_c('v-container',[_c('v-row',[_c('v-col')],1)],1)],1),_c('v-card-actions',{staticStyle:{"padding-top":"50px","text-align":"center"}},[_c('v-row',[_c('v-col',{attrs:{"align-center":""}},[_c('v-btn',{staticClass:"ma-2 notcapital",attrs:{"width":"150","tile":"","outlined":"","color":"black"},on:{"click":function($event){return _vm.$router.push({
                          name: 'new_sites',
                          params: {
                            customer_id: _vm.$store.state.appCustomer.id,
                            type: 'fast',
                          },
                        })}}},[_vm._v(" "+_vm._s(_vm.$t("access"))+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1):_vm._e(),(
              _vm.$store.getters.is_superadmin ||
              _vm.$store.state.appCustomer.amp_is_active
            )?_c('v-col',{staticStyle:{"margin-left":"auto","margin-right":"auto"},attrs:{"cols":"3"}},[_c('v-card',{attrs:{"width":"280","height":"200"}},[_c('v-card-title',[_c('span',{staticStyle:{"color":"black","font-size":"17px","text-indent":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("setup_AMP_amp_nexx360"))+" ")])]),_c('v-card-text',{staticStyle:{"height":"30px","width":"270px"}},[_c('v-container',[_c('v-row',[_c('v-col')],1)],1)],1),_c('v-card-actions',{staticStyle:{"padding-top":"50px","text-align":"center"}},[_c('v-row',[_c('v-col',{attrs:{"align-center":""}},[_c('v-btn',{staticClass:"ma-2 notcapital",attrs:{"width":"150","tile":"","outlined":"","color":"black"},on:{"click":function($event){return _vm.$router.push({
                          name: 'new_sites',
                          params: {
                            customer_id: _vm.$store.state.appCustomer.id,
                            type: 'amp',
                          },
                        })}}},[_vm._v(" "+_vm._s(_vm.$t("access"))+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1):_vm._e(),(
              !_vm.$store.getters.is_superadmin &&
              !_vm.$store.state.appCustomer.adapter_is_active &&
              !_vm.$store.state.appCustomer.web_is_active &&
              !_vm.$store.state.appCustomer.amp_is_active
            )?_c('accessdenied',{staticStyle:{"margin-right":"15%"}}):_vm._e(),_c('v-col',{attrs:{"cols":"2"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }