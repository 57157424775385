import Vue from 'vue'
import axios from 'axios'

import {
  GetProfile,
  GetAppGroups,
  GetStoredRequestTag,
  Logout,
  UpdateLoadCookieFiles
} from '@/store/action_types'

import { SetAppUser, SetAppCustomer } from '@/store/mutation_types'

import { appUserActions } from '@/store/items/appUser'
import { onboardingUserActions } from '@/store/items/onboardingUser'
import { appCustomerActions } from '@/store/items/appCustomer'

import { usersActions } from '@/store/items/users'
import { selectedUserActions } from '@/store/items/selectedUser'

import { customersActions } from '@/store/items/customers'
import { selectedCustomerActions } from '@/store/items/selectedCustomer'

import { proposalsActions } from '@/store/items/proposals'
import { selectedProposalActions } from '@/store/items/selectedProposal'

import { dealsActions } from '@/store/items/deals'
import { selectedDealActions } from '@/store/items/selectedDeal'

import { gamsetupsActions } from '@/store/items/gamsetups'
import { selectedGamSetupActions } from '@/store/items/selectedGamSetup'

import { optionscookielessActions } from '@/store/items/optionscookieless'
import { selectedOptionsCookielessActions } from '@/store/items/selectedOptionsCookieless'

import { sitesActions } from '@/store/items/sites'
import { selectedSiteActions } from '@/store/items/selectedSite'

import { storedRequestsActions } from '@/store/items/storedRequests'
import { selectedStoredRequestActions } from '@/store/items/selectedStoredRequest'

import { injectionsActions } from '@/store/items/injections'
import { selectedInjectionActions } from '@/store/items/selectedInjection'

const actions = {
  ...appUserActions,
  ...onboardingUserActions,
  ...appCustomerActions,
  ...usersActions,
  ...selectedUserActions,
  ...customersActions,
  ...proposalsActions,
  ...selectedProposalActions,
  ...dealsActions,
  ...selectedDealActions,
  ...selectedCustomerActions,
  ...gamsetupsActions,
  ...optionscookielessActions,
  ...selectedOptionsCookielessActions,
  ...selectedGamSetupActions,
  ...sitesActions,
  ...selectedSiteActions,
  ...storedRequestsActions,
  ...injectionsActions,
  ...selectedStoredRequestActions,
  ...selectedInjectionActions,
  async [GetProfile]({ commit }, okta_account) {
    const response = await axios.get("/api/getprofile");
    commit(SetAppUser, {
      ...response.data.user,
      ...okta_account,
    });
    commit(SetAppCustomer, {
      ...response.data.customer,
    });
    return response;
  },
  async [UpdateLoadCookieFiles](customer_id) {
    let url = "/api/customer/gen-load-cookie-file";
    if (customer_id) {
      url += `/${customer_id}`;
    }

    const response = await axios.get(url);
    return response.data;
  },
  async [GetStoredRequestTag](_, site_id) {
    const response = await axios.get(`/api/site/${site_id}/tag`);
    return response.data;
  },
  async [GetAppGroups](id) {
    let url = "/api/groups";
    if (id) {
      url += `/${id}`;
    }

    const response = await axios.get(url);

    //commit(SetAppGroups, response.data)
    return response;
  },
  async [Logout]({ commit }) {
    Vue.prototype.$auth.signOut();
    await axios.get("/api/profile/logout");
    commit(Logout);
  },
};

export default actions;
