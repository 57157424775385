<template>
  <v-container fluid>
    <app-toolbar
      :dateonefromurl="datesfromURL[0]"
      :datetwofromurl="datesfromURL[1]"
      @datesUpdate="datesUpdate"
    />
    <app-filters
      :filters="filters"
      :selects="reportingData.selects"
      :selectedfromtable="selectedfromtable"
      @filtersUpdate="filtersUpdate"
    />
    <app-charts
      :reporting-data="reportingData.perDay"
      :loading="loading"
      :time-unit="'datetime'"
    />
    <app-per-criteria
      :reporting-data="reportingData.perCriteria"
      :loading="loading"
      :cats="cats"
      @perCriteriaUpdate="perCriteriaUpdate"
    />
  </v-container>
</template>

<script>
  import moment from 'moment'
  import axios from 'axios'
  import Toolbar from '@/components/reporting/toolbar.vue'
  import Filters from '@/components/reporting/filters.vue'
  import Charts from './charts/index.vue'
  import PerCriteria from './perCriterias/index.vue'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      AppFilters: Filters,
      AppToolbar: Toolbar,
      AppCharts: Charts,
      AppPerCriteria: PerCriteria
    },
    data() {
      return {
        loading: false,
        dates: [],
        filters: {
          site: [],
          placement: [],
          auction_type: []
        },
        reportingData: {
          perCriteria: {
            site: [],
            auction_type: [],
            placement: []
          },
          perDay: []
        },
        dataSync: false,
        customerId: 0,
        selectedfromtable: [],
        datesfromURL: []
      }
    },
    computed: {
      ...mapGetters(['appCustomer']),
      cats() {
        return Object.keys(this.reportingData.perCriteria)
      }
    },
    watch: {
      appCustomer() {
        this.filters = Object.assign(
          {},
          {
            site: [],
            auction_type: [],
            placement: []
          }
        )
        this.getDataFromApi()
      }
    },
    created() {
      this.generateFilterFromLink()
    },
    methods: {
      unixDates(dates) {
        return [
          moment(dates[0]).startOf('day').format('x'),
          moment(dates[1])
            .startOf('day')
            .add(1, 'days')
            .subtract(1, 'ms')
            .format('x')
        ]
      },
      datesUpdate(dates) {
        this.dates = this.unixDates(dates)
        this.buildLink(this.filters)
        this.getDataFromApi()
        this.dataSync = true
      },
      async filtersUpdate(foo) {
        this.filters = Object.assign({}, foo)
        this.buildLink(foo)
        if (this.dataSync) await this.getDataFromApi()
        this.dataSync = true
      },
      buildLink(filters) {
        var testDates = this.unixDates([
          moment().startOf('day').subtract(6, 'days'),
          moment().startOf('day').subtract(0, 'days')
        ])
        if (
          filters['auction_type'].length > 0 ||
          filters['site'].length > 0 ||
          filters['placement'].length > 0 ||
          ((testDates[0] != this.dates[0] || testDates[1] != this.dates[1]) &&
            this.dates.length > 0)
        ) {
          var routepath = ''
          if (
            this.$route.path !=
            '/nexx-reporting/filters/' + this.appCustomer.id
          ) {
            routepath = 'nexx-reporting/filters/' + this.appCustomer.id
          }
          var route = ''

          route = route + '?date0=' + this.dates[0] + '&date1=' + this.dates[1]
          if (filters['auction_type'].length > 0) {
            var auctions = filters['auction_type'].join()
            route = route + '&auction_type=' + auctions
          }

          if (filters['site'].length > 0) {
            var sites = filters['site'].join()
            route = route + '&sites=' + sites
            if (filters['placement'].length > 0) {
              var placements = filters['placement'].join()
              route = route + '&placements=' + placements
            }
          }
          if (this.$route.fullPath != this.$route.path + routepath + route) {
            // this.$router.push(routepath + route)
            this.$router.push({ name: 'dashboard' })
          }
        }
      },
      generateFilterFromLink() {
        if (
          this.$route.path ==
          '/nexx-reporting/filters/' + this.appCustomer.id
        ) {
          this.datesfromURL[0] = this.$route.query.date0
          this.datesfromURL[1] = this.$route.query.date1
          if (
            this.$route.query.auction_type != undefined ||
            this.$route.query.sites != undefined ||
            this.$route.query.placements != undefined
          ) {
            var foo = []
            if (this.$route.query.auction_type != undefined) {
              var auctions = this.$route.query.auction_type.split(',')
              foo['auction_type'] = auctions
              this.filters['auction_type'] = foo['auction_type']
            }
            if (this.$route.query.sites != undefined) {
              var sites = this.$route.query.sites.split(',')
              foo['site'] = sites
              this.filters['site'] = foo['site']
            }
            if (this.$route.query.placements != undefined) {
              var placements = this.$route.query.placements.split(',')
              foo['placement'] = placements
              this.filters['placement'] = foo['placement']
            }
          }
        } else {
          if (this.$route.path != '/nexx-reporting') {
            // this.$router.push({ name: 'pbs_reporting' })
            this.$router.push({ name: 'dashboard' })
          }
        }
        //this.action = 'build'
      },
      perCriteriaUpdate(foo) {
        this.selectedfromtable = foo
        this.filters[foo.category] = [foo.id]
        if (this.dataSync) this.getDataFromApi()
        this.dataSync = true
      },
      getTimeZone() {
        var offset = new Date().getTimezoneOffset(),
          o = Math.abs(offset)
        return (
          (offset < 0 ? '+' : '-') +
          ('00' + Math.floor(o / 60)).slice(-2) +
          ':' +
          ('00' + (o % 60)).slice(-2)
        )
      },
      async getDataFromApi() {
        this.loading = true
        const payload = {
          timeOffset: this.getTimeZone(),
          filters: this.filters,
          dates: this.dates,
          customerId: this.appCustomer.id,
          sites: this.filters['site'].length > 0 ? this.filters['site'] : []
        }
        try {
          const response = await axios.post(`/api/pbs-reporting`, payload)
          this.reportingData = response.data
          this.loading = false
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
