<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="$emit('hideDialog')"
  >
    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="$emit('hideDialog')">mdi-close</v-icon>
        </v-col>
        <v-card-title>
          <h4 style="color: black">
            {{ $t('s2s config of your website') }}
          </h4>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <p style="font-size: 13px; color: black">
                {{ $t('s2s_config_description_first_line') }}<br />
                {{ $t('s2s_config_description_second_line') }}<br /><br />
                {{ $t('s2s_config_description_third_line') }}
              </p>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col
                cols="11"
                align="right"
                style="padding-top: 0px; padding-right: 0px"
                justify-end
              >
                <div class="text-right">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        style="cursor: pointer"
                        @click="sheet = !sheet"
                        v-on="on"
                        >mdi-email-receive-outline</v-icon
                      >
                    </template>
                    <span>{{ $t('send_to_my_email') }}</span>
                  </v-tooltip>
                  <v-bottom-sheet v-model="sheet" inset>
                    <v-sheet class="text-center" height="100px">
                      <v-row>
                        <v-col
                          cols="12"
                          style="
                            text-align: right;
                            padding: 5px 20px 0px 40px;
                            height: 5px;
                          "
                          @click="sheet = !sheet"
                        >
                          <v-icon color="black" @click.stop="sheet = !sheet"
                            >mdi-close</v-icon
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="emailSent == false"
                        style="padding-top: 20px"
                      >
                        <v-col cols="9">
                          <v-text-field
                            v-model="email"
                            outlined
                            dense
                            :rules="emailRules"
                            placeholder="Your email"
                            style="padding-left: 35%"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            style="border-color: black"
                            class="white--text notcapital"
                            color="black"
                            :disabled="empty"
                            dense
                            @click="sendEmail()"
                          >
                            {{ $t('send') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="emailSent == true" style="padding-top: 20px">
                        <v-col>
                          <v-alert dense text type="success" color="green">
                            {{ $t('email_sent_successfully') }}</v-alert
                          >
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-bottom-sheet>
                </div>
              </v-col>
              <v-col
                cols="1"
                align="center"
                style="padding-top: 0px; padding-left: 0px"
                justify-end
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" @click="copyText" v-on="on"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>{{ $t('copy') }}</span>
                </v-tooltip>
                <v-snackbar
                  v-model="snackbar"
                  color="#2E4089"
                  width="10"
                  :timeout="timeout"
                >
                  {{ $t('copied') }}
                </v-snackbar></v-col
              >
              <v-textarea
                ref="textToCopy"
                v-model="codee"
                filled
                auto-grow
                :readonly="true"
                name="input-7-4"
                label="rtc-config"
                width="1200"
              >
              </v-textarea>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      },
      code: {
        default: '',
        type: String
      }
    },
    data() {
      return {
        dialog: false,
        site: {
          name: ''
        },
        emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
        empty: true,
        email: '',
        TitleDialog: this.$t('update_site'),
        nameRules: [(v) => !!v || this.$t('name is required')],
        currencyRules: [(v) => !!v || this.$t('currency is required')],
        currencies: this.$store.getters.currencies,
        submitLoading: false,
        errors: [],
        btnText: this.$t('update'),
        codeGenerated: '',
        codee: '',
        snackbar: false,
        timeout: 1000,
        sheet: false,
        emailSent: false
      }
    },
    computed: {},
    watch: {
      parentDialog() {
        this.dialog = this.parentDialog
        this.codeGenerated = this.code
      },
      email() {
        if (this.email != '') this.empty = false
        else this.empty = true
      }
    },
    methods: {
      copyText() {
        this.snackbar = true
        setTimeout(() => (this.show = false), 2000)
        navigator.clipboard.writeText(this.codeGenerated)
      },
      sendEmail() {
        this.$emit('sendToEmail', this.email)
        this.emailSent = true
        setTimeout(() => (this.sheet = false), 2000)
        this.email = ''
        setTimeout(() => (this.emailSent = false), 2000)
      }
    }
  }
</script>
