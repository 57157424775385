<template>
  <v-card style="margin-left: 10px">
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>
    <v-card-title
      primary-title
      style="background-color: #f9f9fb; padding-bottom: 0px"
      ><v-container>
        <v-row>
          <v-col>
            <h3 class="cnx">
              <strong> {{ siteName }}</strong>
            </h3>
          </v-col>
        </v-row>
        <!--<v-row>
          <v-col
            cols="8"
            style="padding-top: 0px; padding-left: 15px; padding-bottom: 20px"
          >
            <v-btn
              v-if="$route.name == 'new_sites_edit'"
              class="black--text notcapital"
              tile
              width="200"
              height="30"
              outlined
              style="margin-top: 20px"
              @click="newStoredRequest()"
            >
              <v-icon left> mdi-plus</v-icon>
              Add a placement
            </v-btn>
          </v-col>
        </v-row>-->
      </v-container>
    </v-card-title>
    <v-divider></v-divider>

    <!--
      <v-card-title primary-title v-else>
        Page {{ page.name }}
      </v-card-title>
      -->
    <v-form ref="form" lazy-validation>
      <v-card-text style="margin-top: 20px; padding-left: 40px">
        <v-row v-if="$route.name == 'new_sites_edit'">
          <v-col>
            <h2 style="margin-left: 0px; margin-top: 0px">Set up</h2>
          </v-col>
        </v-row>
        <v-row v-if="$route.name == 'new_sites_new'" style="padding-top: 10px">
          <v-col cols="4">
            <p style="margin-bottom: 5px">Name of the site</p>
            <v-text-field v-model="site.name" :rules="required" dense solo>
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider v-if="$route.name == 'new_sites_new'" />
        <table style="width: 100%; padding-top: 30px">
          <tr>
            <td rowspan="2">
              <v-switch
                v-model="site.is_schain_active"
                label="Is Schain active"
              ></v-switch>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <p style="margin-bottom: 5px">Seller ID (sid)</p>
              <v-text-field
                v-model="site.schain_sid"
                :disabled="!site.is_schain_active"
                :rules="site.is_schain_active ? required : []"
                style="width: 220px"
                dense
                solo
              ></v-text-field>
            </td>
            <td colspan="3">
              <p style="margin-bottom: 5px">Advertising system domain (asi)</p>
              <v-text-field
                v-model="site.schain_asi"
                :disabled="!site.is_schain_active"
                :rules="site.is_schain_active ? required : []"
                style="width: 220px"
                dense
                solo
              ></v-text-field>
            </td>
          </tr>
        </table>
        <v-divider
          v-if="$route.name == 'new_sites_new' && $route.params.type == 'fast'"
        />
        <table
          v-if="$route.params.type == 'fast'"
          style="width: 100%; padding-top: 30px"
        >
          <tr>
            <td rowspan="2">
              <v-switch
                v-model="site.is_refresh_active"
                label="Is Refresh Active"
              ></v-switch>
            </td>
            <td width="10px">
              <p
                style="
                  font-size: 15px;
                  color: rgba(0, 0, 0, 0.6);
                  font-family: Roboto;
                  margin: 0px;
                  padding-right: 10px;
                  padding-bottom: 8px;
                "
                align="right"
                justify-end
              >
                Exclude
              </p>
            </td>
            <td>
              <v-switch
                v-model="selection_type"
                label="Include"
                style="padding: 0px"
                :disabled="!site.is_refresh_active"
              ></v-switch>
            </td>
            <td colspan="3">
              <p style="margin-bottom: 5px">Orders IDs</p>
              <v-text-field
                v-model="site.orders_ids"
                :rules="
                  site.is_refresh_active && site.orders_ids ? arrayRules : []
                "
                :disabled="!site.is_refresh_active"
                dense
                solo
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <p style="margin-bottom: 5px">Time interval</p>
              <v-text-field
                v-model="site.refresh_time"
                :rules="site.is_refresh_active ? integerRequiredRule : []"
                :disabled="!site.is_refresh_active"
                type="number"
                min="0"
                suffix="seconds"
                style="width: 200px"
                value="30"
                dense
                solo
              ></v-text-field>
            </td>
            <td colspan="3">
              <p style="margin-bottom: 5px">Maximum repetitions (Optional)</p>
              <v-text-field
                v-model="site.nb_repetition"
                :disabled="!site.is_refresh_active"
                style="width: 220px"
                type="number"
                min="0"
                dense
                solo
              ></v-text-field>
            </td>
          </tr>
        </table>
        <v-divider v-if="$route.params.type == 'fast'" />
        <table
          v-if="$route.params.type == 'fast'"
          style="width: 100%; margin-top: 15px"
        >
          <tr>
            <td>
              <v-switch
                v-model="site.noconsent_traffic_blocking"
                label="No consent traffic blocking"
              ></v-switch>
            </td>
          </tr>
        </table>
        <v-divider v-if="$route.params.type == 'fast'" />
      </v-card-text>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          :loading="submitLoading"
          :disabled="submitLoading"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="130"
          dense
          @click="submit"
        >
          <v-icon left> mdi-check-circle</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn
          style="margin-left: 20px"
          class="white--text notcapital"
          color="black"
          width="130"
          dense
          @click="back"
        >
          <v-icon left> mdi-arrow-left</v-icon>
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import { SaveSelectedSite } from "@/store/action_types";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { SetSelectedSite } from "@/store/mutation_types";
export default {
  data() {
    return {
      loading: false,
      siteName: "Creating a new website",
      nameRules: [(v) => !!v || "Name is required"],
      integerRequiredRule: [
        (v) => /^\d+$/.test(v) || "Required (Only numbers allowed)",
      ],
      integerRule: [(v) => /^\d+$/.test(v) || "Only numbers allowed"],
      arrayRules: [
        (v) => (v && this.isJsonString(v)) || "Needs to be an array / object",
      ],
      submitLoading: false,

      //items: ['Desktop', 'Tablet', 'Mobile', 'Video'],
      tab: null,
      site: {},
      actionsuccess: false,
      timeout: 2000,
      messageAction: "",
      required: [(v) => !!v || "Required"],
      selection_type: true,
      refresh_time: 30,
    };
  },
  computed: {
    ...mapGetters(["selectedSite", "appCustomer"]),
  },
  watch: {},
  async created() {
    if (this.$route.name == "new_sites_edit") {
      try {
        const siteURL = await axios.get(
          `/api/getsitebyid/${this.$route.params.site_id}`
        );
        this.SetSelectedSite(siteURL.data);
        this.site = this.selectedSite;
        this.siteName = this.site.name;
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    ...mapActions({
      SaveSelectedSite,
    }),
    ...mapMutations([SetSelectedSite]),

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    async submit() {
      this.submitLoading = true;
      if (!this.$refs.form.validate()) {
        console.log("No fullfilled");
        this.submitLoading = false;
        return;
      }
      this.organizeSiteItems();
      try {
        await this.SaveSelectedSite(this.site);
        this.actionsuccess = true;
        this.messageAction = "Site updated successfully";
        setTimeout(
          () =>
            this.$router.push(
              "/management/" +
                this.$route.params.type +
                "/" +
                this.appCustomer.id +
                "/sites"
            ),
          1000
        );
        setTimeout(() => (this.submitLoading = false), 1500);
      } catch (error) {
        console.log(error);
        this.submitLoading = false;
      }
    },
    back() {
      this.$router.back();
    },
    organizeSiteItems() {
      this.site.customer_id = this.$route.params.customer_id;
      console.log("1");
      if (this.site.is_refresh_active == false) {
        this.site.selection_type = "include";
        this.site.orders_ids = "";
        this.site.refresh_time = "";
        console.log("2");

        this.site.nb_repetition = "";
        console.log("3");
      }
      if (this.site.is_schain_active == false) {
        this.site.schain_asi = "";
        this.site.schain_sid = "";
      }
      if (this.selection_type == true) this.site.selection_type = "include";
      else this.site.selection_type = "exclude";

      // this.site.refresh_time = this.refresh_time
    },
    newStoredRequest() {
      if (this.site.id !== undefined) {
        this.$router.push({
          name: "stored_request_new",
          params: {
            site_id: this.site.id,
            type: this.$route.params.type,
            stored_request_id: "new",
          },
        });
      }
    },
  },
};
</script>

<style>
.pageForm {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.avatar {
  padding-left: 20px;
  padding-bottom: 23px;
}
.no-shadow,
.no-shadow::before {
  box-shadow: none;
  background-color: transparent !important;
}
.combobox {
  padding-left: 50px;
}
.cnx {
  padding-left: 0px;
  letter-spacing: 2px;
  font-size: 25px;
}
</style>
