import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en_en',
  messages: {
    fr_fr: require('@/translations/fr.json'),
    en_en: require('@/translations/en.json')
  }
})

export default i18n
