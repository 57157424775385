import { GetInjections } from '@/store/action_types'

import { SetInjections, ClearInjections } from '@/store/mutation_types'

import axios from 'axios'

export const injectionsState = {
  injections: []
}

export const injectionsGetters = {
  injections: (state) => state.injections
}

export const injectionsActions = {
  async [GetInjections]({ commit }, site_id) {
    commit(ClearInjections)
    const response = await axios.get(`/api/getinjectionsbysiteid/${site_id}`)
    commit(SetInjections, response.data)
    return response
  }
}

export const injectionsMutations = {
  [SetInjections](state, injections) {
    state.injections = injections
  },
  [ClearInjections](state) {
    state.injections = []
  }
}
