import {
  GetSelectedCustomer,
  SaveSelectedCustomer,
  SaveSelectedCustomerFromSignUp,
  UpdateSelectedCustomer
} from '@/store/action_types'

import {
  SetSelectedCustomer,
  ClearSelectedCustomer
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedCustomerState = {
  selectedCustomer: {}
}

export const selectedCustomerGetters = {
  selectedCustomer: (state) => state.selectedCustomer
}

export const selectedCustomerActions = {
  async [GetSelectedCustomer]({ commit }, customerId) {
    const response = await axios.get('/api/customers/' + customerId)
    commit(SetSelectedCustomer, response.data)
    return response
  },
  async [SaveSelectedCustomer]({ dispatch }, customer) {
    if (customer.id) {
      return dispatch(UpdateSelectedCustomer, customer)
    }
    const response = await axios.post('/api/customers', customer)
    return response
  },

  async [SaveSelectedCustomerFromSignUp](customer) {
    const response = await axios.post('/api/newcustomer', customer)
    return response.data
  },
  async [UpdateSelectedCustomer](customer) {
    const response = await axios.put(`/api/customers/${customer.id}`, customer)
    //commit(SetSelectedCustomer, customer)
    return response
  }
}

export const selectedCustomerMutations = {
  [SetSelectedCustomer](state, customer) {
    state.selectedCustomer = customer
  },
  [ClearSelectedCustomer](state) {
    state.selectedCustomer = {}
  }
}
