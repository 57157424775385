<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :permanent="$vuetify.breakpoint.mdAndUp"
    app
  >
    <v-list dense>
      <template v-for="item in items">
        <v-list-group
          v-if="item.children"
          :key="item.text"
          :value="false"
          active-class="black--text"
        >
          <template v-slot:activator @click="Activate(item)">
            <v-list-item-icon
              style="margin-right: 12px; padding-bottom: 5px; color: #717171"
            >
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size: 12px; color: black"
              @click="Activate(item)"
            >
              {{ item.text }}
            </v-list-item-title>
          </template>
          <template v-for="child in item.children">
            <v-list-item
              :key="child.text"
              :value="true"
              :class="{ background: child.isActive }"
              @click="Activate(child)"
            >
              <template>
                <v-list-item-icon
                  style="
                    margin-left: 20px;
                    margin-right: 7px;
                    padding-bottom: 5px;
                  "
                >
                  <v-icon size="17">{{ child.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title style="font-size: 12px">
                  {{ child.text }}
                </v-list-item-title>
              </template>
            </v-list-item>
          </template>
        </v-list-group>
        <template v-else>
          <v-list-item
            v-if="item.text == $t('documentation')"
            :key="item.text"
            :class="{ background: item.isActive }"
            @click="Activate(item)"
          >
            <v-list-item-icon style="margin-right: 12px; padding-bottom: 5px">
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 12px">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            :key="item.text"
            :class="{ background: item.isActive }"
            @click="Activate(item)"
          >
            <v-list-item-icon style="margin-right: 12px; padding-bottom: 5px">
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 12px">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { GetSites, GetSelectedCustomer } from "@/store/action_types";

export default {
  props: {
    parentDrawer: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showBar: true,
      loader: null,
      loading: false,
      city: "",
      zip: "",
      customer: {
        name: "",
        code: "",
        country: this.$store.state.appCustomer.country,
      },
      cbCGU: false,
      cbCGC: false,
      errors: [],
      valid: true,
      drawer: false,
      isActive: false,
      checkedU: [
        (v) =>
          this.cbCGU == true ||
          "Please read and accept the terms and conditions",
      ],
      checkedV: [
        (v) =>
          this.cbCGC == true ||
          "Please read and accept the terms and conditions",
      ],
      confirmRegistration: false,
      tmp_items_superadmin: [
        {
          icon: "mdi-account-tie",
          text: this.$t("customers"),
          name: "customer_list",
          allow_user: false,
          allow_admin: false,
          allow_superadmin: true,
        },
        {
          icon: "mdi-table-cog",
          text: this.$t("configuration"),
          allow_user: false,
          allow_admin: false,
          allow_superadmin: true,
          children: [
            {
              icon: "mdi-folder-outline",
              text: this.$t("prebid booster"),
              name: "new_sites_adapter",
              allow_user: false,
              allow_admin: false,
              allow_superadmin: true,
              isActive: false,
            },
            {
              icon: "mdi-folder-outline",
              text: this.$t("stack nexx360"),
              name: "new_sites_fast",
              allow_user: true,
              allow_admin: true,
              allow_superadmin: true,
              isActive: false,
            },
            {
              icon: "mdi-folder-outline",
              text: this.$t("amp nexx360"),
              name: "new_sites_amp",
              allow_user: true,
              allow_admin: true,
              allow_superadmin: true,
              isActive: false,
            },
          ],
        },
        {
          icon: "mdi-file-document-edit-outline",
          text: this.$t("proposals"),
          name: "proposal_list",
          allow_user: false,
          allow_admin: false,
          allow_superadmin: true,
        },
        {
          icon: "mdi-help-circle-outline",
          text: this.$t("documentation"),
          allow_user: true,
          allow_admin: true,
          allow_superadmin: true,
          isActive: false,
        },
      ],

      tmp_items: [
        {
          icon: "mdi-table-cog",
          text: this.$t("configuration"),
          allow_user: true,
          allow_admin: true,
          allow_superadmin: false,
          children: [
            {
              icon: "mdi-folder-outline",
              text: this.$t("prebid booster"),
              name: "new_sites_adapter",
              allow_user: true,
              allow_admin: true,
              allow_superadmin: false,
              isActive: false,
            },
            {
              icon: "mdi-folder-outline",
              text: this.$t("stack nexx360"),
              name: "new_sites_fast",
              allow_user: true,
              allow_admin: true,
              allow_superadmin: false,
              isActive: false,
            },
            {
              icon: "mdi-folder-outline",
              text: this.$t("amp nexx360"),
              name: "new_sites_amp",
              allow_user: true,
              allow_admin: true,
              allow_superadmin: false,
              isActive: false,
            },
          ],
        },
        {
          icon: "mdi-help-circle-outline",
          text: this.$t("documentation"),
          allow_user: true,
          allow_admin: true,
          allow_superadmin: false,
          isActive: false,
        },
        {
          icon: "mdi-email-outline",
          text: this.$t("contact"),
          name: "contact",
          allow_user: true,
          allow_admin: true,
          allow_superadmin: false,
          isActive: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["sites", "appCustomer", "selectedCustomer"]),
    computedSites() {
      return this.sites
        .filter((el) => {
          return this.archived ? true : el.is_archived == 0;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    items() {
      if (this.$store.getters.appUser != undefined) {
        const allow_type = "allow_" + this.$store.getters.appUser.role;
        const adapter_is_active =
          this.$store.getters.appCustomer.adapter_is_active;
        var items = this.$store.getters.is_superadmin
          ? this.tmp_items_superadmin
          : this.tmp_items;
        var arraytoreturn = [];
        var cust =
          this.selectedCustomer.id == undefined
            ? this.$store.getters.appCustomer
            : this.selectedCustomer;

        items.forEach((item) => {
          if (
            item.children == undefined ||
            item.text != "Configuration" ||
            this.$store.getters.is_superadmin
          )
            arraytoreturn.push(item);
          else {
            var childs = [];
            item.children.forEach((child) => {
              if (child.name == "new_sites_adapter") {
                if (cust.adapter_is_active == true) {
                  childs.push(child);
                }
              } else if (child.name == "new_sites_fast") {
                if (cust.web_is_active == true) {
                  childs.push(child);
                }
              } else if (child.name == "new_sites_amp") {
                if (cust.amp_is_active == true) {
                  childs.push(child);
                }
              }
            });
            item.children = childs;
            arraytoreturn.push(item);
            childs = [];
          }
        });
        return arraytoreturn;
      }
      return false;
    },
  },
  watch: {
    parentDrawer() {
      this.drawer = this.parentDrawer;
    },
    drawer() {
      if (this.drawer == false) this.$emit("closed");
    },
    appCustomer() {
      this.GetSites(this.appCustomer.id);
    },
    "$route.name": async function (val) {
      if (val == "account" || val == "customer_list") {
        this.items.forEach((element) => {
          if (element.children)
            element.children.forEach((son) => {
              son.isActive = false;
            });
          else element.isActive = false;
        });
      }
    },
  },
  async created() {
    this.drawer = this.parentDrawer;
    if (this.$route.name == "home") this.showBar = false;
    this.showBar = true;
  },
  methods: {
    ...mapActions({ GetSites, GetSelectedCustomer }),
    Activate(item) {
      this.items.forEach((element) => {
        if (element.children)
          element.children.forEach((son) => {
            son.isActive = false;
          });
        else element.isActive = false;
      });
      item.isActive = true;
      let siteinurl = "";

      if (this.computedSites.length == 0) siteinurl = "0";
      else siteinurl = this.computedSites[0].id;

      if (item.text == this.$t("documentation"))
        window.open("https://developer.nexx360.io/", "_blank");
      else if (item.name == "new_sites_amp") {
        if (item.text == this.$t("nexx AMP")) item.children[0].isActive = true;
        var customerid = this.$store.state.appCustomer.id;
        if (this.$route.params.customer_id != undefined) {
          if (
            this.$store.getters.is_superadmin &&
            this.$store.state.appCustomer.id != this.$route.params.customer_id
          ) {
            customerid = this.$route.params.customer_id;
          }
        }
        this.$router
          .push({
            name: "new_sites",
            params: {
              customer_id: customerid,
              type: "amp",
            },
          })
          .catch(() => {});
      } else if (item.name == "new_sites_fast") {
        if (item.text == this.$t("nexx Web")) item.children[0].isActive = true;
        var customerid = this.$store.state.appCustomer.id;
        if (this.$route.params.customer_id != undefined) {
          if (
            this.$store.getters.is_superadmin &&
            this.$store.state.appCustomer.id != this.$route.params.customer_id
          ) {
            customerid = this.$route.params.customer_id;
          }
        }
        this.$router
          .push({
            name: "new_sites",
            params: {
              customer_id: customerid,
              type: "fast",
            },
          })
          .catch(() => {});
      } else if (item.name == "new_sites_adapter") {
        if (item.text == this.$t("nexx Adapter"))
          item.children[0].isActive = true;
        var customerid = this.$store.state.appCustomer.id;
        if (this.$route.params.customer_id != undefined) {
          if (
            this.$store.getters.is_superadmin &&
            this.$store.state.appCustomer.id != this.$route.params.customer_id
          ) {
            customerid = this.$route.params.customer_id;
          }
        }
        this.$router
          .push({
            name: "new_sites",
            params: {
              customer_id: customerid,
              type: "adapter",
            },
          })
          .catch(() => {});
      } else if (item.name == "new_storedrequests_amp")
        this.$router
          .push({
            name: "new_storedrequests",
            params: {
              site_id: "0",
              type: "amp",
            },
          })
          .catch(() => {});
      else if (item.name == "new_storedrequests_fast")
        this.$router
          .push({
            name: "new_storedrequests",
            params: {
              site_id: "0",
              type: "fast",
            },
          })
          .catch(() => {});
      else if (item.name == "new_storedrequests_adapter")
        this.$router
          .push({
            name: "new_storedrequests",
            params: {
              site_id: "0",
              type: "adapter",
            },
          })
          .catch(() => {});
      else if (item.name == "injections")
        this.$router
          .push({
            name: "injections",
            params: {
              site_id: "0",
              type: "fast",
            },
          })
          .catch(() => {});
      else this.$router.push({ name: item.name }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.background {
  background: #ffc170;
}
</style>
