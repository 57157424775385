import { appCustomerState } from '@/store/items/appCustomer'
import { appUserState } from '@/store/items/appUser'
import { onboardingUserState } from '@/store/items/onboardingUser'

import { usersState } from '@/store/items/users'
import { selectedUserState } from '@/store/items/selectedUser'

import { customersState } from '@/store/items/customers'
import { selectedCustomerState } from '@/store/items/selectedCustomer'

import { proposalsState } from '@/store/items/proposals'
import { selectedProposalState } from '@/store/items/selectedProposal'

import { dealsState } from '@/store/items/deals'
import { selectedDealState } from '@/store/items/selectedDeal'

import { gamsetupsState } from '@/store/items/gamsetups'

import { optionscookielessState } from '@/store/items/optionscookieless'
import { selectedOptionsCookielessState } from '@/store/items/selectedOptionsCookieless'

import { storedRequestsState } from '@/store/items/storedRequests'
import { selectedStoredRequestState } from '@/store/items/selectedStoredRequest'

import { injectionsState } from '@/store/items/injections'
import { selectedInjectionState } from '@/store/items/selectedInjection'

import { sitesState } from '@/store/items/sites'

import { selectedSiteState } from './items/selectedSite'

const state = {
  ...appUserState,
  ...onboardingUserState,
  ...appCustomerState,
  ...usersState,
  ...selectedUserState,
  ...customersState,
  ...selectedCustomerState,
  ...proposalsState,
  ...selectedProposalState,
  ...dealsState,
  ...selectedDealState,
  ...gamsetupsState,
  ...optionscookielessState,
  ...selectedOptionsCookielessState,
  ...storedRequestsState,
  ...injectionsState,
  ...selectedStoredRequestState,
  ...selectedInjectionState,
  ...sitesState,
  ...selectedSiteState,
  loading: false,
  appGroups: ['user', 'admin', 'superadmin'],
  currencies: ['EUR', 'USD'],
  errors: []
}

export default state
