<template>
  <v-container fluid fill-height style="padding: 0px; margin: 0px">
    <template v-for="(value, key) in ids">
      <v-row v-if="key.match('is_active')" :key="key">
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="2" style="padding-right: 0px">
          <p style="margin-top: 10px; font-size: 17px; font-weight: bold">
            {{ $t(ssp) }}
          </p>
        </v-col>
        <v-col cols="10" style="padding-left: 0px">
          <v-btn-toggle
            v-model="ids[key]"
            mandatory
            @change="resetActiveButton(key, ids[key])"
          >
            <v-btn
              style="margin: 0px !important"
              class="mx-2"
              :active-class="optionsColor[0]"
              depressed
              rounded
            >
              Disabled
            </v-btn>
            <v-btn
              style="margin: 0px !important"
              class="mx-2"
              :active-class="optionsColor[1]"
              depressed
              rounded
            >
              Active
            </v-btn>
            <v-btn
              style="margin: 0px !important"
              class="mx-2"
              :active-class="optionsColor[2]"
              depressed
              rounded
            >
              Paused
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!--<v-col v-if="key.match('is_active')" :key="key" cols="12">
          <v-tooltip
            v-if="key.match('smartadserver_is_active')"
            bottom
            color="red"
          >
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-checkbox
                  v-model="ids[key]"
                  :label="$t(ssp)"
                  color="black"
                  v-on="on"
                  @change="
                    UpdateStatus(ids[key])
                    $emit('update-ssp', { key: key, value: ids[key] })
                  "
                ></v-checkbox>
              </span>
            </template>
            <span
              >You need to contact Smartadserver to ask them to activate
              Server-side !</span
            >
          </v-tooltip>
        </v-col>-->
      <v-col v-else :key="key" style="max-width: 25%">
        <v-text-field
          v-if="Object.keys(ids).length == 2"
          v-model="ids[key]"
          dense
          solo
          style="
            margin-top: 10px;
            margin-left: 20px;
            width: 300px;
            font-size: 14px;
          "
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          :label="$t(key)"
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1
              ? required
              : []
          "
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 3"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            key != 'smilewanted_position_type' &&
            key != 'ix_endpoint' &&
            key != 'goodad_member'
              ? required
              : []
          "
          style="
            margin-top: 10px;
            margin-left: 20px;
            width: 300px;
            font-size: 14px;
          "
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          :label="$t(key)"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 4"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            key != 'pubmatic_ad_slot' &&
            key != 'pubmatic_placement_id'
              ? required
              : []
          "
          style="
            margin-top: 10px;
            margin-left: 20px;
            width: 290px;
            font-size: 14px;
          "
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          :label="$t(key)"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 5"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            key != 'rubicon_position'
              ? required
              : []
          "
          style="
            margin-top: 10px;
            margin-left: 20px;
            width: 212px;
            font-size: 14px;
          "
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          :label="$t(key)"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
      </v-col>
    </template>
  </v-container>
</template>

<script>
  export default {
    props: {
      ssp: {
        type: String,
        default: () => ''
      },
      ids: {
        type: Object,
        default: () => {}
      }
    },
    data: () => {
      return {
        status: true,
        init: true,
        val: 0,
        dis: false,
        required: [(v) => !!v || 'Required'],
        optionsColor: [
          'red white--text',
          'green white--text',
          'orange white--text'
        ]
      }
    },
    computed: {},
    watch: {
      test: async function (val, oldVal) {}
    },
    created() {},

    methods: {
      resetActiveButton(key, value) {
        this.$nextTick(() => {
          console.log(key + '--->', value)
          if (value != 1) this.dis = true
          else this.dis = false
          this.$emit('update-ssp', { key: key, value: value })
        })
      },
      show(key, value) {
        console.log(key + ' ====== ' + value)
      },
      UpdateStatus(item) {
        this.status = !item
        return item
      }
    }
  }
</script>

<style lang="scss">
  .ssp_row {
    margin-left: 10px;
  }
  .positionCb {
    margin-top: 10px;
    margin-left: 50px;
  }
</style>
