import {
  GetSelectedInjection,
  UpdateSelectedInjection,
  SaveSelectedInjection
} from '@/store/action_types'

import {
  SetSelectedInjection,
  ClearSelectedInjection
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedInjectionState = {
  selectedInjection: []
}

export const selectedInjectionGetters = {
  SelectedInjection: (state) => state.selectedInjection
}

export const selectedInjectionActions = {
  async [GetSelectedInjection]({ commit }, injection_id) {
    if (injection_id === 'new') return
    const response = await axios.get(`/api/injection/${injection_id}`)
    commit(SetSelectedInjection, response.data)
    return response
  },
  async [UpdateSelectedInjection]({ commit }, injection) {
    const response = await axios.put(
      `/api/injection/${injection.id}`,
      injection
    )
    commit(SetSelectedInjection, injection)
    return response
  },
  async [SaveSelectedInjection]({ dispatch, commit }, injection) {
    if (injection.id != undefined && injection.id.length > 0) {
      return dispatch(UpdateSelectedInjection, injection)
    }

    const response = await axios.post('/api/injection', injection)

    commit(SetSelectedInjection, response.data)
    return response
  }
}

export const selectedInjectionMutations = {
  [SetSelectedInjection](state, injection) {
    state.selectedInjection = injection
  },
  [ClearSelectedInjection](state) {
    Object.assign(state.selectedInjection, [])
  }
}
