import Vue from "vue";
import Router from "vue-router";
import { OktaAuth, TokenManager } from "@okta/okta-auth-js";
import { LoginCallback } from "@okta/okta-vue";
import OktaVue from "@okta/okta-vue";

import login from "@/pages/login/index.vue";
import register from "@/pages/login/contactregister.vue";

import index from "@/pages/index.vue";

import account from "@/pages/account/index.vue";
import user_edit from "@/pages/account/edit.vue";

import customer_list from "@/pages/customer/index.vue";
import customer_edit from "@/pages/customer/edit.vue";

import sitesHome from "@/pages/amp/index.vue";
import siteAction from "@/pages/amp/index.vue";

import new_sites from "@/pages/amp/newSites.vue";
import new_sites_new from "@/pages/amp/newSitesEdit.vue";
import new_sites_edit from "@/pages/amp/newSitesEdit.vue";
//import new_sites_update from '@/pages/amp/newSites.vue'
import new_storedrequests from "@/pages/amp/newSR.vue";
import stored_request_new from "@/pages/management/storedRequest/index.vue";
import stored_request_edit from "@/pages/management/storedRequest/index.vue";
import stored_request_by_tool from "@/pages/management/storedRequest/index.vue";

import deal_list from '@/pages/management/deal/deal/index.vue'
import deal_management from '@/pages/management/deal/deal/edit.vue'
import deal_rules from '@/pages/management/deal/deal/dealRules.vue'

import proposal_list from '@/pages/management/deal/proposal/index.vue'
import proposal_management from '@/pages/management/deal/proposal/edit.vue'

import injections from "@/pages/amp/injectionList.vue";
import injection_new from "@/pages/amp/InjectionEdit.vue";
import injection_edit from "@/pages/amp/InjectionEdit.vue";

import pbs_reporting from "@/pages/pbs-reporting/index.vue";
import pbs_reporting_filters from "@/pages/pbs-reporting/index.vue";
import billing_reporting from "@/pages/facturation/reporting.vue";
import billing_reporting_filters from "@/pages/facturation/reporting.vue";
import consentless_reporting from "@/pages/consentless-reporting/index.vue";
import customer_reporting from "@/pages/customer-reporting/index.vue";
import customer_reporting_filters from "@/pages/customer-reporting/index.vue";

import GamSetup from "@/pages/gamSetup/index.vue";
import GamSetup_edit from "@/pages/gamSetup/edit.vue";

import home from "@/pages/home.vue";
import contact from "@/pages/support/contact.vue";

import cookieless from "@/pages/cookieless/index.vue";
import optionscookieless_new from "@/pages/cookieless/edit.vue";
import optionscookieless_edit from "@/pages/cookieless/edit.vue";
import web from "@/pages/web/index.vue";

import dashboard from "@/pages/dashboard/index.vue";

import axios from "axios";

import tag_management_sites from "@/pages/tag_management/sites/index.vue";
import tag_management_sites_edit from "@/pages/tag_management/sites/edit.vue";
import tag_management_pages from "@/pages/tag_management/pages/index.vue";
import tag_management_pages_edit from "@/pages/tag_management/pages/PageDetails.vue";
import tag_management_page_general from "@/pages/tag_management/pages/general/index.vue";
import tag_management_page_placements from "@/pages/tag_management/pages/placements/index.vue";
import tag_management_page_tag from "@/pages/tag_management/pages/tag/index.vue";

import tag_management_page_placements_DisplayList from "@/pages/tag_management/pages/placements/list/index.vue";
import tag_management_page_placements_PageTypeParameters from "@/pages/tag_management/pages/placements/parameters/index.vue";

import { GetProfile } from "@/store/action_types";
import store from "@/store";
import config from "@/config";


const onAuthRequired = async (from, to, next) => {
  console.log('aaaaa')

  const isAuthenticated = await Vue.prototype.$auth.isAuthenticated();
  if (!isAuthenticated) {

    if (from.name === 'login') next();
    router.push('/login');
    // Navigate to custom login page and login
    // router.push('/login');
  } else {
    const idToken = await Vue.prototype.$auth.getIdToken()
    const accessToken = await Vue.prototype.$auth.getAccessToken()
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    let user = await Vue.prototype.$auth.getUser()
    await store.dispatch(GetProfile, user)
    next();
  }
};


const oktaAuth = new OktaAuth(config.oidc);
Vue.use(Router);
Vue.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    router.push('/login')
  }
});

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: login,
      name: "login",
    },
    {
      path: "/register",
      name: "register",
      component: register,
    },
    {
      path: "/login/callback",
      component: LoginCallback,
    },
    {
      path: "/",
      component: index,
      meta: { requiresAuth: true },
      children: [
        { path: "myaccount", component: account, name: "account" },
        {
          path: "users/new",
          component: user_edit,
          name: "user_new",
        },
        {
          path: "customers/:customer_id/users/new",
          component: user_edit,
          name: "newuser_bycustomer",
        },
        {
          path: "customers/:customer_id/users/:userId",
          component: user_edit,
          name: "edituser_bycustomer",
        },
        {
          path: "users/:userId",
          component: user_edit,
          name: "user_edit",
        },
        { path: "customers", component: customer_list, name: "customer_list" },
        {
          path: "customers/new",
          component: customer_edit,
          name: "customer_new",
        },
        {
          path: "customers/:customer_id",
          component: customer_edit,
          name: "customer_edit",
        },
        {
          path: "",
          alias: "management",
          component: sitesHome,
          name: "Home",
        },
        { path: '/proposal/:proposal_id/deals', component: deal_list, name: 'deal_list' },
        { path: 'proposal/:proposal_id/deals/new', component: deal_management, name: 'deal_new' },
        { path: 'proposal/:proposal_id/deals/:deal_id', component: deal_rules, name: 'deal_edit' },

        { path: 'proposals', component: proposal_list, name: 'proposal_list' },
        { path: 'proposals/new', component: proposal_management, name: 'proposal_new' },
        { path: 'proposals/:proposal_id', component: proposal_management, name: 'proposal_edit' },
        /* {
          path: 'management/amp/:customer_id/:site_id',
          component: sitesHome,
          name: 'sitesHome',
          props: true
        },*/
        {
          path: "management/:type/:customer_id/sites",
          component: new_sites,
          name: "new_sites",
          props: true,
        },
        /*{
          path: 'management/:type/:customer_id/sites/:site_id',
          component: new_sites_update,
          name: 'new_sites_update',
          props: true
        },*/
        {
          path: "management/:type/:customer_id/sites/new",
          component: new_sites_new,
          name: "new_sites_new",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/update",
          component: new_sites_edit,
          name: "new_sites_edit",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/storedrequests",
          component: new_storedrequests,
          name: "new_storedrequests",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/storedrequests/new",
          component: stored_request_new,
          name: "stored_request_new",
          props: true,
        },
        {
          path: "management/fast/:stored_request_id",
          component: stored_request_by_tool,
          name: "stored_request_by_tool",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/storedrequests/:stored_request_id",
          component: stored_request_edit,
          name: "stored_request_edit",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/injections",
          component: injections,
          name: "injections",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/injections/new",
          component: injection_new,
          name: "injection_new",
          props: true,
        },
        {
          path: "management/:type/:customer_id/sites/:site_id/injections/:injection_id",
          component: injection_edit,
          name: "injection_edit",
          props: true,
        },
        {
          path: "management/:type/:parameter",
          component: siteAction,
          name: "siteAction",
          props: true,
        },
        {
          path: "nexx-reporting",
          component: pbs_reporting,
          name: "pbs_reporting",
          props: true,
        },
        {
          path: "nexx-reporting/filters/:customer_id",
          component: pbs_reporting_filters,
          name: "pbs_reporting_filters",
          props: true,
        },
        {
          path: "billing-reporting",
          component: billing_reporting,
          name: "billing_reporting",
          props: true,
        },
        {
          path: "billing-reporting/filters/:customer_id",
          component: billing_reporting_filters,
          name: "billing_reporting_filters",
          props: true,
        },
        {
          path: "consentless-reporting",
          component: consentless_reporting,
          name: "consentless_reporting",
          props: true,
        },
        {
          path: "customer-reporting",
          component: customer_reporting,
          name: "customer_reporting",
          props: true,
        },
        {
          path: "customer-reporting/filters/:customer_id",
          component: customer_reporting_filters,
          name: "customer_reporting_filters",
          props: true,
        },
        {
          path: "gamSetup",
          component: GamSetup,
          name: "GamSetup",
          props: true,
        },
        {
          path: "gamSetup/new",
          component: GamSetup_edit,
          name: "gamsetup_new",
        },
        {
          path: "gamSetup/:gamSetup_id",
          component: GamSetup_edit,
          name: "gamsetup_edit",
        },
        {
          path: "/home",
          component: home,
          name: "home",
        },
        {
          path: "/contact",
          component: contact,
          name: "contact",
        },
        {
          path: "/cookieless",
          component: cookieless,
          name: "cookieless",
        },
        {
          path: ":site_id/optionscookieless/new",
          component: optionscookieless_new,
          name: "optionscookieless_new",
        },
        {
          path: ":site_id/optionscookieless/:oc_id",
          component: optionscookieless_edit,
          name: "optionscookieless_edit",
        },
        {
          path: "/web",
          component: web,
          name: "web",
        },
        {
          path: "/dashboard",
          component: dashboard,
          name: "dashboard",
        },
        /* {
          path: 'management/fast/:customer_id/:site_id',
          component: fast,
          name: 'fast'
        },*/
        {
          path: "tag_management/sites",
          component: tag_management_sites,
          name: "tag_management_sites",
        },
        {
          path: "tag_management/sites/:site_id",
          component: tag_management_sites_edit,
          name: "tag_management_sites_edit",
        },
        {
          path: "tag_management/sites/:site_id/pages",
          component: tag_management_pages,
          name: "tag_management_pages",
        },
        {
          path: "tag_management/sites/:site_id/pages/:page_id",
          component: tag_management_pages_edit,
          children: [
            {
              path: "",
              component: tag_management_page_general,
              name: "tag_management_pageDetails",
            },
            {
              path: "general",
              component: tag_management_page_general,
              name: "tag_management_page_general",
            },
            {
              path: "tag",
              component: tag_management_page_tag,
              name: "tag_management_page_tag",
            },
            {
              path: "placements",
              component: tag_management_page_placements,
              children: [
                {
                  path: "",
                  component: tag_management_page_placements_DisplayList,
                  name: "tag_management_page_placements",
                },
                {
                  path: "list",
                  component: tag_management_page_placements_DisplayList,
                  name: "tag_management_page_placements_DisplayList",
                },
                {
                  path: "parameters",
                  component: tag_management_page_placements_PageTypeParameters,
                  name: "tag_management_page_placements_PageTypeParameters",
                },
              ],
            },
          ],
        },
      ],
    },

    { path: "*", component: sitesHome },
  ],
});

const getProfile = async (from, to, next) => {
  // if (to.name === 'login' || from.name === 'login') next();
  const isAuthenticated = await Vue.prototype.$auth.isAuthenticated();
  const idToken = await Vue.prototype.$auth.getIdToken()
  const accessToken = await Vue.prototype.$auth.getAccessToken()
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  let isAuthentified = !!(idToken && accessToken)
  console.log(isAuthentified && !store.state.appUser.id)

  if (isAuthentified && !store.state.appUser.id) {
    let user = await Vue.prototype.$auth.getUser()
    await store.dispatch(GetProfile, user)
    next();
  } else {
    next();
  }
};
router.beforeEach(getProfile);
export default router;
