<template>
  <v-container>
    <v-row>
      <v-col style="padding-left: 30px">
        <v-container>
          <v-row>
            <v-col cols="12" class="noPadding">
              <p style="color: black; font-size: 15px">
                {{ $t('explaining web first line') }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="noPadding">
              <p style="color: black; font-size: 15px">
                {{ $t('available_prebid_serve_bidders') }} :
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              style="margin-left: 50px; margin-top: 10px"
              class="noPadding"
            >
              <v-row style="padding: 0px; margin: 0px">
                <v-col
                  v-for="item in ssps"
                  :key="item.text"
                  cols="6"
                  style="padding: 0px; margin: 0px"
                >
                  <v-checkbox
                    :key="item.text"
                    v-model="item.enabled"
                    style="padding: 0px; margin: 0px"
                    :label="item.text"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" justify="center">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                dense
                @click="Push()"
              >
                {{ $t('generate code') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CookielessContent',
    components: {},
    data() {
      return {
        sspsEnabled: [],
        codeGenerated: '',
        ssps: [
          { text: this.$t('appnexus'), enabled: false },
          { text: this.$t('criteo'), enabled: false },
          { text: this.$t('openx'), enabled: false },
          { text: this.$t('pubmatic'), enabled: false },
          { text: this.$t('smartadserver'), enabled: false },
          { text: this.$t('triplelift'), enabled: false }
        ]
      }
    },
    computed: {
      ...mapGetters(['appCustomer'])
    },
    watch: {
      appCustomer() {
        this.ssps.forEach((element) => {
          element.enabled = false
        })
      }
    },
    created() {},
    methods: {
      Push() {
        this.ssps.forEach((element) => {
          if (element.enabled == true)
            this.sspsEnabled.push(element.text.toLowerCase())
        })
        if (this.sspsEnabled.length > 0)
          this.$emit('generateCode', this.sspsEnabled)
        this.sspsEnabled = []
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .noPadding {
    padding: 0px 0px 0px;
  }
</style>
