module.exports = [
  {
    id: 'Android Browser',
    label: 'Android Browser'
  },
  {
    id: 'Chrome',
    label: 'Chrome'
  },
  {
    id: 'Chrome WebView',
    label: 'Chrome WebView'
  },
  {
    id: 'Edge',
    label: 'Edge'
  },
  {
    id: 'Facebook',
    label: 'Facebook'
  },
  {
    id: 'Firefox',
    label: 'Firefox'
  },
  {
    id: 'GSA',
    label: 'GSA'
  },
  {
    id: 'Instagram',
    label: 'Instagram'
  },
  {
    id: 'Iron',
    label: 'Iron'
  },
  {
    id: 'Maxthon',
    label: 'Maxthon'
  },
  {
    id: 'MIUI Browser',
    label: 'MIUI Browser'
  },
  {
    id: 'Mobile Safari',
    label: 'Mobile Safari'
  },
  {
    id: 'Opera',
    label: 'Opera'
  },
  {
    id: 'Opera Touch',
    label: 'Opera Touch'
  },
  {
    id: 'Safari',
    label: 'Safari'
  },
  {
    id: 'Samsung Browser',
    label: 'Samsung Browser'
  },
  {
    id: 'Silk',
    label: 'Silk'
  },
  {
    id: 'Tesla',
    label: 'Tesla'
  },
  {
    id: 'UCBrowser',
    label: 'UCBrowser'
  },
  {
    id: 'WebKit',
    label: 'WebKit'
  },
  {
    id: 'Other',
    label: 'Other'
  },
]
