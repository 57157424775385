<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="back(), $emit('hideDialog')"
  >
    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="back(), $emit('hideDialog')"
            >mdi-close</v-icon
          >
        </v-col>
        <v-card-text>
          <v-container>
            <br />
            <v-row>
              <v-col cols="12">
                <h2 style="color: black">{{ TitleDialog }}</h2>
              </v-col>
              <br />
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-form ref="form">
                  <p>{{ $t('website_name') }}</p>
                  <v-text-field
                    v-model="site.name"
                    dense
                    solo
                    :rules="nameRules"
                    required
                  >
                  </v-text-field
                ></v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-col cols="12" align="center">
            <v-btn
              width="320"
              style="height: 40px"
              class="white--text"
              color="black"
              @click="Emit()"
            >
              {{ btnText }}
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      },
      actionType: {
        default: '',
        type: String
      }
    },
    data() {
      return {
        dialog: false,
        site: {
          name: ''
        },
        TitleDialog: this.$t('update_site'),
        nameRules: [(v) => !!v || this.$t('name is required')],
        submitLoading: false,
        errors: [],
        btnText: this.$t('update')
      }
    },
    computed: {
      ...mapGetters(['selectedSite', 'appCustomer'])
    },
    watch: {
      parentDialog() {
        this.dialog = this.parentDialog

        if (this.actionType == 'update') {
          this.EditActions()
        }
        if (this.actionType == 'add') {
          this.AddActions()
        }
      }
    },
    async created() {
      this.site.appCustomer = this.appCustomer.id
    },
    methods: {
      EditActions() {
        this.TitleDialog = this.$t('update_site')
        this.btnText = this.$t('update')
        this.site = Object.assign({}, this.selectedSite)
      },
      AddActions() {
        this.TitleDialog = this.$t('add_site')
        this.btnText = this.$t('add')
        if (this.site.name != '') this.$refs.form.reset()
        this.site = {
          name: ''
        }
      },
      back() {
        this.$router.push({
          name: 'new_sites',
          params: {
            type: this.$route.params.type,
            customer_id: this.appCustomer.id
          }
        })
      },

      Emit() {
        this.$emit('submitted', this.site.name, this.actionType)
      }
    }
  }
</script>
