module.exports = [
  {
    id: 'ConnectedTV',
    label: 'ConnectedTV'
  },
  {
    id: 'Mobile',
    label: 'Mobile'
  },
  {
    id: 'OutOfHome',
    label: 'OutOfHome'
  },
  {
    id: 'PC',
    label: 'PC'
  },
  {
    id: 'Tablet',
    label: 'Tablet'
  },
  {
    id: 'Other',
    label: 'Other'
  },

]