<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="black--text notcapital"
        tile
        width="200"
        height="32"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left> mdi-plus </v-icon>
        Add page
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="dialog = false">mdi-close</v-icon>
        </v-col>
        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <br />
            <v-row>
              <v-col cols="12">
                <h2 style="color: black">Add page</h2>
              </v-col>
              <br />
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-form ref="form">
                  <p style="margin-bottom: 5px">Page name</p>
                  <v-text-field v-model="page.name" dense solo required>
                  </v-text-field
                ></v-form>
              </v-col>
            </v-row>
            <v-row v-if="pageAdded">
              <v-col>
                <v-alert dense text type="success" color="green">
                  Page added successfully</v-alert
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-col cols="12" align="center">
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              width="320"
              style="height: 40px"
              class="white--text"
              color="black"
              @click="createPage"
            >
              Save
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        dialog: false,
        pageName: '',
        pageAdded: false,
        submitLoading: false,
        page: {
          name: '',
          siteId: this.$route.params.site_id
        }
      }
    },
    methods: {
      async createPage() {
        this.submitLoading = true
        await axios.post(`/api/wpages`, this.page)
        this.pageAdded = true
        setTimeout(() => (this.pageAdded = false), 2000)
        this.$emit('pageAdded')
        this.submitLoading = false
        setTimeout(() => (this.dialog = false), 500)
      }
    }
  }
</script>
