<template>
  <v-textarea
    v-model="codeGenerated"
    outlined
    filled
    auto-grow
    :readonly="true"
    :loading="loading"
    :value="content"
    class="tag"
    background-color="white"
  />
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['page'],
    data() {
      return {
        content: '',
        loading: false,
        codeGenerated: ''
      }
    },
    async created() {
      const generateTag = await axios.get(
        `/api/wtagpage/${this.$route.params.page_id}`
      )
      this.codeGenerated = generateTag.data
      //this.submit()
    },
    methods: {
      async submit() {
        this.loading = true
        const request = {
          type: 'management/page',
          action: 'getTag',
          data: this.page
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          console.log(response)
          if (response.data) {
            this.content = response.data
            this.loading = false
          }
        } catch (e) {
          this.$router.push('/login')
          console.error(e)
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped>
  .tag {
    margin: 10px;
  }
</style>
