import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import i18n from '@/translations'

import store from '@/store'

import _ from 'lodash'
Object.defineProperty(Vue.prototype, '$_', { value: _ })

new Vue({
  el: '#app',
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App)
})
