<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title> Deal {{ selectedDeal.name }} </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>
                  {{ $t('deal_name') }}
                </p>
                <v-text-field
                  ref="Name"
                  v-model="deal.name"
                  dense
                  solo
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nopadding">
              <v-col class="nopadding" cols="12">
                <div>
                  <v-row>
                    <v-col class="nopadding" cols="4">
                      <p style="margin-bottom: 5px">Floor</p>
                    </v-col>
                    <v-col class="nopadding" cols="6">
                      <p style="margin-bottom: 5px">Auction type</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="nopadding" cols="2">
                      <v-text-field
                        v-model="deal.rate_amount"
                        placeholder="0"
                        :rules="cpmRules"
                        type="number"
                        dense
                        solo
                        outlined
                        text
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="nopadding" cols="2"
                      ><v-select
                        v-model="deal.rate_currency"
                        :items="currencies"
                        solo
                        dense
                        outlined
                      >
                      </v-select
                    ></v-col>
                    <v-col class="nopadding" cols="3"
                      ><v-select
                        v-model="deal.auction_type"
                        :items="price_behavior_choices"
                        item-text="name"
                        item-value="value"
                        solo
                        dense
                        outlined
                      >
                      </v-select
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="nopadding">
                      <v-row>
                        <v-col class="nopadding" cols="2">
                          <p style="margin-bottom: 5px">Start date</p>
                        </v-col>
                        <v-col
                          v-if="no_end_date == false"
                          class="nopadding"
                          cols="2"
                        >
                          <p style="margin-bottom: 5px">End date</p>
                        </v-col>
                        <v-col class="nopadding" cols="2" />
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="2">
                          <input
                            v-model="start_time"
                            id="dealdatefirst"
                            style="
                              border: 1px solid black;
                              border-radius: 5px;
                              padding: 5px;
                              box-shadow: 1px 1px 2px 2px lightgray;
                            "
                            type="datetime-local"
                            :min="min_start_date"
                          />
                        </v-col>
                        <v-col
                          v-if="no_end_date == false"
                          class="nopadding"
                          cols="2"
                        >
                          <input
                            v-model="end_time"
                            style="
                              border: 1px solid black;
                              border-radius: 5px;
                              padding: 5px;
                              box-shadow: 1px 1px 2px 2px lightgray;
                            "
                            type="datetime-local"
                            id="dealdateend"
                            :min="min_end_date"
                          />
                        </v-col>
                        <v-col class="nopadding" cols="2">
                          <v-checkbox
                            v-model="no_end_date"
                            style="margin-top: 3px; padding-left: 10px"
                            label="No End Date"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-divider style="margin-top: 15px"></v-divider>
                      <v-row class="nopadding">
                        <v-col class="nopadding" cols="12">
                          <p style="margin: 10px 0px; font-size: 18px">
                            <strong>Targeting</strong>
                          </p></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="3">
                          <p style="margin-bottom: 5px">Inventory type</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="3"
                          ><v-select
                            v-model="deal.creative_format"
                            :items="inventory_type_choices"
                            item-text="name"
                            item-value="value"
                            solo
                            dense
                            outlined
                          >
                          </v-select
                        ></v-col>
                        <!--  <v-col class="nopadding" cols="5"
                          ><treeselect
                            :multiple="true"
                            :options="size_choices"
                            :disable-branch-nodes="true"
                            v-model="deal.size"
                            search-nested
                        /></v-col>-->
                      </v-row>
                      <v-row style="margin-top: 30px">
                        <v-col class="nopadding" cols="2">
                          <p style="margin-bottom: 5px">Frequency cap</p>
                        </v-col>
                        <v-col class="nopadding" cols="2">
                          <p style="margin-bottom: 5px">Reset Interval</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="2">
                          <v-text-field
                            v-model="deal.frequency_cap"
                            type="number"
                            :rules="frequencyCapsRules"
                            dense
                            solo
                            outlined
                            text
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          class="nopadding"
                          cols="2"
                          style="padding-right: 0px"
                        >
                          <v-text-field
                            type="number"
                            v-model="deal.reset_interval"
                            dense
                            solo
                            :rules="resetIntervalRules"
                            outlined
                            text
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="nopadding" style="padding-left: 0px">
                          <v-select
                            v-model="reset_interval_type"
                            :items="time_choices"
                            item-text="name"
                            item-value="value"
                            dense
                            outlined
                            solo
                            style="width: 120px"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Sites</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_site_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  ref="siteName"
                                  v-model="site_textfield"
                                  :rules="siteRules"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  :disabled="!validUrlFilters"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  @click="addSiteFilters(site_textfield)"
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorSiteFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorSiteFilters
                                }}</v-alert>
                              </span>
                            </v-col>

                            <v-row
                              class="site_div"
                              v-if="sites_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedSiteFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in sites_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn @click="deleteSiteFilters(i)" icon>
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Geography</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_country_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :options="countriesList"
                              :disable-branch-nodes="true"
                              v-model="country_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Device</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_device_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :disable-branch-nodes="true"
                              :options="devicesList"
                              v-model="devices_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Browser</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_browser_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :disable-branch-nodes="true"
                              :options="browsersList"
                              v-model="browsers_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">OS</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_os_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :options="osList"
                              v-model="os_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">First Party</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_firstparty_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col>
                                <treeselect
                                  :multiple="true"
                                  :options="firstpartyList"
                                  v-model="firstparty_filters"
                                  search-nested
                                />
                              </v-col>
                            </v-row>
                            <v-col
                              v-if="errorFirstPartyFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorFirstPartyFilters
                                }}</v-alert>
                              </span>
                            </v-col>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Advertiser</p></v-col
                          >
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="advertiser_tf"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  @click="addAdvertiser(advertiser_tf)"
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorAdvertiserFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorAdvertiserFilters
                                }}</v-alert>
                              </span>
                            </v-col>
                            <v-row
                              class="site_div"
                              v-if="advertiser_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedAdvertiserFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in advertiser_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn
                                        @click="deleteAdvertiserFilters(i)"
                                        icon
                                      >
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="150"
            dense
            @click="submit"
          >
            {{ $t('submit') }}
            <v-icon right> mdi-content-save-outline </v-icon>
          </v-btn>
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="100"
            dense
            @click="back"
          >
            <v-icon left> mdi-arrow-left </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar v-model="dealupdated" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ dealUpdatedMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  const devices_list = require('@/assets/styles/devices.js')
  const os_list = require('@/assets/styles/os.js')
  const browsers_list = require('@/assets/styles/browsers.js')

  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SetSelectedDeal } from '@/store/mutation_types'
  import { SaveSelectedDeal, UpdateSelectedDeal } from '@/store/action_types'

  import axios from 'axios'

  export default {
    name: 'dealRules',
    components: { Treeselect },
    data() {
      return {
        deal: {
          name: '',
          rate_currency: 'USD',
          auction_type: 'FirstPriceAuction'
        },
        site: '',
        date: '',
        start_time: '',
        end_time: '',
        min_start_date: '',
        min_end_date: '',
        submitLoading: false,
        currencies: ['USD', 'EUR'],
        currency: 'USD',
        price_behavior_choices: [
          { name: 'Standard', value: 'FirstPriceAuction' },
          { name: 'Fixed', value: 'FixedPrice' }
        ],
        inventory_type_choices: [
          { name: 'Display', value: 'Display' },
          { name: 'Video', value: 'Video' }
        ],
        time_choices: [
          { name: 'Minute', value: 'minute' },
          { name: 'Hour', value: 'hour' },
          { name: 'Day', value: 'day' }
        ],
        reset_interval_type: 'minute',
        size_choices: [
          { id: '120x600', label: '[120x600]' },
          { id: '160x600', label: '[160x600]' },
          { id: '250x250', label: '[250x250]' },
          { id: '300x250', label: '[300x250]' },
          { id: '300x600', label: '[300x600]' },
          { id: '300x1050', label: '[300x1050]' },
          { id: '320x50', label: '[320x50]' },
          { id: '320x100', label: '[320x100]' },
          { id: '468x60', label: '[468x60]' },
          { id: '728x90', label: '[728x90]' },
          { id: '970x90', label: '[970x90]' },
          { id: '970x250', label: '[970x250]' },
          { id: '990x90', label: '[990x90]' },
          { id: '1000x90', label: '[1000x90]' },
          { id: '1000x250', label: '[1000x250]' }
        ],
        no_end_date: true,
        osList: os_list,
        os_filters: [],
        devicesList: devices_list,
        devices_filters: [],
        browsersList: browsers_list,
        browsers_filters: [],
        countriesList: [],
        country_filters: [],
        firstpartyList: [],
        firstparty_filters: [],
        sites_filters: [],
        site_textfield: '',
        firstparty_textfield: '',
        selectedSiteFilters: null,
        selectedFirstPartyFilters: null,
        isFrequencyValid: false,
        isResetIntervalValid: false,
        cpmRules: [
          (v) => !!v || 'This field is required',
          (v) => (v && Number(v) > 0.0) || 'CPM should be above 0'
        ],
        frequencyCapsRules: [
          (v) => this.isFrequencyValid || 'Value should be above 0'
        ],
        resetIntervalRules: [
          (v) => this.isResetIntervalValid || 'Value should be above 0'
        ],
        required: [(v) => !!v || 'Required'],
        siteRules: [
          (v) =>
            /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
              v
            ) || 'Please enter a valid domain URL.'
        ],
        validUrlFilters: false,
        errorSiteFilters: '',
        errorFirstPartyFilters: '',
        exclude_site_targeting: false,
        exclude_country_targeting: false,
        exclude_device_targeting: false,
        exclude_browser_targeting: false,
        exclude_os_targeting: false,
        exclude_firstparty_targeting: false,
        dealupdated: false,
        dealUpdatedMessage: 'Deal updated successfully',
        timeout: 3000,
        advertiser_tf: '',
        advertiser_filters: [],
        errorAdvertiserFilters: '',
        selectedAdvertiserFilters: null,
        targetingSite: { excluded: false, filters: [] },
        targetingDevice: { excluded: false, filters: [] },
        targetingOs: { excluded: false, filters: [] },
        targetingBrowser: { excluded: false, filters: [] },
        targetingCountry: { excluded: false, filters: [] },
        targetingFirstParty: { excluded: false, filters: [] }
      }
    },
    computed: {
      ...mapGetters(['selectedDeal', 'selectedProposal'])
    },
    watch: {
      'deal.frequency_cap': async function (val, oldVal) {
        if (
          (this.country_filters.length > 0 ||
            this.sites_filters.length > 0 ||
            this.devices_filters.length > 0 ||
            this.browsers_filters.length > 0 ||
            this.os_filters.length > 0 ||
            this.firstparty_filters.length > 0) &&
          Number(val) <= 0
        )
          this.isFrequencyValid = false
        else this.isFrequencyValid = true
      },
      'deal.reset_interval': async function (val, oldVal) {
        if (
          (this.country_filters.length > 0 ||
            this.sites_filters.length > 0 ||
            this.devices_filters.length > 0 ||
            this.browsers_filters.length > 0 ||
            this.os_filters.length > 0 ||
            this.firstparty_filters.length > 0) &&
          Number(val) <= 0
        )
          this.isResetIntervalValid = false
        else this.isResetIntervalValid = true
      },
      sites_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      browsers_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      firstparty_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      country_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      os_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      start_time: async function (val, oldVal) {
        this.min_end_date = val
      },
      no_end_date: async function (val, oldVal) {
        if (val == false && this.end_time == '') {
          this.min_end_date = this.start_time
          const time = this.start_time.substring(0, this.start_time.length - 5)
          this.end_time = time + '23:59'
        } else if (val == true) {
          this.end_time = ''
        }
      },
      site_textfield: async function (val, oldVal) {
        let regex =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        this.validUrlFilters = regex.test(val)
      },
      selectedSiteFilters: async function (val, oldVal) {
        this.selectedSiteFilters = null
      },
      selectedFirstPartyFilters: async function (val, oldVal) {
        this.selectedFirstPartyFilters = null
      },
      selectedAdvertiserFilters: async function (val, oldVal) {
        this.selectedAdvertiserFilters = null
      }
      /*  exclude_site_targeting: async function (val, oldVal) {
        if (this.targetingSite.filters.length != 0) {
          if (val == this.targetingSite.excluded) {
            this.sites_filters = this.targetingSite.filters
          } else this.sites_filters = []
        }
      },
      exclude_device_targeting: async function (val, oldVal) {
        if (this.targetingDevice.filters.length != 0) {
          if (val == this.targetingDevice.excluded) {
            this.devices_filters = this.targetingDevice.filters
          } else this.devices_filters = []
        }
      },
      exclude_os_targeting: async function (val, oldVal) {
        if (this.targetingOs.filters.length != 0) {
          if (val == this.targetingOs.excluded) {
            this.os_filters = this.targetingOs.filters
          } else this.os_filters = []
        }
      },
      exclude_browser_targeting: async function (val, oldVal) {
        if (this.targetingBrowser.filters.length != 0) {
          if (val == this.targetingBrowser.excluded) {
            this.browsers_filters = this.targetingBrowser.filters
          } else this.browsers_filters = []
        }
      },
      exclude_country_targeting: async function (val, oldVal) {
        if (this.targetingCountry.filters.length != 0) {
          if (val == this.targetingCountry.excluded) {
            this.country_filters = this.targetingCountry.filters
          } else this.country_filters = []
        }
      },
      exclude_firstparty_targeting: async function (val, oldVal) {
        if (this.targetingFirstParty.filters.length != 0) {
          if (val == this.targetingFirstParty.excluded) {
            this.firstparty_filters = this.targetingFirstParty.filters
          } else this.firstparty_filters = []
        }
      }*/
    },
    async created() {
      this.getCountries()
      this.getFirstPartyList()
      this.deal = this.selectedDeal
      if (this.$route.name == 'deal_edit') {
        this.deal.proposal_id = this.selectedProposal.id
        try {
          const dealURL = await axios.get(
            `/api/getdealbyid/${this.$route.params.deal_id}`
          )
          this.SetSelectedDeal(dealURL.data)
          this.deal = this.selectedDeal
        } catch (error) {
          console.log(error)
        }

        this.getTargetings()
        this.start_time = this.getDateFromTimeStamp(this.deal.start_time)
        if (this.deal.start_time == 0) {
          this.start_time = this.getDefaultTimeNow()
        }
        this.min_start_date = this.start_time
      }
    },
    methods: {
      ...mapMutations([SetSelectedDeal]),
      ...mapActions([SaveSelectedDeal, UpdateSelectedDeal]),

      back() {
        this.$router.push({
          name: 'deal_list',
          params: {
            proposal_id: this.$route.params.proposal_id
          }
        })
      },
      async submit() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        this.deal.start_time = new Date(this.start_time).getTime() / 1000
        if (this.end_time != '')
          this.deal.end_time = new Date(this.end_time).getTime() / 1000
        switch (this.reset_interval_type) {
          case 'hour':
            this.deal.reset_interval *= 60
            break
          case 'day':
            this.deal.reset_interval *= 60 * 24
            break
          default:
            break
        }
        if (this.$route.name == 'deal_edit') {
          const dealResponse = await this.UpdateSelectedDeal(this.deal).catch(
            () => {
              this.errors = this.getErrors
              this.submitLoading = false
              return false
            }
          )
          this.dealUpdatedMessage = 'Deal updated successfully'
        } else {
          const dealResponse = await this.SaveSelectedDeal(this.deal).catch(
            () => {
              this.errors = this.getErrors
              this.submitLoading = false
              return false
            }
          )
          this.dealUpdatedMessage = 'Deal created successfully'
        }
        this.manageTargeting()
        this.dealupdated = true
        setTimeout(
          () =>
            this.$router.push({
              name: 'deal_list',
              proposal_id: this.$route.params.proposal_id
            }),
          1500
        )
      },
      async getTargetings() {
        try {
          const getTargetings = await axios.get(
            `/api/gettargetingbydealid/${this.deal.id}`
          )
          var targetings = getTargetings.data
          targetings.forEach((targeting) => {
            switch (targeting.criteria) {
              case 'Site':
                if (targeting.type == 'excluded') {
                  this.exclude_site_targeting = true
                  this.targetingSite.excluded = true
                } else {
                  this.exclude_site_targeting = false
                  this.targetingSite.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingSite.filters = newStr.split(',')
                this.sites_filters = this.targetingSite.filters
                break
              case 'Device':
                if (targeting.type == 'excluded') {
                  this.exclude_device_targeting = true
                  this.targetingDevice.excluded = true
                } else {
                  this.exclude_device_targeting = false
                  this.targetingDevice.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingDevice.filters = newStr.split(',')
                for (let i = 0; i < this.targetingDevice.filters.length; i++) {
                  switch (this.targetingDevice.filters[i]) {
                    case 'Smarttv':
                      this.targetingDevice.filters[i] = 'ConnectedTV'
                      break
                    case 'Desktop':
                      this.targetingDevice.filters[i] = 'PC'
                      break
                    case 'Mobile':
                      break
                    case 'Tablet':
                      break
                    default:
                      this.targetingDevice.filters[i] = 'Other'
                      break
                  }
                }
                this.targetingDevice.filters = [
                  ...new Set(this.targetingDevice.filters)
                ]
                this.targetingDevice.filters.sort()
                this.devices_filters = this.targetingDevice.filters
                break
              case 'Os':
                if (targeting.type == 'excluded') {
                  this.exclude_os_targeting = true
                  this.targetingOs.excluded = true
                } else {
                  this.exclude_os_targeting = false
                  this.targetingOs.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingOs.filters = newStr.split(',')
                this.os_filters = this.targetingOs.filters
                break
              case 'Browser':
                if (targeting.type == 'excluded') {
                  this.exclude_browser_targeting = true
                  this.targetingBrowser.excluded = true
                } else {
                  this.exclude_browser_targeting = false
                  this.targetingBrowser.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingBrowser.filters = newStr.split(',')
                this.browsers_filters = this.targetingBrowser.filters
                break
              case 'Country':
                if (targeting.type == 'excluded') {
                  this.exclude_country_targeting = true
                  this.targetingCountry.excluded = true
                } else {
                  this.exclude_country_targeting = false
                  this.targetingCountry.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingCountry.filters = newStr.split(',')
                this.country_filters = this.targetingCountry.filters
                break
              case 'FirstParty':
                if (targeting.type == 'excluded') {
                  this.exclude_firstparty_targeting = true
                  this.targetingFirstParty.excluded = true
                } else {
                  this.exclude_firstparty_targeting = false
                  this.targetingFirstParty.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingFirstParty.filters = newStr.split(',')
                this.firstparty_filters = this.targetingFirstParty.filters
                break
            }
          })
        } catch (error) {
          console.log(error)
        }
      },
      async manageTargeting() {
        let devices_selected = this.devices_filters
        if (devices_selected.length > 0) {
          for (let i = 0; i < devices_selected.length; i++) {
            switch (devices_selected[i]) {
              case 'ConnectedTV':
                devices_selected[i] = 'Smarttv'
                break
              case 'PC':
                devices_selected[i] = 'Desktop'
                break
              case 'Mobile':
                break
              case 'Tablet':
                break
              default:
                devices_selected[i] = 'Other'
                break
            }
          }
        }
        console.log('before =>', devices_selected)
        devices_selected = [...new Set(devices_selected)]
        console.log('after =>', devices_selected)
        var sites_filters_db = this.getFormatArray(this.sites_filters)
        var country_filters_db = this.getFormatArray(this.country_filters)
        var devices_filters_db = this.getFormatArray(devices_selected)
        var browsers_filters_db = this.getFormatArray(this.browsers_filters)
        var os_filters_db = this.getFormatArray(this.os_filters)
        var firstparty_filters_db = this.getFormatArray(this.firstparty_filters)

        if (sites_filters_db != '') {
          var targeting = {
            deal_id: this.selectedDeal.id,
            criteria: 'Site',
            type:
              this.exclude_site_targeting == false ? 'included' : 'excluded',
            filters: sites_filters_db
          }
          try {
            await axios.post(`/api/ttdtargeting`, targeting)
          } catch (error) {
            console.log(error)
          }
        }
        if (country_filters_db != '') {
          var targeting = {
            deal_id: this.selectedDeal.id,
            criteria: 'Country',
            type:
              this.exclude_country_targeting == false ? 'included' : 'excluded',
            filters: country_filters_db
          }
          try {
            await axios.post(`/api/ttdtargeting`, targeting)
          } catch (error) {
            console.log(error)
          }
        }
        if (devices_filters_db != '') {
          var targeting = {
            deal_id: this.selectedDeal.id,
            criteria: 'Device',
            type:
              this.exclude_device_targeting == false ? 'included' : 'excluded',
            filters: devices_filters_db
          }
          try {
            await axios.post(`/api/ttdtargeting`, targeting)
          } catch (error) {
            console.log(error)
          }
        }
        if (browsers_filters_db != '') {
          var targeting = {
            deal_id: this.selectedDeal.id,
            criteria: 'Browser',
            type:
              this.exclude_browser_targeting == false ? 'included' : 'excluded',
            filters: browsers_filters_db
          }
          try {
            await axios.post(`/api/ttdtargeting`, targeting)
          } catch (error) {
            console.log(error)
          }
        }

        if (os_filters_db != '') {
          var targeting = {
            deal_id: this.selectedDeal.id,
            criteria: 'Os',
            type: this.exclude_os_targeting == false ? 'included' : 'excluded',
            filters: os_filters_db
          }
          try {
            await axios.post(`/api/ttdtargeting`, targeting)
          } catch (error) {
            console.log(error)
          }
        }
        if (firstparty_filters_db != '') {
          var targeting = {
            deal_id: this.selectedDeal.id,
            criteria: 'FirstParty',
            type:
              this.exclude_firstparty_targeting == false
                ? 'included'
                : 'excluded',
            filters: firstparty_filters_db
          }
          try {
            await axios.post(`/api/ttdtargeting`, targeting)
          } catch (error) {
            console.log(error)
          }
        }
      },
      getFormatArray(array) {
        if (array.length == 0) return ''
        var str_toReturn = ''
        str_toReturn = '['
        array.forEach((x) => {
          if (array.indexOf(x) != array.length - 1)
            str_toReturn += '"' + x + '"' + ','
          else str_toReturn += '"' + x + '"'
        })
        str_toReturn += ']'
        return str_toReturn
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)

        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      },
      getDefaultTimeNow() {
        var today = new Date()
        var year = today.getFullYear()
        var month = today.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = today.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (today.getHours() < 10 ? '0' : '') + today.getHours()
        var minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
        var date = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = date + 'T' + time
        return dateTime
      },
      addSiteFilters(site) {
        site = this.getWwwFormat(site)
        var exists = false
        this.sites_filters.forEach((element) => {
          if (element == site) {
            exists = true
          }
        })
        if (exists == false) {
          this.sites_filters.push(site)
          this.site_textfield = ''
        } else {
          this.errorSiteFilters = 'You already added this site'
          setTimeout(() => (this.errorSiteFilters = ''), 2000)
        }
      },
      deleteSiteFilters(index) {
        this.sites_filters.splice(index, 1)
      },
      addFirstPartyFilters(firstparty) {
        var exists = false
        this.firstparty_filters.forEach((element) => {
          if (element == firstparty) {
            exists = true
          }
        })
        if (exists == false) {
          this.firstparty_filters.push(firstparty)
          this.firstparty_textfield = ''
        } else {
          this.errorFirstPartyFilters = 'You already added this site'
          setTimeout(() => (this.errorFirstPartyFilters = ''), 2000)
        }
      },
      deleteFirstPartyFilters(index) {
        this.firstparty_filters.splice(index, 1)
      },
      getWwwFormat(site) {
        if (site.toLowerCase().startsWith('https://www.') == true) {
          site = site.toLowerCase().replace('https://www.', 'www.')
        } else if (site.toLowerCase().startsWith('http://www.') == true) {
          site = site.toLowerCase().replace('http://www.', 'www.')
        } else if (site.toLowerCase().startsWith('https:/www.') == true) {
          site = site.toLowerCase().replace('https:/www.', 'www.')
        } else if (site.toLowerCase().startsWith('http:/www.') == true) {
          site = site.toLowerCase().replace('http:/www.', 'www.')
        } else if (
          site.toLowerCase().startsWith('https://') == true &&
          site.toLowerCase().substring(7, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('https://', 'www.')
        } else if (
          site.toLowerCase().startsWith('http://') == true &&
          site.toLowerCase().substring(6, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('http://', 'www.')
        } else if (
          site.toLowerCase().startsWith('https:/') == true &&
          site.toLowerCase().substring(6, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('https:/', 'www.')
        } else if (
          site.toLowerCase().startsWith('http:/') == true &&
          site.toLowerCase().substring(5, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('http:/', 'www.')
        } else {
          site = 'www.' + site.toLowerCase()
        }
        return site.toLowerCase()
      },
      async getCountries() {
        try {
          const countriesResponse = await axios.get(`/api/getcountries`)
          this.countriesList = countriesResponse.data
        } catch (error) {
          console.log(error)
        }
      },
      async getFirstPartyList() {
        try {
          const firstpartyResponse = await axios.get(`/api/getfirstparty`)
          this.firstpartyList = firstpartyResponse.data
        } catch (error) {
          console.log(error)
        }
      },
      addAdvertiser(advertiser) {
        var exists = false
        this.advertiser_filters.forEach((element) => {
          if (element == advertiser) {
            exists = true
          }
        })
        if (exists == false) {
          this.advertiser_filters.push(advertiser)
          this.advertiser_tf = ''
        } else {
          this.errorAdvertiserFilters = 'You already added this advertiser'
          setTimeout(() => (this.errorAdvertiserFilters = ''), 2000)
        }
      },
      deleteAdvertiserFilters(index) {
        this.advertiser_filters.splice(index, 1)
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .expansion_panel {
    padding: 20px;
    margin-left: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 30px;
  }
  .v-expansion-panels {
    z-index: unset;
    box-shadow: 0.2px 0.2px 0.5px 0.5px lightgrey;
  }
  .v-expansion-panel {
    border: 0.5px solid lightgrey;
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
    > .v-expansion-panel--active
    + .v-expansion-panel {
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
    > .v-expansion-panel--active {
    margin-top: 0px;
  }
  .float-container {
    border: 3px solid #fff;
  }
  .site_targeting_btns {
    width: 6%;
    float: left;
    padding: 5px;
  }
  .site_div {
    width: 70%;
    margin: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    overflow: scroll;
  }
  .include_targeting {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    margin: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
</style>
