<template>
  <v-container fluid>
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>
    <v-card-title primary-title>
      Page {{ pagename }}
      <app-page-active
        :is-active="page.isActive"
        style="margin-top: 2px"
      ></app-page-active>
      <v-spacer></v-spacer>
      <v-btn
        style="margin: 10px"
        class="white--text notcapital"
        color="black"
        width="200"
        dense
        @click="back"
      >
        <v-icon left> mdi-arrow-left </v-icon>
        Back to Page List
      </v-btn>
      <v-btn
        style="margin: 10px"
        class="white--text notcapital"
        color="orange"
        width="170"
        dense
        :loading="generateButtonLoading"
        :disabled="
          generateButtonLoading ||
          page.isUpdateActive == 0 ||
          page.isUpdateActive == false
        "
        @click="generateScript"
      >
        <v-icon left> mdi-code-tags-check </v-icon>
        Generate Script
      </v-btn>
    </v-card-title>
    <v-tabs v-model="viewChoice" background-color="#2E4089" dark>
      <v-tab v-for="view in views" :key="view" @click="goTo(view)">
        {{ view }}
      </v-tab>
    </v-tabs>
    <router-view
      :page="page"
      :devices="devices"
      :device="device"
      @pageUpdated="page.isActive = 2"
    >
    </router-view>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import PageActive from '@/components/common/PageActive.vue'
  export default {
    components: {
      AppPageActive: PageActive
    },
    props: ['pageId'],
    data() {
      return {
        viewChoice: 0,
        views: ['general', 'placements', 'tag'],
        devices: [
          { id: 1, name: 'desktop' },
          { id: 2, name: 'tablet' },
          { id: 3, name: 'mobile' }
        ],
        device: {},
        page: {
          id: 0,
          isActive: 0
        },
        pagename: '',
        showDetails: false,
        loading: false,
        generateButtonLoading: false
      }
    },
    computed: {
      state() {
        if (this.page.isActive == 1) {
          return { name: 'Active', class: 'success white--text' }
        } else if (this.page.isActive == 2) {
          return { name: 'To be updated', class: 'warning white--text' }
        } else {
          return { name: 'Inactive', class: 'error white--text' }
        }
      }
    },
    watch: {},
    created() {
      this.getPage(this.$route.params.page_id)
      this.device = this.devices[0]
      if (
        [
          'tag_management_page_placements_PageTypeParameters',
          'tag_management_page_placements_DisplayList'
        ].indexOf(this.$route.name) !== -1
      ) {
        this.viewChoice = 1
      } else if (this.$route.name == 'tag_management_page_tag') {
        this.viewChoice = 2
      } else {
        this.viewChoice = 0
      }
      this.page.id = this.pageId
      //this.getDataFromApi()
      /*
      if(this.$route.query.device == undefined) return 0
        const foo = this.views.indexOf(this.$route.query.view)
      (foo == -1) ? 0 : foo
      */
      if (this.$route.name == 'pageNew') this.showDetails = false
    },
    methods: {
      back() {
        this.$router.push(
          '/tag_management/sites/' + this.$route.params.site_id + '/pages'
        )
      },
      updatePageName(event) {
        this.pageName = event.pageName
      },
      goTo(view) {
        const page =
          '/tag_management/sites/' +
          this.$route.params.site_id +
          '/pages/' +
          this.$route.params.page_id +
          '/'
        if (view == 'placements') {
          this.$router.push(page + 'placements/list?device=' + this.device.name)
        } else {
          this.$router.push(page + view)
        }
      },
      async getPage(page_id) {
        const response = await axios.get(`/api/getwpagebyid/${page_id}`)
        this.page = response.data
        this.pagename = this.page.name
      },
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: 'management/page',
          action: 'getDetails',
          data: { pageId: this.pageId }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.page = response.data
          this.$emit('pageDataRetrieved', {
            siteId: this.page.siteId,
            pageName: this.page.name
          })
          this.loading = false
        } catch (e) {
          //this.$router.push('/login')
          console.error(e)
          this.loading = false
        }
      },
      async generateScript() {
        this.generateButtonLoading = true
        const request = {
          type: 'management/page',
          action: 'generateScript',
          data: { pageId: this.pageId }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.generateButtonLoading = false
          this.page.isActive = 1
        } catch (e) {
          //this.$router.push('/login')
          console.error(e)
          this.generateButtonLoading = false
        }
      }
    }
  }
</script>

<style>
  .pageForm {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
