import { GetDeals } from '@/store/action_types'

import { SetDeals } from '@/store/mutation_types'

import axios from 'axios'


export const dealsState = {
  deals: []
}

export const dealsGetters = {
  deals: (state) => state.deals
}

export const dealsActions = {
  async [GetDeals]({ commit }, proposal_id) {
    let url = `/api/proposals/deals/${proposal_id}`
    const response = await axios.get(url)
    commit(SetDeals, response.data)
    return response
  },
}

export const dealsMutations = {
  [SetDeals](state, deals) {
    state.deals = deals
  }
}

export default dealsActions
