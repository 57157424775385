<template>
  <v-container style="margin-top: 25px; padding: 0px 0px 0px 0px">
    <v-row>
      <v-col style="padding-bottom: 0px">
        <p
          class="texts"
          style="
            font-weight: 450;
            font-family: Roboto;
            font-size: 16px;
            margin-left: 0px;
          "
        >
          {{
            $t('The next generation Header Bidding solution for publishers :')
          }}
        </p>
      </v-col>
    </v-row>
    <v-list disabled>
      <v-list-item-group color="black" style="margin: 0px 0px 0px 0px">
        <v-list-item v-for="(item, i) in items" :key="i" style="padding: 0px">
          <v-list-item-icon style="margin: 10px 0px 0px 0px">
            <v-icon color="black" class="icon_padding"></v-icon>
            <p class="texts">
              {{ item.text }}
            </p>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        items: [
          {
            text: this.$t('Web page performances improvement'),
            icon: this.$t('check_icon')
          },
          {
            text: this.$t('Advertising revenue optimisation'),
            icon: this.$t('check_icon')
          },
          {
            text: this.$t('Control and transparency'),
            icon: this.$t('check_icon')
          },
          {
            text: this.$t('Easy to manage'),
            icon: this.$t('check_icon')
          }
        ]
      }
    }
  }
</script>
<style>
  .icon_padding {
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .texts {
    font-size: 100%;
  }
</style>
