import {
  GetSelectedStoredRequest,
  UpdateSelectedStoredRequest,
  SaveSelectedStoredRequest
} from '@/store/action_types'

import { sampleStoredRequest, sspList } from '@/store/sspList'

import {
  SetSelectedStoredRequest,
  ClearSelectedStoredRequest
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedStoredRequestState = {
  selectedStoredRequest: Object.assign({}, sampleStoredRequest)
}

export const selectedStoredRequestGetters = {
  SelectedStoredRequest: (state) => state.selectedStoredRequest,
  SspList: () => sspList,
  SampleStoredRequest: () => sampleStoredRequest
}

export const selectedStoredRequestActions = {
  async [GetSelectedStoredRequest]({ commit }, stored_request_id) {
    if (stored_request_id === 'new') return
    const response = await axios.get(`/api/sr/${stored_request_id}`)

    commit(SetSelectedStoredRequest, response.data)
    return response
  },
  async [UpdateSelectedStoredRequest]({ commit }, stored_request) {
    const response = await axios.put(
      `/api/sr/${stored_request.id}`,
      stored_request
    )
    commit(SetSelectedStoredRequest, stored_request)
    return response
  },
  async [SaveSelectedStoredRequest]({ dispatch, commit }, stored_request) {
    console.log(stored_request.id)
    if (stored_request.id != undefined && stored_request.id.length > 0) {
      return dispatch(UpdateSelectedStoredRequest, stored_request)
    }

    const response = await axios.post('/api/sr', stored_request)

    commit(SetSelectedStoredRequest, response.data)
    return response
  }
}

export const selectedStoredRequestMutations = {
  [SetSelectedStoredRequest](state, storedRequest) {
    state.selectedStoredRequest = storedRequest
  },
  [ClearSelectedStoredRequest](state) {
    Object.assign(state.selectedStoredRequest, sampleStoredRequest)
    state.selectedStoredRequest.sizes = []
  }
}
