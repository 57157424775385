export const SetAppUser = 'SetAppUser'
export const SetAppCustomer = 'SetAppCustomer'

export const SetOnboardingUser = 'SetOnboardingUser'

export const SetStoredRequests = 'SetStoredRequests'
export const ClearStoredRequests = 'ClearStoredRequests'

export const SetSelectedStoredRequest = 'SetSelectedStoredRequest'
export const ClearSelectedStoredRequest = 'ClearSelectedStoredRequest'

export const SetInjections = 'SetInjections'
export const ClearInjections = 'ClearInjections'

export const SetSelectedInjection = 'SetSelectedInjection'
export const ClearSelectedInjection = 'ClearSelectedInjection'

export const SetUsers = 'SetUsers'
export const SetSelectedUser = 'SetSelectedUser'

export const SetCustomers = 'SetCustomers'
export const SetSelectedCustomer = 'SetSelectedCustomer'
export const ClearSelectedCustomer = 'ClearSelectedCustomer'

export const SetProposals = 'SetProposals'
export const SetSelectedProposal = 'SetSelectedProposal'
export const ClearSelectedProposal = 'ClearSelectedProposal'

export const SetDeals = 'SetDeals'
export const SetSelectedDeal = 'SetSelectedDeal'
export const ClearSelectedDeal = 'ClearSelectedDeal'

export const SetSites = 'SetSites'
export const PushSites = 'PushSites'
export const ReformSites = 'ReformSites'

export const SetSelectedSite = 'SetSelectedSite'
export const ClearSelectedSite = 'ClearSelectedSite'

export const SetGamSetups = 'SetGamSetups'
export const SetSelectedGamSetup = 'SetSelectedGamSetup'
export const ClearSelectedGamSetup = 'ClearSelectedGamSetup'

export const SetOptionsCookieless = 'SetOptionsCookieless'
export const ClearOptionsCookieless = 'ClearOptionsCookieless'
export const SetSelectedOptionsCookieless = 'SetSelectedOptionsCookieless'
export const ClearSelectedOptionsCookieless = 'ClearSelectedOptionsCookieless'

export const Logout = 'Logout'

export const SetAppGroups = 'SetAppGroups'
export const Loading = 'Loading'
