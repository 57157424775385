import { GetProposals } from '@/store/action_types'

import { SetProposals } from '@/store/mutation_types'

import axios from 'axios'

export const proposalsState = {
  proposals: []
}

export const proposalsGetters = {
  proposals: (state) => state.proposals
}

export const proposalsActions = {
  async [GetProposals]({ commit }) {
    const response = await axios.get('/api/getttdproposal')
    commit(SetProposals, response.data)
    return response
  }
}

export const proposalsMutations = {
  [SetProposals](state, proposals) {
    state.proposals = proposals
  }
}

export default proposalsActions
