<template>
  <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
    <v-progress-circular indeterminate :value="60" color="blue-grey" />
  </v-overlay>
</template>

<script>
  export default {
    props: ['loading'],
    data() {
      return {}
    }
  }
</script>
