<template>
  <div id="siteContent">
    <v-container fluid>
      <v-card id="siteList">
        <v-card-title primary-title>
          Site List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="sites"
            :sort-by="['name']"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
            style="background-color: #f9f9fb"
          >
            <template v-slot:item="props">
              <tr>
                <td
                  style="width: 90%; cursor: pointer"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.name }}
                </td>
                <td>
                  <v-btn
                    class="mr-2"
                    rounded
                    small
                    color="primary"
                    @click="editItem(props.item)"
                  >
                    <v-icon left> mdi-pencil </v-icon>
                    {{ $t('edit_properties') }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            class="black--text notcapital"
            tile
            width="200"
            height="32"
            style="margin: 10px"
            outlined
            :loading="generateButtonLoading"
            :disabled="generateButtonLoading"
            @click="refreshScripts"
          >
            <v-icon left> mdi-refresh </v-icon>
            Refresh All Scripts
          </v-btn>
          <v-btn
            class="black--text notcapital"
            tile
            width="200"
            height="32"
            outlined
            @click="newItem"
          >
            <v-icon left> mdi-plus </v-icon>
            {{ $t('add_site') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    components: {},
    data() {
      return {
        headers: [
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Action', align: 'center', value: 'action' }
        ],
        sites: [],
        loading: false,
        search: '',
        generateButtonLoading: false
      }
    },
    async created() {
      this.loading = true
      const response = await axios.get(`/api/wsites`)
      this.sites = response.data
      this.loading = false
    },
    methods: {
      async refreshScripts() {
        this.generateButtonLoading = true
        const request = {
          type: 'management/site',
          action: 'refreshScripts'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.generateButtonLoading = false
        } catch (e) {
          this.$router.push('/login')
          console.error(e)
          this.generateButtonLoading = false
        }
      },
      editItem(item) {
        this.$router.push('/tag_management/sites/' + item.id)
      },
      selectItem(item) {
        this.$router.push('/tag_management/sites/' + item.id + '/pages')
      },
      newItem() {
        this.$router.push('/tag_management/sites/new')
      }
    }
  }
</script>
