<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="archivedrules"
      class="elevation-1"
      hide-default-footer
      :disable-pagination="true"
      :loading="loading"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            {{ props.item.product }}
          </td>
          <td class="text-xs-right">
            <v-container style="padding: 5px">
              <sitesgroup :items="props.item.sites" />
            </v-container>
          </td>
          <td>
            {{ props.item.cpm_value }}
          </td>
          <td>
            {{ props.item.sites[0].start_date.substring(0, 10) }}
          </td>
          <td>
            {{ props.item.sites[0].end_date.substring(0, 10) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import ruleSitesGroup from './ruleSitesGroup.vue'

  export default {
    components: {
      sitesgroup: ruleSitesGroup
    },
    props: ['archivedrules'],
    data() {
      return {
        //elementId: '',
        headers: [
          { text: 'Product', align: 'left', value: 'product' },
          { text: 'Site', align: 'left', value: 'site' },
          { text: 'CPM', align: 'left', value: 'cpm_value' },
          { text: 'Start date', align: 'left', value: 'start_date' },
          { text: 'End date', align: 'left', value: 'end_date' }
        ],
        loading: false
      }
    },
    computed: {},
    watch: {},
    created() {},
    methods: {}
  }
</script>
