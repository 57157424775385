<template>
  <div id="PageContent">
    <v-container fluid>
      <v-card id="PageList">
        <v-card-title primary-title>
          Page List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="pages"
            :sort-by="['name']"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
            style="background-color: #f9f9fb"
          >
            <template v-slot:item="props">
              <tr>
                <td style="width: 15%; cursor: pointer">
                  {{ props.item.id }}
                </td>
                <td
                  style="width: 85%; cursor: pointer"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            class="black--text notcapital"
            tile
            width="200"
            height="32"
            style="margin: 10px"
            outlined
            :loading="generateButtonLoading"
            :disabled="generateButtonLoading"
            @click="refreshScripts"
          >
            <v-icon left> mdi-refresh </v-icon>
            Refresh All Scripts
          </v-btn>
          <app-page-new @pageAdded="refreshTable"> </app-page-new>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  import PageNew from './new.vue'

  export default {
    components: {
      AppPageNew: PageNew
    },
    data() {
      return {
        headers: [
          { text: 'ID', align: 'left', value: 'id' },
          { text: 'Name', align: 'left', value: 'name' }
        ],
        pages: [],
        loading: false,
        search: '',
        generateButtonLoading: false
      }
    },
    async created() {
      this.loading = true
      const response = await axios.get(
        `/api/getwpagesbysiteid/${this.$route.params.site_id}`
      )
      this.pages = response.data
      this.loading = false
    },
    methods: {
      async refreshScripts() {
        this.generateButtonLoading = true
        const request = {
          type: 'management/site',
          action: 'refreshScripts'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.generateButtonLoading = false
        } catch (e) {
          //this.$router.push('/login')
          console.error(e)
          this.generateButtonLoading = false
        }
      },
      editItem(item) {
        this.$router.push(
          '/tag_management/sites/' +
            this.$route.params.site_id +
            '/pages/' +
            item.id
        )
      },
      selectItem(item) {
        this.$router.push(
          '/tag_management/sites/' +
            this.$route.params.site_id +
            '/pages/' +
            item.id +
            '/general'
        )
      },
      newItem() {
        this.$router.push(
          '/tag_management/sites/' + this.$route.params.site_id + '/pages/new'
        )
      },
      async refreshTable() {
        const response = await axios.get(
          `/api/getwpagesbysiteid/${this.$route.params.site_id}`
        )
        this.pages = response.data
      }
    }
  }
</script>
