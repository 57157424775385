<template>
  <div>
    <v-btn
      v-for="timeChoice in timeChoices"
      :key="timeChoice"
      outlined
      small
      style="border: none"
      @click="selectTime(timeChoice)"
    >
      {{ timeChoice }}
    </v-btn>
    <v-menu v-model="datePickerActive" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" outlined v-on="on">
          {{ dates[0] }}
          <v-icon small style="padding-right: 5px; padding-left: 5px">
            mdi-arrow-right
          </v-icon>
          {{ dates[1] }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-date-picker v-model="dates" range></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="datePickerActive = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  //import moment-timezone from 'moment-timezone'
  export default {
    props: ['dateonefromurl', 'datetwofromurl'],
    data() {
      return {
        timeChoices: ['Today', 'YSD', '7D', 'PW', '30D', 'MTD', 'PM'],
        dates: [],
        datePickerActive: false
      }
    },
    computed: {
      ...mapGetters(['appCustomer']),
      unixDates() {
        return [
          moment(this.dates[0]).startOf('day').format('x'),
          moment(this.dates[1])
            .startOf('day')
            .add(1, 'days')
            .subtract(1, 'ms')
            .format('x')
        ]
      }
    },
    watch: {
      appCustomer() {
        if (this.$route.name == 'billing_reporting') {
          this.selectTime('MTD')
        } else this.selectTime('7D')
        this.$router.push({ name: 'pbs_reporting' })
      },
      dates() {
        //const dates = this.dates.map((x) => x.replace(/-/g, ''))
        this.$emit('datesUpdate', this.dates)
      }
    },
    created() {
      this.selectTime('7D')
      if (this.$route.name == 'billing_reporting') {
        this.timeChoices = ['MTD', 'PM']
        this.selectTime('MTD')
      }
      if (this.dateonefromurl != undefined) {
        var date = new Date(parseInt(this.dateonefromurl))
        const dateformat = new Date(
          date.setDate(date.getDate()) - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)
        this.dates[0] = dateformat
      }
      if (this.datetwofromurl != undefined) {
        var date = new Date(parseInt(this.datetwofromurl))
        const dateformat = new Date(
          date.setDate(date.getDate()) - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)
        this.dates[1] = dateformat
      }
    },
    methods: {
      selectTime(timeChoice) {
        let dates = []
        switch (timeChoice) {
          case 'Today':
            dates = [moment().startOf('day'), moment().startOf('day')]
            break
          case 'YSD':
            dates = [
              moment().startOf('day').subtract(1, 'days'),
              moment().startOf('day').subtract(1, 'days')
            ]
            break
          case '7D':
            dates = [
              moment().startOf('day').subtract(6, 'days'),
              moment().startOf('day').subtract(0, 'days')
            ]
            break
          case 'PW':
            dates[0] = moment().startOf('week').subtract(6, 'days')
            dates[1] = moment().startOf('week')
            break
          case '30D':
            dates = [
              moment().startOf('day').subtract(29, 'days'),
              moment().startOf('day')
            ]
            break
          case 'MTD':
            dates = [moment().startOf('month'), moment().startOf('day')]
            break
          case 'PM':
            dates = [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month')
            ]
            break
        }
        this.dates = [
          dates[0].format('YYYY-MM-DD'),
          dates[1].format('YYYY-MM-DD')
        ]
      }
    }
  }
</script>
