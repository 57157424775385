var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding":"0px","margin":"0px"},attrs:{"fluid":"","fill-height":""}},[_vm._l((_vm.ids),function(value,key){return [(key.match('is_active'))?_c('v-row',{key:key},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticStyle:{"padding-right":"0px"},attrs:{"cols":"2"}},[_c('p',{staticStyle:{"margin-top":"10px","font-size":"17px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.ssp))+" ")])]),_c('v-col',{staticStyle:{"padding-left":"0px"},attrs:{"cols":"10"}},[_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":function($event){return _vm.resetActiveButton(key, _vm.ids[key])}},model:{value:(_vm.ids[key]),callback:function ($$v) {_vm.$set(_vm.ids, key, $$v)},expression:"ids[key]"}},[_c('v-btn',{staticClass:"mx-2",staticStyle:{"margin":"0px !important"},attrs:{"active-class":_vm.optionsColor[0],"depressed":"","rounded":""}},[_vm._v(" Disabled ")]),_c('v-btn',{staticClass:"mx-2",staticStyle:{"margin":"0px !important"},attrs:{"active-class":_vm.optionsColor[1],"depressed":"","rounded":""}},[_vm._v(" Active ")]),_c('v-btn',{staticClass:"mx-2",staticStyle:{"margin":"0px !important"},attrs:{"active-class":_vm.optionsColor[2],"depressed":"","rounded":""}},[_vm._v(" Paused ")])],1)],1)],1):_c('v-col',{key:key,staticStyle:{"max-width":"25%"}},[(Object.keys(_vm.ids).length == 2)?_c('v-text-field',{staticStyle:{"margin-top":"10px","margin-left":"20px","width":"300px","font-size":"14px"},attrs:{"dense":"","solo":"","disabled":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] != 1,"name":key,"label":_vm.$t(key),"rules":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] == 1
            ? _vm.required
            : []},on:{"input":function($event){return _vm.$emit('update-ssp', { key: key, value: _vm.ids[key] })}},model:{value:(_vm.ids[key]),callback:function ($$v) {_vm.$set(_vm.ids, key, $$v)},expression:"ids[key]"}}):_vm._e(),(Object.keys(_vm.ids).length == 3)?_c('v-text-field',{staticStyle:{"margin-top":"10px","margin-left":"20px","width":"300px","font-size":"14px"},attrs:{"dense":"","solo":"","rules":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
          key != 'smilewanted_position_type' &&
          key != 'ix_endpoint' &&
          key != 'goodad_member'
            ? _vm.required
            : [],"disabled":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] != 1,"name":key,"label":_vm.$t(key)},on:{"input":function($event){return _vm.$emit('update-ssp', { key: key, value: _vm.ids[key] })}},model:{value:(_vm.ids[key]),callback:function ($$v) {_vm.$set(_vm.ids, key, $$v)},expression:"ids[key]"}}):_vm._e(),(Object.keys(_vm.ids).length == 4)?_c('v-text-field',{staticStyle:{"margin-top":"10px","margin-left":"20px","width":"290px","font-size":"14px"},attrs:{"dense":"","solo":"","rules":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
          key != 'pubmatic_ad_slot' &&
          key != 'pubmatic_placement_id'
            ? _vm.required
            : [],"disabled":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] != 1,"name":key,"label":_vm.$t(key)},on:{"input":function($event){return _vm.$emit('update-ssp', { key: key, value: _vm.ids[key] })}},model:{value:(_vm.ids[key]),callback:function ($$v) {_vm.$set(_vm.ids, key, $$v)},expression:"ids[key]"}}):_vm._e(),(Object.keys(_vm.ids).length == 5)?_c('v-text-field',{staticStyle:{"margin-top":"10px","margin-left":"20px","width":"212px","font-size":"14px"},attrs:{"dense":"","solo":"","rules":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
          key != 'rubicon_position'
            ? _vm.required
            : [],"disabled":_vm.ids[key.substring(0, key.search('_')) + '_is_active'] != 1,"name":key,"label":_vm.$t(key)},on:{"input":function($event){return _vm.$emit('update-ssp', { key: key, value: _vm.ids[key] })}},model:{value:(_vm.ids[key]),callback:function ($$v) {_vm.$set(_vm.ids, key, $$v)},expression:"ids[key]"}}):_vm._e()],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }