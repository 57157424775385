<template>
  <v-container v-show="show" fluid style="background-color: #f9f9fb">
    <v-row v-if="options == 'defined'">
      <v-col cols="12">
        <v-banner single-line>
          <v-icon slot="icon" color="green darken-1" size="36">
            mdi-check
          </v-icon>
          You already updated your website cookie less options. Click on the
          update button if you want to update them again.
          <template v-slot:actions>
            <v-btn
              color="black"
              style="background-color: #f39200"
              text
              @click="$emit('update')"
            >
              Update
            </v-btn>
          </template>
        </v-banner>
      </v-col>
    </v-row>
    <v-row v-if="options == 'empty'">
      <v-container>
        <v-row>
          <v-col
            class="noPaddingCenter"
            cols="12"
            style="padding: 15px 0px 0px"
          >
            <v-img
              class="mx-2"
              src="@/assets/logoEarth.png"
              max-height="120"
              contain
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="noPaddingCenter" cols="12">
            <p style="padding-left: 20px; font-size: 27px">
              {{ $t('define options') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" />
          <v-col class="noPaddingCenter" cols="10">
            <p style="padding-left: 20px; font-size: 17px">
              {{ $t('explaining cookieless') }}
            </p>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row>
          <v-col cols="1" />
          <v-col cols="10" class="noPaddingCenter">
            <v-btn
              style="border-color: black"
              class="white--text notcapital"
              color="black"
              dense
              @click="$emit('defineOptions')"
            >
              {{ $t('define options') }}
            </v-btn>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'CookielessContent',
    components: {},
    props: {
      parentExists: {
        default: 'empty',
        type: String
      }
    },
    data() {
      return {
        options: '',
        show: false
      }
    },
    watch: {
      parentExists() {
        this.options = this.parentExists
        if (this.options == 'empty') this.showEmpty == true
        else this.showDefined == true
      }
    },
    created() {
      this.options = this.parentExists
      if (this.options == 'empty') this.showEmpty == true
      else this.showDefined == true
      setTimeout(() => (this.show = true), 500)
    },
    methods: {}
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
</style>
