<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="$emit('hideDialog')"
  >
    <template>
      <v-card>
        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <br />
            <v-row>
              <v-col cols="12">
                <h2 style="color: black">{{ this.$t('Add_users') }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding-top: 0px">
                <p style="color: black">
                  {{ this.$t('invitation_description_first_line') }}<br />
                  {{ this.$t('invitation_description_second_line') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-form ref="form">
                  <p style="margin-bottom: 5px">{{ $t('emails') }}</p>
                  <v-text-field v-model="emails" dense solo required>
                  </v-text-field
                ></v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          align="center"
          class="justify-end"
          style="padding-top: 0px"
        >
          <v-col cols="12" align="center" style="padding-top: 0px">
            <v-btn
              width="320"
              style="height: 40px"
              class="white--text"
              color="black"
              @click="$emit('submitted', emails), (emails = '')"
            >
              {{ this.$t('send_invitations') }}
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        dialog: false,
        emails: '',
        submitLoading: false,
        errors: []
      }
    },
    computed: {
      ...mapGetters(['', ''])
    },
    watch: {
      parentDialog() {
        this.dialog = this.parentDialog
      }
    },
    async created() {},
    methods: {}
  }
</script>
