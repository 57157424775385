import {
  GetSelectedDeal,
  SaveSelectedDeal,
  UpdateSelectedDeal
} from '@/store/action_types'

import {
  SetSelectedDeal,
  ClearSelectedDeal
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedDealState = {
  selectedDeal: {}
}

export const selectedDealGetters = {
  selectedDeal: (state) => state.selectedDeal
}

export const selectedDealActions = {
  async [GetSelectedDeal]({ commit }, dealId) {
    const response = await axios.get('/api/ttddeal/' + dealId)
    commit(SetSelectedDeal, response.data)
    return response
  },
  async [SaveSelectedDeal]({ dispatch }, deal) {
    if (deal.id) {
      return dispatch(UpdateSelectedDeal, deal)
    }
    const response = await axios.post('/api/ttddeal', deal)
    return response.data
  },

  async [UpdateSelectedDeal]({ commit }, deal) {
    const response = await axios.put(`/api/ttddeal/${deal.id}`, deal)
    return response
  }
}

export const selectedDealMutations = {
  [SetSelectedDeal](state, deal) {
    state.selectedDeal = deal
  },
  [ClearSelectedDeal](state) {
    state.selectedDeal = {}
  }
}
