<template>
  <v-card>
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>
    <v-card-title v-if="page.id == 'new'" primary-title>
      New Page
    </v-card-title>
    <!--
      <v-card-title primary-title v-else>
        Page {{ page.name }}
      </v-card-title>
      -->
    <v-form ref="form" lazy-validation>
      <v-card-text>
        <v-container style="padding: 0px">
          <v-row>
            <v-col cols="12" md="4" class="pageForm">
              <v-text-field
                v-model="page.name"
                label="Name"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pageForm">
              <v-switch
                v-model="page.isUpdateActive"
                label="Is Update Active"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4" class="pageForm">
              <v-switch v-model="page.tagVersion" label="Is New Tag"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5" class="pageForm">
              <v-text-field
                v-model="page.adUnit"
                label="Google Ad Unit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pageForm">
              <v-text-field
                v-model="page.targeting"
                label="Targeting"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- BeOps Part -->
          <v-row>
            <v-col cols="12" md="3" class="pageForm">
              <v-switch
                v-model="page.isBeOpsActive"
                label="Is BeOps Active"
              ></v-switch>
            </v-col>
            <v-col v-if="page.isBeOpsActive" cols="12" md="3" class="pageForm">
              <v-text-field
                v-model="page.beOpsId"
                label="beOpsId"
              ></v-text-field>
            </v-col>
            <v-col v-if="page.isBeOpsActive" cols="12" md="3" class="pageForm">
              <v-text-field
                v-model="page.beOpsSelector"
                label="beOpsSelector"
              ></v-text-field>
            </v-col>
            <v-col v-if="page.isBeOpsActive" cols="12" md="3" class="pageForm">
              <v-text-field
                v-model="page.beOpsPosition"
                label="beOpsPosition"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Adjesty Part -->
          <v-row>
            <v-col cols="12" md="4" class="pageForm">
              <v-switch
                v-model="page.isAdjestyActive"
                label="Is Adjesty Active"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-row v-if="updated == true" style="padding-top: 20px">
        <v-col>
          <v-alert dense text type="success" color="green">
            Page updated successfully</v-alert
          >
        </v-col>
      </v-row>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          :loading="submitLoading"
          :disabled="submitLoading"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="130"
          dense
          @click="submit"
        >
          <v-icon left> mdi-check-circle</v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['page'],
    data() {
      return {
        loading: false,
        nameRules: [(v) => !!v || 'Name is required'],
        submitLoading: false,

        //items: ['Desktop', 'Tablet', 'Mobile', 'Video'],
        tab: null,
        updated: false
      }
    },
    watch: {},
    created() {
      //console.log(this.page)
    },
    methods: {
      async submit() {
        this.submitLoading = true
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }

        if (this.page.isBeOpsActive == false) {
          this.page.beOpsId = ''
          this.page.beOpsSelector = ''
          this.page.beOpsPosition = ''
        }
        try {
          const response = await axios.put(
            `/api/wpages/${this.page.id}`,
            this.page
          )
          if (response.data) {
            this.updated = true
            this.submitLoading = false
            this.$emit('pageUpdated')
            setTimeout(() => (this.updated = false), 2000)
            //this.back()
          }
        } catch (e) {
          console.error(e)
          this.loading = false
          this.submitLoading = false
        }
      }
    }
  }
</script>

<style>
  .pageForm {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
