<template>
  <div>
    <div>
      <highcharts :options="chartOptions" />
    </div>
  </div>
</template>
<script>
  import moment from 'moment'
  import { Chart } from 'highcharts-vue'
  import * as formatters from '@/components/common/formatters.js'
  export default {
    components: {
      highcharts: Chart
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['data', 'total', 'timeUnit'],
    data() {
      return {}
    },
    computed: {
      chartOptions() {
        const type = this.type // Revenue, Impressions, CPM
        let output = {}
        output.credits = { enabled: false }
        output.chart = {
          type: 'column',
          height: '450px'
        }
        output.title = {}
        output.title.style = '{color: #666666}'
        output.legend = { enabled: true }
        output.xAxis = {}
        output.xAxis.type = 'datetime' //'datetime'
        output.xAxis.title = 'Time' //'datetime'
        output.xAxis.dateformat = {
          year: '%I:%M:%S %p',
          month: '%I:%M:%S %p',
          day: '%I:%M:%S %p'
        }
        //output.xAxis.units = 'week';
        output.yAxis = {}
        output.yAxis.title = { text: '' }
        output.tooltip = {}
        output.tooltip.useHTML = true
        output.tooltip.split = false
        output.plotOptions = {}
        output.plotOptions.column = {}
        output.plotOptions.column.stacking = 'normal'
        output.plotOptions.column.dataLabels = { enabled: false }
        output.series = this.data
        output.title.text = ''

        /*output.title.text = formatters.billingFormatter(
          (this.total * 0.02) / 1000
        )*/
        output.yAxis.labels = {
          formatter: function () {
            return formatters.billingFormatter(this.value)
          }
        }
        output.tooltip = {
          formatter() {
            let output = moment(this.x).format('MMM Do, YY') + '<br/>'
            this.series.name = formatters.ucFirst(this.series.name)
            if (this.series.name == 'AMP') this.series.name = 'Nexx AMP'
            if (this.series.name == 'Fast') this.series.name = 'Nexx Web'
            if (this.series.name == 'Adapter') this.series.name = 'Nexx Adapter'
            output += this.series.name + ': <b>'
            output += formatters.billingFormatter(this.y)
            if (this.total !== undefined) {
              output +=
                '<br/>Total: <b>' +
                formatters.billingFormatter(this.total) +
                '</b>'
            }
            return output
          }
        }
        return output
      }
    },
    watch: {
      data() {}
    },
    created() {}
  }
</script>
