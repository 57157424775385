import {
  GetSelectedProposal,
  SaveSelectedProposal,
  UpdateSelectedProposal
} from '@/store/action_types'

import {
  SetSelectedProposal,
  ClearSelectedProposal
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedProposalState = {
  selectedProposal: {}
}

export const selectedProposalGetters = {
  selectedProposal: (state) => state.selectedProposal
}

export const selectedProposalActions = {
  async [GetSelectedProposal]({ commit }, proposalId) {
    const response = await axios.get('/api/ttdproposal/' + proposalId)
    commit(SetSelectedProposal, response.data)
    return response
  },
  async [SaveSelectedProposal]({ dispatch }, proposal) {
    if (proposal.id) {
      return dispatch(UpdateSelectedProposal, proposal)
    }
    const response = await axios.post('/api/ttdproposal', proposal)
    return response.data
  },

  async [UpdateSelectedProposal]({ commit }, proposal) {
    const response = await axios.put(`/api/ttdproposal/${proposal.id}`, proposal)
    return response
  }
}

export const selectedProposalMutations = {
  [SetSelectedProposal](state, proposal) {
    state.selectedProposal = proposal
  },
  [ClearSelectedProposal](state) {
    state.selectedProposal = {}
  }
}
