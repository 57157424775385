<template>
  <v-container fluid>
    <app-toolbar @datesUpdate="datesUpdate" />
    <app-filters
      :filters="filters"
      :selects="reportingData.selects"
      @filtersUpdate="filtersUpdate"
    />
    <app-charts
      :reporting-data="reportingData.perDay"
      :fillrate-data="reportingData.fillratePerDay"
      :loading="loading"
      :time-unit="'datetime'"
    />
    <app-per-criteria
      :reporting-data="reportingData.perCriteria"
      :loading="loading"
      :cats="cats"
      @perCriteriaUpdate="perCriteriaUpdate"
    />
  </v-container>
</template>

<script>
  import moment from 'moment'
  import axios from 'axios'
  import Toolbar from '@/components/reporting/toolbar.vue'
  import Filters from '@/components/reporting/filters.vue'
  import Charts from './charts/index.vue'
  import PerCriteria from './perCriterias/index.vue'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      AppFilters: Filters,
      AppToolbar: Toolbar,
      AppCharts: Charts,
      AppPerCriteria: PerCriteria
    },
    data() {
      return {
        loading: false,
        dates: [],
        filters: {
          domain: [],
          auction_type: []
        },
        reportingData: {
          perCriteria: {
            domain: [],
            bidder: [],
            adunit: []
          },
          perDay: [],
          fillratePerDay: []
        },
        dataSync: false,
        customerId: 0
      }
    },
    computed: {
      ...mapGetters(['appCustomer']),
      cats() {
        return Object.keys(this.reportingData.perCriteria)
      }
    },
    watch: {
      appCustomer() {
        this.filters = Object.assign(
          {},
          {
            domain: [],
            auction_type: []
          }
        )
        this.getDataFromApi()
      }
    },
    methods: {
      unixDates(dates) {
        return [
          moment(dates[0]).startOf('day').format('x'),
          moment(dates[1])
            .startOf('day')
            .add(1, 'days')
            .subtract(1, 'ms')
            .format('x')
        ]
      },
      datesUpdate(dates) {
        this.dates = this.unixDates(dates)
        console.log(this.dates)
        this.getDataFromApi()
        this.dataSync = true
      },
      filtersUpdate(foo) {
        this.filters = Object.assign({}, foo)
        if (this.dataSync) this.getDataFromApi()
        this.dataSync = true
      },
      perCriteriaUpdate(foo) {
        this.filters[foo.category] = [foo.id]
        if (this.dataSync) this.getDataFromApi()
        this.dataSync = true
      },
      getTimeZone() {
        var offset = new Date().getTimezoneOffset(),
          o = Math.abs(offset)
        return (
          (offset < 0 ? '+' : '-') +
          ('00' + Math.floor(o / 60)).slice(-2) +
          ':' +
          ('00' + (o % 60)).slice(-2)
        )
      },
      async getDataFromApi() {
        this.loading = true
        const payload = {
          timeOffset: this.getTimeZone(),
          filters: this.filters,
          dates: this.dates,
          customerId: this.appCustomer.id
        }
        try {
          const response = await axios.post(
            `/api/consentless-reporting`,
            payload
          )
          this.reportingData = response.data
          this.loading = false
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
