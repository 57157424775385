export const sspDetails = {
  adform: {
    adform_is_active: 0,
    adform_mid: ''
  },
  adnuntius: {
    adnuntius_is_active: 0,
    adnuntius_au_id: ''
  },
  adot: {
    adot_is_active: 0,
  },
  adyoulike: {
    adyoulike_is_active: 0,
    adyoulike_placement: ''
  },
  appnexus: {
    appnexus_is_active: 0,
    appnexus_placement_id: ''
  },
  bliink: {
    bliink_is_active: 0,
    bliink_tag_id: ''
  },
  conversant: {
    criteo_is_active: 0,
    criteo_network_id: ''
  },
  criteo: {
    conversant_is_active: 0,
    conversant_site_id: ''
  },
  cwire: {
    cwire_is_active: 0,
    cwire_page_id: '',
    cwire_placement_id: ''
  },
  firstid: {
    firstid_is_active: 0,
    firstid_tag_id: '',
  },
  freewheel: {
    freewheel_is_active: 0,
    freewheel_zone_id: '',
  },
  fueldigital: {
    fueldigital_is_active: 0,
    fueldigital_site_id: '',
    fueldigital_page_id: '',
    fueldigital_format_id: '',
  },
  fueldigitalix: {
    fueldigitalix_is_active: 0,
    fueldigitalix_site_id: '',
  },
  fueldigitalmedia: {
    fueldigitalmedia_is_active: 0,
    fueldigitalmedia_tag_id: '',
  },
  galaxiemedia: {
    galaxiemedia_is_active: 0,
    galaxiemedia_tag_id: ''
  },
  goodad: {
    goodad_is_active: 0,
    goodad_member: '',
    goodad_invcode: ''
  },
  gravity: {
    gravity_is_active: 0,
    gravity_placement_id: ''
  },
  improve: {
    improve_is_active: 0,
    improve_placement_id: ''
  },
  ix: {
    ix_is_active: 0,
    ix_site_id: ''
  },
  invibes: {
    invibes_is_active: 0,
    invibes_placement_id: ''
  },
  mediasquare: {
    mediasquare_is_active: 0,
    mediasquare_zone: '',
    mediasquare_code: ''
  },
  moneytag: {
    moneytag_is_active: 0,
    moneytag_placement_id: ''
  },
  nexx360: {
    nexx360_is_active: 0,
    nexx360_tag_id: '',
  },
  onetag: {
    onetag_is_active: 0,
    onetag_pub_id: ''
  },
  openx: {
    openx_is_active: 0,
    openx_del_domain: '',
    openx_unit: ''
  },
  pubmatic: {
    pubmatic_is_active: 0,
    pubmatic_publisher_id: '',
    pubmatic_placement_id: '',
    pubmatic_ad_slot: ''
  },
  revenuemaker: {
    revenuemaker_is_active: 0,
    //revenuemaker_account: '',
    revenuemaker_tag_id: ''
  },
  richaudience: {
    richaudience_is_active: 0,
    richaudience_pid: ''
  },
  rubicon: {
    rubicon_is_active: 0,
    rubicon_account_id: '',
    rubicon_site_id: '',
    rubicon_zone_id: '',
    rubicon_position: ''
  },
  smartadserver: {
    smartadserver_is_active: 0,
    smartadserver_network_id: '',
    smartadserver_site_id: '',
    smartadserver_page_id: '',
    smartadserver_format_id: ''
  },
  smilewanted: {
    smilewanted_is_active: 0,
    smilewanted_zone_id: '',
    smilewanted_position_type: ''
  },
  mediagrid: {
    mediagrid_is_active: 0,
    mediagrid_uuid: '',
  },
  test: {
    test_is_active: 0
  },
  ttd: {
    ttd_is_active: 0,
    ttd_endpoint: ''
  },
  traffective: {
    traffective_is_active: 0,
    traffictive_placement_id: ''
  },
  triplelift: {
    triplelift_is_active: 0,
    triplelift_inventory_code: ''
  },
  verizon: {
    verizon_is_active: 0,
    verizon_dcn: '',
    verizon_pos: ''
  }
}

export const formatStoredRequest = (sspDetails) => {
  const output = {
    id: '',
    name: '',
    sizes: [],
    is_archived: false,
    type: [],
    site_id: '',
    pricefloor_is_active: false,
    pricefloor_cpm: '',
    goodad_is_active: 0,
    goodad_member: '',
    goodad_invcode: '',
    adform_is_active: 0,
    adform_mid: '',
    adnuntius_is_active: 0,
    adnuntius_au_id: '',
    adot_is_active: 0,
    adyoulike_is_active: 0,
    adyoulike_placement: '',
    revenuemaker_is_active: 0,
    // revenuemaker_account: '',
    revenuemaker_tag_id: '',
    richaudience_is_active: 0,
    richaudience_pid: '',
    appnexus_is_active: 0,
    appnexus_placement_id: '',
    criteo_is_active: 0,
    criteo_network_id: '',
    conversant_is_active: 0,
    conversant_site_id: '',
    bliink_is_active: 0,
    bliink_tag_id: '',
    cwire_is_active: 0,
    cwire_page_id: '',
    cwire_placement_id: '',
    firstid_is_active: 0,
    firstid_tag_id: '',
    freewheel_is_active: 0,
    freewheel_zone_id: '',
    fueldigital_is_active: 0,
    fueldigital_site_id: '',
    fueldigital_page_id: '',
    fueldigital_format_id: '',
    fueldigitalix_is_active: 0,
    fueldigitalix_site_id: '',
    fueldigitalmedia_is_active: 0,
    fueldigitalmedia_tag_id: '',
    ix_is_active: 0,
    ix_site_id: '',
    // ix_endpoint: '',
    smartadserver_is_active: 0,
    smartadserver_network_id: '',
    smartadserver_site_id: '',
    smartadserver_page_id: '',
    smartadserver_format_id: '',
    rubicon_is_active: 0,
    rubicon_account_id: '',
    rubicon_site_id: '',
    rubicon_zone_id: '',
    rubicon_position: '',
    onetag_is_active: 0,
    onetag_pub_id: '',
    openx_is_active: 0,
    openx_del_domain: '',
    openx_unit: '',
    gravity_is_active: 0,
    gravity_placement_id: '',
    improve_is_active: 0,
    improve_placement_id: '',
    invibes_is_active: 0,
    invibes_placement_id: '',
    pubmatic_is_active: 0,
    pubmatic_publisher_id: '',
    pubmatic_placement_id: '',
    pubmatic_ad_slot: '',
    verizon_is_active: 0,
    verizon_dcn: '',
    verizon_pos: '',
    triplelift_is_active: 0,
    triplelift_inventory_code: '',
    test_is_active: 0,
    ttd_is_active: 0,
    ttd_endpoint: '',
    traffective_is_active: 0,
    traffictive_placement_id: '',
    smilewanted_is_active: 0,
    smilewanted_zone_id: '',
    smilewanted_position_type: '',
    mediasquare_is_active: 0,
    mediasquare_zone: '',
    mediasquare_code: '',
    mediagrid_is_active: 0,
    mediagrid_uuid: '',
    moneytag_is_active: 0,
    moneytag_placement_id: '',
    nexx360_is_active: 0,
    nexx360_tag_id: '',
  }
  Object.keys(sspDetails).forEach((ssp) => {
    Object.assign(output, sspDetails[ssp])
  })
  return output
}

export const formatSspDetails = (sspList, storedRequest) => {
  const output = {}
  sspList.forEach((ssp) => {
    output[ssp] = {}
    Object.keys(storedRequest).forEach((key) => {
      if (key.substr(0, ssp.length) == ssp) {
        output[ssp][key] = storedRequest[key]
      }
    })
  })
  return output
}

const getSspList = (sspDetails) => {
  const output = []
  Object.keys(sspDetails).forEach((ssp) => output.push(ssp))
  return output
}

export const sampleStoredRequest = formatStoredRequest(sspDetails)
export const sspList = getSspList(sspDetails)
