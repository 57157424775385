import { GetCustomers } from '@/store/action_types'

import { SetCustomers } from '@/store/mutation_types'

import axios from 'axios'

export const customersState = {
  customers: []
}

export const customersGetters = {
  customers: (state) => state.customers
}

export const customersActions = {
  async [GetCustomers]({ commit }) {
    const response = await axios.get('/api/customers')

    commit(SetCustomers, response.data)
    return response
  }
}

export const customersMutations = {
  [SetCustomers](state, customers) {
    state.customers = customers
  }
}

export default customersActions
