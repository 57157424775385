<template>
  <div>
    <v-tabs v-model="selectedTypeIndex" background-color="#f39200" dark left>
      <v-tab v-for="type in types" :key="type" @click="goTo(type)">
        {{ type }}
      </v-tab>
    </v-tabs>

    <v-tabs v-model="selectedDeviceIndex" fixed-tabs>
      <v-tab
        v-for="device in devices"
        :key="device.id"
        @click="selectedDevice(device)"
      >
        {{ device.name }}
      </v-tab>
    </v-tabs>

    <!-- This can be list or parameters -->
    <router-view
      :device="device"
      :page="page"
      @pageUpdated="$emit('pageUpdated')"
    >
    </router-view>
  </div>
</template>

<script>
  export default {
    props: ['page'],

    data() {
      return {
        devices: [
          { id: 1, name: 'desktop' },
          { id: 2, name: 'tablet' },
          { id: 3, name: 'mobile' }
        ],
        types: ['list', 'parameters'],
        selectedDeviceIndex: 0,
        selectedTypeIndex: 0,
        showDetails: true
      }
    },
    computed: {
      device() {
        const foo = this.devices.findIndex(
          (x) => x.name == this.$route.query.device
        )
        return foo == -1 ? this.devices[0] : this.devices[foo]
      }
    },
    watch: {
      device() {
        if (this.$route.query.device == undefined) {
          this.$route.query.device = this.devices[0].name
        }
      }
    },
    created() {
      this.selectedDeviceIndex = this.devices.findIndex(
        (x) => x.name == this.device.name
      )
      this.selectedTypeIndex =
        this.$route.name == 'tag_management_page_placements_PageTypeParameters'
          ? 1
          : 0
    },
    methods: {
      selectedDevice(device) {
        this.$router.push(this.$route.path + '?device=' + device.name)
      },
      updatePageName(event) {
        this.pageName = event.pageName
        //this.siteId = event.siteId
      },
      goTo(type) {
        this.$router.push(
          '/tag_management/sites/' +
            this.$route.params.site_id +
            '/pages/' +
            this.$route.params.page_id +
            '/placements/' +
            type
        )
      }
    }
  }
</script>
