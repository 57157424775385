<template>
  <v-form ref="form" lazy-validation>
    <v-container style="padding: 0px">
      <v-row>
        <v-col
          v-for="field in switches"
          :key="field.field"
          cols="12"
          md="4"
          class="pageForm"
        >
          <v-switch
            v-model="placement[field.field]"
            :label="field.label"
          ></v-switch>
        </v-col>
        <v-col
          v-for="field in type.fields"
          :key="field.name"
          cols="12"
          md="4"
          class="pageForm"
        >
          <v-text-field
            v-model="placement[field.name]"
            :label="field.label"
            :rules="getRules(field.rules)"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  export default {
    props: ['placement', 'type'],
    data() {
      return {
        arrayRules: [
          (v) => !!v || 'Required',
          (v) => (v && this.isJsonString(v)) || 'Needs to be an array / object'
        ],
        targetingRules: [
          (v) => !!v || 'Required',
          (v) => (v && this.isJsonString(v)) || 'Needs to be an array / object'
        ],
        switches: [
          { field: 'isBannerActive', label: 'Is Display Active' },
          {
            field: 'isVideoOutstreamActive',
            label: 'Is Video Outstream Active'
          }
          //{ field: 'isFluidActive', label: 'Is Size Fluid Active'}
        ]
      }
    },
    created() {},
    methods: {
      isJsonString(str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      },
      getRules(ruleName) {
        if (ruleName == 'arrayRules') {
          return this.arrayRules
        } else {
          return []
        }
      }
    }
  }
</script>
