<template>
  <v-app id="inspire" style="background-color: white">
    <app-topbarLogin></app-topbarLogin>
    <v-container fluid style="padding-top: 100px">
      <v-row>
        <v-col cols="4"
          ><h1 class="cnx">
            <strong>{{ $t('create_account') }}</strong>
          </h1>
          <textleft />
          <span style="padding-left: 20px; padding-top: 0px; font-weight: 520"
            ><strong>{{ $t('start_now') }}</strong></span
          >
        </v-col>
        <v-divider vertical></v-divider>
        <v-col v-if="status != 'confirmRegistration'" cols="1"></v-col>
        <v-col v-if="status != 'confirmRegistration'" cols="6">
          <v-form ref="form" v-model="valid" style="padding-top: 30px">
            <v-row no-gutters>
              <v-col cols="8">
                <p>{{ $t('name') }}</p>
                <v-text-field
                  v-model="contact.name"
                  :rules="required"
                  dense
                  solo
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="8">
                <p>{{ $t('email') }}</p>
                <v-text-field
                  v-model="contact.email"
                  :rules="emailRules"
                  dense
                  solo
                >
                </v-text-field>
              </v-col>
              <v-col cols="1" />
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p>{{ $t('message') }}</p>
                <v-textarea
                  ref="textToCopy"
                  v-model="contact.content"
                  :rules="required"
                  dense
                  solo
                  auto-grow
                  width="1200"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-btn
              :loading="loading"
              style="margin: 10px"
              class="white--text notcapital"
              color="black"
              width="250"
              dense
              @click="SendEmail()"
            >
              {{ $t('submit') }}
            </v-btn>
          </v-form>
        </v-col>
        <v-col v-else cols="7" style="padding-left: 30px">
          <confirmRegistration />
        </v-col>
      </v-row>
      <v-row v-if="status != 'confirmRegistration'">
        <v-col cols="4"> </v-col>
        <v-col cols="8" style="text-align: center">
          <p class="registerLogin">
            {{ $t('already_have_an_account?') }}
            <router-link v-slot="{ navigate }" to="/login" custom>
              <span
                style="color: #2e4089"
                role="button"
                @click="navigate"
                @keypress.enter="navigate"
                ><strong>{{ $t('login') }}</strong></span
              >
            </router-link>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import textleft from '@/pages/login/components/textleft.vue'
  import topbarLogin from '@/components/layout/topbarLogin.vue'
  import confirmRegistration from '@/pages/login/components/confirmationRegistration.vue'

  export default {
    components: {
      appTopbarLogin: topbarLogin,
      textleft,
      confirmRegistration
    },
    data() {
      return {
        contact: {
          email: '',
          name: '',
          content: ''
        },
        required: [(v) => !!v || 'Required'],
        emailRules: [
          (v) => !!v || 'required',
          (v) => /.+@.+\..+/.test(v) || 'Email must be valid'
        ],
        loading: false,
        valid: true,
        customer: {},
        status: 'registerUser'
      }
    },
    watch: {
      loader() {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 6000)

        this.loader = null
      }
    },
    methods: {
      async SendEmail() {
        if (this.$refs.form.validate() == false) return
        else {
          this.loading = true
          await axios.post(
            `/api/sendsupportemail/${this.contact.name}/${this.contact.email}/${this.contact.content}`
          )
          this.$refs.form.reset()
          this.loading = false
          this.status = 'confirmRegistration'
        }
      }
    }
  }
</script>
