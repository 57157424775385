<template>
  <v-card style="margin-top: 10px">
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>
    <v-card-title secondary-title>
      {{ placement_name }}
    </v-card-title>
    <v-card-text>
      <br /><br />
      <v-row>
        <v-col cols="12" md="4" class="pageForm">
          <v-text-field
            v-model="placement.elementName"
            label="Name"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-item-group v-model="placement.typeId">
          <v-item
            v-for="type in types"
            :key="type.id"
            v-slot:default="{ active, toggle }"
          >
            <v-btn
              class="mx-2"
              :input-value="active"
              active-class="indigo white--text"
              depressed
              rounded
              @click="toggle"
            >
              {{ type.name }}
            </v-btn>
          </v-item>
        </v-item-group>
      </v-row>

      <app-ad-type
        :placement="placement"
        :type="selectedType"
        style="margin-top: 20px"
      >
      </app-ad-type>
    </v-card-text>
    <v-divider></v-divider>
    <v-row>
      <v-card-title>
        <div style="font-size: 0.8em; margin-left: 15px; margin-right: 20px">
          Is Google AdX blocked
        </div>
        <v-switch v-model="placement.isAdxBlocked"></v-switch>
      </v-card-title>
    </v-row>
    <app-header-bidding
      v-for="ssp in Object.keys(hbConfig)"
      :key="ssp"
      :placement="placement"
      :ssp="ssp"
      :fields="hbConfig[ssp]"
    >
    </app-header-bidding>
    <v-row v-if="updated == true" style="padding-top: 20px">
      <v-col>
        <v-alert dense text type="success" color="green">
          placement updated successfully</v-alert
        >
      </v-col>
    </v-row>
    <v-card-actions class="d-flex flex-row-reverse">
      <v-btn
        :loading="saveLoading"
        :disabled="saveLoading"
        style="margin: 10px"
        class="white--text notcapital"
        color="black"
        width="130"
        dense
        @click="save"
      >
        <v-icon left> mdi-check-circle</v-icon>
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import AdType from './AdType.vue'
  import HeaderBidding from './HeaderBidding.vue'

  export default {
    components: {
      AppAdType: AdType,
      AppHeaderBidding: HeaderBidding
    },
    props: ['page', 'device', 'element'],
    data() {
      return {
        loading: true,
        updated: false,
        placement: {
          typeId: 0
        },
        placement_name: '',
        types: [
          {
            id: 0,
            name: 'Regular',
            fields: [
              { name: 'nexx360TagId', label: 'nexx360TagId', rules: '' },
              { name: 'sizes', label: 'Sizes', rules: 'arrayRules' },
              { name: 'style', label: 'Style', rules: '' },
              { name: 'targeting', label: 'Targeting', rules: 'targetingRules' }
            ]
          },
          {
            id: 1,
            name: 'Oop',
            fields: [
              { name: 'nexx360TagId', label: 'nexx360TagId', rules: '' },
              { name: 'targeting', label: 'Targeting', rules: 'targetingRules' }
            ]
          },
          {
            id: 2,
            name: 'Injected',
            fields: [
              { name: 'nexx360TagId', label: 'nexx360TagId', rules: '' },
              { name: 'sizes', label: 'Sizes', rules: '' },
              { name: 'style', label: 'Style', rules: '' },
              {
                name: 'injectedSelector',
                label: 'Injected Selector',
                rules: ''
              },
              {
                name: 'injectedPosition',
                label: 'Injected Position',
                rules: ''
              },
              {
                name: 'injectedRepetition',
                label: 'Injected Repetition',
                rules: ''
              },
              { name: 'targeting', label: 'Targeting', rules: 'targetingRules' }
            ]
          },
          {
            id: 3,
            name: 'Skyscraper',
            fields: [
              { name: 'sizes', label: 'Sizes', rules: '' },
              {
                name: 'skyscraperContentDiv',
                label: 'Skyscraper Content Div',
                rules: ''
              },
              {
                name: 'skyscraperVerticalOffsetElement',
                label: 'Skyscraper Vertical Offset Element',
                rules: ''
              },
              {
                name: 'skyscraperPosition',
                label: 'Skyscraper Position',
                rules: ''
              },
              {
                name: 'skyscraperHeaderSelector',
                label: 'Skyscraper Header Selector',
                rules: ''
              },
              { name: 'targeting', label: 'Targeting', rules: 'targetingRules' }
            ]
          }
        ],
        activeTypeId: 0,
        hbConfig: {
          appnexus: ['placementId'],
          smart: ['domain', 'siteId', 'pageId', 'formatId'],
          rubicon: ['accountId', 'siteId', 'zoneId', 'position'],
          triplelift: ['inventoryCode'],
          freewheelSsp: ['zoneId'],
          teads: ['pageId', 'placementId'],
          openx: ['domain', 'unit'],
          audienceNetworkNative: ['placementId'],
          audienceNetworkDisplay: ['placementId'],
          gravity: ['placementId'],
          onemobile: ['dcn', 'pos'],
          pubmatic: ['publisherId', 'siteId'],
          justpremium: ['zone', 'allow'],
          sublime: ['zoneId'],
          adagio: ['site'],
          richaudience: ['pid'],
          smilewanted: ['zoneId']
        },
        saveLoading: false
      }
    },
    computed: {
      selectedType() {
        let foo =
          this.types[
            this.types.findIndex((x) => x.id === this.placement.typeId)
          ]
        if (foo != undefined) return foo
        else return this.types[0]
      }
    },
    watch: {
      element() {
        this.getDataFromApi()
      }
    },
    created() {
      this.getDataFromApi()
      this.placement_name = this.placement.elementName
    },

    methods: {
      async getDataFromApi() {
        this.loading = true
        try {
          const response = await axios.get(
            `/api/getwplacementbyid/${this.element.id}`
          )
          this.placement = response.data
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      isJsonString(str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      },
      async save() {
        this.saveLoading = true
        /*if (this.placement.isBannerActive == false)
          this.placement.isBannerActive = 0
        if (this.placement.isVideoOutstreamActive == false)
          this.placement.isVideoOutstreamActive = 0*/
        try {
          await axios.put(
            `/api/wplacements/${this.placement.id}`,
            this.placement
          )
          this.$emit('change')
          this.updated = true
          this.saveLoading = false
          setTimeout(() => (this.updated = false), 2000)
        } catch (e) {
          this.saveLoading = false
        }
      }
    }
  }
</script>
