<template>
  <v-container>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#2E418A"
      dark
    >
      <v-app-bar-nav-icon
        class="mobile"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="ml-0 pl-0" style="padding-right: 122px">
        <v-img
          width="100"
          class="mx-2"
          src="@/assets/nexx360_logoB.png"
          max-height="40"
          contain
        />
      </v-toolbar-title>
      <v-divider vertical></v-divider>
      <app-customer-selector
        v-if="
          $store.getters.is_superadmin || appCustomer.code.startsWith('prisma')
        "
        style="padding-left: 15px"
        @update-customer="updateAppCustomer"
      />
      <span
        v-else
        style="
          font-size: 16px;
          padding-right: 3%;
          padding-top: 0.3%;
          padding-left: 15px;
        "
      >
        {{ $t('customer_code') }} : {{ appCustomer.code }}
      </span>

      <v-spacer></v-spacer>
      <p style="margin-top: 18px; padding-right: 20px">
        Customer ID :
        {{ $store.getters.appCustomer.id }}
      </p>
      <v-divider vertical />
      <p style="margin-top: 18px; padding-left: 20px">
        {{ $store.getters.appUser.first_name }}
        {{ $store.getters.appUser.last_name }}
      </p>
      <v-menu ref="menuRef" rounded bottom min-width="200px" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon class="no-shadow" v-on="on">
            <v-avatar size="20" tile>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card style="margin-top: 8px">
          <div class="mx-auto text-left">
            <v-list class="justify-left">
              <v-list-item
                v-if="$store.getters.appUser.role != 'user'"
                @click="$router.push('/myaccount')"
              >
                <v-icon>mdi-account-circle-outline</v-icon>
                <p style="padding-left: 10px; padding-top: 17px">
                  {{ $t('my_account') }}
                </p>
              </v-list-item>
              <!-- <v-divider
                v-if="$store.getters.appUser.role != 'user'"
              ></v-divider>
              <v-list-item
                v-if="$store.getters.appUser.role == 'superadmin'"
                @click="$router.push('/customers')"
              >
                <v-icon>mdi-account-tie</v-icon>
                <p style="padding-left: 10px; padding-top: 17px">
                  {{ $t('customers') }}
                </p>
              </v-list-item>
              <v-list-item
                v-if="$store.getters.appUser.role == 'superadmin'"
                @click="$router.push('/customer-reporting')"
              >
                <v-icon>mdi-chart-areaspline</v-icon>
                <p style="padding-left: 10px; padding-top: 17px">
                  {{ $t('results') }}
                </p>
              </v-list-item>
              <v-list-item
                v-if="$store.getters.appUser.role == 'superadmin'"
                @click="$router.push('/nexx-reporting')"
              >
                <v-icon>mdi-chart-bar</v-icon>
                <p style="padding-left: 10px; padding-top: 17px">
                  {{ $t('nexx reporting') }}
                </p>
              </v-list-item>-->
              <v-divider
                v-if="$store.getters.appUser.role != 'user'"
              ></v-divider>
              <v-list-item @click="logout">
                <v-icon>mdi-logout</v-icon>
                <p style="padding-left: 10px; padding-top: 17px">
                  {{ $t('sign_out') }}
                </p>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-menu>
    </v-app-bar>
    <app-sidebar
      class="mobile"
      :parent-drawer="drawer"
      @closed="drawer = false"
    ></app-sidebar>
  </v-container>
</template>

<script>
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { Logout } from '@/store/action_types'
  import { SetAppCustomer } from '@/store/mutation_types'
  import CustomerSelector from './../customer/selector'
  import sidebar from '@/components/layout/sidebar.vue'
  import { SetSelectedSite } from '@/store/mutation_types'

  export default {
    components: {
      AppCustomerSelector: CustomerSelector,
      appSidebar: sidebar
    },
    data: function () {
      return {
        name: '',
        drawer: null
      }
    },
    computed: {
      ...mapGetters(['appCustomer'])
    },
    methods: {
      ...mapActions([Logout]),
      ...mapMutations([SetAppCustomer, SetSelectedSite]),
      updateAppCustomer(customer) {
        //console.log('topbar', customer)
        this.SetAppCustomer(customer)
        //this.$router.push({ path: '/', query: { t: new Date().getTime() } })
      },
      logout() {
        this.Logout()
        this.$router.push('/login')
        this.name = ''
      }
    }
  }
</script>

<style scoped>
  .mobile {
    display: none;
  }
  @media only screen and (max-width: 908px) {
    .mobile {
      display: flex;
    }
  }
</style>
