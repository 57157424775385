<template>
  <div id="PageContent">
    <v-container fluid>
      <v-card id="PageList">
        <v-card-title primary-title>
          <v-col cols="12"> List of proposals </v-col>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 10px"
                class="white--text notcapital"
                color="black"
                width="200"
                dense
                @click="newProposal()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add a new proposal
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="$route.params.site_id != '0'">
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-data-table
                  :headers="headers"
                  :sort-by="['name']"
                  :items-per-page="10"
                  :items="proposals"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  :disable-pagination="true"
                  :single-expand="false"
                  show-expand
                  :expanded.sync="expanded"
                  style="background-color: #f9f9fb; cursor: pointer"
                  @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                  <template v-slot:[`item.proposal_state`]="props">
                    <td>
                      <div class="proposal_details_col" style="display: flex">
                        <div class="proposal_details_col">
                          <span
                            v-if="props.item.proposal_state == 'Proposed'"
                            class="dot"
                            style="background-color: orange"
                          ></span>
                          <span
                            v-if="props.item.proposal_state == 'Draft'"
                            class="dot"
                            style="background-color: gray"
                          ></span>
                        </div>
                        <div class="proposal_details_col">
                          <strong>{{ props.item.proposal_state }}</strong>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-slot:[`item.actions`]="props">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="#000"
                          v-bind="attrs"
                          v-on="on"
                          @click="editItem(props.item)"
                        >
                          <v-icon size="20">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit the proposal</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="#000"
                          v-bind="attrs"
                          v-on="on"
                          @click="goToDeals(props.item)"
                        >
                          <v-icon size="20">mdi-table-arrow-up</v-icon>
                        </v-btn>
                      </template>
                      <span>Manage the deals</span>
                    </v-tooltip>
                  </template>
                  <template v-if="loading" v-slot:expanded-item="">
                    <td class="proposal_details" :colspan="12">
                      <v-progress-linear
                        indeterminate
                        color="indigo"
                      ></v-progress-linear>
                    </td>

                    <!-- here use a loaded you prefer -->
                  </template>
                  <template v-else v-slot:expanded-item="{ headers, item }">
                    <td class="proposal_details" :colspan="headers.length">
                      <v-container>
                        <div style="display: flex">
                          <div style="flex: 1">
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Status :</p>
                              </div>
                              <div class="proposal_details_col">
                                <span
                                  v-if="item.proposal_state == 'Proposed'"
                                  class="dot"
                                  style="background-color: orange"
                                ></span>
                                <span
                                  v-if="item.proposal_state == 'Draft'"
                                  class="dot"
                                  style="background-color: gray"
                                ></span>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.proposal_state }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="item.proposal_state != 'Draft'"
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Proposal ID :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.proposal_id }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="item.proposal_state != 'Draft'"
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Revision number :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.revision_number }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Proposal name :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.name }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Deals type :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.deal_type }}</strong>
                              </div>
                            </div>
                          </div>
                          <div style="flex: 1">
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Number of deals :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.nb_deals }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Buyer name :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.buyer_name }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Seller name :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.seller_name }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="item.proposal_state != 'Draft'"
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Create time :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{
                                  getDateFromTimeStamp(
                                    item.create_time
                                  ).replace('T', ' ')
                                }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="
                                item.create_time != item.update_time &&
                                item.proposal_state != 'Draft'
                              "
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Update time :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{
                                  getDateFromTimeStamp(
                                    item.update_time
                                  ).replace('T', ' ')
                                }}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <v-row>
                          <v-col
                            cols="11"
                            align="left"
                            justify="left"
                            style="margin-left: 15px"
                          >
                            <span v-if="errors.length > 0" width="700">
                              <v-alert
                                v-for="error in errors"
                                :key="error"
                                color="#db2727"
                                type="error"
                                dense
                                >{{ error }}</v-alert
                              >
                            </span>
                          </v-col>
                        </v-row>
                        <v-card-actions class="d-flex flex-row-reverse">
                          <v-btn
                            v-if="
                              item.proposal_id == 0 || item.proposal_id == null
                            "
                            :loading="submitLoading"
                            :disabled="submitLoading"
                            style="margin: 10px"
                            class="white--text notcapital"
                            color="black"
                            width="250"
                            dense
                            @click="sendProposal(item)"
                          >
                            Send the proposal request
                            <v-icon right> mdi-email-fast </v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            :loading="submitLoading"
                            :disabled="submitLoading"
                            style="margin: 10px"
                            class="white--text notcapital"
                            color="black"
                            width="300"
                            dense
                            @click="sendProposal(item)"
                          >
                            Update the proposal request
                            <v-icon right> mdi-update </v-icon>
                          </v-btn>
                          <deleteProposal
                            v-if="
                              item.proposal_id != 0 && item.proposal_id != null
                            "
                            :item="item"
                            @proposalDeleted="deleteProposal"
                          >
                          </deleteProposal>
                        </v-card-actions>
                      </v-container>
                    </td>
                  </template>
                  <!-- <tr class="proposal_details_row">
                        <td class="cols_proposal_details">Status :</td>
                        <td class="cols_proposal_details">
                          <span
                            v-if="item.proposal_state == 'Proposed'"
                            class="dot"
                            style="background-color: orange"
                          ></span>
                          <span
                            v-if="item.proposal_state == 'Draft'"
                            class="dot"
                            style="background-color: gray"
                          ></span>
                        </td>
                        <td class="cols_proposal_details">
                          <strong>{{ item.proposal_state }}</strong>
                        </td>
                      </tr>
                      <tr class="proposal_details_row">
                        <td class="cols_proposal_details">Date created :</td>
                        <td class="cols_proposal_details">
                          <strong>{{ item.create_time }}</strong>
                        </td>
                      </tr>-->
                </v-data-table>
                <!--  <v-row>
                  <v-col
                    cols="12"
                    style="
                      padding-top: 0px;
                      padding-left: 15px;
                      padding-bottom: 20px;
                    "
                    align="right"
                    justify="right"
                  >
                    <v-btn
                      class="black--text notcapital"
                      width="250"
                      height="30"
                      outlined
                      style="margin-top: 20px"
                      @click="showArchived = !showArchived"
                    >
                      <v-icon left>
                        {{
                          showArchived ? 'mdi-eye-off-outline' : 'mdi-archive'
                        }}</v-icon
                      >
                      {{
                        showArchived
                          ? 'Hide archived proposals'
                          : 'Show archived proposals'
                      }}
                    </v-btn>
                  </v-col>
                </v-row>-->
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="proposalsent" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ proposal_message }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  import delete_proposal_request from './delete_proposal_request.vue'

  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import { SetSelectedProposal } from '@/store/mutation_types'
  import {
    GetProposals,
    GetDeals,
    SaveSelectedProposal,
    SaveSelectedDeal
  } from '@/store/action_types'
  export default {
    components: {
      deleteProposal: delete_proposal_request
    },

    data() {
      return {
        headers: [
          {
            text: this.$t('Id'),
            align: 'left',
            value: 'proposal_id'
          },
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('buyer_name'),
            align: 'left',
            value: 'buyer_name'
          },
          {
            text: this.$t('seller_name'),
            align: 'left',
            value: 'seller_name'
          },
          {
            text: this.$t('status'),
            align: 'left',
            value: 'proposal_state'
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
            sortable: false
          }
        ],
        loadingProposal: false,
        showArchived: false,
        proposalsent: false,
        timeout: 3000,
        expanded: [],
        singleExpand: false,
        submitLoading: false,
        loading: false,
        proposal_message: 'Proposal request sent successfully',
        errors: []
      }
    },
    computed: {
      ...mapGetters(['proposals', 'deals'])
    },
    watch: {},

    async created() {
      await this.GetProposals()
    },
    methods: {
      ...mapMutations([SetSelectedProposal]),
      ...mapActions({
        GetProposals,
        GetDeals,
        SaveSelectedProposal,
        SaveSelectedDeal
      }),
      newProposal() {
        this.$router.push({
          name: 'proposal_new'
        })
      },
      editItem(proposal) {
        this.SetSelectedProposal(proposal)
        this.$router.push({
          name: 'proposal_edit',
          params: {
            proposal_id: proposal.id
          }
        })
      },
      goToDeals(proposal) {
        this.SetSelectedProposal(proposal)
        this.$router.push({
          name: 'deal_list',
          params: {
            proposal_id: proposal.id
          }
        })
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      },
      async InitializeProposal(proposal) {
        var proposalUpdated = {
          id: proposal.id,
          proposal_id: null,
          revision_number: null,
          proposal_state: 'Draft',
          last_updated_by: 'Buyer',
          create_time: null,
          update_time: null
        }
        await this.SaveSelectedProposal(proposalUpdated)
        await this.GetDeals(proposal.id)
        this.deals.forEach(async (item) => {
          var deal = {
            id: item.id,
            deal_id: null,
            create_time: null,
            update_time: null
          }
          await this.SaveSelectedDeal(deal)
        })
      },
      async deleteProposal(proposal) {
        try {
          const response = await axios.post(`/api/deleteproposal`, proposal)
          await this.InitializeProposal(proposal)
          await this.GetProposals()
          this.proposalsent = true
          this.proposal_message = 'Proposal request deleted successfully'
        } catch (error) {
          return error
        }
      },
      async verifyDeals(proposal) {
        await this.GetDeals(proposal.id)
        if (this.deals.length == 0)
          this.errors.push(
            'You need to add at least a deal before sending your proposal request'
          )
        else
          this.deals.forEach((deal) => {
            if (deal.rate_amount == 0.0)
              this.errors.push(
                'The deal with id = ' +
                  deal.id +
                  ' has the value of 0 as rate amount'
              )
          })
      },
      async sendProposal(proposal) {
        this.submitLoading = true
        await this.verifyDeals(proposal)
        if (this.errors.length == 0) {
          var proposalToSend = await this.getProposalJson(proposal)
          try {
            var response = {}
            if (proposal.proposal_id != 0 && proposal.proposal_id != null) {
              response = await axios.post(`/api/updateproposal`, proposalToSend)
              this.proposal_message = 'Proposal request updated successfully'
            } else {
              response = await axios.post(`/api/sendproposal`, proposalToSend)
              this.proposal_message = 'Proposal request sent successfully'
            }
            const proposalFromApi = response.data
            console.log('response from API =>', proposalFromApi)
            if (proposalFromApi.ProposalId != undefined) {
              var proposalUpdated = {
                id: proposal.id,
                proposal_id: proposalFromApi.ProposalId,
                revision_number: proposalFromApi.RevisionNumber,
                proposal_state: proposalFromApi.ProposalState,
                last_updated_by: proposalFromApi.LastUpdatedBy,
                create_time:
                  new Date(proposalFromApi.CreatedTime.slice(0, -1)).getTime() /
                  1000,
                update_time:
                  new Date(proposalFromApi.UpdatedTime.slice(0, -1)).getTime() /
                  1000
              }
              //updating the proposal
              await this.SaveSelectedProposal(proposalUpdated)

              proposalFromApi.Deals.forEach(async (dealFromApi) => {
                var deal = {
                  id: dealFromApi.ExternalDealId,
                  deal_id: dealFromApi.DealId,
                  create_time:
                    new Date(dealFromApi.CreateTime.slice(0, -1)).getTime() /
                    1000,
                  update_time:
                    new Date(dealFromApi.UpdateTime.slice(0, -1)).getTime() /
                    1000
                }
                //updating the deal
                await this.SaveSelectedDeal(deal)
              })
              this.proposalsent = true
              this.submitLoading = false
              await this.GetProposals()
            } else {
              console.log('ALREADY SENT')
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          this.submitLoading = false
          setTimeout(() => (this.errors = []), 4000)
        }
      },
      async getProposalJson(proposal) {
        await this.GetDeals(proposal.id)
        const proposal_response = await axios.get(
          `/api/getproposalinfo/${proposal.id}`
        )
        const proposalinfo = proposal_response.data
        var dealsInTheProposal = []
        this.deals.forEach(async (deal) => {
          var item = {
            DisplayName: deal.name,
            ExternalDealId: deal.id,
            PublisherIds: [],
            DealType: proposalinfo[0].deal_type,
            AuctionType: deal.auction_type,
            StartTime: this.getDateFromTimeStamp(deal.start_time),
            CreativeRestrictions: {
              CreativeFormat: deal.creative_format
            }
          }
          if (proposalinfo[0].deal_type == 'Preferred') {
            item['PreferredDealTerms'] = {
              RateType: 'CPM',
              Rate: {
                Amount: Number(deal.rate_amount),
                CurrencyCode: deal.rate_currency
              }
            }
          } else {
            item['PrivateAuctionTerms'] = {
              RateType: 'CPM',
              Rate: {
                Amount: deal.rate_amount,
                CurrencyCode: deal.rate_currency
              }
            }
          }
          if (deal.deal_id != null) item['DealId'] = deal.deal_id
          if (deal.end_time != null && deal.end_time != 0)
            item['EndTime'] = this.getDateFromTimeStamp(deal.end_time)
          dealsInTheProposal.push(item)
        })
        var proposalToSend = {
          DisplayName: proposalinfo[0].name,
          Buyer: {
            BuyerId: proposalinfo[0].buyer_id,
            BuyerName: proposalinfo[0].buyer_name
          },
          Seller: {
            SellerId: proposalinfo[0].seller_id,
            SellerName: proposalinfo[0].seller_name
          }
        }
        proposalToSend['Deals'] = dealsInTheProposal
        if (proposal.revision_number != undefined)
          proposalToSend['RevisionNumber'] = proposal.revision_number
        if (proposal.proposal_id != 0)
          proposalToSend['ProposalId'] = proposal.proposal_id

        return proposalToSend
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
  .clickable {
    cursor: pointer;
  }
  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
  .proposal_details_row {
    padding-top: 20px !important;
    font-size: 14px;
  }
  .proposal_details_col {
    padding-left: 10px;
  }
  .proposal_details {
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    font-family: Roboto;
    background: whitesmoke;
    box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 25%),
      inset 0px -4px 8px -5px rgb(50 50 50 / 100%);
  }
  .element {
    display: inline-block;
  }
</style>
