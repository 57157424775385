import { SetAppUser } from '@/store/mutation_types'

export const appUserState = {
  appUser: {}
}

export const appUserGetters = {
  is_admin: (state) => state.appUser.is_admin,
  is_superadmin: (state) => state.appUser.is_superadmin,
  appUser: (state) => state.appUser
}

export const appUserActions = {}

export const appUserMutations = {
  [SetAppUser](state, user) {
    state.appUser = user
  }
}
