<template>
  <v-container fluid>
    <v-card>
      <v-card-title v-if="$route.name == 'proposal_edit'" primary-title>
        Updating the proposal "{{ proposalName }}"
      </v-card-title>
      <v-card-title v-else primary-title> Creating a proposal </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row class="nopadding">
              <v-col class="nopadding" cols="5">
                <p>
                  {{ $t('proposal_name')
                  }}<strong style="color: red"> *</strong>
                </p>
                <v-text-field
                  v-model="proposal.name"
                  :rules="required"
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  dense
                  solo
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nopadding">
              <v-col cols="6" class="nopadding"
                ><p>{{ $t('buyer') }}</p>
                <v-autocomplete
                  v-model="proposal.buyer_id"
                  :items="buyers"
                  clearable
                  item-text="text"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-autocomplete
              ></v-col>
            </v-row>
            <v-row class="nopadding">
              <v-col cols="6" class="nopadding"
                ><p>{{ $t('seller') }}</p>
                <v-autocomplete
                  v-model="proposal.seller_id"
                  :items="sellers"
                  clearable
                  item-text="name"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-autocomplete
              ></v-col>
            </v-row>
            <v-radio-group
              row
              style="margin-top: 5px"
              v-model="proposal.deal_type"
              :rules="required"
            >
              <v-radio label="Private Auction" value="PrivateAuction"></v-radio>
              <v-radio label="Preferred" value="Preferred"></v-radio>
            </v-radio-group>
            <v-row no-gutters class="nopadding">
              <v-col cols="12" class="nopadding">
                <p>{{ $t('notes') }}</p>
                <v-textarea
                  v-model="proposal.notes"
                  dense
                  solo
                  outlined
                  auto-grow
                  width="1200"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="100"
            dense
            @click="createProposal"
          >
            {{ $t('save') }}
            <v-icon right> mdi-content-save-outline </v-icon>
          </v-btn>
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="100"
            dense
            @click="back"
          >
            <v-icon left> mdi-cancel </v-icon>
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar v-model="proposalcreated" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ proposalUpdatedMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapActions, mapGetters } from 'vuex'
  import {
    GetSelectedProposal,
    SaveSelectedProposal
  } from '@/store/action_types'

  export default {
    name: 'UserEdit',
    components: {},
    data() {
      return {
        proposal: {
          name: ''
        },
        advertiser_tf: '',
        advertiser_filters: [],
        buyers: [],
        sellers: [],
        submitLoading: false,
        required: [(v) => !!v || 'Required'],
        proposalcreated: false,
        timeout: 3000,
        proposalUpdatedMessage:
          this.$route.params.proposal_id == undefined
            ? 'Proposal created successfully'
            : 'Proposal updated successfully',
        errors: [],
        proposalName: '',
        errorAdvertiserFilters: '',
        selectedAdvertiserFilters: null
      }
    },
    computed: {
      ...mapGetters(['selectedProposal'])
    },
    watch: {
      selectedAdvertiserFilters: async function (val, oldVal) {
        this.selectedAdvertiserFilters = null
      }
    },
    async created() {
      const response_buyer = await axios.get(`/api/ttdbuyer`)
      this.buyers = response_buyer.data
      this.buyers.forEach((element) => {
        element['text'] = element['id'] + ' - ' + element['name']
      })
      const response_seller = await axios.get(`/api/ttdseller`)
      this.sellers = response_seller.data
      if (this.$route.name != 'proposal_new') {
        await this.GetSelectedProposal(this.$route.params.proposal_id)
        this.proposal = this.selectedProposal
        this.proposalName = this.selectedProposal.name
      }
    },
    methods: {
      ...mapActions([GetSelectedProposal, SaveSelectedProposal]),
      back() {
        this.$router.push({
          name: 'proposal_list'
        })
      },
      async createProposal() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        const output = await this.SaveSelectedProposal(this.proposal).catch(
          () => {
            this.submitLoading = false
            return false
          }
        )
        this.proposalcreated = true
        setTimeout(
          () =>
            this.$router.push({
              name: 'proposal_list'
            }),
          2000
        )
      },
      addAdvertiser(advertiser) {
        var exists = false
        this.advertiser_filters.forEach((element) => {
          if (element == advertiser) {
            exists = true
          }
        })
        if (exists == false) {
          this.advertiser_filters.push(advertiser)
          this.advertiser_tf = ''
        } else {
          this.errorAdvertiserFilters = 'You already added this advertiser'
          setTimeout(() => (this.errorAdvertiserFilters = ''), 2000)
        }
      },
      deleteAdvertiserFilters(index) {
        this.advertiser_filters.splice(index, 1)
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .expansion_panel {
    padding: 20px;
    margin-left: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 30px;
  }
  .v-expansion-panels {
    z-index: unset;
    box-shadow: 0.2px 0.2px 0.5px 0.5px lightgrey;
  }
  .v-expansion-panel {
    border: 0.5px solid lightgrey;
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
    > .v-expansion-panel--active
    + .v-expansion-panel {
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
    > .v-expansion-panel--active {
    margin-top: 0px;
  }
  .float-container {
    border: 3px solid #fff;
  }
  .site_targeting_btns {
    width: 6%;
    float: left;
    padding: 5px;
  }
  .site_div {
    width: 70%;
    margin: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    overflow: scroll;
  }
  .include_targeting {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    margin: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
</style>
