import { GetGamSetups } from '@/store/action_types'

import { SetGamSetups } from '@/store/mutation_types'

import axios from 'axios'

export const gamsetupsState = {
  gamsetups: []
}

export const gamsetupsGetters = {
  gamsetups: (state) => state.gamsetups
}

export const gamsetupsActions = {
  async [GetGamSetups]({ commit }, customerId) {
    let url = `/api/gamsetup/${customerId}`
    const response = await axios.get(url)
    commit(SetGamSetups, response.data)
    return response
  }
}

export const gamsetupsMutations = {
  [SetGamSetups](state, gamsetups) {
    state.gamsetups = gamsetups
  }
}

export default gamsetupsActions
