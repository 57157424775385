<template>
  <v-container style="padding-top: 0px">
    <v-row
      v-if="
        selectedSite.id == undefined &&
        this.$store.state.appCustomer.id == $route.params.customer_id
      "
    >
      <v-container>
        <v-row>
          <v-col
            class="noPaddingCenter"
            cols="12"
            style="padding: 15px 0px 0px"
          >
            <v-img
              class="mx-2"
              src="@/assets/logoEarth.png"
              max-height="120"
              contain
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="noPaddingCenter" cols="12">
            <p style="padding-left: 20px; font-size: 27px">
              {{ $t('no_site_added') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" />
          <v-col class="noPaddingCenter" cols="10">
            <p style="padding-left: 20px; font-size: 17px">
              {{ $t('website_description_first_line') }} <br />{{
                $t('website_description_second_line')
              }}
            </p>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row style="padding-top: 20px">
          <v-col cols="1" />
          <v-col cols="10" class="noPaddingCenter">
            <v-btn
              class="black--text notcapital"
              tile
              outlined
              color="black"
              @click.stop="$emit('showDialog', 'add')"
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t('add_website') }}
            </v-btn>
            <v-btn
              class="black--text notcapital"
              tile
              disabled
              style="margin-left: 20px"
              outlined
              color="black"
            >
              {{ $t('gam_setup') }}
            </v-btn>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </v-container>
    </v-row>
    <v-row
      v-else-if="
        selectedSite.id != undefined && computedStoredRequests.length == 0
      "
    >
      <v-container>
        <v-row>
          <v-col
            class="noPaddingCenter"
            cols="12"
            style="padding: 15px 0px 0px"
          >
            <v-img
              class="mx-2"
              src="@/assets/logoEarth.png"
              max-height="120"
              contain
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="noPaddingCenter" cols="12">
            <p style="padding-left: 20px; font-size: 27px">
              {{ $t('configure_your_placements') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" />
          <v-col class="noPaddingCenter" cols="10">
            <p style="padding-left: 20px; font-size: 17px">
              {{ $t('placement_description_first_line') }} <br />{{
                $t('placement_description_second_line')
              }}
            </p>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row style="padding-top: 20px">
          <v-col cols="1" />
          <v-col cols="10" class="noPaddingCenter">
            <v-btn
              class="black--text notcapital"
              tile
              outlined
              color="black"
              @click.stop="$emit('createSR')"
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t('add placement') }}
            </v-btn>
            <v-btn
              class="black--text notcapital"
              tile
              disabled
              style="margin-left: 20px"
              outlined
              color="black"
            >
              {{ $t('gam_setup') }}
            </v-btn>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </v-container>
    </v-row>

    <v-row
      v-else-if="
        $store.getters.is_superadmin == false &&
        this.$store.state.appCustomer.id != $route.params.customer_id
      "
    >
      <v-container>
        <v-row>
          <v-col
            class="noPaddingCenter"
            cols="12"
            style="padding: 15px 0px 0px"
          >
            <v-img
              class="mx-2"
              src="@/assets/accessDenied.png"
              max-height="180"
              contain
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="noPaddingCenter" cols="12">
            <p style="padding-left: 20px; font-size: 32px">
              {{ $t('access denied') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row
      v-else-if="
        selectedSite.id != undefined && computedStoredRequests.length > 0
      "
    >
      <v-col style="padding-top: 0px">
        <v-container fluid>
          <v-row v-if="$route.params.type == 'amp'">
            <v-col cols="12" style="padding-top: 0px; padding-bottom: 80px">
              <v-alert
                colored-border
                type="info"
                elevation="1"
                style="margin: 0px; font-size: 15px"
              >
                {{ $t('placements_configuration_first_line') }} <br />
                {{ $t('placements_configuration_second_line') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-card-title>
            <v-row>
              <v-col cols="12" style="padding: 0px 0px 10px 0px">
                <v-card
                  style="padding: 0px 10px 10px 10px; background-color: #f9f9fb"
                >
                  <v-row>
                    <v-icon style="padding: 0px 10px 0px 10px" size="28"
                      >mdi-filter-outline</v-icon
                    >
                    <v-col col="2" style="padding: 15px 20px 0px 10px">
                      <v-select
                        v-model="namesfilter"
                        label="Filter by name"
                        flat
                        dense
                        hide-details
                        small
                        multiple
                        clearable
                        :items="
                          computedStoredRequests
                            .map((d) => d['name'])
                            .sort((a, b) => a.localeCompare(b))
                        "
                      >
                      </v-select>
                    </v-col>
                    <v-col col="2" style="padding: 15px 0px 20px 0px">
                      <v-select
                        v-model="sspsfilter"
                        label="Filter by SSP"
                        flat
                        dense
                        hide-details
                        small
                        multiple
                        clearable
                        :items="sspListFilter"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2" />
                    <v-col cols="4" style="padding: 15px 10px 0px 0px">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        dense
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="filteredStoredRequests"
            :sort-by="['name']"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            :loading="data_table_loading"
            hide-default-footer
            :disable-pagination="true"
            style="background-color: #f9f9fb"
          >
            <template v-slot:item="props">
              <tr
                :active="props.selected"
                @click="props.selected = !props.selected"
              >
                <td>
                  {{ props.item.id }}
                </td>
                <td v-if="props.item.is_archived == 1" style="opacity: 0.5">
                  {{ props.item.name }}
                </td>
                <td v-else>
                  <strong>{{ props.item.name }}</strong>
                </td>
                <td v-if="sspsElementsByItem(props.item)" class="text-xs-right">
                  <tr>
                    <td v-for="ssp in SspList" :key="ssp">
                      <v-btn
                        v-if="props.item[ssp + '_is_active']"
                        small
                        color="white"
                        class="ssp_tab"
                      >
                        {{ $t(ssp) }}
                      </v-btn>
                    </td>
                  </tr>
                </td>
                <td v-else class="text-xs-right">
                  <span>
                    <v-icon size="30" color="red darken-1">
                      mdi-alert-octagram
                    </v-icon>
                    {{ $t('warning_ssp_empty') }}
                  </span>
                </td>
                <td>
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon color="black">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="$emit('update', props.item)">{{
                        $t('edit_placement')
                      }}</v-list-item>
                      <v-list-item @click="$emit('duplicate', props.item)">{{
                        $t('duplicate_placement')
                      }}</v-list-item>
                      <v-list-item
                        v-if="props.item.is_archived == 0"
                        @click="$emit('archiveItem', props.item)"
                      >
                        {{ $t('archive_placement') }}</v-list-item
                      >
                      <v-list-item
                        v-else
                        @click="$emit('unarchiveItem', props.item)"
                      >
                        {{ $t('unarchive_placement') }}</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-switch v-model="archived" :label="`${switch_text}`"></v-switch>
            </v-col>
          </v-row>
          <!--<v-col cols="12">
            <p style="font-size: 15px">{{ $t('archiving_explanation') }}</p>
          </v-col>-->
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SiteList',
    data() {
      return {
        headers: [
          {
            text: this.$t('Id'),
            align: 'left',
            value: 'id'
          },
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('ssp'),
            align: 'left',
            value: 'SSP',
            sortable: false
          },
          {
            text: this.$t('Actions'),
            align: 'left',
            value: '',
            sortable: false
          }
        ],
        switch_text: this.$t('show archivedSR'),
        search: '',
        data_table_loading: false,
        loading: false,
        existsitem: true,
        archived: false,
        namesfilter: [],
        sspsfilter: []
      }
    },
    computed: {
      ...mapGetters([
        'appCustomer',
        'storedRequests',
        'selectedSite',
        'SspList'
      ]),
      computedStoredRequests() {
        if (this.$route.name == 'fast') {
          return this.archived
            ? this.storedRequests.filter((el) => {
                return el.type == 'fast'
              })
            : this.storedRequests.filter((el) => {
                return el.is_archived == 0 && el.type == 'fast'
              })
        } else {
          return this.archived
            ? this.storedRequests.filter((el) => {
                return el.type == 'amp'
              })
            : this.storedRequests.filter((el) => {
                return el.is_archived == 0 && el.type == 'amp'
              })
        }
      },
      filteredStoredRequests() {
        return this.computedStoredRequests
          .filter((d) => {
            return (
              this.namesfilter.includes(d['name']) ||
              this.namesfilter.length < 1
            )
          })
          .filter((s) => {
            return this.sspsfilter.length < 1 || this.checkssp(s) === true
          })
      },
      sspListFilter() {
        let activatedSspList = []
        this.computedStoredRequests.forEach((item) => {
          this.SspList.forEach((element) => {
            if (item[element + '_is_active']) {
              activatedSspList.push(this.$t(element))
            }
          })
        })
        return activatedSspList.sort((a, b) => a.localeCompare(b))
      }
    },
    watch: {},
    created() {},
    methods: {
      new_storedRequest() {
        if (this.selectedSite.id !== undefined) {
          this.$router.push({
            name: 'stored_request_new',
            params: { site_id: this.selectedSite.id, stored_request_id: 'new' }
          })
        }
      },
      sspsElementsByItem(item) {
        var count = 0
        this.SspList.forEach((element) => {
          if (item[element + '_is_active']) {
            count++
          }
        })
        if (count != 0) return true
        return false
      },
      sspsListByItem(item) {
        let listToReturn = []
        this.SspList.forEach((element) => {
          if (item[element + '_is_active']) {
            listToReturn.push(this.$t(element))
          }
        })
        return listToReturn
      },
      checkssp(item) {
        let result = false
        this.sspsfilter.forEach((ssp) => {
          this.sspsListByItem(item).forEach((element) => {
            if (ssp == element) {
              result = true
            }
          })
        })
        return result
        /* ssps.forEach((ssp) => {
          if (ssp == 'Index Exchange') {
            if (item['ix' + '_is_active']) {
              console.log('item name ===> ', item.name)
              console.log('true')
              return true
            }
          } else {
            if (item[ssp.toLowerCase() + '_is_active']) {
              console.log('item name ===> ', item.name)
              console.log('true')
              return true
            }
          }
          return false
        })*/
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
</style>
