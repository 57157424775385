import { Logout, SetAppGroups, Loading } from '@/store/mutation_types'

import { appUserMutations } from '@/store/items/appUser'
import { onboardingUserMutations } from '@/store/items/onboardingUser'
import { appCustomerMutations } from '@/store/items/appCustomer'

import { usersMutations } from '@/store/items/users'
import { selectedUserMutations } from '@/store/items/selectedUser'

import { customersMutations } from '@/store/items/customers'
import { selectedCustomerMutations } from '@/store/items/selectedCustomer'

import { proposalsMutations } from '@/store/items/proposals'
import { selectedProposalMutations } from '@/store/items/selectedProposal'

import { dealsMutations } from '@/store/items/deals'
import { selectedDealMutations } from '@/store/items/selectedDeal'

import { gamsetupsMutations } from '@/store/items/gamsetups'
import { selectedGamSetupMutations } from '@/store/items/selectedGamSetup'

import { optionscookielessMutations } from '@/store/items/optionscookieless'
import { selectedOptionsCookielessMutations } from '@/store/items/selectedOptionsCookieless'

import { sitesMutations } from '@/store/items/sites'
import { selectedSiteMutations } from '@/store/items/selectedSite'

import { storedRequestsMutations } from '@/store/items/storedRequests'
import { selectedStoredRequestMutations } from '@/store/items/selectedStoredRequest'

import { injectionsMutations } from '@/store/items/injections'
import { selectedInjectionMutations } from '@/store/items/selectedInjection'

const mutations = {
  ...appUserMutations,
  ...onboardingUserMutations,
  ...appCustomerMutations,
  ...customersMutations,
  ...selectedCustomerMutations,
  ...proposalsMutations,
  ...selectedProposalMutations,
  ...dealsMutations,
  ...selectedDealMutations,
  ...gamsetupsMutations,
  ...optionscookielessMutations,
  ...selectedOptionsCookielessMutations,
  ...selectedGamSetupMutations,
  ...usersMutations,
  ...selectedUserMutations,
  ...sitesMutations,
  ...selectedSiteMutations,
  ...storedRequestsMutations,
  ...injectionsMutations,
  ...selectedStoredRequestMutations,
  ...selectedInjectionMutations,
  /*
  Error(state, e) {
    if (e && e.response && e.response.data) {
      state.errors = Object.values(e.response.data.messages)
    } else {
      state.errors = [Vue.prototype.$t('unknown_error')]
    }
  },
  */
  [SetAppGroups](state, app_groups) {
    state.app_groups = app_groups
  },
  [Logout](state) {
    state.appUser = undefined
  },
  [Loading](state) {
    state.loading = !state.loading
  }
}
export default mutations
