<template>
  <v-container fluid>
    <v-form id="nexx360__sr__form" ref="form" v-model="valid" lazy-validation>
      <v-row style="margin-left: 10px">
        <v-col>
          <v-row>
            <v-col style="padding-bottom: 0px">
              <span class="title_emplacement">
                <strong>{{ addEditPlacement }} {{ site.name }} </strong>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding-top: 0px">
              <span class="text">
                {{ $t("fill_in_your_placement_details") }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="sous_titres"> {{ $t("placement_details") }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col v-if="setDisable()" cols="4">
              <v-subheader> {{ id }} </v-subheader>
            </v-col>
            <v-col v-if="setDisable()" cols="1"> </v-col>
            <v-col cols="6">
              <v-subheader> {{ $t("name") }} * </v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters style="margin-top: 0px">
            <v-col v-if="setDisable()" cols="4">
              <v-text-field v-model="storedRequest.id" dense solo disabled>
              </v-text-field>
            </v-col>
            <v-col v-if="setDisable()" cols="1"> </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="storedRequest.name"
                dense
                solo
                :rules="nameRules"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <table style="width: 100%; margin-top: 15px">
              <tr>
                <td>
                  <v-switch
                    v-model="storedRequest.pricefloor_is_active"
                    label="Pricefloor"
                  ></v-switch>
                </td>
                <td colspan="2">
                  <p style="margin-bottom: 5px">CPM</p>
                  <v-text-field
                    v-model="storedRequest.pricefloor_cpm"
                    :rules="storedRequest.pricefloor_is_active ? required : []"
                    :disabled="!storedRequest.pricefloor_is_active"
                    suffix="EUR"
                    style="width: 220px"
                    type="number"
                    min="0"
                    dense
                    solo
                  ></v-text-field>
                </td>
              </tr>
            </table>
          </v-row>
          <!--<v-row v-if="$route.params.type == 'amp'" v-show="false" no-gutters>
            <v-col cols="1" style="padding-right: 15%">
              <v-subheader> {{ $t('height') }} </v-subheader>
            </v-col>
            <v-col cols="2">
              <v-subheader> {{ $t('width') }} </v-subheader>
            </v-col>
            <v-col cols="7"> </v-col>
            <v-col cols="7">
              <size
                v-for="(size, index) in storedRequest.sizes"
                :key="`size_${index}`"
                :index="index"
                :size="size"
                :stored_request_id="storedRequest.id"
                @update-size="updateSize"
                @delete-size="deleteSize"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                tile
                color="transparent"
                class="no-shadow"
                @click="storedRequest.sizes.push([0, 0])"
              >
                <v-icon left> mdi-plus </v-icon>
                {{ $t('add_sizes') }}
              </v-btn>
            </v-col>
            <v-row no-gutters>
              <v-col cols="7"> <v-col> </v-col></v-col>
            </v-row>
          </v-row>-->
          <br />
          <v-row no-gutters>
            <v-col cols="12" style="margin-bottom: 20px">
              <span class="sous_titres">
                {{ $t("choose_ssps_you_activate_immediately") }}
              </span>
            </v-col>
            <ssp
              v-for="(ids, ssp) in formattedSspList"
              :key="ssp"
              :ids="ids"
              :ssp="ssp"
              @update-ssp="updateSsp($event)"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="11" align="left" justify="left" style="margin-left: 15px">
        <span v-if="errors.length > 0" width="700">
          <v-alert
            v-for="error in errors"
            :key="error"
            color="#db2727"
            type="error"
            dense
            >{{ error }}</v-alert
          >
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <v-btn
          :loading="submitLoading"
          :disabled="submitLoading"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="250"
          dense
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
        <v-btn
          style="margin-left: 20px"
          class="white--text notcapital"
          color="black"
          width="250"
          dense
          @click="back"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="800"
          @click:outside="$emit('submitted')"
        >
          <template>
            <v-card>
              <v-container no-gutters dense>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <h2 style="text-indent: 2px">
                      {{ $t("placement_with_the_same_name_created") }}
                    </h2>
                    <br />
                    <p style="text-indent: 15px">
                      {{ $t("confirmation_placement_creation") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-card-actions align="center" class="justify-center">
                      <v-col cols="3">
                        <v-btn
                          width="200"
                          style="height: 40px"
                          class="white--text"
                          color="black"
                          @click="confirmCreation"
                        >
                          {{ $t("yes") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="1" />
                      <v-col cols="3">
                        <v-btn
                          width="200"
                          style="height: 40px"
                          class="white--text"
                          color="black"
                          @click="dialog = false"
                        >
                          {{ $t("no") }}
                        </v-btn>
                      </v-col>
                      <br />
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GetSelectedSite,
  GetStoredRequests,
  GetSelectedCustomer,
} from "@/store/action_types";
import Ssp from "@/pages/management/storedRequest/ssp.vue";
//import Size from '@/pages/management/storedRequest/size.vue'
import {
  GetSelectedStoredRequest,
  SaveSelectedStoredRequest,
} from "@/store/action_types";
import {
  ClearSelectedStoredRequest,
  SetSelectedSite,
  SetSelectedCustomer,
} from "@/store/mutation_types";

export default {
  components: {
    Ssp,
    // Size
  },
  data() {
    return {
      site: {
        name: "",
      },
      addEditPlacement: this.$t("add placement to"),
      basicName: "",
      dialog: false,
      storedRequest: {},
      nameRules: [
        (v) =>
          !!v || `${this.$t("name")} ${this.$t("is")} ${this.$t("required")}`,
      ],
      submitLoading: false,
      errors: [],
      id: "",
      valid: true,
      required: [(v) => !!v || "Required"],
    };
  },
  computed: {
    ...mapGetters([
      "appCustomer",
      "selectedCustomer",
      "SspList",
      "SampleStoredRequest",
      "SelectedStoredRequest",
      "selectedSite",
      "storedRequests",
    ]),
    formattedSspList() {
      const output = {};
      console.log(this.storedRequest);
      this.SspList.forEach((ssp) => {
        if (this.selectedCustomer[ssp + "_is_activated"] == true) {
          output[ssp] = {};
          Object.keys(this.storedRequest).forEach((key) => {
            if (
              key.substr(0, this.getPosition(key, "_", 1)) == ssp &&
              key != "ix_endpoint"
            ) {
              output[ssp][key] = this.storedRequest[key];
            }
          });
        }
      });
      console.log("toto -->", output);
      return output;
    },
  },
  watch: {
    appCustomer() {
      if (this.$route.params.customer_id != this.appCustomer.id) {
        this.$router.push({
          name: "new_sites",
          params: {
            type: this.$route.params.type,
            customer_id: this.appCustomer.id,
          },
        });
      }
    },
  },
  async created() {
    await this.GetSelectedCustomer(this.appCustomer.id);
    if (this.$route.name == "stored_request_by_tool") {
      try {
        const sr = await axios.get(
          `/api/getsrbyid/${this.$route.params.stored_request_id}`
        );
        var stored_r = sr.data;
        const siteURL = await axios.get(`/api/getsitebyid/${stored_r.site_id}`);
        this.SetSelectedSite(siteURL.data);
        this.$router.push({
          name: "stored_request_edit",
          params: {
            customer_id: siteURL.data.customer_id,
            site_id: sr.data.site_id,
            type: "fast",
            stored_request_id: this.$route.params.stored_request_id,
          },
        });
      } catch (error) {
        this.$router.push({
          name: "dashboard",
        });
      }
    }
    if (this.selectedSite.id == undefined)
      await this.GetSelectedSite(this.$route.params.site_id);

    this.site.name = this.selectedSite.name;
    if (this.$route.params.stored_request_id != "new")
      this.addEditPlacement = this.$t("edit placement");
    if (
      this.SelectedStoredRequest.id == "" &&
      this.$route.params.stored_request_id != undefined
    )
      await this.GetSelectedStoredRequest(this.$route.params.stored_request_id);

    this.storedRequest = this.SelectedStoredRequest;
    if (this.storedRequest.id == "") this.storedRequest.sizes.push([0, 0]);
    await this.GetStoredRequests(this.selectedSite.id);
    this.basicName = this.storedRequest.name;
  },
  methods: {
    ...mapActions([
      GetSelectedStoredRequest,
      SaveSelectedStoredRequest,
      GetSelectedSite,
      GetStoredRequests,
      GetSelectedCustomer,
    ]),
    ...mapMutations([
      ClearSelectedStoredRequest,
      SetSelectedSite,
      SetSelectedCustomer,
    ]),
    validate() {
      /*  this.errors = []
          if (this.storedRequest.sizes.length == 0)
            this.errors.push('Please add sizes')
          else if (
            this.storedRequest.sizes[0][0] == 0 &&
            this.storedRequest.sizes[0][1] == 0 &&
            this.$route.params.type == 'amp'
          ) {
            this.errors.push(
              this.$t('sizes') + ' ' + this.$t('are') + ' ' + this.$t('required')
            )
          }*/
      if (
        this.storedRequest.name == "" ||
        this.storedRequest.name == undefined
      ) {
        this.errors.push(
          this.$t("name") + " " + this.$t("is") + " " + this.$t("required")
        );
      }
      if (this.errors.length > 0) {
        return false;
      }
      return true;
    },
    async submit() {
      this.errors = [];
      this.submitLoading = true;
      if (!this.$refs.form.validate() && this.validate())
        this.errors.push(
          "Please provide the Ids of the SSPs that you've activated."
        );
      if (!this.$refs.form.validate() || !this.validate()) {
        this.submitLoading = false;
        return;
      }
      if (this.basicName != this.storedRequest.name) {
        this.storedRequests.forEach((sr) => {
          if (sr.name == this.storedRequest.name.trim()) {
            this.dialog = true;
            this.submitLoading = false;
            return;
          }
        });
      }

      if (this.dialog == false) {
        this.storedRequest.site_id = this.$route.params.site_id;
        this.storedRequest.name = this.storedRequest.name.trim();
        this.storedRequest.type = this.$route.params.type;
        if (this.storedRequest.pricefloor_is_active == false) {
          this.storedRequest.pricefloor_cpm = "";
        }
        this.CleanSSPs();
        let output = await this.SaveSelectedStoredRequest(
          this.storedRequest
        ).catch(() => {
          this.errors = this.getErrors;
          this.submitLoading = false;
          return false;
        });

        if (!output) {
          return;
        }
        this.submitLoading = false;
        this.ClearSelectedStoredRequest();
        await this.GetStoredRequests(this.$route.params.site_id);
        this.$router.push(
          "/management/" +
            this.$route.params.type +
            "/" +
            this.appCustomer.id +
            "/sites/" +
            this.selectedSite.id +
            "/storedrequests"
        );
      }
    },
    async confirmCreation() {
      this.storedRequest.site_id = this.$route.params.site_id;
      this.storedRequest.name = this.storedRequest.name.trim();
      this.storedRequest.type = this.$route.params.type;
      this.CleanSSPs();
      let output = await this.SaveSelectedStoredRequest(
        this.storedRequest
      ).catch(() => {
        this.errors = this.getErrors;
        this.submitLoading = false;
        return false;
      });

      if (!output) {
        return;
      }
      this.submitLoading = false;
      this.ClearSelectedStoredRequest();
      this.$router.push(
        "/management/" +
          this.$route.params.type +
          "/" +
          this.appCustomer.id +
          "/sites/" +
          this.selectedSite.id +
          "/storedrequests"
      );
    },
    updateSsp(elem) {
      this.storedRequest[elem["key"]] = elem["value"];
    },
    updateSize(item) {
      this.storedRequest.sizes[item.index][item.dimension] = item.value;
    },
    deleteSize(index) {
      this.storedRequest.sizes.splice(index, 1);
    },
    setDisable() {
      if (this.storedRequest.id !== "") {
        this.id = "ID";
        return true;
      }
      return false;
    },
    back() {
      this.$router.back();
    },
    getPosition(text, subString, index) {
      return text.split(subString, index).join(subString).length;
    },
    CleanSSPs() {
      if (this.storedRequest.appnexus_is_active == false)
        this.storedRequest.appnexus_placement_id = "";
      if (this.storedRequest.gravity_is_active == false)
        this.storedRequest.gravity_placement_id = "";
      if (this.storedRequest.improve_is_active == false)
        this.storedRequest.improve_placement_id = "";
      if (this.storedRequest.ix_is_active == false) {
        this.storedRequest.ix_site_id = "";
        // this.storedRequest.ix_endpoint = ''
      }
      if (this.storedRequest.mediasquare_is_active == false) {
        this.storedRequest.mediasquare_zone = "";
        this.storedRequest.mediasquare_code = "";
      }
      if (this.storedRequest.moneytag_is_active == false)
        this.storedRequest.moneytag_placement_id = "";
      if (this.storedRequest.invibes_is_active == false)
        this.storedRequest.invibes_placement_id = "";
      if (this.storedRequest.openx_is_active == false) {
        this.storedRequest.openx_del_domain = "";
        this.storedRequest.openx_unit = "";
      }
      if (this.storedRequest.pubmatic_is_active == false) {
        this.storedRequest.pubmatic_placement_id = "";
        this.storedRequest.pubmatic_publisher_id = "";
        this.storedRequest.pubmatic_ad_slot = "";
      }
      if (this.storedRequest.rubicon_is_active == false) {
        this.storedRequest.rubicon_account_id = "";
        this.storedRequest.rubicon_site_id = "";
        this.storedRequest.rubicon_zone_id = "";
      }
      if (this.storedRequest.smartadserver_is_active == false) {
        this.storedRequest.smartadserver_network_id = "";
        this.storedRequest.smartadserver_site_id = "";
        this.storedRequest.smartadserver_page_id = "";
        this.storedRequest.smartadserver_format_id = "";
      }
      if (this.storedRequest.smilewanted_is_active == false) {
        this.storedRequest.smilewanted_zone_id = "";
        this.storedRequest.smilewanted_position_type = "";
      }
      if (this.storedRequest.triplelift_is_active == false)
        this.storedRequest.triplelift_inventory_code = "";
      if (this.storedRequest.ttd_is_active == false)
        this.storedRequest.ttd_endpoint = "";
      if (this.storedRequest.verizon_is_active == false) {
        this.storedRequest.verizon_dcn = "";
        this.storedRequest.verizon_pos = "";
      }
      if (this.storedRequest.pricefloor_is_active == false) {
        this.storedRequest.pricefloor_cpm = "";
      }
    },
  },
};
</script>

<style scoped>
.title_emplacement {
  font-size: 20px;
}
.sous_titres {
  font-size: 17px;
}

.sitename {
  font-size: 21px;
}

.text {
  font-size: 14px;
  padding-top: 10px;
  display: inline-block;
}

.combobox {
  padding-left: 50px;
  width: 370px;
}
.notcapital {
  text-transform: none !important;
}
.parameters {
  background-color: black;
}
.positionCb {
  margin-top: 10px;
  margin-left: 50px;
}
.no-shadow,
.no-shadow::before {
  margin-left: 5px;
  box-shadow: none;
  background-color: transparent !important;
}
</style>
