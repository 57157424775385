<template>
  <div id="customerList">
    <v-container fluid>
      <v-card id="customerList">
        <v-col style="padding-bottom: 0px">
          <h3>{{ $t("customer_list") }}</h3>
        </v-col>
        <v-card-title primary-title>
          <v-col cols="8">
            <v-btn
              class="notcapital"
              color="secondary"
              style="padding: 10px"
              width="180px"
              small
              @click="newItem"
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t("add_customer") }}
            </v-btn>
          </v-col>
          <v-col cols="4" style="text-align: right" justify-end>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="customers"
            :sort-by="['name']"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td
                  style="width: 15%; font-weight: bold; cursor: pointer"
                  @click="editItem(item)"
                >
                  {{ item.name }}
                </td>
                <td style="width: 20%">{{ item.legal_name }}</td>
                <td style="width: 15%">{{ item.country }}</td>
                <td style="width: 12%">{{ item.id }}</td>
                <td>
                  <v-chip-group multiple column active-class="primary--text">
                    <v-btn
                      v-if="item.adapter_is_active == 1"
                      small
                      color="#04778c"
                      style="padding: 5px; margin: 5px; color: white"
                    >
                      PB
                    </v-btn>
                    <v-btn
                      v-if="item.web_is_active == 1"
                      small
                      color="#b4b700"
                      style="padding: 5px; margin: 5px; color: white"
                    >
                      Stack
                    </v-btn>
                    <v-btn
                      v-if="item.amp_is_active == 1"
                      small
                      color="#ba8501"
                      style="padding: 5px; margin: 5px; color: white"
                    >
                      AMP
                    </v-btn>
                  </v-chip-group>
                </td>
                <td>
                  <v-btn
                    class="mr-2"
                    rounded
                    small
                    color="primary"
                    @click="editItem(item)"
                  >
                    <v-icon left> mdi-pencil </v-icon>
                    {{ $t("edit_properties") }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { GetCustomers } from "@/store/action_types";
import { SetAppCustomer } from "@/store/mutation_types";
import {
  SetSelectedCustomer,
  ClearSelectedCustomer,
} from "@/store/mutation_types";

export default {
  name: "CustomerList",
  props: {
    customerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("name"), align: "left", value: "name" },
        { text: this.$t("legal_name"), align: "left", value: "legal_name" },
        { text: this.$t("country"), align: "left", value: "country" },
        { text: this.$t("customer_id"), align: "left", value: "customer_id" },
        {
          text: this.$t("products"),
          align: "left",
          value: "adapter_is_active",
        },
        {
          text: this.$t("action"),
          align: "left",
          value: "action",
          width: 200,
        },
      ],
      search: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["customers", "appCustomer"]),
  },
  async created() {
    try {
      this.loading = true;
      await this.GetCustomers();
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([GetCustomers]),
    ...mapMutations([
      SetSelectedCustomer,
      ClearSelectedCustomer,
      SetAppCustomer,
    ]),

    editItem(customer) {
      this.SetAppCustomer(customer);
      this.SetSelectedCustomer(customer);
      this.$router.push("/customers/" + customer.id);
    },
    newItem() {
      this.ClearSelectedCustomer();
      this.$router.push({ name: "customer_new" });
    },
  },
};
</script>

<style>
.notcapital {
  text-transform: none !important;
}
</style>
