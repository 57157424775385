<template>
  <v-container fluid>
    <v-form
      id="nexx360__optionscookieless__form"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row style="margin-left: 10px">
        <v-col>
          <v-row>
            <v-col style="padding: 20px 0px 0px 0px">
              <span class="title_emplacement">
                <strong>Define options</strong>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding: 0px 0px 0px 0px">
              <span style="font-size: 18px"> Cookie less monetisation / </span>
              <span style="font-size: 18px">{{ site.name }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <span class="text">{{ $t('define_options_explanation') }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <v-checkbox
                v-model="optionscookieless.price_activated"
                :label="$t('dont_activate')"
                color="black"
                @change="activated = !activated"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <span style="font-size: 18px">Floor price</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 10px 0px 0px 0px">
              <span style="font-size: 16px; opacity: 0.7"
                >Define floor price</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" style="padding: 0px 0px 0px 0px">
              <v-text-field
                v-model="optionscookieless.price"
                dense
                solo
                :disabled="activated"
                suffix="cts"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <span style="font-size: 18px"
                >{{ $t('campagne de récupération de consentement') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" style="padding: 0px 0px 0px 0px">
              <v-checkbox
                v-model="optionscookieless.consent_retrieval"
                :label="$t('activate campagne')"
                color="black"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <span style="font-size: 16px; opacity: 0.7"
                >{{ $t('consentement manage plateform') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" style="padding: 3px 0px 0px 0px">
              <v-select item-text="name" return-object solo dense></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <span style="font-size: 18px">{{ $t('ads formats') }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 10px 0px 0px 0px">
              <span style="font-size: 16px; opacity: 0.7"
                >you can customize your consent retrieval campaign and view the
                result
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 20px 0px 0px 0px">
              <span style="font-size: 16px; opacity: 0.7"
                >Message de visuel
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9" style="padding: 2px 0px 0px 0px">
              <v-text-field
                v-model="optionscookieless.message"
                dense
                solo
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" style="padding: 20px 0px 0px 0px">
              <span style="font-size: 16px; opacity: 0.7">Couleur du TCA </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" style="padding: 2px 0px 0px 0px">
              <v-text-field
                v-model="color"
                mask="mask"
                return-masked-value
                hide-details
                class="ma-0 pa-0"
                solo
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="color" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" style="padding: 20px 0px 0px 0px">
              <span style="font-size: 16px; opacity: 0.7"
                >Logo de votre entreprise
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9" style="padding: 2px 0px 0px 0px">
              <v-file-input
                v-model="optionscookieless.logo"
                filled
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding: 0px 0px 0px 0px">
              <span style="font-size: 18px"
                >{{ $t('visualization of your formats') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="padding-left: 0px">
              <v-card>
                <v-card-title>Format1</v-card-title>
                <v-card-text>
                  <v-img class="mx-2" src="@/assets/format1.png" contain
                /></v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" style="padding-left: 0px">
              <v-card>
                <v-card-title>Format2</v-card-title>
                <v-card-text>
                  <v-img class="mx-2" src="@/assets/format2.png" contain
                /></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8" style="padding-left: 0px">
              <v-card>
                <v-card-title>Format3</v-card-title>
                <v-card-text>
                  <v-img class="mx-2" src="@/assets/format3.png" contain
                /></v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="text-align: center; padding-top: 100px">
              <v-btn
                style="border-color: black"
                width="500"
                class="white--text notcapital"
                color="black"
                dense
                :loading="submitLoading"
                @click="submit"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import {
    GetSelectedSite,
    SaveSelectedOptionsCookieless,
    GetSelectedOptionsCookieless
  } from '@/store/action_types'

  export default {
    data() {
      return {
        color: '#000000',
        mask: '!#XXXXXXXX',
        menu: false,
        activated: false,

        site: {
          name: ''
        },
        optionscookieless: {},
        nameRules: [
          (v) =>
            !!v || `${this.$t('name')} ${this.$t('is')} ${this.$t('required')}`
        ],
        submitLoading: false,
        errors: [],
        valid: true,
        cbIgnorePrice: false,
        cbActivateCampaign: false
      }
    },
    computed: {
      ...mapGetters(['selectedSite', 'selectedOptionsCookieless']),
      swatchStyle() {
        const { color, menu } = this
        return {
          backgroundColor: color,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      }
    },
    async created() {
      if (
        this.selectedOptionsCookieless.id == '' &&
        this.$route.params.oc_id != undefined
      )
        await this.GetSelectedOptionsCookieless(this.$route.params.site_id)
      this.optionscookieless = this.selectedOptionsCookieless[0]
      if (this.selectedSite.id == undefined)
        await this.GetSelectedSite(this.$route.params.site_id)
      this.site.name = this.selectedSite.name
      this.optionscookieless.site_id = this.selectedSite.id
    },
    methods: {
      ...mapActions([
        GetSelectedSite,
        SaveSelectedOptionsCookieless,
        GetSelectedOptionsCookieless
      ]),
      ...mapMutations([]),
      async submit() {
        this.submitLoading = true
        this.optionscookieless.color = this.color
        const output = await this.SaveSelectedOptionsCookieless(
          this.optionscookieless
        ).catch(() => {
          this.errors = this.getErrors
          this.submitLoading = false
          return false
        })
        if (!output) {
          return
        }
        this.$router.push('/cookieless')
      }
    }
  }
</script>

<style scoped>
  .title_emplacement {
    font-size: 20px;
  }
  .subtitles {
    font-size: 17px;
  }

  .sitename {
    font-size: 21px;
  }

  .text {
    font-size: 14px;
    padding-top: 10px;
    display: inline-block;
  }

  .combobox {
    padding-left: 50px;
    width: 370px;
  }
  .notcapital {
    text-transform: none !important;
  }
  .parameters {
    background-color: black;
  }
  .positionCb {
    margin-top: 10px;
    margin-left: 50px;
  }
  .no-shadow,
  .no-shadow::before {
    margin-left: 5px;
    box-shadow: none;
    background-color: transparent !important;
  }
</style>
