<template>
  <div>
    <v-toolbar dense flat style="background-color: #fafafa">
      <v-toolbar-title>
        <h4>{{ categoryReplace | ucFirst }}</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-col>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="displayData"
            disable-pagination
            disable-filtering
            dense
            hide-default-footer
            :sort-by="['auctions']"
            :sort-desc="[true]"
            @click="filterItem"
          >
            <template v-slot:item="{ item }">
              <tr v-if="item.name == 'Total'">
                <td style="color: #fc8f00; font-weight: bold; width: 80%">
                  {{ item.name }}
                </td>
                <td style="color: #fc8f00; font-weight: bold; text-align: end">
                  {{ new Intl.NumberFormat('fr-FR').format(item.auctions) }}
                </td>
              </tr>
              <tr v-else @click="filterItem(item)">
                <td>{{ item.name }}</td>
                <td style="text-align: end; width: 80%">
                  {{ new Intl.NumberFormat('fr-FR').format(item.auctions) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
  //| cpmFormat
  import * as formatters from '@/components/common/formatters.js'
  export default {
    filters: {
      impressionsFormat(item) {
        return formatters.impressionsFormatter(item)
      },
      ucFirst(item) {
        return formatters.ucFirst(item)
      }
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['category', 'reportingData', 'loading'],
    data() {
      return {
        categoryReplace: this.category.replace('_', ' '),
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Auctions', value: 'auctions' }
        ]
      }
    },

    computed: {
      displayData() {
        var parsedobj = JSON.parse(JSON.stringify(this.reportingData))
        for (let i = 0; i < parsedobj.length; i++) {
          if (parsedobj[i].name == 'Others') {
            if (parsedobj[i].auctions == null) {
              const index = parsedobj.findIndex(
                (item) => item.name === 'Others'
              )
              parsedobj.splice(index, 1)
              break
            }
          }
        }
        if (parsedobj[0] != undefined) {
          var totalauctions = 0
          for (let i = 0; i < parsedobj.length; i++) {
            totalauctions = totalauctions + parseInt(parsedobj[i].auctions)
          }
          parsedobj.splice(0, 0, {
            id: 'total',
            name: 'Total',
            auctions: totalauctions
          })
          return parsedobj.map((data) => {
            if (data.name == 'amp') data.name = 'Nexx AMP'
            if (data.name == 'display') data.name = 'Server'
            if (data.name == 'fast') data.name = 'Nexx Web'
            return data
          })
        }

        return parsedobj.map((data) => {
          if (data.name == 'amp') data.name = 'Nexx AMP'
          if (data.name == 'display') data.name = 'Server'
          if (data.name == 'fast') data.name = 'Nexx Web'
          return data
        })
      }
    },
    watch: {},
    methods: {
      filterItem(item) {
        const filter = {
          category: this.category,
          id: item.id
        }
        if (filter['id'] != '0' && filter['id'] != 'N/A')
          this.$emit('filter', filter)
      }
    }
  }
</script>
