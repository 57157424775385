<template>
  <v-container style="padding: 0px">
    <template>
      <td v-for="element in items" :key="element.name">
        <v-chip
          v-if="items.indexOf(element) < 3 && element.name != 'ALL'"
          style="margin: 5px"
        >
          <span>{{ element.name }}</span>
        </v-chip>
        <v-chip
          v-if="items.indexOf(element) < 3 && element.name == 'ALL'"
          style="margin: 5px"
          class="ma-2"
          color="red"
          label
          text-color="white"
        >
          <v-icon left> mdi-earth </v-icon>
          <span>{{ element.name }}</span>
        </v-chip>
        <v-menu
          v-if="items.indexOf(element) === 3"
          v-model="menu"
          bottom
          right
          transition="scale-transition"
          origin="top left"
        >
          <template v-slot:activator="{ on }">
            <span
              v-if="items.indexOf(element) === 3 && items.length == 4"
              class="grey--text text-caption"
              style="font-size: 20px; cursor: pointer"
              v-on="on"
            >
              (+{{ items.length - 3 }} other)
            </span>
            <span
              v-if="items.indexOf(element) === 3 && items.length > 4"
              class="grey--text text-caption"
              style="font-size: 20px; cursor: pointer"
              v-on="on"
            >
              (+{{ items.length - 3 }} others)
            </span>
          </template>
          <v-card width="400">
            <v-col
              cols="12"
              style="text-align: right; padding: 5px 5px 0px 40px; height: 5px"
            >
              <v-icon color="black" @click="menu = false">mdi-close</v-icon>
            </v-col>
            <v-card-text style="padding-top: 5px">
              <v-chip-group multiple column active-class="primary--text">
                <v-chip v-for="el in items.slice(3)" :key="el.name">
                  {{ el.name }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </td>
    </template>
  </v-container>
</template>
<script>
  export default {
    props: ['items', 'customer'],
    data() {
      return {
        headers: [
          {
            text: 'Site name',
            align: 'left',
            value: 'name'
          },
          { text: 'CPM', value: 'cpm_invoicing' }
        ],
        rule: {
          cpm_value: ''
        },
        cpm: '',
        domainSelected: false,
        product: '',
        confirmLoading: false,
        loading: false,
        cpmUpdated: false,
        ruleAdded: false,
        menu: false,
        products: [
          {
            name: 'amp'
          },
          {
            name: 'fast'
          },
          {
            name: 'adapter'
          }
        ]
      }
    },
    created() {},
    methods: {}
  }
</script>
