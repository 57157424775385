<template>
  <div>
    <div>
      <highcharts :options="chartOptions" />
    </div>
  </div>
</template>
<script>
  import moment from 'moment'
  import { Chart } from 'highcharts-vue'
  import * as formatters from '@/components/common/formatters.js'
  export default {
    components: {
      highcharts: Chart
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['data', 'total', 'timeUnit'],
    data() {
      return {
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
      }
    },
    computed: {
      chartOptions() {
        const type = this.type // Revenue, Impressions, CPM
        let output = {}
        output.credits = { enabled: false }
        output.chart = { type: 'column', zoomType: 'xy' }
        output.title = {}
        output.title.style = '{color: #666666}'
        output.legend = { enabled: true }
        output.xAxis = [
          {
            categories: this.getxAxisDates(),
            crosshair: true
          }
        ]
        output.yAxis = [
          {
            // Secondary yAxis
            labels: {
              format: '{value}',
              formatter: function () {
                return formatters.billingFormatter(this.value)
              }
            },
            title: {
              text: 'Revenue',
              style: {}
            },
            opposite: true
          },
          {
            // Primary yAxis
            labels: {
              format: '{value}',
              formatter: function () {
                return formatters.impressionsFormatter(this.value)
              }
            },
            title: {
              text: 'Auctions',
              style: {}
            }
          }
        ]
        output.tooltip = {}
        output.tooltip.useHTML = true
        output.tooltip.shared = true
        output.plotOptions = {}
        output.plotOptions.column = {}
        output.plotOptions.column.stacking = 'normal'
        output.plotOptions.column.dataLabels = { enabled: false }
        let dataoptions = []
        let revenue = []
        if (this.data.length > 0) {
          for (
            let i = 0;
            i < this.data[this.getMax(this.data)].data.length;
            i++
          ) {
            let count = 0
            for (let j = 0; j < this.data.length; j++) {
              try {
                count = count + this.data[j].data[i][2]
              } catch (error) {}
            }
            revenue.push(count)
          }
          for (let index = 0; index < this.data.length; index++) {
            let auctions = []
            this.data[index].data.forEach((element) => {
              auctions.push(element[1])
            })
            dataoptions.push({
              name: this.data[index].name,
              type: 'column',
              yAxis: 1,
              data: auctions
            })
          }
          dataoptions.push({
            name: 'Revenue',
            type: 'spline',
            data: revenue,
            color: '#00d6d2'
          })
        }
        dataoptions.forEach((element) => {
          if (element.name == 'Nexx AMP') element.color = '#2E418A'
          if (element.name == 'Nexx Web') element.color = '#fc8f00'
          if (element.name == 'Nexx Adapter') element.color = '#00ead3'
        })
        output.title.text = ''
        output.series = dataoptions
        output.tooltip = {
          formatter() {
            let output = moment(this.x).format('MMM Do') + '<br/>'
            this.series.name = formatters.ucFirst(this.series.name)
            if (this.series.name == 'AMP') this.series.name = 'Nexx AMP'
            if (this.series.name == 'Fast') this.series.name = 'Nexx Web'
            if (this.series.name == 'Adapter') this.series.name = 'Nexx Adapter'
            output += this.series.name + ': <b>'
            if (this.series.name != 'Revenue') {
              output += formatters.impressionsFormatter(this.y)
              if (this.total !== undefined) {
                output +=
                  '<br/>Total: <b>' +
                  formatters.impressionsFormatter(this.total) +
                  '</b>'
              }
            } else {
              output += this.series.name + ': <b>'
              output += formatters.billingFormatter(this.y)
              if (this.total !== undefined) {
                output +=
                  '<br/>Total: <b>' +
                  formatters.billingFormatter(this.total) +
                  '</b>'
              }
            }
            return output
          }
        }
        return output
      }
    },
    watch: {
      data() {}
    },
    created() {},
    methods: {
      getxAxisDates() {
        let categories = []
        if (this.data.length > 0) {
          for (let index = 0; index < this.data[0].data.length; index++) {
            var timestamp = this.data[0].data[index][0]
            var date = new Date(timestamp)
            categories.push(
              date.getDate() + ' ' + this.monthNames[date.getMonth()]
            )
          }
        }
        return categories
      },
      getMax(data) {
        if (data.length > 1) {
          let toreturn = 0
          for (let index = 0; index < data.length - 1; index++) {
            if (data[index].data.length > data[index + 1].data.length)
              toreturn = index
            if (data[index].data.length < data[index + 1].data.length)
              toreturn = index + 1
          }
          return toreturn
        } else return 0
      }
    }
  }
</script>
