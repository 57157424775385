<template>
  <v-container fluid>
    <v-card v-if="user">
      <!--<app-overlay :loading="submitLoading" />-->
      <v-card-title
        v-if="$route.name == 'user_new'"
        primary-title
        style="padding-bottom: 0px"
      >
        {{ $t('new user') }}
      </v-card-title>
      <v-card-title v-else primary-title>
        User {{ user.first_name + ' ' + user.last_name }}
      </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="user.first_name"
                  :label="$t('first_name')"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="user.last_name"
                  class="ml-4"
                  :label="$t('last_name')"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  ref="Email"
                  v-model="email"
                  :label="$t('email')"
                  :rules="emailRules"
                  required
                  type="email"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <group-list
                  class="ml-4"
                  :group="user.role"
                  @update-groups="user.role = $event"
                />
              </v-col>
            </v-row>
            <!--<v-row>
              <v-col v-if="$store.getters.appUser.role == 'superadmin'" cols="5"
                ><customer-list
                  :customerid="user"
                  :rules="customerIdRules"
                  @update-customer="
                    user.customer_id = $event ? Number($event.id) : 0
                  "
                />
              </v-col>
            </v-row>-->
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="130"
            dense
            @click="submit"
          >
            <v-icon left> mdi-check-circle</v-icon>
            {{ $t('save') }}
          </v-btn>
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="130"
            dense
            @click="back"
          >
            <v-icon left> mdi-arrow-left </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import groupList from '@/components/group/selector'
  import customerList from '@/components/customer/selector'
  import { mapActions, mapGetters } from 'vuex'
  import {
    GetSelectedUser,
    SaveSelectedUser,
    GetAppGroups,
    UserAlreadyExists
  } from '@/store/action_types'

  export default {
    name: 'UserEdit',
    components: {
      //AppOverlay: overlay,
      groupList: groupList
      //customerList: customerList
    },
    data() {
      return {
        isEmailValid: true,
        nameRules: [(v) => !!v || this.$t('name is required')],
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          (v) => this.isEmailValid || 'Email is already used'
        ],
        customerIdRules: [
          (v) => (!!v && v > 0) || this.$t('customer_id is required')
        ],
        email: '',
        /*
          passwordRules: [
            (v) => !!v || this.$t('password is required'),
            (v) => (!!v && v.length >= 8) || this.$t('password is too short'),
            (v) =>
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[?/<~#`!@$%^&*()+=}|:";',>{ -]).{8,}/.test(
                v
              ) || this.$t('password not in regex')
          ],
          */
        submitLoading: false,
        errors: [],
        user: {
          last_name: '',
          first_name: '',
          customer_id: 0,
          role: ''
        }
      }
    },
    computed: {
      ...mapGetters(['selectedUser', 'appUser'])
    },
    watch: {
      email: async function (val, oldVal) {
        if (val != undefined && val != '') {
          if (this.$route.name == 'user_new') {
            if ((await this.UserAlreadyExists(val)) == true) {
              this.isEmailValid = false
            } else {
              this.isEmailValid = true
            }
          } else {
            if (
              (await this.UserAlreadyExists(val)) == true &&
              val != this.user.email
            ) {
              this.isEmailValid = false
            } else {
              this.isEmailValid = true
            }
          }
          this.$refs.Email.validate()
        }
      }
    },
    async created() {
      if (this.$store.getters.appUser.role != 'superadmin')
        this.user.customer_id = this.appUser.customer_id
      else this.user.customer_id = this.$route.params.customer_id
      if (
        this.$route.name != 'user_new' &&
        this.$route.name != 'newuser_bycustomer'
      ) {
        await this.GetSelectedUser(this.$route.params.userId)
        this.user = this.selectedUser
        this.email = this.user.email
      }
    },
    methods: {
      ...mapActions([
        GetSelectedUser,
        SaveSelectedUser,
        GetAppGroups,
        UserAlreadyExists
      ]),
      /*
        validatePasswords() {
          this.errors = []
          console.log(this.password, this.password_confirmation)
          if (
            this.password.length > 0 &&
            this.password_confirmation.length > 0 &&
            this.password.length !== this.password_confirmation.length &&
            this.password !== this.password_confirmation
          ) {
            this.errors = [this.$t('passwords not equals')]
            return
          }
          this.user.password = this.password
        },
        */
      async submit() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.user.email = this.email

        this.submitLoading = true
        const output = await this.SaveSelectedUser(this.user).catch(() => {
          this.errors = this.getErrors
          this.submitLoading = false
          return false
        })

        if (!output) {
          return
        }
        this.$router.back()
      },
      back() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
</style>
