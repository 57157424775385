import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    light: true,
    themes: {
      light: {
        primary: '#2E418A',
        success: '#F39200',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#ff5252',
        info: '#2196F3',
        warning: '#FFC107',
        border: '#c0c0c0',
        nexx360_active: '#40E80F',
        table: '#f9f9f9',
        updated: '#14b200'
      }
    }
  }
})
