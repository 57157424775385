<template>
  <v-btn class="mx-2" :class="state.class" depressed small>
    {{ state.name }}
  </v-btn>
</template>

<script>
  export default {
    props: ['isActive'],
    computed: {
      state() {
        if (this.isActive == 1) {
          return { name: 'Active', class: 'green white--text' }
        } else if (this.isActive == 2) {
          return { name: 'To be updated', class: 'orange white--text' }
        } else {
          return { name: 'Inactive', class: 'red white--text' }
        }
      }
    }
  }
</script>
