<template>
  <div id="PageContent">
    <v-container fluid>
      <v-card id="PageList">
        <v-card-title primary-title>
          <v-col v-if="$route.params.site_id != '0'" cols="12">
            List of placements of {{ selectedSite.name }}</v-col
          >
          <v-col v-else cols="12">
            Please select a site first to manage placements</v-col
          >
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row v-if="$route.params.site_id != '0'">
            <v-col cols="12" align="right" justify="right">
              <v-btn
                :disabled="storedRequests.length == 0"
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                dense
                @click="Show_rtc_config"
              >
                <v-icon left> mdi-code-tags </v-icon>
                {{ messageButtonTag }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="$route.params.site_id != '0'">
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 10px"
                class="white--text notcapital"
                color="black"
                width="250"
                dense
                @click="newStoredRequest()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add a single placement
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                width="250"
                dense
                @click="
                  $router.push($route.path + '?action=addmultiple'),
                    (multipleSRDialog = true)
                "
              >
                <v-icon left> mdi-table-column-plus-after </v-icon>
                Add multiple placements
              </v-btn>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="3" align="right" justify="right">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                width="168"
                dense
                @click="
                  $router.push($route.path + '?action=bulkupload'),
                    (bulkUploadDialog = true)
                "
              >
                <v-icon left> mdi-download-outline </v-icon>
                Bulk upload
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="5">
              <v-row>
                <siteMenu />
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="$route.params.site_id != '0'">
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-row v-if="$route.params.type == 'amp'">
                  <v-col
                    cols="12"
                    style="padding-top: 0px; padding-bottom: 50px"
                  >
                    <v-alert
                      colored-border
                      type="info"
                      elevation="1"
                      style="margin: 0px; font-size: 15px"
                    >
                      {{ $t("placements_configuration_first_line") }} <br />
                      {{ $t("placements_configuration_second_line") }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-card-title
                  v-if="$route.params.site_id != '0'"
                  style="padding: 10px"
                >
                  <v-row>
                    <v-col cols="12" style="padding: 0px 0px 10px 0px">
                      <v-row>
                        <v-icon style="padding: 0px 10px 0px 10px" size="28"
                          >mdi-filter-outline</v-icon
                        >
                        <v-col col="2" style="padding: 15px 20px 0px 10px">
                          <v-select
                            v-model="namesfilter"
                            label="Filter by name"
                            flat
                            dense
                            hide-details
                            small
                            multiple
                            clearable
                            :items="
                              computedStoredRequests
                                .map((d) => d['name'])
                                .sort((a, b) => a.localeCompare(b))
                            "
                          >
                          </v-select>
                        </v-col>
                        <v-col col="2" style="padding: 15px 0px 20px 0px">
                          <v-select
                            v-model="sspsfilter"
                            label="Filter by SSP"
                            flat
                            dense
                            hide-details
                            small
                            multiple
                            clearable
                            :items="sspListFilter"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="6" />
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-row>
                  <v-col cols="2">
                    <p
                      style="
                        position: relative;
                        top: 30px;
                        left: 3px;
                        font-size: 14px;
                      "
                    >
                      <strong> {{ numberOfStoredRequests }}</strong>
                    </p>
                  </v-col>
                  <v-col cols="6" />
                  <v-col cols="4" style="padding: 15px 10px 0px 0px">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <br />
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="filteredStoredRequests"
                  :sort-by="['name']"
                  :items-per-page="10"
                  item-key="id"
                  class="elevation-1"
                  :loading="loading"
                  hide-default-footer
                  :disable-pagination="true"
                  style="background-color: #f9f9fb"
                >
                  <template v-slot:item="props">
                    <tr
                      :active="props.selected"
                      @click="props.selected = !props.selected"
                    >
                      <td style="width: 5%; font-size: 14px">
                        {{ props.item.id }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 1"
                        style="opacity: 0.5; width: 35%; font-size: 12px"
                      >
                        {{ props.item.name }}
                      </td>
                      <td
                        v-else
                        style="width: 35%; font-size: 14px; cursor: pointer"
                        @click="editItem(props.item)"
                      >
                        <strong>{{ props.item.name }}</strong>
                      </td>
                      <td
                        v-if="sspsElementsByItem(props.item)"
                        style="width: 40%; padding: 5px; font-size: 13px"
                      >
                        <sspgroup :ssp-list="SspList" :item="props.item" />
                      </td>
                      <td
                        v-else
                        style="width: 40%; padding: 5px; font-size: 13px"
                        class="text-xs-right"
                      >
                        <span>
                          <v-icon size="30" color="red darken-1">
                            mdi-alert-octagram
                          </v-icon>
                          {{ $t("warning_ssp_empty") }}
                        </span>
                      </td>
                      <td
                        style="width: 20%; padding: 0px"
                        align="right"
                        justify="right"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(props.item)"
                            >
                              <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit the placement</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                showConfirmationDialog(props.item, 'duplicate')
                              "
                            >
                              <v-icon size="20">mdi-content-duplicate</v-icon>
                            </v-btn>
                          </template>
                          <span>Duplicate the placement</span>
                        </v-tooltip>
                        <v-tooltip v-if="props.item.is_archived == 0" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                showConfirmationDialog(props.item, 'archive')
                              "
                            >
                              <v-icon size="20">mdi-archive-arrow-down</v-icon>
                            </v-btn>
                          </template>
                          <span>Archive the placement</span>
                        </v-tooltip>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="unarchiveStoredRequest(props.item)"
                            >
                              <v-icon size="20">mdi-archive-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <span>Unarchive the placement</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-row>
                  <v-col
                    cols="12"
                    style="
                      padding-top: 0px;
                      padding-left: 15px;
                      padding-bottom: 20px;
                    "
                    align="right"
                    justify="right"
                  >
                    <v-btn
                      class="black--text notcapital"
                      width="250"
                      height="30"
                      outlined
                      style="margin-top: 20px"
                      @click="showArchived = !showArchived"
                    >
                      <v-icon left>
                        {{
                          showArchived ? "mdi-eye-off-outline" : "mdi-archive"
                        }}</v-icon
                      >
                      {{
                        showArchived
                          ? "Hide archived placements"
                          : "Show archived placements"
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <v-col cols="12">
                  <p style="font-size: 15px">
                    {{ $t('archiving_explanation') }}
                  </p>
                </v-col>-->
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <bulkupload
        :parent-dialog="bulkUploadDialog"
        @hideDialog="bulkUploadDialog = false"
        @hideUploadDialog="bulkUploadDialog = true"
      />
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @duplicate="duplicatePlacement"
        @archive="archiveStoredRequest"
      />
      <multipleSR
        :parent-dialog="multipleSRDialog"
        :sradded="multipleSRadded"
        @hideDialog="multipleSRDialog = false"
        @showBulk="
          $router.push($route.path + '?action=bulkupload'),
            (bulkUploadDialog = true)
        "
        @submitted="addMultipleSR"
      />
      <rtcConfig
        :code="codeGenerated"
        :parent-dialog="rtcConfigDialog"
        :site="selectedSite.name"
        @sendToEmail="SendConfigToEmail"
        @hideDialog="rtcConfigDialog = false"
      />
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GetStoredRequests,
  GenerateTag,
  SaveSelectedStoredRequest,
  UpdateSelectedStoredRequest,
} from "@/store/action_types";
import bulkupload from "@/pages/amp/bulkUpload.vue";
import multipleSR from "@/pages/amp/addmultipleSR.vue";
import rtcConfig from "@/pages/management/site/rtc-config.vue";
import sspgroup from "./SspGroup.vue";
import ConfirmAction from "./confirmAction.vue";
import siteMenu from "@/pages/management/site/menuSite.vue";

import {
  SetSelectedSite,
  SetSelectedStoredRequest,
  ClearSelectedStoredRequest,
} from "@/store/mutation_types";
import axios from "axios";

export default {
  components: {
    siteMenu,
    bulkupload,
    rtcConfig,
    multipleSR,
    sspgroup,
    ConfirmAction,
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("Id"),
          align: "left",
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "name",
        },
        {
          text: this.$t("ssp"),
          align: "left",
          value: "SSP",
          sortable: false,
        },
        {
          text: this.$t("Actions"),
          align: "center",
          value: "",
          sortable: false,
        },
      ],
      pages: [],
      loading: false,
      search: "",
      generateButtonLoading: false,
      namesfilter: [],
      sspsfilter: [],
      showArchived: false,
      rtcConfigDialog: false,
      messageButtonTag: "",
      codeGenerated: "",
      bulkUploadDialog: false,
      multipleSRDialog: false,
      multipleSRadded: false,
      menu: false,
      confirmActionDialog: false,
      confirmationAction: "",
      item: {},
      actionsuccess: false,
      timeout: 3000,
      messageAction: "",
      numberOfStoredRequests: "",
    };
  },
  computed: {
    ...mapGetters(["appCustomer", "selectedSite", "storedRequests", "SspList"]),
    computedStoredRequests() {
      if (this.$route.params.type == "fast") {
        return this.showArchived
          ? this.storedRequests.filter((el) => {
              return el.type == "fast";
            })
          : this.storedRequests.filter((el) => {
              return el.is_archived == 0 && el.type == "fast";
            });
      } else if (this.$route.params.type == "amp") {
        return this.showArchived
          ? this.storedRequests.filter((el) => {
              return el.type == "amp";
            })
          : this.storedRequests.filter((el) => {
              return el.is_archived == 0 && el.type == "amp";
            });
      } else {
        return this.showArchived
          ? this.storedRequests.filter((el) => {
              return el.type == "adapter";
            })
          : this.storedRequests.filter((el) => {
              return el.is_archived == 0 && el.type == "adapter";
            });
      }
    },
    filteredStoredRequests() {
      this.computedStoredRequests.forEach((element) => {
        element.site_name = this.selectedSite.name;
      });
      return this.computedStoredRequests
        .filter((d) => {
          return (
            this.namesfilter.includes(d["name"]) || this.namesfilter.length < 1
          );
        })
        .filter((s) => {
          return this.sspsfilter.length < 1 || this.checkssp(s) === true;
        });
    },
    sspListFilter() {
      let activatedSspList = [];
      this.computedStoredRequests.forEach((item) => {
        this.SspList.forEach((element) => {
          if (item[element + "_is_active"]) {
            activatedSspList.push(this.$t(element));
          }
        });
      });
      return activatedSspList.sort((a, b) => a.localeCompare(b));
    },
  },
  watch: {
    appCustomer() {
      if (this.$route.params.site_id != this.appCustomer.id) {
        this.$router.push({
          name: "new_sites",
          params: {
            type: this.$route.params.type,
            customer_id: this.appCustomer.id,
          },
        });
      }
    },
  },

  async created() {
    if (this.$route.params.site_id != "0") {
      await this.GetStoredRequests(this.$route.params.site_id);
      this.ClearSelectedStoredRequest();
      if (this.$route.fullPath != this.$route.path)
        this.$router.push(this.$route.path);
      this.loading = true;
      try {
        const siteURL = await axios.get(
          `/api/getsitebyid/${this.$route.params.site_id}`
        );
        this.SetSelectedSite(siteURL.data);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
    if (this.$route.params.type == "amp")
      this.messageButtonTag = this.$t("view_rtc_config");
    else this.messageButtonTag = this.$t("view_config");
    this.getNumbersOfPlacements();
  },
  methods: {
    ...mapActions({
      GetStoredRequests,
      GenerateTag,
      SaveSelectedStoredRequest,
      UpdateSelectedStoredRequest,
    }),
    ...mapMutations([
      SetSelectedSite,
      SetSelectedStoredRequest,
      ClearSelectedStoredRequest,
    ]),
    getNumbersOfPlacements() {
      const items = this.showArchived
        ? this.storedRequests.filter((el) => {
            return el.type == this.$route.params.type;
          })
        : this.storedRequests.filter((el) => {
            return el.is_archived == 0 && el.type == this.$route.params.type;
          });
      console.log(items);
      if (items.length == 1)
        this.numberOfStoredRequests = items.length + " placement created";
      else if (items.length > 1)
        this.numberOfStoredRequests = items.length + " placements created";
      else this.numberOfStoredRequests = "No placements created";
    },
    sspsElementsByItem(item) {
      var count = 0;
      this.SspList.forEach((element) => {
        if (item[element + "_is_active"]) {
          count++;
        }
      });
      if (count != 0) return true;
      return false;
    },
    sspsListByItem(item) {
      let listToReturn = [];
      this.SspList.forEach((element) => {
        if (item[element + "_is_active"]) {
          listToReturn.push(this.$t(element));
        }
      });
      return listToReturn;
    },
    checkssp(item) {
      let result = false;
      this.sspsfilter.forEach((ssp) => {
        this.sspsListByItem(item).forEach((element) => {
          if (ssp == element) {
            result = true;
          }
        });
      });
      return result;
    },
    newStoredRequest() {
      if (this.selectedSite.id !== undefined) {
        this.$router.push({
          name: "stored_request_new",
          params: {
            site_id: this.selectedSite.id,
            type: this.$route.params.type,
            stored_request_id: "new",
          },
        });
      }
    },
    editItem(stored_request) {
      this.SetSelectedStoredRequest(stored_request);
      this.$router.push({
        name: "stored_request_edit",
        params: {
          site_id: this.$route.params.site_id,
          type: this.$route.params.type,
          stored_request_id: stored_request.id,
        },
      });
    },
    async duplicatePlacement(item, name) {
      const stored_request_duplicated = Object.assign({}, item);
      stored_request_duplicated.id = "";
      stored_request_duplicated.name = name;
      try {
        await this.SaveSelectedStoredRequest(stored_request_duplicated);
        this.GetStoredRequests(this.selectedSite.id);
        this.actionsuccess = true;
        this.messageAction = "placement duplicated successfully";
      } catch (error) {}
    },
    archiveStoredRequest(stored_request) {
      stored_request.is_archived = 1;
      this.UpdateSelectedStoredRequest(stored_request);
      this.actionsuccess = true;
      this.messageAction = "placement archived successfully";
    },
    unarchiveStoredRequest(stored_request) {
      stored_request.is_archived = 0;
      this.UpdateSelectedStoredRequest(stored_request);
    },
    async Show_rtc_config() {
      if (this.$route.params.type == "amp") {
        const generateTag = await this.GenerateTag(this.selectedSite.id);
        this.codeGenerated = generateTag;
        this.rtcConfigDialog = true;
      } else if (this.$route.params.type == "fast") {
        const url =
          "https://developer.nexx360.io/nexx-web/integrate-nexx-web-script-on-your-site";
        window.open(url, "_blank");
      } else {
        const url =
          "https://developer.nexx360.io/prebid-booster/update-prebid-with-nexx-adapter";
        window.open(url, "_blank");
      }
    },
    async SendConfigToEmail(email) {
      const siteId = this.selectedSite.id;
      const response = await axios.get(`/api/rtc-email/${siteId}/${email}`);
    },
    async addMultipleSR(input) {
      const arrayOfSR = input.split("\n");
      arrayOfSR.forEach(async (sr) => {
        var stored_request = {};
        stored_request.id = "";
        stored_request.name = sr.trim();
        stored_request.site_id = this.$route.params.site_id;
        stored_request.type = this.$route.params.type;
        try {
          await axios.post(`/api/sr`, stored_request);
          setTimeout(() => (this.multipleSRadded = true), 1500);
          setTimeout(() => (this.multipleSRadded = false), 2500);
          setTimeout(() => this.GetStoredRequests(this.selectedSite.id), 3000);
        } catch (error) {
          console.log(error);
        }
      });
    },
    showConfirmationDialog(item, action) {
      this.item = item;
      if (action == "duplicate")
        this.$router.push(this.$route.path + "?action=confirmDuplication");
      else this.$router.push(this.$route.path + "?action=confirmArchiving");
      this.confirmActionDialog = true;
      this.confirmationAction = action;
    },
  },
};
</script>

<style scoped>
.notcapital {
  text-transform: none !important;
}
.ssp_tab {
  color: grey;
  margin-left: 5px;
  cursor: initial;
  text-transform: none !important;
}
.noPaddingCenter {
  padding: 0px;
  text-align: center;
}
</style>
