<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="elements"
          class="elevation-1"
          hide-default-footer
          :disable-pagination="true"
          :loading="loading"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                {{ props.item.Name }}
              </td>
              <td>
                {{ props.item.Type }}
              </td>
              <td style="width: 70%">
                {{ props.item.Sizes }}
              </td>
              <td style="width: 10%">
                <v-btn
                  style="margin: 10px"
                  class="white--text notcapital"
                  color="#2E4089"
                  width="100"
                  height="27"
                  dense
                  @click="selectItem(props.item)"
                >
                  {{ $t('select') }}

                  <v-icon right> mdi-arrow-right </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-end" style="margin-top: 50px">
          <app-element-selector
            :device="device"
            :page="page"
            @listSubmitted="getDataFromApi"
            @placementAdded="refreshTable()"
          ></app-element-selector>
        </div>
      </v-card-text>
    </v-card>
    <br />
    <app-placement-details
      v-if="placementDetailsVisible"
      :device="device"
      :page="page"
      :element="element"
      style="margin-top: 10px"
      @pageUpdated="$emit('pageUpdated')"
      @change="refreshTable()"
    >
    </app-placement-details>
  </div>
</template>
<script>
  import axios from 'axios'
  import ElementSelector from './ElementSelector.vue'
  import PlacementDetails from './placement/PlacementDetails.vue'

  export default {
    components: {
      AppElementSelector: ElementSelector,
      AppPlacementDetails: PlacementDetails
    },
    props: ['device', 'page'],
    data() {
      return {
        //elementId: '',
        headers: [
          //{text: 'Id', align: 'left', value : 'id'},
          { text: 'Name', align: 'left', value: 'Name' },
          { text: 'Type', align: 'left', value: 'Type' },
          { text: 'Sizes', align: 'left', value: 'Sizes' },
          { text: 'Action', align: 'left', value: 'action' }
        ],
        elements: [],
        loading: false
      }
    },
    computed: {
      element() {
        if (this.elements == []) {
          return []
        }
        if (this.$route.query.placement == undefined) return []
        const foo = this.elements.findIndex(
          (x) => x.id == this.$route.query.placement
        )
        return foo == -1 ? [] : this.elements[foo]
      },
      placementDetailsVisible() {
        return this.element.length !== 0
      }
    },
    watch: {
      device() {
        this.getDataFromApi()
      }
    },
    async mounted() {
      this.getDataFromApi()
    },
    methods: {
      async getDataFromApi() {
        this.loading = true
        try {
          const response = await axios.get(
            `/api/getwplacementstable/${this.$route.params.page_id}/${this.device.id}`
          )
          this.elements = response.data
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      selectItem(item) {
        if (
          this.$route.fullPath !=
          this.$route.path +
            '?device=' +
            this.device.name +
            '&placement=' +
            item.id
        )
          this.$router.push(
            this.$route.path +
              '?device=' +
              this.device.name +
              '&placement=' +
              item.id
          )
        //this.placementDetailsVisible =  true
        //this.elementId = item.elementId
      },
      back() {
        this.$router.push('/management/' + this.page.siteId + '/pages')
      },
      async refreshTable() {
        const response = await axios.get(
          `/api/getwplacementstable/${this.$route.params.page_id}/${this.device.id}`
        )
        this.elements = response.data
      }
    }
  }
</script>
