import { GetOptionsCookieless } from '@/store/action_types'

import {
  SetOptionsCookieless,
  ClearOptionsCookieless
} from '@/store/mutation_types'

import axios from 'axios'

export const optionscookielessState = {
  optionscookieless: []
}

export const optionscookielessGetters = {
  optionscookieless: (state) => state.optionscookieless
}

export const optionscookielessActions = {
  async [GetOptionsCookieless]({ commit }) {
    const response = await axios.get('/api/optionscookieless')

    commit(SetOptionsCookieless, response.data)
    return response
  }
}

export const optionscookielessMutations = {
  [SetOptionsCookieless](state, optionscookieless) {
    state.optionscookieless = optionscookieless
  },
  [ClearOptionsCookieless](state) {
    state.optionscookieless = []
  }
}

export default optionscookielessActions
