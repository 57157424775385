<template>
  <div id="userList">
    <v-container fluid>
      <v-card id="userList">
        <v-card-title primary-title>
          {{ $t('my_account') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row v-if="alertSiteAdded">
                <v-col>
                  <v-alert dense text type="success" color="green">
                    {{ $t('account_updated_successfully') }}</v-alert
                  >
                </v-col>
              </v-row>
              <v-row no-gutters dense>
                <v-col cols="5">
                  <v-text-field
                    v-model="appUser.first_name"
                    class="ml-4"
                    :label="$t('first_name')"
                    required
                    @keydown="btnSave = true"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" />
                <v-col cols="6">
                  <v-text-field
                    v-model="appUser.last_name"
                    class="ml-4"
                    :label="$t('last_name')"
                    required
                    @keydown="btnSave = true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              v-if="$store.getters.appUser.role == 'admin'"
              dense
              no-gutters
            >
              <v-row no-gutters dense>
                <v-col cols="5">
                  <v-text-field
                    ref="Name"
                    v-model="customer.name"
                    class="ml-4"
                    :label="$t('account_name')"
                    :rules="nameRules"
                    @keydown="btnSave = true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1" />
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.legal_name"
                    class="ml-4"
                    :label="$t('company')"
                    :rules="required"
                    @keydown="btnSave = true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters dense>
                <v-col cols="5">
                  <countrySelect
                    class="ml-4"
                    :default-country="customer.country"
                    @countryUpdate="countryUpdate"
                    @changed="btnSave = true"
                  />
                </v-col>
              </v-row>
              <br />
              <v-row no-gutters dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="customer.street_adress"
                    class="ml-4"
                    :label="$t('address')"
                    :rules="required"
                    @keydown="btnSave = true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters dense>
                <v-col cols="2">
                  <v-text-field
                    v-model="customer.zip_code"
                    class="ml-4"
                    :label="$t('zip_code')"
                    :rules="required"
                    @keydown="btnSave = true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="customer.city"
                    class="ml-4"
                    :label="$t('city')"
                    :rules="required"
                    @keydown="btnSave = true"
                  >
                    {{ customer.city }}
                  </v-text-field></v-col
                >
              </v-row>
              <v-row no-gutters dense>
                <v-col cols="8">
                  <v-text-field
                    v-model="customer.vat"
                    class="ml-4"
                    :label="$t('tva_number')"
                    @keydown="btnSave = true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn
              v-if="btnSave"
              :loading="submitLoading"
              :disabled="submitLoading"
              style="margin: 10px"
              class="white--text notcapital"
              color="black"
              width="150"
              dense
              @click="updateAccount()"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <br />
        <v-col v-if="!$store.getters.is_superadmin">
          <h3>{{ $t('user_list') }}</h3>
        </v-col>
        <v-card-title v-if="!$store.getters.is_superadmin" primary-title>
          <v-col cols="2">
            <v-btn
              class="notcapital"
              color="secondary"
              style="padding: 10px"
              width="180px"
              small
              @click="newItem"
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t('add_user') }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <!--<v-btn
              class="notcapital"
              color="secondary"
              style="padding: 10px"
              width="180px"
              small
              @click="ShowInviteDialog()"
            >
              <v-icon left> mdi-email-outline </v-icon>
              {{ $t('invite_user') }}
            </v-btn>-->
          </v-col>
          <v-col cols="2" style="text-align: right">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="notcapital"
                  color="orange"
                  style="margin-top: 16px; color: white"
                  v-bind="attrs"
                  small
                  :loading="refreshLoading"
                  v-on="on"
                  @click="RefreshTable()"
                >
                  <v-icon
                    center
                    style="border-radius: 5px; width: 20px"
                    color="white"
                    >mdi-refresh</v-icon
                  >
                </v-btn>
              </template>
              <span>Refresh user's table</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" style="text-align: right" justify-end>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-row v-if="alertInvitationSent" no-gutters dense>
          <v-col>
            <v-alert dense text type="success" color="green">
              {{ $t('invitations_sent_successfully') }}</v-alert
            >
          </v-col>
        </v-row>
        <v-card-text v-if="!$store.getters.is_superadmin">
          <v-data-table
            :search="search"
            :headers="headers"
            :items="computedUsers"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.first_name }}</td>
                <td v-if="$store.getters.is_superadmin">
                  <router-link
                    :to="{
                      name: 'customer_edit',
                      params: {
                        customer: item,
                        customer_id: item.customer_id
                      }
                    }, "
                  >
                    {{ item.customer_id }}
                  </router-link>
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.role.toLowerCase() }}</td>
                <td>{{ Status(item.okta_status) }}</td>
                <td>
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon color="black">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('deactivated')"
                        @click="ActionUser('activateuser', item.email)"
                        >{{ $t('activate_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) != $t('deactivated')"
                        @click="ActionUser('deactivateuser', item.email)"
                        >{{ $t('deactivate_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="
                          Status(item.okta_status) != $t('deactivated') &&
                          Status(item.okta_status) != $t('suspended')
                        "
                        @click="ActionUser('suspenduser', item.email)"
                        >{{ $t('suspend_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('pending')"
                        @click="ActionUser('reactivateuser', item.email)"
                        >{{ $t('reactivate_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('suspended')"
                        @click="ActionUser('unsuspenduser', item.email)"
                        >{{ $t('unsuspend_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) != $t('deleted')"
                        @click="ActionUser('resetpassword', item.email)"
                        >{{ $t('reset_password') }}</v-list-item
                      >
                      <v-list-item @click="editItem(item)">
                        {{ $t('edit_properties') }}</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <inviteUsers
      :parent-dialog="InviteDialog"
      @hideDialog="InviteDialog = false"
      @submitted="SendInvitation"
    />
  </div>
</template>

<script>
  import countrySelect from '@/pages/login/components/countrySelect.vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import {
    GetUsers,
    SaveSelectedUser,
    SaveSelectedCustomer,
    InviteSelectedUser,
    AccountNameAlreadyExistsUpdate,
    GetSelectedUser,
    GetSelectedCustomer,
    UserAlreadyExists
  } from '@/store/action_types'
  import axios from 'axios'
  import { SetSelectedCustomer } from '@/store/mutation_types'
  import inviteUsers from '@/pages/account/invite.vue'
  export default {
    name: 'UserList',
    components: { countrySelect, inviteUsers },
    data() {
      return {
        headers: [
          { text: 'Id', align: 'left', value: 'id' },
          { text: this.$t('last_name'), align: 'left', value: 'last_name' },
          { text: this.$t('first_name'), align: 'left', value: 'first_name' },
          { text: this.$t('customer_id'), align: 'left', value: 'customer_id' },
          { text: this.$t('email'), align: 'left', value: 'email' },
          { text: this.$t('role'), align: 'left', value: 'role' },
          { text: this.$t('status'), align: 'left', value: 'status' },
          { text: this.$t('action'), align: 'left', value: 'action' }
        ],
        oktaUsers: {},
        search: '',
        InviteDialog: false,
        customer: {},
        appUser: {},
        btnSave: false,
        loading: false,
        submitLoading: false,
        refreshLoading: false,
        isNameValid: true,
        required: [(v) => !!v || 'Required'],
        nameRules: [
          (v) => !!v || 'Account name is required',
          (v) => this.isNameValid || 'Account name is already used'
        ],
        alertSiteAdded: false,
        alertInvitationSent: false,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      }
    },
    computed: {
      ...mapGetters([
        'users',
        'appCustomer',
        'selectedCustomer',
        'selectedUser'
      ]),
      computedUsers() {
        return this.users.filter((el) => {
          return this.$store.getters.is_superadmin
            ? true
            : el.customer_id == this.appCustomer.id
        })
      }
    },
    watch: {
      'customer.name': async function (val, oldVal) {
        if (val != undefined && val != '') {
          const arrayName = await this.AccountNameAlreadyExistsUpdate(val)
          if (arrayName.length != 0) {
            if (arrayName[0].id != this.customer.id) {
              this.isNameValid = false
            } else {
              this.isNameValid = true
            }
          }
          if (this.$ref != undefined) this.$refs.Name.validate()
        }
      }
    },
    async created() {
      await this.GetSelectedUser(this.$store.getters.appUser.id)
      this.appUser = Object.assign({}, this.selectedUser)
      await this.GetSelectedCustomer(this.appCustomer.id)
      this.customer = Object.assign({}, this.selectedCustomer)
      this.SetSelectedCustomer(this.appCustomer)
      try {
        this.loading = true
        await this.GetUsers()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
      if (this.$store.getters.appUser.role == 'admin') this.headers.splice(3, 1)
    },
    methods: {
      ...mapMutations([SetSelectedCustomer]),
      ...mapActions([
        GetUsers,
        SaveSelectedUser,
        SaveSelectedCustomer,
        InviteSelectedUser,
        AccountNameAlreadyExistsUpdate,
        GetSelectedUser,
        GetSelectedCustomer,
        UserAlreadyExists
      ]),
      countryUpdate(country) {
        this.customer.country = country
      },
      ShowInviteDialog() {
        this.InviteDialog = true
      },
      editItem(user) {
        this.$router.push({
          name: 'user_edit',
          params: {
            userId: user.id,
            user: user
          }
        })
      },
      newItem() {
        this.$router.push({ name: 'user_new' })
      },
      async updateAccount() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        if (this.$store.getters.appUser.is_admin)
          this.customer.code = this.customer.name
            .trim()
            .replace(/\s+/g, '-')
            .toLowerCase()
        await this.SaveSelectedCustomer(this.customer)
        const output = await this.SaveSelectedUser(this.appUser).catch(() => {
          this.errors = this.getErrors
          this.submitLoading = false
          return false
        })
        await this.GetUsers()
        if (!output) {
          return
        }
        this.appCustomer.code = this.customer.code
        this.submitLoading = false
        this.btnSave = false
        this.alertSiteAdded = true
        setTimeout(() => (this.alertSiteAdded = false), 3000)
      },
      async SendInvitation(emails) {
        this.InviteDialog = false
        var emailsArr = emails.split(',')
        var count = 0
        for (const email of emailsArr) {
          if (
            (await this.UserAlreadyExists(email)) == false &&
            this.reg.test(email)
          ) {
            this.InviteSelectedUser(email)
            count = count + 1
          }
        }
        if (count > 0) this.alertInvitationSent = true
        setTimeout(() => (this.alertInvitationSent = false), 3000)
      },
      Status(status) {
        switch (status) {
          case 'ACTIVE':
            return this.$t('active')
            break
          case 'DEPROVISIONED':
            return this.$t('deactivated')
            break
          case 'SUSPENDED':
            return this.$t('suspended')
            break
          case 'PASSWORD_EXPIRED':
            return this.$t('pass_expired')
            break
          case 'PROVISIONED':
            return this.$t('pending')
            break
          case 'RECOVERY':
            return this.$t('recovery')
            break
          case 'PENDING':
            return this.$t('pending')
            break
          default:
            return this.$t('deleted')
        }
      },
      async ActionUser(action, email) {
        /*const response = await axios.get(`/api/getuserbyemail/${email}`)
        const oktauser = response.data*/
        await axios.post(`/api/${action}/${email}`)
        const rr = await axios.post(`/api/updatestatus`)
        await this.GetUsers()
      },
      async RefreshTable() {
        this.refreshLoading = true
        const response = await axios.post(`/api/updatestatus`)
        await this.GetUsers()
        this.refreshLoading = false
      }
    }
  }
</script>

<style>
  .notcapital {
    text-transform: none !important;
  }
</style>
