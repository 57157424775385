<template>
  <div id="PageContent">
    <v-container fluid>
      <v-card id="PageList">
        <v-card-title primary-title>
          <v-col cols="12" style="padding-bottom: 0px">
            <div>
              <v-breadcrumbs
                style="
                  padding-left: 20px;
                  padding-top: 0px;
                  padding-bottom: 0px;
                "
                :items="pages"
              ></v-breadcrumbs>
            </div>
          </v-col>
          <v-col cols="12" class="nopadding">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12">
            Deals of the proposal {{ selectedProposal.name }}
          </v-col>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 10px"
                class="white--text notcapital"
                color="black"
                width="200"
                dense
                @click="newDeal()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add a new deal
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-data-table
                  :headers="headers"
                  :sort-by="['name']"
                  :items-per-page="10"
                  :items="deals"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  :disable-pagination="true"
                  style="background-color: #f9f9fb"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="clickable" @click="editItem(props.item)">
                        {{ props.item.deal_id }}
                      </td>
                      <td class="clickable" @click="editItem(props.item)">
                        {{ props.item.name }}
                      </td>
                      <td class="clickable" @click="editItem(props.item)">
                        {{ selectedProposal.deal_type }}
                      </td>
                      <td class="clickable" @click="editItem(props.item)">
                        {{ props.item.auction_type }}
                      </td>
                      <td class="clickable" @click="editItem(props.item)">
                        {{ props.item.rate_amount }}
                        {{ props.item.rate_currency }}
                      </td>
                      <td class="clickable" @click="editItem(props.item)">
                        {{
                          getDateFromTimeStamp(props.item.start_time).replace(
                            'T',
                            ' '
                          )
                        }}
                      </td>
                      <td
                        v-if="
                          props.item.end_time != null &&
                          props.item.end_time != 0
                        "
                        class="clickable"
                        @click="editItem(props.item)"
                      >
                        {{
                          getDateFromTimeStamp(props.item.end_time).replace(
                            'T',
                            ' '
                          )
                        }}
                      </td>
                      <td
                        v-else
                        class="clickable"
                        @click="editItem(props.item)"
                      ></td>
                      <td align="center" justify="center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(props.item)"
                            >
                              <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit the deal</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <!--<v-row>
                  <v-col
                    cols="12"
                    style="
                      padding-top: 0px;
                      padding-left: 15px;
                      padding-bottom: 20px;
                    "
                    align="right"
                    justify="right"
                  >
                    <v-btn
                      class="black--text notcapital"
                      width="250"
                      height="30"
                      outlined
                      style="margin-top: 20px"
                      @click="showArchived = !showArchived"
                    >
                      <v-icon left>
                        {{
                          showArchived ? 'mdi-eye-off-outline' : 'mdi-archive'
                        }}</v-icon
                      >
                      {{
                        showArchived
                          ? 'Hide archived deals'
                          : 'Show archived deals'
                      }}
                    </v-btn>
                  </v-col>
                </v-row>-->
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import { SetSelectedDeal } from '@/store/mutation_types'
  import { GetDeals } from '@/store/action_types'

  export default {
    components: {},

    data() {
      return {
        headers: [
          {
            text: this.$t('Id'),
            align: 'left',
            value: 'id'
          },
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('deal_type'),
            align: 'left',
            value: 'deal_type'
          },
          {
            text: this.$t('auction_type'),
            align: 'left',
            value: 'auction_type'
          },
          {
            text: this.$t('rate_amount'),
            align: 'left',
            value: 'rate_amount'
          },
          {
            text: this.$t('start_date'),
            align: 'left',
            value: 'start_date'
          },
          {
            text: this.$t('end_date'),
            align: 'left',
            value: 'end_date'
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: '',
            sortable: false
          }
        ],
        showArchived: false,
        pages: [
          {
            text: 'Home',
            disabled: false,
            href: '/'
          },
          {
            text: 'Proposals',
            disabled: false,
            href: '/proposals'
          },
          {
            text: 'Deals',
            disabled: true
          }
        ]
      }
    },
    computed: {
      ...mapGetters(['selectedProposal', 'deals'])
    },
    watch: {},

    async created() {
      await this.GetDeals(this.$route.params.proposal_id)
    },
    methods: {
      ...mapMutations([SetSelectedDeal]),
      ...mapActions({
        GetDeals
      }),
      newDeal() {
        this.$router.push({
          name: 'deal_new'
        })
      },
      editItem(deal) {
        this.SetSelectedDeal(deal)
        this.$router.push({
          name: 'deal_edit',
          params: {
            proposal_id: this.$route.params.proposal_id,
            deal_id: deal.id
          }
        })
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)

        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = day + '/' + month + '/' + year
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
  .clickable {
    cursor: pointer;
  }
</style>
