<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <h2 style="font-size: 25px; text-indent: 10px">
          {{ $t('setup_gam') }}
        </h2>
      </v-col>
      <v-col cols="3" align="right">
        <v-btn
          class="white--text notcapital"
          color="black"
          style="padding: 10px; margin-top: 20px"
          width="180px"
          small
          @click.stop="showEdit('add')"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t('add_new_gam_account') }}
        </v-btn></v-col
      >
    </v-row>
    <v-card style="padding: 10px 5px 10px 20px; margin: 20px 0 20px 0">
      <p style="font-size: 18px; text-indent: 10px">{{ $t('setup_title') }}</p>
      <p style="font-size: 14px; margin-left: 7px">
        {{ $t('setup_subtitle_l1') }} <br />
        {{ $t('setup_subtitle_l2') }}
      </p>
      <p style="font-size: 14px; margin-left: 7px">
        {{ $t('see') }}
        <a
          style="color: black"
          href="https://developer.nexx360.io/"
          target="_blank"
          >{{ $t('documentation_link') }}</a
        >
        {{ $t('for_full_process') }}.
      </p>
    </v-card>

    <v-spacer></v-spacer>
    <v-container>
      <v-row no-gutters dense>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            style="width: 50%"
          ></v-text-field>
        </v-col>
      </v-row>

      <br />
      <v-data-table
        :search="search"
        :headers="headers"
        :sort-by="['network_name']"
        :items="gamsetups"
        class="elevation-1 table"
        :loading="loading"
        hide-default-footer
        :disable-pagination="true"
      >
        <v-data-table-header class="accent" />
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span>{{ item.network_id }}</span>
            </td>

            <td>
              <span>{{ item.network_name }}</span>
            </td>
            <td v-if="item.status == 0">
              <div class="status-0" align="center">
                {{ $t('No') }}
              </div>
            </td>
            <td v-if="item.status == 1 || item.status == 2 || item.status == 3">
              <div class="status-3" align="center">
                {{ $t('Yes') }}
              </div>
            </td>

            <td v-if="item.status == 0 || item.status == 1">
              <div class="status-0" align="center">
                {{ $t('No') }}
              </div>
            </td>
            <td v-if="item.status == 2">
              <div class="status-2" align="center">
                {{ $t('in_progress') }}
              </div>
            </td>
            <td v-if="item.status == 3">
              <div class="status-3" align="center">
                {{ $t('Yes') }}
              </div>
            </td>

            <td>
              <v-btn
                v-if="item.status == 1 || item.status == 2"
                class="mr-2"
                small
                color="#fc8f00"
              >
                {{ $t('set_up_amp') }}
              </v-btn>
              <v-btn v-if="item.status == 0" class="mr-2" small color="#fc8f00">
                {{ $t('test_api') }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-row>
        <v-col cols="4"> </v-col>
        <v-col style="margin-top: 20px" cols="8" align="right">
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="200"
            height="30"
            dense
            @click="$router.push('/')"
          >
            {{ $t('go_to_websites') }}
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <gamSetupEdit
      :action-type="actionType"
      :parent-dialog="gamSetupDialog"
      @submitted="AddGS"
      @hideDialog="HideDialog"
    />
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import {
    GetGamSetups,
    SaveSelectedGamSetup,
    UpdateSelectedGamSetup
  } from '@/store/action_types'
  import {
    SetSelectedGamSetup,
    ClearSelectedGamSetup
  } from '@/store/mutation_types'
  import gamSetupEdit from './edit.vue'

  export default {
    components: { gamSetupEdit },
    data() {
      return {
        headers: [
          {
            text: this.$t('gam_network_id'),
            align: 'left',
            value: 'network_id'
          },
          {
            text: this.$t('gam_network_name'),
            align: 'left',
            value: 'network_name'
          },
          {
            text: this.$t('api_enabled'),
            align: 'left',
            value: 'status'
          },
          {
            text: this.$t('amp_line_items_pushed'),
            align: 'left',
            value: 'line_items'
          },
          { text: this.$t('action'), align: 'left', value: 'Set Up AMP' }
        ],
        search: '',
        loading: false,
        gamSetupDialog: false,
        actionType: '',
        selectedGamSetup: {}
      }
    },
    computed: {
      ...mapGetters(['gamsetups', 'appCustomer'])
    },
    watch: {
      async appCustomer() {
        await this.GetGamSetups(this.appCustomer.id)
      }
    },
    async created() {
      await this.GetGamSetups(this.appCustomer.id)
    },
    methods: {
      ...mapActions([
        GetGamSetups,
        SaveSelectedGamSetup,
        UpdateSelectedGamSetup
      ]),
      ...mapMutations([SetSelectedGamSetup, ClearSelectedGamSetup]),

      async showEdit(actionType) {
        this.gamSetupDialog = true
        this.actionType = actionType
        await this.ClearSelectedGamSetup
      },
      async AddGS(gamSetup) {
        await this.SaveSelectedGamSetup(gamSetup)
        await this.GetGamSetups(this.appCustomer.id)
        this.gamSetupDialog = false
      },
      HideDialog() {
        this.gamSetupDialog = false
      }
    }
  }
</script>

<style>
  .status-0 {
    width: 50px;
    border: solid 2px #ef0000;
    background-color: #ef0000;
    border-radius: 5px;
    color: white;
  }
  .status-1 {
    width: 110px;
    border: solid 2px #2e4089;
    border-radius: 5px;
    color: #2e4089;
  }
  .status-2 {
    width: 90px;
    border: solid 2px #fc8f00;
    background-color: #fc8f00;
    border-radius: 5px;
    color: white;
  }
  .status-3 {
    width: 50px;
    border: solid 2px #35b746;
    background-color: #35b746;
    border-radius: 5px;
    color: white;
  }
</style>
