<template>
  <div>
    <v-divider></v-divider>
    <v-card-title>
      <div style="font-size: 0.8em">
        {{ ucFirst(ssp) }}
      </div>
      <v-item-group v-model="placement['is' + ucFirst(ssp) + 'Active']">
        <v-item
          v-for="state in states"
          :key="state.id"
          v-slot:default="{ active, toggle }"
        >
          <v-btn
            class="mx-2"
            :input-value="active"
            :active-class="state.activeClass"
            depressed
            rounded
            small
            @click="toggle"
          >
            {{ state.name }}
          </v-btn>
        </v-item>
      </v-item-group>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-container style="padding: 0px">
          <v-row>
            <v-col
              v-for="field in fields"
              :key="field"
              cols="12"
              md="4"
              class="pageForm"
            >
              <v-text-field
                v-model="placement[ssp + ucFirst(field)]"
                :label="ucFirst(field)"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </div>
</template>
<script>
  export default {
    props: ['placement', 'fields', 'ssp'],
    data() {
      return {
        states: [
          { id: 0, name: 'Disabled', activeClass: 'red white--text' },
          { id: 1, name: 'Active', activeClass: 'green white--text' },
          { id: 2, name: 'Paused', activeClass: 'orange white--text' }
        ]
      }
    },
    methods: {
      ucFirst(name) {
        return name.charAt(0).toUpperCase() + name.slice(1)
      }
    }
  }
</script>
