<template>
  <v-container v-show="show" fluid style="background-color: #f9f9fb">
    <v-container
      v-if="
        $store.getters.is_superadmin == false &&
        this.$store.state.appCustomer.id != $route.params.customer_id &&
        this.$store.state.appCustomer.id != '1086' &&
        this.$store.state.appCustomer.id != '1094'
      "
    >
      <accessdenied />
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col>
          <h1 class="cnx">
            <strong> {{ $t("sites management") }}</strong>
          </h1>
          <p style="padding-left: 30px; font-size: 14px">
            {{ $t("amp") }}
          </p>
        </v-col>
      </v-row>
      <v-row v-if="alertSiteAdded">
        <v-col>
          <v-alert dense text type="success" color="green">
            {{ textAlert }}</v-alert
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-row>
            <siteMenu @refresh="RefreshPage" @showDialog="ShowEdit" />
          </v-row>
        </v-col>
        <v-col cols="2" />
        <v-col
          v-if="storedRequests.length == 0 && selectedSite != undefined"
          cols="4"
          align="right"
          justify="right"
        >
          <v-btn
            class="black--text notcapital"
            tile
            width="200"
            height="32"
            outlined
            color="black"
            @click="ShowEdit('add')"
          >
            <v-icon left> mdi-plus </v-icon>
            {{ $t("add_site") }}
          </v-btn>
        </v-col>
        <v-col
          v-if="storedRequests.length > 0"
          cols="3"
          align="right"
          justify="right"
        >
          <v-btn
            class="black--text notcapital"
            color="white"
            dense
            @click="new_storedRequest()"
          >
            {{ $t("add placement") }}
          </v-btn>
        </v-col>
        <v-col
          v-if="storedRequests.length > 0"
          cols="2"
          align="left"
          justify="left"
        >
          <v-btn
            style="border-color: black"
            class="white--text notcapital"
            color="black"
            dense
            @click="Show_rtc_config"
          >
            {{ $t("view_rtc_config") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <tableSR
            @createSR="new_storedRequest()"
            @duplicate="Duplicate_placement"
            @update="editItem"
            @archiveItem="Archive_StoredRequest"
            @unarchiveItem="Unarchive_StoredRequest"
            @showDialog="ShowEdit"
          />
        </v-col>
      </v-row>
      <site-edit
        :action-type="actionType"
        :parent-dialog="siteEditDialog"
        @hideDialog="siteEditDialog = false"
        @submitted="ConfirmEdit"
      />
      <rtcConfig
        :code="codeGenerated"
        :parent-dialog="rtcConfigDialog"
        @sendToEmail="SendConfigToEmail"
        @hideDialog="rtcConfigDialog = false"
      />
    </v-container>
  </v-container>
</template>

<script>
import {
  GetStoredRequests,
  GetSites,
  SaveSelectedStoredRequest,
  UpdateSelectedStoredRequest,
  GenerateTag,
  SaveSelectedSite,
} from "@/store/action_types";
import { mapActions, mapMutations, mapGetters } from "vuex";
import {
  SetSelectedSite,
  ClearSelectedStoredRequest,
  SetSelectedStoredRequest,
  ClearSelectedSite,
} from "@/store/mutation_types";
import siteMenu from "@/pages/management/site/menuSite.vue";
import axios from "axios";
import siteEdit from "@/pages/management/site/editSite.vue";
import rtcConfig from "@/pages/management/site/rtc-config.vue";
import tableSR from "@/pages/management/storedRequest/tableSR.vue";
import accessdenied from "@/pages/accessdenied.vue";

export default {
  name: "SiteList",
  components: { siteMenu, tableSR, siteEdit, rtcConfig, accessdenied },
  data() {
    return {
      site: {
        name: "",
      },
      actionType: "",
      search: "",
      loading: false,
      show_tag: false,
      tag_content: "",
      siteEditDialog: false,
      rtcConfigDialog: false,
      codeGenerated: "",
      alertSiteAdded: false,
      textAlert: "",
      show: false,
    };
  },
  computed: {
    ...mapGetters(["sites", "selectedSite", "appCustomer", "storedRequests"]),
  },

  async created() {
    this.ClearSelectedStoredRequest();
    setTimeout(() => (this.show = true), 400);
    await axios.get(`/api/getappusers`);
    await axios.post(`/api/updatestatus`);
  },
  methods: {
    ...mapActions({
      GetSites,
      GetStoredRequests,
      SaveSelectedStoredRequest,
      UpdateSelectedStoredRequest,
      GenerateTag,
      SaveSelectedSite,
    }),
    ...mapMutations([
      SetSelectedSite,
      ClearSelectedStoredRequest,
      SetSelectedStoredRequest,
      ClearSelectedSite,
    ]),
    new_storedRequest() {
      this.ClearSelectedStoredRequest();
      if (this.selectedSite.id !== undefined) {
        this.$router.push({
          name: "stored_request_new",
          params: {
            site_id: this.selectedSite.id,
            type: "amp",
            stored_request_id: "new",
          },
        });
      }
    },
    editItem(stored_request) {
      this.SetSelectedStoredRequest(stored_request);
      this.$router.push({
        name: "stored_request_edit",
        params: {
          site_id: this.selectedSite.id,
          type: "amp",
          stored_request_id: stored_request.id,
        },
      });
    },
    async Show_rtc_config() {
      const generateTag = await this.GenerateTag(this.selectedSite.id);
      this.codeGenerated = generateTag;
      this.rtcConfigDialog = true;
    },
    ShowEdit(actionType) {
      this.actionType = actionType;
      this.siteEditDialog = true;
    },
    async Duplicate_placement(item) {
      this.RefreshPage(400);
      this.$emit("duplicate", item);
      const stored_request_duplicated = Object.assign({}, item);
      stored_request_duplicated.id = "";
      stored_request_duplicated.name = item.name + " _1";
      await this.SaveSelectedStoredRequest(stored_request_duplicated);
      this.GetStoredRequests(this.selectedSite.id);
    },
    Archive_StoredRequest(stored_request) {
      stored_request.is_archived = 1;
      this.UpdateSelectedStoredRequest(stored_request);
    },
    Unarchive_StoredRequest(stored_request) {
      stored_request.is_archived = 0;
      this.UpdateSelectedStoredRequest(stored_request);
    },
    async ConfirmEdit(siteName, actionType) {
      try {
        if (actionType == "update") {
          this.selectedSite.name = siteName;
          await this.SaveSelectedSite(this.selectedSite);
          await this.GetSites(this.appCustomer.id);
          await this.SetSelectedSite(this.site);
          this.textAlert = this.$t("success_website_updated");
        }
        if (actionType == "add") {
          this.site.name = siteName;
          this.site.customer_id = this.appCustomer.id;
          await this.SaveSelectedSite(this.site);
          this.textAlert = this.$t("success_website_added");
          this.$router.push({
            name: "Home",
            params: {
              customer_id: this.appCustomer.id,
              site_id: this.$store.state.selectedSite.id,
            },
          });
        }
        this.siteEditDialog = false;
        this.alertSiteAdded = true;
        setTimeout(() => (this.alertSiteAdded = false), 3000);
      } catch (error) {
        console.log(error);
      }
    },
    RefreshPage(period) {
      this.show = false;
      setTimeout(() => (this.show = true), period);
    },
    async SendConfigToEmail(email) {
      const siteId = this.selectedSite.id;
      const response = await axios.get(`/api/rtc-email/${siteId}/${email}`);
    },
  },
};
</script>

<style scoped>
.cnx {
  letter-spacing: 2px;
  font-size: 30px;
  padding-left: 30px;
}

.combobox {
  padding-left: 50px;
  width: 370px;
}
.notcapital {
  text-transform: none !important;
}
.parameters {
  background-color: black;
}
.ssp_tab {
  color: grey;
  margin-left: 5px;
  cursor: initial;
  text-transform: none !important;
}
</style>
