<template>
  <v-container fluid>
    <v-card v-if="customer">
      <v-tabs v-model="selectedIndex" background-color="#2E4089" dark>
        <v-tab @click="goTo('general')"> General </v-tab>
        <v-tab-item>
          <v-card-title
            v-if="$route.name == 'customer_new'"
            primary-title
            style="padding-bottom: 0px"
          >
            {{ $t("new customer") }}
          </v-card-title>
          <v-card-title v-else primary-title style="padding-bottom: 0px">
            {{ $t("customer") }} {{ customer.name }}
          </v-card-title>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <p>{{ $t("account_name") }}</p>
                    <v-text-field
                      ref="Name"
                      v-model="customer.name"
                      :rules="nameRules"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" style="padding-left: 20px">
                    <p>{{ $t("company") }}</p>
                    <v-text-field
                      v-model="customer.legal_name"
                      :rules="required"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="5">
                    <countrySelect
                      :default-country="customer.country"
                      @countryUpdate="countryUpdate"
                    />
                  </v-col>
                </v-row>
                <br />
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <p>
                      {{ $t("address") }}
                    </p>
                    <v-text-field
                      v-model="customer.street_adress"
                      :rules="required"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="3">
                    <p>
                      {{ $t("zip_code") }}
                    </p>
                    <v-text-field
                      v-model="customer.zip_code"
                      :rules="required"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="6"
                    ><p>
                      {{ $t("city") }}
                    </p>
                    <v-text-field
                      v-model="customer.city"
                      :rules="required"
                      dense
                      solo
                    >
                      {{ customer.city }}
                    </v-text-field></v-col
                  >
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <p>{{ $t("tva_number") }} ({{ $t("optional") }})</p>
                    <v-text-field v-model="customer.vat" dense solo>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!--<v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.adapter_is_active"
                      inset
                      :label="`Activate adapter`"
                    ></v-switch>
                  </v-col>
                </v-row>-->
              </v-container>
            </v-card-text>
            <v-row v-if="updated == true" style="padding-top: 20px">
              <v-col>
                <v-alert dense text type="success" color="green">
                  Customer updated successfully</v-alert
                >
              </v-col>
            </v-row>
            <v-card-actions class="d-flex flex-row-reverse">
              <v-btn
                :loading="submitLoading"
                :disabled="submitLoading"
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="submit"
              >
                <v-icon left> mdi-check-circle</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-btn
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="back"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $t("back") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>
        <v-tab @click="goTo('setup')"> Set up </v-tab>
        <v-tab-item>
          <v-card-title primary-title style="padding-bottom: 0px">
            Set up
          </v-card-title>
          <v-row
            v-if="updated == true"
            style="padding-top: 10px; padding-bottom: 0px"
          >
            <v-col style="padding-bottom: 0px">
              <v-alert dense text type="success" color="green">
                Customer updated successfully</v-alert
              >
            </v-col>
          </v-row>
          <v-form ref="form" lazy-validation no-gutters dense>
            <v-card-text style="padding-top: 10px">
              <v-container style="padding-top: 0px">
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.adapter_is_active"
                      inset
                      :label="`Activate Prebid Booster`"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.web_is_active"
                      inset
                      :label="`Activate Stack Nexx360`"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.amp_is_active"
                      inset
                      :label="`Activate AMP Nexx360`"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.deal_is_active"
                      inset
                      :label="`Activate Deal`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex flex-row-reverse">
              <v-btn
                :loading="submitLoading"
                :disabled="submitLoading"
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="submit"
              >
                <v-icon left> mdi-check-circle</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-btn
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="back"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $t("back") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab @click="goTo('ssp')"> SSP </v-tab>
        <v-tab-item>
          <v-card-title> SSP activation </v-card-title>
          <v-row style="margin-left: 20px; padding-top: 20px">
            <v-data-table
              :search="searchSSP"
              :headers="headersSSP"
              :items="sspstableList"
              :items-per-page="5"
              class="elevation-1"
              :loading="loadingSSP"
              hide-default-footer
              :disable-pagination="true"
              style="width: 100%"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td style="width: 10%; font-weight: bold; cursor: pointer">
                    <v-checkbox
                      v-model="customer[item['name'] + '_is_activated']"
                      color="black"
                    ></v-checkbox>
                  </td>
                  <td style="width: 15%">{{ $t(item["name"]) }}</td>
                  <td style="width: 10%">
                    <v-text-field
                      v-model="customer[item['name'] + '_cpm_adjustment']"
                      dense
                      solo
                      style="padding-top: 13px"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="item['country_list']"
                      :items="countryList"
                      item-text="name"
                      item-value="code"
                      label="Choose countries"
                      outlined
                      dense
                      multiple
                      style="padding-top: 13px; width: 100%"
                    >
                      <template v-slot:selection="{ index }">
                        <v-chip
                          close
                          style="margin-top: 5px; margin-bottom: 5px"
                          @click:close="remove(item['country_list'], index)"
                        >
                          <span>{{
                            getNameCountry(item["country_list"][index])
                          }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </td>
                  <td style="width: 20%; padding-right: 0px">
                    <tr style="padding-left: 10px">
                      <td style="padding-right: 0px">
                        <p
                          style="
                            font-size: 15px;
                            color: rgba(0, 0, 0, 0.6);
                            font-family: Roboto;
                            margin: 0px;
                            padding-right: 0px;
                            padding-bottom: 8px;
                            margin-left: 5px;
                          "
                          align="right"
                          justify-end
                        >
                          Include
                        </p>
                      </td>
                      <td style="padding-left: 5px">
                        <v-switch
                          v-model="item['exclude']"
                          label="Exclude"
                          style="padding: 0px; margin-left: 5px"
                        ></v-switch>
                      </td>
                    </tr>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>

          <v-row v-if="updated == true" style="padding-top: 20px">
            <v-col>
              <v-alert dense text type="success" color="green">
                Customer updated successfully</v-alert
              >
            </v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col cols="12">
              <v-card-actions align="right" class="justify-end">
                <v-col cols="12">
                  <v-btn
                    width="200"
                    style="height: 40px"
                    class="white--text"
                    color="black"
                    :loading="submitLoading"
                    :disabled="submitLoading"
                    @click="submit"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-col>
                <br />
              </v-card-actions>
            </v-col>
          </v-row>
          <!--<ssp @saveSspChanges="addRule" />-->
        </v-tab-item>
        <v-tab @click="goTo('billing')"> Billing </v-tab>
        <v-tab-item>
          <v-card-title> Billing settings </v-card-title>
          <v-row v-if="updated == true" style="padding-top: 20px">
            <v-col>
              <v-alert dense text type="success" color="green">
                Rule updated successfully</v-alert
              >
            </v-col>
          </v-row>
          <v-col style="padding-top: 0px">
            <v-btn
              class="black--text notcapital"
              tile
              width="200"
              height="30"
              outlined
              style="margin-top: 20px"
              @click="showArchived = !showArchived"
            >
              <v-icon left>
                {{ showArchived ? "mdi-earth" : "mdi-archive" }}</v-icon
              >
              {{ showArchived ? "Show active rules" : "Show archived rules" }}
            </v-btn>
          </v-col>
          <archivedRules v-if="showArchived" :archivedrules="archivedRules">
          </archivedRules>
          <v-data-table
            v-if="showArchived == false"
            :headers="headers"
            :items="rules"
            class="elevation-1"
            hide-default-footer
            :disable-pagination="true"
            :loading="loadingrules"
          >
            <template v-slot:item="props">
              <tr>
                <td style="width: 9%">
                  {{ props.item.product }}
                </td>
                <td class="text-xs-right">
                  <v-container style="padding: 5px">
                    <sitesgroup :items="props.item.sites" />
                  </v-container>
                </td>
                <td style="width: 8%">
                  {{ props.item.cpm_value }}
                </td>
                <td style="width: 12%">
                  {{ format_date(props.item.start_date) }}
                </td>
                <td v-if="props.item.end_date != null" style="width: 12%">
                  {{ format_date(props.item.end_date) }}
                </td>
                <td v-else style="width: 12%">Undefined</td>
                <td style="width: 10%; padding: 0px">
                  <updateRule
                    :sites="sites"
                    :selectedsitesprop="props.item.sites"
                    :rule="rule"
                    :customer="customer"
                    :item="props.item"
                    @refreshData="getDataFromApi"
                  >
                  </updateRule>
                  <archiveRule :item="props.item" @ruleArchived="archiveRule">
                  </archiveRule>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-form
            v-if="showArchived == false"
            ref="billingform"
            lazy-validation
          >
            <v-row style="padding: 20px 10px">
              <v-col cols="12" align="left" justify="left">
                <span v-if="errorsrules.length > 0">
                  <v-alert
                    v-for="error in errorsrules"
                    :key="error"
                    color="#db2727"
                    type="error"
                    dense
                    >{{ error }}</v-alert
                  >
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <v-container v-if="addingRule == true" style="padding-top: 20px">
              <formRule
                :selectedsitesprop="[]"
                :customer="customer"
                :actiontype="'add'"
                @ruleAdding="addRule"
                @cancel="addingRule = false"
                @RefreshSites="refreshSites"
                @refreshData="getDataFromApi"
              />
            </v-container>
            <v-form ref="form" lazy-validation>
              <v-row v-if="added == true" style="padding-top: 20px">
                <v-col>
                  <v-alert dense text type="success" color="green">
                    Rule added successfully</v-alert
                  >
                </v-col>
              </v-row>
              <v-card-actions
                v-if="addingRule == false"
                class="d-flex flex-row-reverse"
              >
                <v-btn
                  v-if="addingRule == false"
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  style="margin: 10px"
                  class="white--text notcapital"
                  color="black"
                  width="130"
                  dense
                  @click="
                    $router.push(
                      $route.path + '?settings=billing' + '&rule=new'
                    ),
                      (addingRule = true)
                  "
                >
                  <v-icon left> mdi-plus</v-icon>
                  {{ $t("add_rule") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-form>
        </v-tab-item>
        <v-tab @click="goTo('user')"> User </v-tab>
        <v-tab-item>
          <v-row style="padding: 20px">
            <v-col cols="2">
              <v-btn
                class="notcapital"
                color="secondary"
                style="padding: 10px"
                width="180px"
                small
                @click="newItem"
              >
                <v-icon left> mdi-plus </v-icon>
                {{ $t("add_user") }}
              </v-btn>
            </v-col>
            <v-col cols="6" />
            <v-col cols="4" style="text-align: right" justify-end>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :search="search"
            :headers="headersUsers"
            :items="computedUsers"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.first_name }}</td>
                <td v-if="$store.getters.is_superadmin">
                  <router-link
                    :to="{
                      name: 'customer_edit',
                      params: {
                        customer: item,
                        customer_id: item.customer_id
                      }
                    }, "
                  >
                    {{ item.customer_id }}
                  </router-link>
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.role.toLowerCase() }}</td>
                <td>{{ Status(item.okta_status) }}</td>
                <td>
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon color="black">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('deactivated')"
                        @click="ActionUser('activateuser', item.email)"
                        >{{ $t("activate_user") }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) != $t('deactivated')"
                        @click="ActionUser('deactivateuser', item.email)"
                        >{{ $t("deactivate_user") }}</v-list-item
                      >
                      <v-list-item
                        v-if="
                          Status(item.okta_status) != $t('deactivated') &&
                          Status(item.okta_status) != $t('suspended')
                        "
                        @click="ActionUser('suspenduser', item.email)"
                        >{{ $t("suspend_user") }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('pending')"
                        @click="ActionUser('reactivateuser', item.email)"
                        >{{ $t("reactivate_user") }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('suspended')"
                        @click="ActionUser('unsuspenduser', item.email)"
                        >{{ $t("unsuspend_user") }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) != $t('deleted')"
                        @click="ActionUser('resetpassword', item.email)"
                        >{{ $t("reset_password") }}</v-list-item
                      >
                      <v-list-item @click="editItem(item)">
                        {{ $t("edit_properties") }}</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
const countries = require("@/assets/styles/countries.js");
import axios from "axios";
import countrySelect from "@/pages/login/components/countrySelect.vue";
import { SetSelectedSite } from "@/store/mutation_types";
import update_rule from "./update_rule.vue";
import archive_rule from "./archive_rule.vue";
import archived_rules from "./archived_rules.vue";
import ruleSitesGroup from "./ruleSitesGroup.vue";
import form_rule from "./form_rule.vue";
import {
  GetSelectedCustomer,
  SaveSelectedCustomer,
  GetSites,
  SaveSelectedSite,
  GetUsers,
} from "@/store/action_types";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CustomerEdit",
  components: {
    countrySelect,
    updateRule: update_rule,
    archiveRule: archive_rule,
    archivedRules: archived_rules,
    formRule: form_rule,
    sitesgroup: ruleSitesGroup,
  },
  data() {
    return {
      customer: {
        name: "",
        code: "",
      },
      selectAll: false,
      countryList: countries,
      updateComplete: false,
      rule: {},
      rules: [],
      archivedRules: [],
      sspsTable: [],
      headers: [
        //{text: 'Id', align: 'left', value : 'id'},
        { text: "Product", align: "left", value: "product" },
        { text: "Site", align: "left", value: "site" },
        { text: "CPM", align: "left", value: "cpm_value" },
        { text: "From", align: "left", value: "start_date" },
        { text: "To", align: "left", value: "end_date" },
        { text: "Actions", align: "center", value: "actions" },
      ],
      headersUsers: [
        { text: "Id", align: "left", value: "id" },
        { text: this.$t("last_name"), align: "left", value: "last_name" },
        { text: this.$t("first_name"), align: "left", value: "first_name" },
        { text: this.$t("customer_id"), align: "left", value: "customer_id" },
        { text: this.$t("email"), align: "left", value: "email" },
        { text: this.$t("role"), align: "left", value: "role" },
        { text: this.$t("status"), align: "left", value: "status" },
        { text: this.$t("action"), align: "left", value: "action" },
      ],

      search: "",
      site: {},
      selectedItem: [],
      required: [(v) => !!v || "Required"],
      nameRules: [(v) => !!v || this.$t("name is required")],
      submitLoading: false,
      confirmLoading: false,
      loadingrules: false,
      loading: false,
      errors: [],
      errorsrules: [],
      added: false,
      updated: false,
      selectedIndex: 0,
      addingRule: false,
      productSelected: false,
      showArchived: false,
      headersSSP: [
        { text: this.$t("activation"), align: "left", value: "activation" },
        { text: this.$t("ssp"), align: "left", value: "SSP" },
        {
          text: this.$t("bid_reduction"),
          align: "left",
          value: "bid_reduction",
        },
        {
          text: this.$t("geotargeting"),
          align: "left",
          value: "geotargeting",
        },
        {
          text: this.$t("type"),
          align: "left",
          value: "type",
        },
      ],
      searchSSP: "",
      loadingSSP: false,
    };
  },
  computed: {
    ...mapGetters([
      "customers",
      "users",
      "sites",
      "SspList",
      "selectedSite",
      "appCustomer",
      "selectedCustomer",
    ]),
    sspstableList() {
      if (
        this.sspsTable.length < this.SspList.length &&
        Object.keys(this.selectedCustomer).length != 0
      ) {
        this.SspList.forEach((ssp) => {
          console.log(ssp);
          this.sspsTable.push({
            name: ssp,
            exclude:
              this.selectedCustomer[ssp + "_country_blacklist"].length == 0
                ? false
                : true,
            country_list:
              this.selectedCustomer[ssp + "_country_blacklist"].length == 0
                ? this.getCountrylist(ssp, "whitelist")
                : this.getCountrylist(ssp, "blacklist"),
          });
        });
      }
      return this.sspsTable;
    },
    formattedSspList() {
      const output = {};
      this.SspList.forEach((ssp) => {
        output[ssp] = {};
        Object.keys(this.selectedCustomer).forEach((key) => {
          if (key.substr(0, this.getPosition(key, "_", 1)) == ssp) {
            output[ssp][key] = this.selectedCustomer[key];
          }
        });
      });
      return output;
    },
    computedSites() {
      return this.sites
        .filter((el) => {
          return el.is_archived == 0;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    computedUsers() {
      return this.users.filter((el) => {
        return el.customer_id == this.selectedCustomer.id;
      });
    },
  },
  watch: {
    selectedSite() {
      this.site = this.selectedSite;
    },
    async appCustomer() {
      await this.GetSites(this.appCustomer.id);
      await this.GetUsers();
      if (this.computedSites[0] != undefined) {
        this.SetSelectedSite(this.computedSites[0]);
        this.site = this.selectedSite;
      }
      this.customer = this.appCustomer;
      if (this.$route.params.customer_id != this.appCustomer.id) {
        this.$router.push({
          name: "customer_edit",
          params: {
            customer_id: this.appCustomer.id,
            customer: this.customer,
          },
        });
      }
      this.getDataFromApi();
    },
  },
  async created() {
    switch (this.$route.query.settings) {
      case "setup":
        this.selectedIndex = 1;
        break;
      case "ssp":
        this.selectedIndex = 2;
        break;
      case "billing":
        this.selectedIndex = 3;
        break;
      case "user":
        this.selectedIndex = 4;
        break;
      default:
        this.selectedIndex = 0;
    }
    if (this.$route.query.rule) {
      if (this.$route.query.rule == "new") this.addingRule = true;
    }

    if (this.$route.name != "customer_new") {
      await this.GetSelectedCustomer(this.$route.params.customer_id);
      await this.GetSites(this.appCustomer.id);
      await this.GetUsers();
      this.customer = this.selectedCustomer;
      this.getDataFromApi();
    }
  },
  methods: {
    ...mapMutations([SetSelectedSite]),
    ...mapActions([
      GetSelectedCustomer,
      SaveSelectedCustomer,
      GetSites,
      SaveSelectedSite,
      GetUsers,
    ]),
    getCountrylist(ssp, type) {
      var str = this.selectedCustomer[ssp + "_country_" + type];
      if (str != "") {
        let substr = str.substring(2, str.length - 2);
        var arr = substr.split('","');
        return arr;
      }
      return [];
    },
    getNameCountry(code) {
      var index = this.countryList.findIndex((obj) => obj.code == code);
      return this.countryList[index].name;
    },
    async submit() {
      if (this.$refs.form != undefined) {
        if (!this.$refs.form.validate()) {
          console.log("No fullfilled");
          return;
        }
      }

      this.submitLoading = true;
      this.manageCountriesList();
      this.saveCustomer();
    },
    async saveCustomer() {
      this.customer.code = this.customer.name
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();
      if (this.$route.name != "customer_new") await this.saveLogsCpm();
      let ret = await this.SaveSelectedCustomer(this.customer).catch(() => {
        this.errors = this.getErrors;
        this.submitLoading = false;
        return false;
      });
      this.updated = true;
      setTimeout(() => (this.updated = true), 500);
      setTimeout(() => (this.updated = false), 1500);
      setTimeout(() => (this.submitLoading = false), 1500);
      if (!ret) {
        return;
      }
      if (this.$route.name == "customer_new") {
        setTimeout(() => this.$router.push("/customers"), 1000);
      }

      // await this.GetSelectedCustomer(ret)
    },
    async saveLogsCpm() {
      const customerURL = await axios.get(
        `/api/getcustomerbyid/${this.$route.params.customer_id}`
      );
      var customerdb = customerURL.data;
      for (const ssp of this.SspList) {
        if (
          customerdb[ssp + "_cpm_adjustment"] !=
          this.customer[ssp + "_cpm_adjustment"]
        ) {
          var cpmlogs = {
            customer_id: customerdb.id,
            ssp: ssp,
            cpm_value: this.customer[ssp + "_cpm_adjustment"],
          };
          await axios.post(`/api/customers/cpmlogs`, cpmlogs);
        }
      }
    },
    manageCountriesList() {
      this.sspstableList.forEach((element) => {
        if (element["country_list"].length > 0) {
          if (element["exclude"] == false) {
            this.fillCustomerWithCountries(element, "whitelist");
            this.emptyCountriesInCustomer(element, "blacklist");
          } else {
            this.fillCustomerWithCountries(element, "blacklist");
            this.emptyCountriesInCustomer(element, "whitelist");
          }
        } else {
          if (element["exclude"] == false) {
            this.emptyCountriesInCustomer(element, "whitelist");
          } else {
            this.emptyCountriesInCustomer(element, "blacklist");
          }
        }
      });
    },
    fillCustomerWithCountries(element, type) {
      this.customer[element.name + "_country_" + type] = "";
      this.customer[element.name + "_country_" + type] = "[";
      element["country_list"].forEach((x) => {
        if (
          element["country_list"].indexOf(x) !=
          element["country_list"].length - 1
        )
          this.customer[element.name + "_country_" + type] +=
            '"' + x + '"' + ",";
        else this.customer[element.name + "_country_" + type] += '"' + x + '"';
      });
      this.customer[element.name + "_country_" + type] += "]";
    },
    emptyCountriesInCustomer(element, type) {
      this.customer[element.name + "_country_" + type] = "";
    },
    async addRule(rule) {
      this.errorsrules = [];
      this.confirmLoading = true;
      try {
        const response = await axios.post(`/api/rules`, rule);
        const errormessage = response.data;
        if (errormessage != true) {
          this.errorsrules.push(errormessage);
        } else {
          this.added = true;
          setTimeout(() => (this.added = false), 2000);
          this.addingRule = false;
          this.$router.push(this.$route.path + "?settings=billing");
        }
        this.confirmLoading = false;
        this.getDataFromApi();
      } catch (e) {
        console.error(e);
        this.confirmLoading = false;
      }
    },
    remove(item, index) {
      item.splice(item.indexOf(item[index]), 1);
      item = [...item];
    },
    newItem() {
      this.$router.push(this.$route.path + "/users/new");
    },
    countryUpdate(country) {
      this.customer.country = country;
    },
    back() {
      this.$router.back();
    },
    getPosition(text, subString, index) {
      return text.split(subString, index).join(subString).length;
    },
    goTo(view) {
      if (this.$route.fullPath != this.$route.path + "?settings=" + view)
        this.$router.push(this.$route.path + "?settings=" + view);
    },
    updateSsp(elem) {
      this.customer[elem["key"]] = elem["value"];
    },
    async getDataFromApi() {
      this.loading = true;
      try {
        const firstresponse = await axios.get(
          `/api/getrulessitesgroups/${this.customer.id}`
        );
        this.rules = firstresponse.data;
        this.rules.forEach((rule) => {
          if (rule.product == "fast") rule.product = "Nexx Web";
          if (rule.product == "amp") rule.product = "Nexx AMP";
          if (rule.product == "display") rule.product = "Server";
          if (rule.product == "adapter") rule.product = "Adapter";
        });

        const secondresponse = await axios.get(
          `/api/getrulesarchivedsitesgroups/${this.customer.id}`
        );
        this.archivedRules = secondresponse.data;
        this.archivedRules.forEach((rule) => {
          if (rule.product == "fast") rule.product = "Nexx Web";
          if (rule.product == "amp") rule.product = "Nexx AMP";
          if (rule.product == "display") rule.product = "Server";
          if (rule.product == "adapter") rule.product = "Adapter";
        });
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    format_date(item) {
      var myDate = item.substr(0, 10).split("-");
      var datetoreturn = myDate[2] + "/" + myDate[1] + "/" + myDate[0];

      return datetoreturn;
    },
    coloringSelectedItem(item) {
      if (item.id == this.selectedItem.id) {
        return "selectedRow";
      } else return "";
    },
    async refreshSites() {
      await this.GetSites(this.customer.id);
    },
    async archiveRule(item) {
      var objrule = {};
      objrule.id = item.sites[0].rule_id;
      objrule.product = item.product;
      objrule.cpm_value = item.cpm_value;
      objrule.site_id = item.sites[0].id;
      objrule.customer_id = item.customer_id;
      objrule.start_date = item.sites[0].start_date;
      //await axios.post(`/api/archiverule`, objrule)

      if (item.sites[0].name != "ALL") {
        for (let j = 0; j < item.sites.length; j++) {
          try {
            objrule.site_id = item.sites[j].id;
            await axios.post(`/api/archiverule`, objrule);
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        try {
          await axios.post(`/api/archiverule`, objrule);
        } catch (e) {
          console.error(e);
        }
      }

      this.getDataFromApi();
    },
    Status(status) {
      switch (status) {
        case "ACTIVE":
          return this.$t("active");
        case "DEPROVISIONED":
          return this.$t("deactivated");
        case "SUSPENDED":
          return this.$t("suspended");
        case "PASSWORD_EXPIRED":
          return this.$t("pass_expired");
        case "PROVISIONED":
          return this.$t("pending");
        case "RECOVERY":
          return this.$t("recovery");
        case "PENDING":
          return this.$t("pending");
        default:
          return this.$t("deleted");
      }
    },
    async ActionUser(action, email) {
      await axios.post(`/api/${action}/${email}`);
      const rr = await axios.post(`/api/updatestatus`);
      await this.GetUsers();
    },
    editItem(user) {
      this.$router.push(this.$route.path + "/users/" + user.id);
    },
  },
};
</script>

<style scoped>
.avatar {
  padding-left: 20px;
  padding-bottom: 23px;
}
.no-shadow,
.no-shadow::before {
  box-shadow: none;
  background-color: transparent !important;
}
.combobox {
  padding-left: 0px;
  padding-top: 0px;
}
.selectedRow {
  background-color: red;
  font-weight: bold;
}
.selectedRows {
  background-color: blue;
  font-weight: bold;
}
</style>
