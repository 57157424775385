<template>
  <v-card>
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>

    <div v-for="partner in partners" :key="partner.code">
      <v-divider />
      <app-partner-config :partner="partner" :pagedevice="pagedevice" />
    </div>
    <v-row v-if="updated == true" style="padding-top: 20px">
      <v-col>
        <v-alert dense text type="success" color="green">
          parameters updated successfully</v-alert
        >
      </v-col>
    </v-row>
    <v-card-actions class="d-flex flex-row-reverse">
      <v-btn
        :loading="saveLoading"
        :disabled="saveLoading"
        style="margin: 10px"
        class="white--text notcapital"
        color="black"
        width="130"
        dense
        @click="save"
      >
        <v-icon left> mdi-check-circle</v-icon>
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import PartnerConfig from './PartnerConfig.vue'

  export default {
    components: {
      AppPartnerConfig: PartnerConfig
    },
    props: ['device', 'page'],
    data() {
      return {
        loading: false,
        saveLoading: false,
        pagedevice: {},
        action: '',
        updated: false,
        partners: [
          {
            name: 'Is Ads Wrapper active',
            code: 'adsWrapper',
            fields: []
          },
          {
            name: 'Dailymotion Injected Slot',
            code: 'dm',
            fields: [{ model: 'dmSelector', label: 'Selector' }]
          },
          {
            name: 'Dailymotion Display Video Competition Slot',
            code: 'displayVideoCompetition',
            fields: [
              { model: 'displayVideoCompetitionSlot', label: 'Selector' },
              { model: 'isVideoForced', label: 'IsVideoForced' }
            ]
          },
          {
            name: 'Dailymotion',
            fields: [
              { model: 'dmPosition', label: 'Position' },
              { model: 'dmStyle', label: 'Style' },
              { model: 'dmDefaultVideoId', label: 'Default Video Id' },
              { model: 'dmSiblingsIds', label: 'Siblings Ids' },
              { model: 'dmHeaderSelector', label: 'Header Selector' },
              { model: 'dmMessage', label: 'Message' },
              { model: 'dfpVideoAdUnit', label: 'Dfp Video AdUnit' }
            ]
          },
          {
            name: 'Appnexus',
            code: 'videoAppnexus',
            fields: [
              { model: 'videoAppnexusPlacementId', label: 'PlacementId' }
            ]
          },
          {
            name: 'Smart Ad Server',
            code: 'videoSmart',
            fields: [
              { model: 'videoSmartDomain', label: 'Domain' },
              { model: 'videoSmartSiteId', label: 'SiteId' },
              { model: 'videoSmartPageId', label: 'PageId' },
              { model: 'videoSmartFormatId', label: 'FormatId' }
            ]
          },
          {
            name: 'OpenX',
            code: 'videoOpenx',
            fields: [
              { model: 'videoOpenxDomain', label: 'Domain' },
              { model: 'videoOpenxAdUnitId', label: 'AdUnitId' }
            ]
          },
          {
            name: 'Gravity',
            code: 'videoGravity',
            fields: [{ model: 'videoGravityPlacementId', label: 'PlacementId' }]
          },
          {
            name: 'Freewheel',
            code: 'videoFreewheelSsp',
            fields: [{ model: 'videoFreewheelSspZoneId', label: 'ZoneId' }]
          },
          {
            name: 'Rubicon',
            code: 'videoRubicon',
            fields: [
              { model: 'videoRubiconAccountId', label: 'AccountId' },
              { model: 'videoRubiconSiteId', label: 'SiteId' },
              { model: 'videoRubiconZoneId', label: 'ZoneId' }
            ]
          },
          {
            name: 'Verizon',
            code: 'videoOneVideo',
            fields: []
          }
        ]
      }
    },
    watch: {
      device() {
        this.getDataFromApi()
      }
    },
    mounted() {
      this.getDataFromApi()
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      async getDataFromApi() {
        this.loading = true
        try {
          const response = await axios.get(
            `/api/getwpagedevices/${this.$route.params.page_id}/${this.device.id}`
          )
          if (response.data.length != 0) {
            this.pagedevice = response.data
            this.action = 'update'
          } else this.action = 'new'
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      async save() {
        this.saveLoading = true
        try {
          if (this.action == 'update') {
            await axios.put(
              `/api/wpagedevices/${this.pagedevice.id}`,
              this.pagedevice
            )
          } else {
            this.pagedevice.pageId = this.$route.params.page_id
            this.pagedevice.deviceId = this.device.id
            try {
              await axios.post(`/api/wpagedevices`, this.pagedevice)
            } catch (e) {
              console.error(e)
            }
          }
          this.updated = true
          this.saveLoading = false
          setTimeout(() => (this.updated = false), 2000)
        } catch (e) {
          this.saveLoading = false
        }

        /*this.data.pageName =
          this.data.pageName == undefined ? '' : this.data.pageName
        this.data.deviceName =
          this.data.deviceName == undefined ? '' : this.data.deviceName
        Object.keys(this.data).forEach((key) => {
          this.data[key] = this.data[key] == null ? '' : this.data[key]
        })*/
      }
    }
  }
</script>
