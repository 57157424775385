<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="#2E418A"
    dark
  >
    <v-spacer></v-spacer>
    <span class="hidden-sm-and-down">
      <v-img
        class="mx-2"
        src="@/assets/nexx360_logoB.png"
        max-height="40"
        contain
      />
    </span>
    <v-spacer></v-spacer>
    <div>
      <p v-if="register" class="registerLogintext">
        {{ $t('not_registered_yet') }}
        <router-link v-slot="{ navigate }" to="/register" custom>
          <span role="button" @click="navigate" @keypress.enter="navigate"
            ><strong>{{ $t('register') }} ></strong></span
          >
        </router-link>
      </p>
    </div>
    <div>
      <p v-if="login" class="registerLogintext">
        {{ $t('already_registered') }}
        <router-link v-slot="{ navigate }" to="/login" custom>
          <span role="button" @click="navigate" @keypress.enter="navigate"
            ><strong>{{ $t('login') }} ></strong></span
          >
        </router-link>
      </p>
    </div>
  </v-app-bar>
</template>

<script>
  export default {
    data: function () {
      return {
        name: '',
        login: true,
        register: false
      }
    },
    created() {
      if (this.$route.name != 'login') {
        this.login = true
        this.register = false
      } else {
        this.login = false
        this.register = true
      }
    }
  }
</script>

<style>
  .registerLogintext {
    font-size: 0.8em;
    margin-top: 10px;
    margin-right: 20px;
    color: white;
    display: none;
  }
</style>
