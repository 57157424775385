<template>
  <v-card>
    <v-container style="width: 1200px; margin-top: 10px" fluid>
      <app-overlay :loading="loading" />
      <v-row>
        <v-col cols="12" md="12">
          <app-chart :data="timeSeries" :total="total" :time-unit="timeUnit" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import Chart from './chart.vue'
  import Overlay from '@/components/common/overlay.vue'
  export default {
    components: {
      AppChart: Chart,
      AppOverlay: Overlay
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['reportingData', 'loading', 'timeUnit'],
    data() {
      return {}
    },
    computed: {
      timeSeries() {
        const series = []
        this.reportingData.map((item) => {
          const unixDate = item.day
          if (series.find((x) => x.name == item.auction_type) == undefined) {
            series.push({ name: item.auction_type, data: [] })
          }
          const foo = series.find((x) => x.name == item.auction_type)
          foo.data.push([parseInt(unixDate) * 1000, parseInt(item.auctions)])
        })
        if (series.length == 0) this.timeUnit == 'hourly'
        series.forEach((element) => {
          const params = {
            amp: { label: 'Nexx AMP', color: '#2E418A' },
            display: { label: 'Server', color: '#00ead3' },
            fast: { label: 'Nexx Web', color: '#fc8f00' }
          }
          element.color = params[element.name].color
          element.name = params[element.name].label
        })
        return series
      },
      total() {
        return this.reportingData.reduce((output, item) => {
          return output + parseInt(item.auctions)
        }, 0)
      }
    }
  }
</script>
