<template>
  <v-autocomplete
    :value="group"
    clearable
    :label="$t('role')"
    single-line
    :items="roles"
    @input="$emit('update-groups', $event)"
  >
  </v-autocomplete>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'GroupSelector',
    props: {
      group: {
        default: '',
        type: String
      }
    },
    data: () => ({
      roles: {}
    }),
    computed: {
      ...mapState(['appGroups'])
    },
    created() {
      this.roles = this.appGroups
      if (this.$store.getters.appUser.role == 'admin') this.roles.length = 2
    },
    beforeMount() {},
    methods: {}
  }
</script>
