<template>
  <v-app id="inspire" style="background-color: #f9f9fb">
    <app-topbar></app-topbar>
    <appSidebarr></appSidebarr>
    <v-main style="padding: 50px 10px 0px 256px">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import topbar from '@/components/layout/topbar.vue'
  import sidebar from '@/components/layout/sidebar.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { GetSites } from '@/store/action_types'

  export default {
    components: {
      appTopbar: topbar,
      appSidebarr: sidebar
    },
    data() {
      return {
        type: ''
      }
    },
    computed: {
      ...mapGetters(['sites', 'appCustomer', 'appUser'])
    },
    watch: {
      $route(to, from) {
        // Update the data type when the route changes.
        this.type = to.name
      }
    },
    mounted() {
      this.type = this.$route.name
    },
    async created() {
      await this.GetSites(this.appCustomer.id)
      if (this.appUser.role == 'superadmin' && this.$route.name == 'Home') {
        //this.$router.push('/customer-reporting')
        this.$router.push({ name: 'dashboard' })
      } else {
        if (this.$route.name == 'Home') {
          if (this.sites.length == 0) this.$router.push('/home')
          else if (this.sites.length != 0) this.$router.push('/dashboard')
        }
      }
    },
    methods: {
      ...mapActions({ GetSites })
    }
  }
</script>
