import { GetStoredRequests } from '@/store/action_types'

import { SetStoredRequests, ClearStoredRequests } from '@/store/mutation_types'

import axios from 'axios'

export const storedRequestsState = {
  storedRequests: []
}

export const storedRequestsGetters = {
  storedRequests: (state) => state.storedRequests
}

export const storedRequestsActions = {
  async [GetStoredRequests]({ commit }, site_id) {
    commit(ClearStoredRequests)

    const response = await axios.get(`/api/sites/sr/${site_id}`)

    commit(SetStoredRequests, response.data)
    return response
  }
}

export const storedRequestsMutations = {
  [SetStoredRequests](state, storedRequests) {
    state.storedRequests = storedRequests
  },
  [ClearStoredRequests](state) {
    state.storedRequests = []
  }
}
