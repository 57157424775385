export const GetStoredRequests = 'GetStoredRequests'

export const GetInjections = 'GetInjections'

export const GetStoredRequestTag = 'GetStoredRequestTag'

export const GetCustomers = 'GetCustomers'
export const GetSelectedCustomer = 'GetSelectedCustomer'
export const SaveSelectedCustomer = 'SaveSelectedCustomer'
export const SaveSelectedCustomerFromSignUp = 'SaveSelectedCustomerFromSignUp'
export const UpdateSelectedCustomer = 'UpdateSelectedCustomer'

export const GetProposals = 'GetProposals'
export const GetSelectedProposal = 'GetSelectedProposal'
export const SaveSelectedProposal = 'SaveSelectedProposal'
export const UpdateSelectedProposal = 'UpdateSelectedProposal'

export const GetDeals = 'GetDeals'
export const GetSelectedDeal = 'GetSelectedDeal'
export const SaveSelectedDeal = 'SaveSelectedDeal'
export const UpdateSelectedDeal = 'UpdateSelectedDeal'

export const GetSites = 'GetSites'
export const GenerateTag = 'GenerateTag'
export const GenerateTagWeb = 'GenerateTagWeb'

export const UpdateSites = 'UpdateSites'

export const GetSelectedSite = 'GetSelectedSite'
export const SaveSelectedSite = 'SaveSelectedSite'
export const UpdateSelectedSite = 'UpdateSelectedSite'

export const GetSelectedStoredRequest = 'GetSelectedStoredRequest'
export const SaveSelectedStoredRequest = 'SaveSelectedStoredRequest'
export const UpdateSelectedStoredRequest = 'UpdateSelectedStoredRequest'

export const GetSelectedInjection = 'GetSelectedInjection'
export const SaveSelectedInjection = 'SaveSelectedInjection'
export const UpdateSelectedInjection = 'UpdateSelectedInjection'

export const GetUsers = 'GetUsers'
export const UserAlreadyExists = 'UserAlreadyExists'
export const AccountNameAlreadyExists = 'AccountNameAlreadyExists'
export const AccountNameAlreadyExistsUpdate = 'AccountNameAlreadyExistsUpdate'

export const SaveSelectedUser = 'SaveSelectedUser'
export const SaveSelectedUserFromSignUp = 'SaveSelectedUserFromSignUp'
export const UpdateSelectedUser = 'UpdateSelectedUser'
export const GetSelectedUser = 'GetSelectedUser'
export const InviteSelectedUser = 'InviteSelectedUser'

export const GetGamSetups = 'GetGamSetups'
export const GetSelectedGamSetup = 'GetSelectedGamSetup'
export const SaveSelectedGamSetup = 'SaveSelectedGamSetup'
export const UpdateSelectedGamSetup = 'UpdateSelectedGamSetup'
export const DeleteSelectedGamSetup = 'DeleteSelectedGamSetup'

export const GetOptionsCookieless = 'GetOptionsCookieless'
export const GetSelectedOptionsCookieless = 'GetSelectedOptionsCookieless'
export const SaveSelectedOptionsCookieless = 'SaveSelectedOptionsCookieless'
export const UpdateSelectedOptionsCookieless = 'UpdateSelectedOptionsCookieless'

export const GetProfile = 'GetProfile'

export const GetAppGroups = 'GetAppGroups'

export const UpdateLoadCookieFiles = 'UpdateLoadCookieFiles'

export const Logout = 'Logout'
