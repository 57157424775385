module.exports = [
  {
    id: 'Android',
    label: 'Android'
  },
  {
    id: 'Chromium OS',
    label: 'Chromium OS'
  },
  {
    id: 'FreeBSD',
    label: 'FreeBSD'
  },
  {
    id: 'iOS',
    label: 'iOS'
  },
  {
    id: 'Linux',
    label: 'Linux'
  },
  {
    id: 'Mac OS',
    label: 'Mac OS'
  },
  {
    id: 'Nintendo',
    label: 'Nintendo'
  },
  {
    id: 'PlayStation',
    label: 'PlayStation'
  },
  {
    id: 'Tizen',
    label: 'Tizen'
  },
  {
    id: 'Windows',
    label: 'Windows'
  },
  {
    id: 'Other',
    label: 'Other'
  }
]