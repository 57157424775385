<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="
      dialog = false
      placement.elementName = ''
    "
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        style="margin: 10px"
        class="white--text notcapital"
        color="black"
        width="200"
        dense
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left> mdi-plus </v-icon>
        Add a placement
      </v-btn>
    </template>

    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon
            color="black"
            @click=";(dialog = false), (placement.elementName = '')"
            >mdi-close</v-icon
          >
        </v-col>
        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <br />
            <v-row>
              <v-col cols="12">
                <h2 style="color: black">Add a placement</h2>
              </v-col>
              <br />
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-form ref="form">
                  <p style="margin-bottom: 5px">Placement name</p>
                  <v-text-field
                    v-model="placement.elementName"
                    dense
                    solo
                    required
                  >
                  </v-text-field
                ></v-form>
              </v-col>
            </v-row>
            <v-row v-if="placementAdded">
              <v-col>
                <v-alert dense text type="success" color="green">
                  Page added successfully</v-alert
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-col cols="12" align="center">
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              width="320"
              style="height: 40px"
              class="white--text"
              color="black"
              @click="createPlacement"
            >
              Save
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['page', 'device'],
    data() {
      return {
        dialog: false,
        loading: false,
        submitLoading: false,
        placementAdded: false,
        placement: {
          elementName: ''
        },
        elements: [],
        selectedElements: []
      }
    },
    methods: {
      async getDataFromApi() {
        this.selectedElements = []
        this.loading = true
        try {
          const response = await axios.get(`/api/welements`)
          this.elements = response.data
          const responseplacement = await axios.get(
            `/api/getwplacementstable/${this.$route.params.page_id}/${this.device.id}`
          )
          let wplacements = responseplacement.data

          for (let i = 0; i < this.elements.length; i++) {
            wplacements.forEach((wplacement) => {
              if (this.elements[i].name == wplacement.Name)
                if (wplacement.is_active == 1) this.selectedElements.push(i)
            })
          }
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      async createPlacement() {
        this.submitLoading = true
        this.placement.pageId = this.page.id
        this.placement.deviceId = this.device.id
        this.placement.typeId = 0
        await axios.post(`/api/wplacements`, this.placement)
        this.placementAdded = true
        setTimeout(() => (this.placementAdded = false), 2000)
        this.$emit('placementAdded')
        this.submitLoading = false
        setTimeout(() => (this.dialog = false), 500)
        this.placement.elementName = ''
      }
    }
  }
</script>
