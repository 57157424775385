<template>
  <div id="PageContent">
    <v-container fluid>
      <v-card id="PageList">
        <v-card-title primary-title>
          <v-col cols="12" v-if="$route.params.site_id != '0'">
            List of injections of {{ selectedSite.name }}</v-col
          >
          <v-col cols="12" v-else>
            Please select a site first to manage injections</v-col
          >
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row v-if="$route.params.site_id != '0'">
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 10px"
                class="white--text notcapital"
                color="black"
                width="250"
                dense
                @click="newInjection()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add an injection
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="$route.params.site_id != '0'">
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-row v-if="$route.params.type == 'amp'">
                  <v-col
                    cols="12"
                    style="padding-top: 0px; padding-bottom: 50px"
                  >
                    <v-alert
                      colored-border
                      type="info"
                      elevation="1"
                      style="margin: 0px; font-size: 15px"
                    >
                      {{ $t('placements_configuration_first_line') }} <br />
                      {{ $t('placements_configuration_second_line') }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-card-title
                  v-if="$route.params.site_id != '0'"
                  style="padding: 10px"
                >
                  <v-row>
                    <v-col cols="12" style="padding: 0px 0px 10px 0px">
                      <v-row>
                        <v-icon style="padding: 0px 10px 0px 10px" size="28"
                          >mdi-filter-outline</v-icon
                        >
                        <v-col col="2" style="padding: 15px 20px 0px 10px">
                          <v-select
                            v-model="namesfilter"
                            label="Filter by name"
                            flat
                            dense
                            hide-details
                            small
                            multiple
                            clearable
                            :items="
                              computedInjections
                                .map((d) => d['name'])
                                .sort((a, b) => a.localeCompare(b))
                            "
                          >
                          </v-select>
                        </v-col>
                        <v-col col="2" style="padding: 15px 0px 20px 0px">
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4" style="padding: 15px 10px 0px 0px">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            dense
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
                <br /><br />
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="filteredInjections"
                  :sort-by="['name']"
                  :items-per-page="10"
                  item-key="id"
                  class="elevation-1"
                  :loading="loading"
                  hide-default-footer
                  :disable-pagination="true"
                  style="background-color: #f9f9fb"
                >
                  <template v-slot:item="props">
                    <tr
                      :active="props.selected"
                      @click="props.selected = !props.selected"
                    >
                      <td style="width: 5%; font-size: 14px">
                        {{ props.item.id }}
                      </td>
                      <td
                        style="width: 20%; font-size: 14px; cursor: pointer"
                        @click="editItem(props.item)"
                      >
                        <strong>{{ props.item.name }}</strong>
                      </td>
                      <td style="width: 65%; font-size: 14px; cursor: pointer">
                        <strong>{{ props.item.type }}</strong>
                      </td>
                      <td
                        style="width: 10%; padding: 0px"
                        align="center"
                        justify="center"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(props.item)"
                            >
                              <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit the injection</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="5">
              <v-row>
                <siteMenu />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import {
    GetInjections,
    SaveSelectedInjection,
    UpdateSelectedInjection
  } from '@/store/action_types'

  import {
    SetSelectedSite,
    SetSelectedInjection,
    ClearSelectedInjection
  } from '@/store/mutation_types'
  import axios from 'axios'
  import siteMenu from '@/pages/management/site/menuSite.vue'

  export default {
    components: { siteMenu },

    data() {
      return {
        headers: [
          {
            text: this.$t('Id'),
            align: 'left',
            value: 'id'
          },
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('type'),
            align: 'left',
            value: '',
            sortable: false
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: '',
            sortable: false
          }
        ],
        pages: [],
        loading: false,
        search: '',
        generateButtonLoading: false,
        namesfilter: [],
        sspsfilter: [],
        rtcConfigDialog: false,
        messageButtonTag: '',
        codeGenerated: '',
        bulkUploadDialog: false,
        menu: false,
        confirmActionDialog: false,
        confirmationAction: '',
        item: {},
        actionsuccess: false,
        timeout: 3000,
        messageAction: ''
      }
    },
    watch: {
      async appCustomer() {
        if (this.$route.params.site_id != this.appCustomer.id) {
          this.$router.push({
            name: 'new_sites',
            params: {
              type: this.$route.params.type,
              customer_id: this.appCustomer.id
            }
          })
        }
      }
    },
    computed: {
      ...mapGetters(['selectedSite', 'injections']),
      computedInjections() {
        return this.injections
      },
      filteredInjections() {
        return this.computedInjections.filter((d) => {
          return (
            this.namesfilter.includes(d['name']) || this.namesfilter.length < 1
          )
        })
      }
    },
    async created() {
      if (this.$route.params.site_id != '0') {
        await this.GetInjections(this.$route.params.site_id)
        this.ClearSelectedInjection()
        if (this.$route.fullPath != this.$route.path)
          this.$router.push(this.$route.path)
        this.loading = true
        try {
          const siteURL = await axios.get(
            `/api/getsitebyid/${this.$route.params.site_id}`
          )
          this.SetSelectedSite(siteURL.data)
          // this.GetStoredRequests(this.selectedSite.id)
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      }
    },
    methods: {
      ...mapActions({
        GetInjections,
        SaveSelectedInjection,
        UpdateSelectedInjection
      }),
      ...mapMutations([
        SetSelectedSite,
        SetSelectedInjection,
        ClearSelectedInjection
      ]),

      newInjection() {
        if (this.selectedSite.id !== undefined) {
          this.$router.push({
            name: 'injection_new',
            params: {
              site_id: this.selectedSite.id,
              type: this.$route.params.type,
              injection_id: 'new'
            }
          })
        }
      },
      editItem(injection) {
        this.SetSelectedInjection(injection)
        this.$router.push({
          name: 'injection_edit',
          params: {
            site_id: this.$route.params.site_id,
            type: this.$route.params.type,
            injection_id: injection.id
          }
        })
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
</style>
