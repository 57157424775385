<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="1000"
    @click:outside="
      $router.push($route.path + '?settings=billing'), (dialog = false)
    "
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-2"
        text
        icon
        color="blue lighten-2"
        v-bind="attrs"
        @click="
          $router.push($route.path + '?settings=billing&rule=update'),
            selectItem(item)
        "
        v-on="on"
      >
        <v-icon size="20">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon
            color="black"
            @click="
              $router.push($route.path + '?settings=billing'), (dialog = false)
            "
            >mdi-close</v-icon
          >
        </v-col>
        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <br />
            <v-row>
              <v-col cols="12">
                <h2 style="color: black">Updating Rule</h2>
              </v-col>
              <br />
            </v-row>
            <v-row>
              <v-col cols="12" style="margin-left: 10px; padding-top: 20px">
                <v-form ref="editform" lazy-validation>
                  <formRule
                    :selectedsitesprop="selectedsitesprop"
                    :item="item"
                    :customer="customer"
                    :actiontype="'update'"
                    :dialog="dialog"
                    @ruleUpdating="verifyChangesAndEmit"
                  />
                </v-form>
              </v-col>
            </v-row>
            <v-row style="padding: 20px 10px">
              <v-col
                cols="11"
                align="left"
                justify="left"
                style="margin-left: 15px"
              >
                <span v-if="errors.length > 0" width="700">
                  <v-alert
                    v-for="error in errors"
                    :key="error"
                    color="#db2727"
                    type="error"
                    dense
                    >{{ error }}</v-alert
                  >
                </span>
              </v-col>
            </v-row>
            <v-row v-if="added == true" style="padding-top: 20px">
              <v-col>
                <v-alert dense text type="success" color="green">
                  Rule updated successfully</v-alert
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import axios from 'axios'
  import form_rule from './form_rule.vue'
  import { error } from 'highcharts'

  export default {
    components: {
      formRule: form_rule
    },
    props: ['item', 'customer', 'rule', 'sites', 'selectedsitesprop'],
    data() {
      return {
        dialog: false,
        submitLoading: false,
        errorstype: '',
        errors: [],

        added: false,
        page: {
          name: '',
          siteId: this.$route.params.site_id
        }
      }
    },
    watch: {
      selectedsitesprop() {}
    },
    created() {},
    methods: {
      selectItem(item) {
        this.rule.id = item.id
      },
      async verifyChangesAndEmit(rule, selectedSites) {
        if (!this.$refs.editform.validate()) {
          console.log('No fullfilled')
          return
        }

        await this.archivefirst()
        this.addthen(rule, selectedSites)

        /*  if (selectedSites.length == 0) {
            await this.archiveOldAddNewAll(rule, selectedSitesInit)
          } else if (selectedSitesInit[0] == undefined) {
            await this.archiveOldAllAddNew(rule, selectedSites)
          } else {
            await this.executeItemsToUpdate(
              rule,
              selectedSites,
              selectedSitesInit
            )
            await this.executeItemsToArchive(selectedSites, selectedSitesInit)
            await this.executeItemsToAdd(rule, selectedSites, selectedSitesInit)
          }*/
        /*  await this.executeItemsToUpdate(rule, selectedSites, selectedSitesInit)
          await this.executeItemsToArchive(selectedSites, selectedSitesInit)
          await this.executeItemsToAdd(rule, selectedSites, selectedSitesInit)
          this.submitLoading = true
  */
        if (this.errors.length == 0) {
          // setTimeout(() => (this.dialog = false), 1000)
          // this.$router.push(this.$route.path + '?settings=billing')
        }
      },
      archivefirst() {
        for (let i = 0; i < this.item.sites.length; i++) {
          var ruletodelete = {}
          ruletodelete.product = this.item.product
          ruletodelete.cpm_value = this.item.cpm_value
          ruletodelete.customer_id = this.item.customer_id
          ruletodelete.start_date = this.item.sites[i].start_date
          ruletodelete.id = this.item.sites[i].rule_id
          if (this.item.sites[0].name != 'ALL') {
            ruletodelete.site_id = this.item.sites[i].id
          }
          this.archiveItem(ruletodelete)
        }
      },
      addthen(rule, selectedSites) {
        for (let i = 0; i < selectedSites.length; i++) {
          var ruletoadd = {}
          ruletoadd.product = rule.product
          ruletoadd.cpm_value = rule.cpm_value
          ruletoadd.customer_id = rule.customer_id
          ruletoadd.site_id = selectedSites[i]
          this.addItem(ruletoadd)
        }
      },
      executeItemsToAdd(rule, selectedSites, selectedSitesInit) {
        for (let i = 0; i < selectedSites.length; i++) {
          const index = selectedSitesInit.indexOf(selectedSites[i])
          rule.site_id = selectedSites[i]
          if (index == -1) {
            var ruletoadd = {}
            ruletoadd.product = rule.product
            ruletoadd.cpm_value = rule.cpm_value
            ruletoadd.customer_id = rule.customer_id
            ruletoadd.site_id = selectedSites[i]
            this.addItem(ruletoadd)
          }
        }
      },
      executeItemsToArchive(selectedSites, selectedSitesInit) {
        for (let i = 0; i < selectedSitesInit.length; i++) {
          const index = selectedSites.indexOf(selectedSitesInit[i])
          if (index == -1) {
            var ruletoarchieve = {}
            ruletoarchieve.start_date = this.item.sites[i].start_date
            ruletoarchieve.site_id = selectedSitesInit[i]
            ruletoarchieve.product = this.item.product
            ruletoarchieve.cpm_value = this.item.cpm_value
            ruletoarchieve.customer_id = this.item.customer_id
            this.archiveItem(ruletoarchieve)
          }
        }
      },
      executeItemsToUpdate(rule, selectedSites, selectedSitesInit) {
        for (let i = 0; i < selectedSites.length; i++) {
          const index = selectedSitesInit.indexOf(selectedSites[i])
          rule.site_id = selectedSites[i]
          if (index != -1) {
            if (
              rule.product != this.item.product ||
              rule.cpm_value != this.item.cpm_value
            ) {
              var ruletoupdate = {}
              ruletoupdate.product = rule.product
              ruletoupdate.cpm_value = rule.cpm_value
              ruletoupdate.customer_id = rule.customer_id
              ruletoupdate.site_id = selectedSites[i]
              ruletoupdate.id = parseInt(this.item.sites[i].rule_id)
              ruletoupdate.start_date = this.getTimeNow()
              this.updateItem(ruletoupdate)
            }
          }
        }
      },
      async archiveOldAddNewAll(rule, selectedSitesInit) {
        this.archiveOldItems(selectedSitesInit)
        await this.addNewAll(rule)
      },
      async archiveOldAllAddNew(rule, selectedSites) {
        await this.archiveOldAll()
        this.addNewItems(rule, selectedSites)
      },
      async archiveItem(rule) {
        try {
          const response = await axios.post(`/api/archiverule`, rule)
        } catch (e) {
          console.error(e)
        }
        this.$emit('refreshData')
      },
      async updateItem(rule) {
        try {
          const response = await axios.put(`/api/rules/${rule.id}`, rule)
        } catch (e) {
          console.error(e)
        }
        this.$emit('refreshData')
      },
      async addItem(rule) {
        try {
          const response = await axios.post(`/api/rules`, rule)
          /* const errormessage = response.data
          this.errors.push(errormessage)*/
        } catch (e) {
          console.error(e)
        }
        this.$emit('refreshData')
      },
      archiveOldItems(selectedSitesInit) {
        for (let i = 0; i < selectedSitesInit.length; i++) {
          var ruletoarchieve = {}
          ruletoarchieve.start_date = this.item.sites[i].start_date
          ruletoarchieve.site_id = selectedSitesInit[i]
          ruletoarchieve.product = this.item.product
          ruletoarchieve.cpm_value = this.item.cpm_value
          ruletoarchieve.customer_id = this.item.customer_id
          this.archiveItem(ruletoarchieve)
        }
      },
      addNewAll(rule) {
        var ruletoadd = {}
        ruletoadd.product = rule.product
        ruletoadd.cpm_value = rule.cpm_value
        ruletoadd.customer_id = rule.customer_id
        this.addItem(ruletoadd)
      },
      archiveOldAll() {
        var ruletoarchieve = {}
        ruletoarchieve.start_date = this.item.sites[0].start_date
        ruletoarchieve.product = this.item.product
        ruletoarchieve.cpm_value = this.item.cpm_value
        ruletoarchieve.customer_id = this.item.customer_id
        this.archiveItem(ruletoarchieve)
      },
      addNewItems(rule, selectedSites) {
        selectedSites.splice(0, 1)
        for (let i = 0; i < selectedSites.length; i++) {
          var ruletoadd = {}
          ruletoadd.product = rule.product
          ruletoadd.site_id = selectedSites[i]
          ruletoadd.cpm_value = rule.cpm_value
          ruletoadd.customer_id = rule.customer_id

          this.addItem(ruletoadd)
        }
      },

      getTimeNow() {
        var today = new Date()
        var date =
          today.getFullYear() +
          '-' +
          (today.getMonth() + 1) +
          '-' +
          today.getDate() +
          ' ' +
          today.getHours() +
          ':' +
          today.getMinutes() +
          ':' +
          today.getSeconds()
        return date
      }
    }
  }
</script>
