import {
  SaveSelectedSite,
  UpdateSelectedSite,
  GetSelectedSite
} from '@/store/action_types'

import {
  SetSelectedSite,
  ClearSelectedSite,
  PushSites,
  ReformSites
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedSiteState = {
  selectedSite: {}
}

export const selectedSiteGetters = {
  selectedSite: (state) => state.selectedSite
}

export const selectedSiteActions = {
  async [GetSelectedSite]({ commit }, siteId) {
    let url = `/api/sites/${siteId}`
    const response = await axios.get(url)
    commit(SetSelectedSite, response.data)
    return response
  },
  async [UpdateSelectedSite]({ commit }, site) {
    const response = await axios.put(`/api/sites/${site.id}`, site)
    commit(SetSelectedSite, response.data)
    commit(ReformSites, response.data)
    return response
  },
  /*
  async [UpdateSite]({ commit }, site) {
    const response = await axios.put(`/api/sites/${site.id}`, site)
    commit(SitesEdit, response.data)
    return response
  },
  */
  async [SaveSelectedSite]({ dispatch, commit }, site) {
    if (site.id !== undefined) {
      return dispatch(UpdateSelectedSite, site)
    }
    const response = await axios.post('/api/sites', site)
    commit(SetSelectedSite, response.data)
    if (response.data.id) {
      return commit(PushSites, response.data)
    }
    return response
  }
}

export const selectedSiteMutations = {
  [SetSelectedSite](state, site) {
    state.selectedSite = site
  },
  [ClearSelectedSite](state) {
    state.selectedSite = {}
  }
}
