<template>
  <v-card>
    <v-container fluid style="width: 1200px; margin-top: 10px">
      <app-overlay :loading="loading" />
      <v-row>
        <v-col v-for="type in seriesTypes" :key="type" cols="12" md="6">
          <app-chart
            :type="type"
            :data="timeSeries[type]"
            :total="catTotals[type]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import Chart from './chart.vue'
  import Overlay from '@/components/common/overlay.vue'
  export default {
    components: {
      AppChart: Chart,
      AppOverlay: Overlay
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['reportingData', 'fillrateData', 'loading', 'timeUnit'],
    data() {
      return {
        seriesTypes: ['revenue', 'impressions', 'cpm', 'fillrate']
      }
    },
    computed: {
      timeSeries() {
        const cats = ['impressions', 'revenue']
        const cpmArray = {}
        const series = {}
        cats.forEach((type) => {
          series[type] = []
        })
        series.cpm = [{ name: 'cpm', data: [] }]
        this.reportingData.forEach((item) => {
          const unixDate = parseInt(item.day) * 1000
          cats.forEach((cat) => {
            if (
              series[cat].find((x) => x.name == item.bidder_name) == undefined
            ) {
              series[cat].push({ name: item.bidder_name, data: [] })
            }
            const foo = series[cat].find((x) => x.name == item.bidder_name)
            //console.log(cat, foo)
            foo.data.push([
              unixDate,
              cat == 'impressions' ? parseInt(item[cat]) : parseFloat(item[cat])
            ])
          })
          if (cpmArray[unixDate] == undefined) {
            cpmArray[unixDate] = { revenue: 0, impressions: 0 }
          } // Creates a new CPM array
          cpmArray[unixDate].revenue += parseFloat(item.revenue)
          cpmArray[unixDate].impressions += parseInt(item.impressions)

          if (series.length == 0) this.timeUnit == 'hourly'
        })
        Object.keys(cpmArray).forEach((unixDate) => {
          series.cpm[0].data.push([
            parseInt(unixDate),
            (cpmArray[unixDate].revenue / cpmArray[unixDate].impressions) * 1000
          ])
        })
        series.fillrate = [{ name: 'fillrate', data: [] }]
        series.fillrate[0].data = this.fillrateData.map((item) => [
          parseInt(item.day) * 1000,
          parseFloat(item.impressions / item.inventory)
        ])
        return series
      },
      catTotals() {
        const output = {}
        const cats = ['revenue', 'impressions']
        cats.forEach((type) => {
          output[type] = 0
          this.timeSeries[type].forEach((item) => {
            item.data.forEach((elem) => {
              output[type] += elem[1]
            })
          })
        })
        output['cpm'] = (output['revenue'] / output['impressions']) * 1000
        const totalInventory = this.fillrateData.reduce((output, item) => {
          return output + parseInt(item.inventory)
        }, 0)
        output.fillrate = output.impressions / totalInventory
        return output
      }
    }
  }
</script>
