import { GetUsers } from '@/store/action_types'

import { SetUsers } from '@/store/mutation_types'

import axios from 'axios'

export const usersState = {
  users: []
}

export const usersGetters = {
  users: (state) => state.users
}

export const usersActions = {
  async [GetUsers]({ commit }) {
    const response = await axios.get('/api/users')
    commit(SetUsers, response.data)
    return response
  }
}

export const usersMutations = {
  [SetUsers](state, users) {
    state.users = users
  }
}
