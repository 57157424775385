<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <h4 style="font-size: 22px; height: 55px">
            {{ $t('current_month') }}
          </h4></v-row
        >
        <v-container style="padding: 0px">
          <v-row dense no-gutters>
            <v-col>
              <h4>{{ getdate('c') }}</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding-top: 0px">
              <app-cat-datatable
                :reporting-data="reportingData['auction_type']"
                :category="'auction_type'"
                :loading="loading"
                @filter="filter"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row style="padding-top: 20px">
          <h4 style="font-size: 22px; height: 35px">
            {{ $t('previous_three_months') }}
          </h4>
        </v-row>
        <v-container>
          <v-row dense no-gutters>
            <v-col style="padding-top: 10px">
              <h4>{{ getdate('p') }}</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding-top: 0px">
              <app-cat-datatable
                :reporting-data="reportingDataPM['auction_type']"
                :category="'auction_type'"
                :loading="loading"
                @filter="filter"
              />
            </v-col>
          </v-row>
          <v-row dense no-gutters>
            <v-col style="padding-top: 10px">
              <h4>{{ getdate('pp') }}</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding-top: 0px">
              <app-cat-datatable
                :reporting-data="reportingDataPPM['auction_type']"
                :category="'auction_type'"
                :loading="loading"
                @filter="filter"
              />
            </v-col>
          </v-row>
          <v-row dense no-gutters>
            <v-col style="padding-top: 10px">
              <h4>{{ getdate('ppp') }}</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding-top: 0px">
              <app-cat-datatable
                :reporting-data="reportingDataPPPM['auction_type']"
                :category="'auction_type'"
                :loading="loading"
                @filter="filter"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <h4 style="font-size: 22px; height: 64px">
          {{ $t('site') + ' (' + dates[0] + ' => ' + dates[1] + ')' }}
        </h4>
        <app-cat-datatable
          :reporting-data="reportingDataUpdate['site']"
          :category="'site'"
          :loading="loading"
          :period="period"
          @filter="filter"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import * as formatters from '@/components/common/formatters.js'
  import CatDatatable from './catDatatable.vue'
  export default {
    components: {
      AppCatDatatable: CatDatatable
    },
    // eslint-disable-next-line vue/require-prop-types
    props: [
      // eslint-disable-next-line vue/require-prop-types
      'reportingDataPM',
      // eslint-disable-next-line vue/require-prop-types
      'reportingDataPPM',
      // eslint-disable-next-line vue/require-prop-types
      'reportingDataPPPM',
      // eslint-disable-next-line vue/require-prop-types
      'reportingData',
      // eslint-disable-next-line vue/require-prop-types
      'reportingDataUpdate',
      // eslint-disable-next-line vue/require-prop-types
      'loading',
      // eslint-disable-next-line vue/require-prop-types
      'cats',
      // eslint-disable-next-line vue/require-prop-types
      'period'
    ],
    data() {
      return {
        dates: [],
        month: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
      }
    },
    computed: {},
    watch: {
      period() {
        var dateConvertedfirst = new Date(this.period[0])
        var dateConvertedsecond = new Date(this.period[1])
        this.dates[0] =
          dateConvertedfirst.getDate() +
          ' ' +
          this.month[dateConvertedfirst.getMonth()] +
          ' ' +
          dateConvertedfirst.getFullYear()
        this.dates[1] =
          dateConvertedsecond.getDate() +
          ' ' +
          this.month[dateConvertedsecond.getMonth()] +
          ' ' +
          dateConvertedsecond.getFullYear()
      }
    },
    created() {},
    methods: {
      getdate(code) {
        const current = new Date()
        current.setDate(1)
        const currentYear = current.getFullYear()
        if (code == 'c') current.setMonth(current.getMonth())
        else if (code == 'p') current.setMonth(current.getMonth() - 1)
        else if (code == 'pp') current.setMonth(current.getMonth() - 2)
        else if (code == 'ppp') current.setMonth(current.getMonth() - 3)
        var date = this.month[current.getMonth()]
        const previousDate = date + ' ' + currentYear
        return formatters.ucFirst(previousDate)
      },
      filter(filter) {
        this.$emit('perCriteriaUpdate', filter)
      }
    }
  }
</script>
