<template>
  <v-card style="margin-left: 10px">
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>
    <v-card-title
      primary-title
      style="background-color: #f9f9fb; padding-bottom: 0px"
      ><v-container>
        <v-row>
          <v-col>
            <h3 class="cnx">
              <strong> {{ injectionName }}</strong>
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-divider></v-divider>

    <v-form ref="form" lazy-validation>
      <v-card-text style="margin-top: 20px; padding-left: 40px">
        <v-row>
          <v-col>
            <h2 style="margin-left: 0px; margin-top: 0px">Set up</h2>
          </v-col>
        </v-row>
        <v-row style="padding-top: 10px">
          <v-col cols="6" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Name of the injection</p>
            <v-text-field v-model="injection.name" :rules="required" dense solo>
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider style="margin-top: 20px" />
        <v-row style="padding-top: 20px">
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Type of the injection</p>
            <v-select
              class="col-12"
              v-model="injection.type"
              :items="type"
              dense
              solo
              item-text="text"
              item-value="value"
              :rules="required"
              :placeholder="'Select type'"
              style="padding-left: 0px"
            ></v-select>
          </v-col>
          <v-col cols="5" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Selector</p>
            <v-text-field
              v-model="injection.selector"
              :rules="required"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row style="padding-top: 10px" v-if="injection.type == 'dynamic'">
          <v-col cols="5" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">First offset selector</p>
            <v-text-field
              v-model="injection.first_offset_selector"
              :rules="required"
              dense
              solo
            >
            </v-text-field>
          </v-col>
          <v-col cols="5" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Density index</p>
            <v-text-field
              v-model="injection.density_index"
              :rules="floatRequiredRule"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row style="padding-top: 10px" v-if="injection.type == 'fixed'">
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Position</p>
            <v-select
              class="col-12"
              v-model="injection.position"
              :items="position"
              dense
              solo
              item-text="text"
              item-value="value"
              :placeholder="'Select position'"
              style="padding-left: 0px"
            ></v-select>
          </v-col>
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Repetition</p>
            <v-text-field
              v-model="injection.repetition"
              :rules="integerRequiredRule"
              :min="0"
              type="number"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider style="margin-top: 20px" />

        <v-row style="padding-top: 30px">
          <v-col cols="6" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Placement</p>
            <v-select
              class="col-12"
              v-model="injection.stored_request_id"
              :items="computedSR"
              dense
              solo
              item-text="name"
              item-value="id"
              :placeholder="'Select placement'"
              style="padding-left: 0px"
            ></v-select>
          </v-col>
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Sizes</p>
            <v-text-field
              v-model="injection.sizes"
              :rules="required"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!--  <v-row style="padding-top: 20px">
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Traffic source</p>
            <v-select
              class="col-12"
              v-model="injection.traffic_source"
              :items="traffic_source"
              dense
              solo
              item-text="text"
              item-value="value"
              :placeholder="'Select traffic source'"
              style="padding-left: 0px"
            ></v-select>
          </v-col>
        </v-row>-->
        <!--<v-row style="padding-top: 10px">
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">AB test</p>
            <v-select
              class="col-12"
              v-model="injection.abtest"
              :items="abtest"
              dense
              solo
              item-text="AB Test"
              :placeholder="'Select AB Test'"
              style="padding-left: 0px"
            ></v-select>
          </v-col>
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">AB test share</p>
            <v-text-field
              v-model="injection.abtest_share"
              :rules="floatRequiredRule"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>-->
        <v-row style="padding-top: 10px">
          <v-col cols="5" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Targeting</p>
            <v-text-field
              v-model="injection.targeting"
              :rules="required"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row style="padding-top: 10px">
          <v-col cols="5" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Style</p>
            <v-text-field
              v-model="injection.style"
              :rules="required"
              dense
              solo
            >
            </v-text-field>
          </v-col>
          <v-col cols="5" style="padding-bottom: 0px; padding-top: 0px">
            <p style="margin-bottom: 5px">Before style</p>
            <v-text-field
              v-model="injection.before_style"
              :rules="required"
              dense
              solo
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          :loading="submitLoading"
          :disabled="submitLoading"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="130"
          dense
          @click="submit"
        >
          <v-icon left> mdi-check-circle</v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-form>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import {
    SaveSelectedInjection,
    GetStoredRequests
  } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { SetSelectedInjection } from '@/store/mutation_types'
  export default {
    data() {
      return {
        loading: false,
        injectionName: 'Creating a new injection',
        nameRules: [(v) => !!v || 'Name is required'],
        floatRequiredRule: [
          (v) =>
            /^[+-]?\d+(\.\d+)?$/.test(v) || 'Required (Decimal numbers only)'
        ],
        integerRule: [(v) => /^\d+$/.test(v) || 'Only numbers are allowed'],
        arrayRules: [
          (v) => !!v || 'Required',
          (v) => (v && this.isJsonString(v)) || 'Needs to be an array / object'
        ],
        submitLoading: false,
        tab: null,
        injection: {},
        actionsuccess: false,
        timeout: 2000,
        messageAction: '',
        required: [(v) => !!v || 'Required'],
        selection_type: true,
        refresh_time: 30,
        type: [
          { text: 'Dynamic', value: 'dynamic' },
          { text: 'Fixed', value: 'fixed' }
        ],
        abtest: ['A', 'B', 'C'],
        position: [
          { text: 'Before begin', value: 'beforebegin' },
          { text: 'After begin', value: 'afterbegin' },
          { text: 'Before end', value: 'beforeend' },
          { text: 'After end', value: 'afterend' }
        ],
        traffic_source: [{ text: 'All', value: 'all' }]
      }
    },
    computed: {
      ...mapGetters(['SelectedInjection', 'storedRequests', 'appCustomer']),
      computedSR() {
        return this.storedRequests
      }
    },
    watch: {},
    async created() {
      await this.GetStoredRequests(this.$route.params.site_id)
      if (this.$route.name == 'injection_edit') {
        try {
          const siteURL = await axios.get(
            `/api/getinjectionbyid/${this.$route.params.injection_id}`
          )
          this.SetSelectedInjection(siteURL.data)

          this.injection = this.SelectedInjection
          this.injectionName = this.injection.name
        } catch (error) {
          console.log(error)
        }
      }
    },
    methods: {
      ...mapActions({
        SaveSelectedInjection,
        GetStoredRequests
      }),
      ...mapMutations([SetSelectedInjection]),

      isJsonString(str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      },
      async submit() {
        this.submitLoading = true
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          this.submitLoading = false
          return
        }
        this.organizeInjectionItems()
        try {
          await this.SaveSelectedInjection(this.injection)
          this.actionsuccess = true
          this.messageAction = 'Injection updated successfully'
          setTimeout(
            () =>
              this.$router.push(
                '/management/' +
                  this.$route.params.type +
                  '/' +
                  this.appCustomer.id +
                  '/sites/' +
                  this.$route.params.site_id +
                  '/injections'
              ),
            1000
          )
          setTimeout(() => (this.submitLoading = false), 1500)
        } catch (error) {
          console.log(error)
          this.submitLoading = false
        }
      },
      organizeInjectionItems() {
        this.injection.site_id = this.$route.params.site_id
        if (this.injection.type != 'dynamic') {
          this.injection.first_offset_selector = ''
          this.injection.density_index = ''
        }
        if (this.injection.type != 'fixed') {
          this.injection.position = ''
          this.injection.repetition = ''
        }
      }
    }
  }
</script>

<style>
  .pageForm {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    padding-left: 0px;
    letter-spacing: 2px;
    font-size: 25px;
  }
</style>
