<template>
  <v-container fluid style="padding-top: 0px">
    <v-row no-gutters>
      <v-col lg10 offset-lg1 style="padding-left: 10px">
        <h1 class="text-xs-center">{{ $t('contact_us') }}</h1>
      </v-col>
    </v-row>
    <v-row v-if="emailSent == true" style="padding-top: 0px">
      <v-col style="padding-top: 0px">
        <v-alert dense text type="success" color="green">
          {{ $t('email_sent_successfully') }}</v-alert
        >
      </v-col>
    </v-row>
    <v-card style="padding: 20px 20px 20px 20px; margin-top: 10px">
      <v-form ref="form" v-model="valid" style="padding-top: 30px">
        <v-row no-gutters>
          <v-col cols="8">
            <p>{{ $t('name') }}</p>
            <v-text-field v-model="contact.name" :rules="required" dense solo>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="8">
            <p>{{ $t('email') }}</p>
            <v-text-field
              v-model="contact.email"
              :rules="emailRules"
              dense
              solo
            >
            </v-text-field>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <p>{{ $t('message') }}</p>
            <v-textarea
              ref="textToCopy"
              v-model="contact.content"
              :rules="required"
              dense
              solo
              auto-grow
              width="1200"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-btn
          :loading="loading"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="250"
          dense
          @click="SendEmail()"
        >
          {{ $t('submit') }}
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        contact: {
          email: '',
          name: '',
          content: ''
        },
        required: [(v) => !!v || 'Required'],
        emailRules: [
          (v) => !!v || 'required',
          (v) => /.+@.+\..+/.test(v) || 'Email must be valid'
        ],
        loading: false,
        valid: true,
        emailSent: false
      }
    },
    watch: {
      loader() {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 6000)

        this.loader = null
      }
    },
    methods: {
      async SendEmail() {
        if (this.$refs.form.validate() == false) return
        else {
          this.loading = true
          const response = await axios.post(
            `/api/sendsupportemail/${this.contact.name}/${this.contact.email}/${this.contact.content}`
          )
          this.emailSent = true
          this.$refs.form.reset()
          this.loading = false
          setTimeout(() => (this.emailSent = false), 2000)
        }
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
    border: 1px solid black;
  }
</style>
