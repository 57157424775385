<template>
  <v-form ref="form" lazy-validation>
    <v-row dense>
      <v-col cols="3">
        <p style="margin-bottom: 10px">{{ $t('products') }}</p>
        <v-select
          v-model="product"
          class="combobox"
          :items="products"
          item-text="value"
          return-object
          solo
          dense
          :rules="required"
        >
        </v-select>
      </v-col>
      <v-col cols="5">
        <p style="margin-bottom: 10px">Sites</p>
        <v-select
          v-model="selectedSites"
          :items="computedSites"
          item-text="name"
          item-value="id"
          label="Choose sites"
          solo
          dense
          multiple
        >
          <template v-if="sites.length > 1" v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="selectedSites.length > 0 ? 'indigo darken-4' : ''"
                >
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index < 3"
              close
              style="margin-top: 5px; margin-bottom: 5px"
              @click:close="remove(item)"
            >
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 3"
              class="grey--text text-caption"
              style="font-size: 20px"
            >
              (+{{ selectedSites.length - 3 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="1" />
      <v-col cols="3">
        <p style="margin-bottom: 10px">{{ $t('price') }}</p>
        <v-text-field
          v-model="cpm"
          dense
          solo
          :suffix="customer.currency"
          :rules="required"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="4">
        <p style="margin-bottom: 10px">Start date</p>
        <v-menu
          v-model="menu_start_date"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_start"
              label="Start date"
              prepend-icon="mdi-calendar"
              readonly
              solo
              dense
              v-bind="attrs"
              :rules="required"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_start"
            @input="menu_start_date = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <p style="margin-bottom: 10px">End date</p>
        <v-menu
          v-model="menu_end_date"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_end"
              label="End date"
              prepend-icon="mdi-calendar"
              readonly
              solo
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_end"
            :min="date_start"
            @input="menu_end_date = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-card-actions class="d-flex flex-row-reverse">
      <v-btn
        :loading="confirmLoading"
        :disabled="confirmLoading"
        style="margin: 10px"
        class="white--text notcapital"
        color="black"
        width="130"
        dense
        @click="submit()"
      >
        <v-icon left> mdi-check</v-icon>
        {{ $t('confirm') }}
      </v-btn>
      <v-btn
        v-if="actiontype != 'update'"
        style="margin: 10px"
        class="white--text notcapital"
        color="black"
        width="130"
        dense
        @click="$router.push($route.path + '?settings=billing'), back()"
      >
        <v-icon left> mdi-arrow-left</v-icon>
        {{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
  import axios from 'axios'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import { GetSites } from '@/store/action_types'
  import { SetSites } from '@/store/mutation_types'

  export default {
    props: ['customer', 'selectedsitesprop', 'actiontype', 'item', 'dialog'],
    data() {
      return {
        rule: {
          cpm_value: ''
        },
        cpm: '',
        selectedSites: [],
        selectedSitesInit: [],
        product: {
          name: ''
        },
        confirmLoading: false,
        selectAll: false,
        required: [(v) => !!v || 'Required'],
        menu_start_date: false,
        menu_end_date: false,
        //mindate: this.getmindate(),
        date_start: '',

        date_end: '',
        products: [
          {
            name: 'amp',
            value: 'Nexx AMP'
          },
          {
            name: 'fast',
            value: 'Nexx Web'
          },
          {
            name: 'adapter',
            value: 'Nexx Adapter'
          }
        ]
      }
    },
    computed: {
      ...mapGetters(['sites']),
      computedSites() {
        return this.sites
          .filter((el) => {
            return el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      },
      likesAllSite() {
        return this.selectedSites.length === this.sites.length
      },
      likesSomeSite() {
        return this.selectedSites.length > 0 && !this.likesAllSite
      },
      icon() {
        if (this.likesAllSite) return 'mdi-close-box'
        if (this.likesSomeSite) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      }
    },
    watch: {
      selectedsitesprop() {
        if (this.actiontype == 'update') {
          this.getSelectedItems()
        }
      },
      product: async function (val, oldVal) {
        await this.GetSites(this.customer.id)
        if (this.actiontype == 'update') {
          this.setSitesListToUpdateDynamically()
        }
        if (this.product != null && this.actiontype != 'update') {
          // this.setSitesListToAddDynamically()
        }
      },
      async dialog() {
        await this.GetSites(this.customer.id)
        if (this.dialog == true) {
          this.setSitesListToUpdateDynamically()
          this.product.name = this.item.product
          this.cpm = this.item.cpm_value
        }
      }
    },
    created() {
      if (this.actiontype == 'update') {
        this.getSelectedItems()
        this.product.name = this.item.product
        this.cpm = this.item.cpm_value
        //this.setSitesListToUpdateDynamically()
      }
    },
    methods: {
      ...mapMutations([SetSites]),
      ...mapActions([GetSites]),
      getSelectedItems() {
        if (this.item.product == this.product.name || this.product.name == '') {
          if (this.selectedsitesprop.length > 0) {
            this.selectedSites = []
            if (this.selectedsitesprop[0].name != 'ALL') {
              for (let i = 0; i < this.selectedsitesprop.length; i++) {
                this.selectedSites[i] = this.selectedsitesprop[i].id
                this.selectedSitesInit[i] = this.selectedsitesprop[i].id
              }
            }
          }
        } else this.selectedSites = []
      },

      submit() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.confirmLoading = true
        this.rule.cpm_value = this.cpm
        this.rule.customer_id = this.customer.id
        this.rule.product = this.product.name
        this.rule.start_date = this.date_start
        if (this.date_end != '') this.rule.end_date = this.date_end
        else this.rule.end_date = null
        if (this.actiontype == 'update') {
          this.$emit('ruleUpdating', this.rule, this.selectedSites)
        } else {
          if (this.selectedSites.length > 0 && !this.selectAll) {
            this.selectedSites.forEach((element) => {
              this.rule.site_id = element
              this.$emit('ruleAdding', this.rule)
            })
          } else {
            this.$emit('ruleAdding', this.rule)
          }
          this.$refs.form.reset()
        }
        setTimeout(() => (this.confirmLoading = false), 1000)
      },
      back() {
        this.$emit('cancel')
        this.$refs.form.reset()
      },
      toggle() {
        this.$nextTick(() => {
          if (this.likesAllSite) {
            this.selectedSites = []
            this.selectAll = false
          } else {
            this.selectedSites = this.sites.slice()
            this.selectAll = true
          }
        })
      },
      remove(item) {
        this.selectedSites.splice(this.selectedSites.indexOf(item.id), 1)
        this.selectedSites = [...this.selectedSites]
      },
      async setSitesListToUpdateDynamically() {
        this.getSelectedItems()
        const response = await axios.get(
          `/api/getsiteswithsameproduct/${this.customer.id}/${this.product.name}`
        )
        let sitesToEliminate = []
        let arrayx = []
        sitesToEliminate = response.data
        for (let index = 0; index < sitesToEliminate.length; index++) {}
        for (let i = 0; i < sitesToEliminate.length; i++) {
          for (let j = 0; j < this.selectedSites.length; j++) {
            if (sitesToEliminate[i].site_id == this.selectedSites[j]) {
              arrayx.push(i)
            }
          }
        }
        for (let i = 0; i < this.sites.length; i++) {
          for (let j = 0; j < sitesToEliminate.length; j++) {
            if (this.sites[i].id != sitesToEliminate[j].site_id) {
              var occ = 0
              arrayx.forEach((id) => {
                if (j == id) occ = 1
              })
              if (occ == 0) this.sites.splice(i, 1)
            }
          }
        }
      },
      async setSitesListToAddDynamically() {
        try {
          const response = await axios.get(
            `/api/getsiteswithsameproduct/${this.customer.id}/${this.product.name}`
          )
          var sitesToEliminate = []
          sitesToEliminate = response.data
          if (sitesToEliminate.length > 0) {
            if (sitesToEliminate[0].site_id == 0) {
              this.SetSites([])
            } else {
              for (let i = 0; i < this.sites.length; i++) {
                for (let j = 0; j < sitesToEliminate.length; j++) {
                  if (this.sites[i].id == sitesToEliminate[j].site_id)
                    this.sites.splice(i, 1)
                }
              }
            }
          }
        } catch (e) {
          console.error(e)
        }
        this.selectedSites = []
      },
      getmindate() {
        var date = new Date()
        date.setDate(date.getDate() + 1)
        return new Date(
          date.setDate(date.getDate()) - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)
      }
    }
  }
</script>
