<template>
  <div>
    <v-card-title>
      <div style="font-size: 0.8em">
        {{ partner.name }}
      </div>
      <v-item-group
        v-if="partner.code !== undefined"
        v-model="pagedevice[isActiveField]"
      >
        <v-item
          v-for="state in states"
          :key="state.id"
          v-slot:default="{ active, toggle }"
        >
          <v-btn
            class="mx-2"
            :input-value="active"
            :active-class="state.activeClass"
            depressed
            rounded
            small
            @click="toggle"
          >
            {{ state.name }}
          </v-btn>
        </v-item>
      </v-item-group>
    </v-card-title>
    <v-card-text>
      <v-container style="padding: 0px">
        <v-row v-if="partner.fields.length > 0">
          <v-col
            v-for="field in partner.fields"
            :key="field.model"
            cols="12"
            md="4"
            class="pageForm"
          >
            <v-text-field
              v-model="pagedevice[field.model]"
              :label="field.label"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    props: ['partner', 'pagedevice'],
    data() {
      return {
        states: [
          { id: 0, name: 'Disabled', activeClass: 'red white--text' },
          { id: 1, name: 'Active', activeClass: 'green white--text' },
          { id: 2, name: 'Paused', activeClass: 'orange white--text' }
        ]
      }
    },
    computed: {
      isActiveField() {
        const foo =
          this.partner.code.charAt(0).toUpperCase() + this.partner.code.slice(1)
        const param = 'is' + foo + 'Active'
        return param
      }
    }
  }
</script>
