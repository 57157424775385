<template>
  <v-container py-0 fluid>
    <app-overlay :loading="loading" />
    <v-row>
      <v-col>
        <v-select
          slot="prepend-item"
          v-model="selectedAuctions"
          :items="returnedItems('auction_type')"
          item-text="text"
          item-value="value"
          :label="labels['auction_type']"
          solo
          dense
          multiple
          clearable
        >
          <template
            v-if="returnedItems('auction_type').length > 1"
            v-slot:prepend-item
          >
            <v-list-item ripple @click="toggle('auction_type')">
              <v-list-item-action>
                <v-icon
                  :color="selectedAuctions.length > 0 ? 'indigo darken-4' : ''"
                >
                  {{ icon_auctions }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index < 3"
              close
              style="margin-top: 5px; margin-bottom: 5px"
              @click:close="remove(item, 'auction_type')"
            >
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 3"
              class="grey--text text-caption"
              style="font-size: 20px"
            >
              (+{{ selectedAuctions.length - 3 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col
        v-if="
          $route.name == 'customer_reporting' ||
          $route.name == 'customer_reporting_filters'
        "
      >
        <v-select
          slot="prepend-item"
          v-model="selectedCustomers"
          :items="returnedItems('customer')"
          item-text="text"
          item-value="value"
          :label="labels['customer']"
          solo
          dense
          multiple
          clearable
        >
          <template
            v-if="returnedItems('customer').length > 1"
            v-slot:prepend-item
          >
            <v-list-item ripple @click="toggle('customer')">
              <v-list-item-action>
                <v-icon
                  :color="selectedSites.length > 0 ? 'indigo darken-4' : ''"
                >
                  {{ icon_customer }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index < 2"
              close
              style="margin-top: 5px; margin-bottom: 5px"
              @click:close="remove(item, 'customer')"
            >
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="grey--text text-caption"
              style="font-size: 20px"
            >
              (+{{ selectedCustomers.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-select
          slot="prepend-item"
          v-model="selectedSites"
          :items="returnedItems('site')"
          item-text="text"
          item-value="value"
          :label="labels['site']"
          solo
          dense
          multiple
          clearable
        >
          <template v-if="returnedItems('site').length > 1" v-slot:prepend-item>
            <v-list-item ripple @click="toggle('site')">
              <v-list-item-action>
                <v-icon
                  :color="selectedSites.length > 0 ? 'indigo darken-4' : ''"
                >
                  {{ icon_site }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index < 2"
              close
              style="margin-top: 5px; margin-bottom: 5px"
              @click:close="remove(item, 'site')"
            >
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="grey--text text-caption"
              style="font-size: 20px"
            >
              (+{{ selectedSites.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col
        v-if="
          $route.name != 'customer_reporting' &&
          $route.name != 'customer_reporting_filters'
        "
      >
        <v-select
          slot="prepend-item"
          v-model="selectedPlacements"
          :items="returnedItems('placement')"
          item-text="text"
          item-value="value"
          :label="labels['placement']"
          :no-data-text="
            selectedSites.length > 0
              ? 'No data available for the selected site'
              : 'Please select a site first'
          "
          solo
          dense
          multiple
          clearable
        >
          <template
            v-if="returnedItems('placement').length > 1"
            v-slot:prepend-item
          >
            <v-list-item ripple @click="toggle('placement')">
              <v-list-item-action>
                <v-icon
                  :color="
                    selectedPlacements.length > 0 ? 'indigo darken-4' : ''
                  "
                >
                  {{ icon_placement }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index < 2"
              close
              style="margin-top: 5px; margin-bottom: 5px"
              @click:close="remove(item, 'placement')"
            >
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 2"
              class="grey--text text-caption"
              style="font-size: 20px"
            >
              (+{{ selectedPlacements.length - 2 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Overlay from './../common/overlay'
  import axios from 'axios'
  export default {
    components: {
      AppOverlay: Overlay
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['selects', 'filters', 'selectedfromtable'],
    data() {
      return {
        loading: false,
        selectedAuctions: [],
        selectedCustomers: [],
        selectedSites: [],
        selectedPlacements: []
      }
    },
    computed: {
      likesAllPlacements() {
        return (
          this.selectedPlacements.length ===
          this.returnedItems('placement').length
        )
      },
      likesSomePlacements() {
        return this.selectedPlacements.length > 0 && !this.likesAllPlacements
      },
      likesAllSites() {
        return this.selectedSites.length === this.returnedItems('site').length
      },
      likesSomeSites() {
        return this.selectedSites.length > 0 && !this.likesAllSites
      },
      likesAllCustomers() {
        return (
          this.selectedCustomers.length ===
          this.returnedItems('customer').length
        )
      },
      likesSomeCustomers() {
        return this.selectedCustomers.length > 0 && !this.likesAllCustomers
      },
      likesAllAuctions() {
        return (
          this.selectedAuctions.length ===
          this.returnedItems('auction_type').length
        )
      },
      likesSomeAuctions() {
        return this.selectedAuctions.length > 0 && !this.likesAllAuctions
      },
      icon_auctions() {
        if (this.likesAllAuctions) return 'mdi-close-box'
        if (this.likesSomeAuctions) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      icon_site() {
        if (this.likesAllSites) return 'mdi-close-box'
        if (this.likesSomeSites) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      icon_customer() {
        if (this.likesAllCustomers) return 'mdi-close-box'
        if (this.likesSomeCustomers) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      icon_placement() {
        if (this.likesAllPlacements) return 'mdi-close-box'
        if (this.likesSomePlacements) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      cats() {
        if (this.selects == undefined) return []
        return Object.keys(this.selects)
      },
      labels() {
        let output = {}
        this.cats.forEach((cat) => {
          if (this.selects[cat][1] != undefined) {
            if (this.selects[cat][1].text == 'adapter') {
              this.selects[cat][1].text = 'Nexx Adapter'
            }
            if (this.selects[cat][0].text == 'amp') {
              this.selects[cat][0].text = 'Nexx AMP'
            }
            if (this.selects[cat][2].text == 'fast') {
              this.selects[cat][2].text = 'Nexx Web'
            }
          }

          let foo = cat.charAt(0).toUpperCase() + cat.slice(1)
          output[cat] = foo.replace('_', ' ')
        })
        return output
      }
    },
    watch: {
      selectedAuctions() {
        this.filters['auction_type'] = this.selectedAuctions
        this.emitChange()
      },
      selectedCustomers() {
        this.filters['customer'] = this.selectedCustomers
        if (
          this.filters['customer'].length == 0 &&
          this.$route.name == 'customer_reporting'
        ) {
          this.selectedSites = []
        }
        this.emitChange()
      },
      selectedSites() {
        this.filters['site'] = this.selectedSites
        if (this.filters['site'].length == 0) {
          this.selectedPlacements = []
        }
        this.emitChange()
      },
      selectedPlacements() {
        this.filters['placement'] = this.selectedPlacements
        if (this.filters['site'].length > 0) this.emitChange()
      },
      async selectedfromtable() {
        if (this.selectedfromtable['category'] == 'auction_type') {
          this.selectedAuctions.push(this.selectedfromtable['id'])
        }
        if (this.selectedfromtable['category'] == 'customer') {
          this.selectedCustomers.push(this.selectedfromtable['id'])
        }
        if (this.selectedfromtable['category'] == 'site') {
          if (this.$route.name != 'customer_reporting') {
            this.selectedSites.push(this.selectedfromtable['id'])
          } else {
            const response = await axios.get(
              `/api/getcustomerbysiteid/${this.selectedfromtable['id']}`
            )
            var customer = response.data
            await this.selectedCustomers.push(customer[0].customer_id)
            this.selectedSites.push(this.selectedfromtable['id'])
          }
        }
        if (this.selectedfromtable['category'] == 'placement') {
          const response = await axios.get(
            `/api/getsitebyplacementid/${this.selectedfromtable['id']}`
          )
          var site = response.data
          await this.selectedSites.push(site[0].site_id)
          this.selectedPlacements.push(this.selectedfromtable['id'])
        }
      }
    },
    created() {
      this.generateFilterFromLink()
    },
    methods: {
      generateFilterFromLink() {
        if (
          this.$route.name == 'pbs_reporting_filters' ||
          this.$route.name == 'billing_reporting_filters' ||
          this.$route.name == 'customer_reporting_filters'
        ) {
          if (
            this.$route.query.auction_type != undefined ||
            this.$route.query.sites != undefined ||
            this.$route.query.placements != undefined ||
            this.$route.query.customers != undefined
          ) {
            if (this.$route.query.auction_type != undefined) {
              var auctions = this.$route.query.auction_type.split(',')
              for (let i = 0; i < auctions.length; i++) {
                this.selectedAuctions.push(auctions[i])
              }
            }
            if (this.$route.query.sites != undefined) {
              var sites = this.$route.query.sites.split(',')
              for (let i = 0; i < sites.length; i++) {
                this.selectedSites.push(sites[i])
              }
            }
            if (this.$route.query.customers != undefined) {
              var customers = this.$route.query.customers.split(',')
              for (let i = 0; i < customers.length; i++) {
                this.selectedCustomers.push(customers[i])
              }
            }
            if (this.$route.query.placements != undefined) {
              var placements = this.$route.query.placements.split(',')
              for (let i = 0; i < placements.length; i++) {
                this.selectedPlacements.push(placements[i])
              }
            }
          }
        }
      },
      returnedItems(cat) {
        if (this.selects != undefined) {
          let arr = [...this.selects[cat]]
          if (arr.length > 0)
            return arr.sort((a, b) => a.text.localeCompare(b.text))
          else return arr
        } else return []
      },
      emitChange() {
        this.$emit('filtersUpdate', this.filters)
      },
      remove(item, type) {
        if (type == 'auction_type') {
          this.selectedAuctions.splice(
            this.selectedAuctions.indexOf(item.value),
            1
          )
          this.selectedAuctions = [...this.selectedAuctions]
        } else if (type == 'customer') {
          this.selectedCustomers.splice(
            this.selectedCustomers.indexOf(item.value),
            1
          )
          this.selectedCustomers = [...this.selectedCustomers]
        } else if (type == 'site') {
          this.selectedSites.splice(this.selectedSites.indexOf(item.value), 1)
          this.selectedSites = [...this.selectedSites]
        } else {
          this.selectedPlacements.splice(
            this.selectedPlacements.indexOf(item.value),
            1
          )
          this.selectedPlacements = [...this.selectedPlacements]
        }
      },
      toggle(type) {
        if (type == 'auction_type') {
          this.$nextTick(() => {
            if (this.likesAllAuctions) {
              this.selectedAuctions = []
            } else {
              this.selectedAuctions = []
              this.returnedItems('auction_type').forEach((element) => {
                this.selectedAuctions.push(element.value.slice())
              })
            }
          })
        } else if (type == 'customer') {
          this.$nextTick(() => {
            if (this.likesAllCustomers) {
              this.selectedCustomers = []
            } else {
              this.selectedCustomers = []
              this.returnedItems('customer').forEach((element) => {
                this.selectedCustomers.push(element.value.slice())
              })
            }
          })
        } else if (type == 'site') {
          this.$nextTick(() => {
            if (this.likesAllSites) {
              this.selectedSites = []
            } else {
              this.selectedSites = []
              this.returnedItems('site').forEach((element) => {
                this.selectedSites.push(element.value.slice())
              })
            }
          })
        } else {
          this.$nextTick(() => {
            if (this.likesAllPlacements) {
              this.selectedPlacements = []
            } else {
              this.selectedPlacements = []
              this.returnedItems('placement').forEach((element) => {
                this.selectedPlacements.push(element.value.slice())
              })
            }
          })
        }
      }
    }
  }
</script>
