import {
  GetSelectedUser,
  SaveSelectedUser,
  SaveSelectedUserFromSignUp,
  UpdateSelectedUser,
  InviteSelectedUser
} from '@/store/action_types'
import { SetSelectedUser } from '@/store/mutation_types'

import axios from 'axios'

export const selectedUserState = {
  selectedUser: {}
}

export const selectedUserGetters = {
  selectedUser: (state) => state.selectedUser
}

export const selectedUserActions = {
  async [GetSelectedUser]({ commit }, id) {
    const response = await axios.get('/api/users/' + id)
    commit(SetSelectedUser, response.data)
    return response
  },

  async [SaveSelectedUser]({ dispatch }, User) {
    if (User.id) {
      return dispatch(UpdateSelectedUser, User)
    }
    const response = await axios.post('/api/users', User)
    return response
  },

  async [SaveSelectedUserFromSignUp](User) {
    const response = await axios.post('/api/newuser', User)
    return response
  },

  async [InviteSelectedUser](Email) {
    const response = await axios.get('/api/inviteuser/' + Email)
    return response
  },

  async [UpdateSelectedUser](User) {
    const response = await axios.put(`/api/users/${User.id}`, User)

    return response
  }
}

export const selectedUserMutations = {
  [SetSelectedUser](state, User) {
    state.selectedUser = User
  }
}
