<template>
  <v-container fluid style="background-color: #f9f9fb">
    <v-row>
      <v-col class="noPaddingCenter" cols="12" style="padding: 35px 0px 0px">
        <v-img
          class="mx-2"
          src="@/assets/accessDenied.png"
          max-height="180"
          contain
        />
        <p style="padding-left: 10px; font-size: 32px">
          {{ $t('access denied') }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: center; padding: 0px 0px 0px">
        <v-btn
          width="370px"
          height="40px"
          style="border-color: black"
          class="white--text"
          color="black"
          dense
          @click="gotoSiteManagement()"
        >
          {{ $t('return_to_my_account') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['sites', 'appCustomer']),
      computedSites() {
        return this.sites
          .filter((el) => {
            return this.archived ? true : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    methods: {
      gotoSiteManagement() {
        let siteinurl = ''
        if (this.computedSites.length == 0) siteinurl = '0'
        else siteinurl = this.computedSites[0].id
        this.$router
          .push({
            name: this.$route.name,
            params: {
              customer_id: this.appCustomer.id,
              site_id: siteinurl
            }
          })
          .catch(() => {})
      }
    }
  }
</script>
<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
</style>
