<template>
  <div id="siteContent">
    <v-container
      v-if="
        $store.getters.is_superadmin == false &&
        $store.state.appCustomer.id != $route.params.customer_id &&
        this.$store.state.appCustomer.id != '1086' &&
        this.$store.state.appCustomer.id != '1094'
      "
    >
      <accessdenied />
    </v-container>
    <v-container v-else fluid style="padding-top: 0px">
      <v-card id="siteList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col cols="5">
              <h3 v-if="$route.params.type == 'amp'" class="cnx">
                <strong> {{ $t("sites management") + " " + $t("amp") }}</strong>
              </h3>
              <h3 v-if="$route.params.type == 'fast'" class="cnx">
                <strong> {{ $t("sites management") + " " + $t("web") }}</strong>
              </h3>
              <h3 v-if="$route.params.type == 'adapter'" class="cnx">
                <strong>
                  {{ $t("sites management") + " " + $t("adapter") }}</strong
                >
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                width="200"
                dense
                @click="newSite()"
              >
                <v-icon left> mdi-plus </v-icon>
                {{ $t("add_site") }}
              </v-btn>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="3" align="right" justify="right">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                width="170"
                dense
                @click="
                  $router.push($route.path + '?action=bulkupload'),
                    (bulkUploadDialog = true)
                "
              >
                <v-icon left> mdi-download-outline </v-icon> Bulk upload
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p
                style="
                  position: relative;
                  top: 30px;
                  left: 5px;
                  font-size: 14px;
                "
              >
                <strong> {{ numberOfSites }}</strong>
              </p>
            </v-col>
            <v-col cols="5" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-data-table
            :search="search"
            :headers="headers"
            :items="computedSites"
            :sort-by="['name']"
            :items-per-page="10"
            class="elevation-1"
            :loading="loading"
            :disable-pagination="true"
            hide-default-footer
            style="background-color: #f9f9fb"
          >
            <template v-slot:item="props">
              <tr>
                <td
                  v-if="props.item.is_archived == 0"
                  style="width: 85%; cursor: pointer"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-if="props.item.is_archived == 1"
                  style="width: 85%; cursor: pointer; opacity: 0.5"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.name }}
                </td>
                <!--<td style="width: 5%; font-size: 14px">
                  {{ getCountStoredRequests(props.item.id) }}
                </td>-->
                <td style="padding: 0px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="updateSite(props.item)"
                      >
                        <v-icon size="20">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit the website</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="selectItem(props.item)"
                      >
                        <v-icon size="20">mdi-table-arrow-up</v-icon>
                      </v-btn>
                    </template>
                    <span>Manage the placements</span>
                  </v-tooltip>
                  <!--<v-tooltip top v-if="$route.params.type == 'fast'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="goToInjectionList(props.item)"
                      >
                        <v-icon size="20">mdi-needle</v-icon>
                      </v-btn>
                    </template>
                    <span>Manage the injections</span>
                  </v-tooltip>-->
                  <!--<v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="showConfirmationDialog(props.item, 'duplicate')"
                      >
                        <v-icon size="20">mdi-content-duplicate</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate the website</span>
                  </v-tooltip>-->
                  <v-tooltip v-if="props.item.is_archived == 0" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="showConfirmationDialog(props.item)"
                      >
                        <v-icon size="20">mdi-archive-arrow-down</v-icon>
                      </v-btn>
                    </template>
                    <span>Archive the website</span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="unarchiveSite(props.item)"
                      >
                        <v-icon size="20">mdi-archive-arrow-up</v-icon>
                      </v-btn>
                    </template>
                    <span>Unarchive the website</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-col
            cols="12"
            align="right"
            justify="right"
            style="padding-top: 0px; padding-left: 15px; padding-bottom: 20px"
          >
            <v-btn
              class="black--text notcapital"
              tile
              width="200"
              height="30"
              outlined
              style="margin-top: 20px"
              @click="showArchived = !showArchived"
            >
              <v-icon left>
                {{ showArchived ? "mdi-earth" : "mdi-archive" }}</v-icon
              >
              {{ showArchived ? "Hide archived sites" : "Show archived sites" }}
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
      <bulkupload
        :parent-dialog="bulkUploadDialog"
        @hideDialog="bulkUploadDialog = false"
        @hideUploadDialog="bulkUploadDialog = true"
      />
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @archive="archiveSite"
      />

      <rtcConfig
        :code="codeGenerated"
        :parent-dialog="rtcConfigDialog"
        @sendToEmail="SendConfigToEmail"
        @hideDialog="rtcConfigDialog = false"
      />
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
  GetSites,
  GetStoredRequests,
  SaveSelectedStoredRequest,
  SaveSelectedSite,
  UpdateSelectedSite,
} from "@/store/action_types";
import { mapActions, mapMutations, mapGetters } from "vuex";
import {
  SetSelectedSite,
  ClearSelectedSite,
  SetAppCustomer,
} from "@/store/mutation_types";
import bulkupload from "@/pages/amp/bulkUpload.vue";
import rtcConfig from "@/pages/management/site/rtc-config.vue";
import accessdenied from "@/pages/accessdenied.vue";
import JsonCSV from "vue-json-csv";
import ConfirmAction from "./confirmAction.vue";

Vue.component("downloadCsv", JsonCSV);

export default {
  name: "SiteList",
  components: { bulkupload, rtcConfig, accessdenied, ConfirmAction },
  data() {
    return {
      headers: [
        { text: "Name", align: "left", value: "name" },
        // { text: 'Placements', align: 'left', value: 'placements' },
        { text: "Action", align: "center", value: "action" },
      ],
      site: {},
      stored_request_duplicated: {},
      site_duplicated: {},
      loading: false,
      search: "",
      generateButtonLoading: false,
      stored_request_siteIDduplicated: "",
      showArchived: false,
      actionType: "",
      bulkUploadDialog: false,
      codeGenerated: "",
      rtcConfigDialog: false,
      actionsuccess: false,
      confirmActionDialog: false,
      confirmationAction: "",
      timeout: 3000,
      messageAction: "",
      numberOfSites: "",
      item: [],
      fieldsToExport: [
        "name",
        "customer_id",
        "is_refresh_active",
        "selection_type",
        "orders_ids",
        "refresh_time",
        "nb_repetition",
        "noconsent_traffic_blocking",
        "pricefloor_is_active",
        "pricefloor_cpm",
      ],
    };
  },
  computed: {
    ...mapGetters(["sites", "selectedSite", "appCustomer", "storedRequests"]),
    computedSites() {
      return this.sites
        .filter((el) => {
          return this.showArchived ? true : el.is_archived == 0;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    appCustomer() {
      if (this.$route.params.customer_id != this.appCustomer.id) {
        this.$router.push({
          name: "new_sites",
          params: {
            type: this.$route.params.type,
            customer_id: this.appCustomer.id,
          },
        });
      }
    },
  },
  async created() {
    this.loading = true;
    if (this.$route.fullPath != this.$route.path)
      this.$router.push(this.$route.path);
    if (
      this.$store.getters.is_superadmin &&
      this.$store.state.appCustomer.id != this.$route.params.customer_id
    ) {
      this.GetSites(this.$route.params.customer_id);
    } else this.GetSites(this.appCustomer.id);
    if (this.sites.length == 1)
      this.numberOfSites = this.sites.length + " site created";
    else if (this.sites.length > 1)
      this.numberOfSites = this.sites.length + " sites created";
    else this.numberOfSites = "No sites created";
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GetSites,
      GetStoredRequests,
      SaveSelectedStoredRequest,
      SaveSelectedSite,
      UpdateSelectedSite,
    }),
    ...mapMutations([SetSelectedSite, ClearSelectedSite, SetAppCustomer]),
    async archiveSite(item) {
      item.is_archived = 1;
      await this.UpdateSelectedSite(item);
      this.SetSelectedSite(item);
      await this.GetSites(this.appCustomer.id);
      this.actionsuccess = true;
      this.messageAction = "Site archived successfully";
    },
    async unarchiveSite(item) {
      item.is_archived = 0;
      this.UpdateSelectedSite(item);
      this.SaveSelectedSite(item);
      await this.GetSites(this.appCustomer.id);
    },
    async duplicateSite(item, sitename) {
      this.SetSelectedSite(item);
      const site_to_duplicate = Object.assign({}, this.selectedSite);
      const site_duplicated = Object.assign({}, this.selectedSite);
      site_duplicated.id = undefined;
      site_duplicated.name = sitename;
      try {
        await this.SaveSelectedSite(site_duplicated);
        await this.GetStoredRequests(site_to_duplicate.id);
        this.storedRequests
          .filter((el) => {
            return el.type == "fast";
          })
          .forEach((element) => {
            const stored_request_duplicated = Object.assign({}, element);
            stored_request_duplicated.id = "";
            stored_request_duplicated.name = element.name + " _1";
            stored_request_duplicated.site_id = this.selectedSite.id;
            this.SaveSelectedStoredRequest(stored_request_duplicated);
          });
        this.actionsuccess = true;
        this.messageAction = "Site duplicated successfully";
      } catch (error) {
        console.log(error);
      }
    },
    async ConfirmEdit(siteName, actionType) {
      try {
        if (actionType == "update") {
          this.selectedSite.name = siteName;
          try {
            await this.SaveSelectedSite(this.selectedSite);
            this.actionsuccess = true;
            this.messageAction = this.$t("success_website_updated");
            await this.GetSites(this.appCustomer.id);
            await this.SetSelectedSite(this.site);
          } catch (error) {}
        }
        if (actionType == "add") {
          this.site.name = siteName;
          this.site.customer_id = this.appCustomer.id;
          try {
            await this.SaveSelectedSite(this.site);
            this.actionsuccess = true;
            this.messageAction = this.$t("success_website_added");
            this.$router.push($route.path);
          } catch (error) {}
        }
        this.siteEditDialog = false;
        this.alertSiteAdded = true;
        setTimeout(() => (this.alertSiteAdded = false), 3000);
      } catch (error) {
        console.log(error);
      }
    },
    async SendConfigToEmail(email) {
      const siteId = this.selectedSite.id;
      const response = await axios.get(`/api/rtc-email/${siteId}/${email}`);
    },
    showDialog(actionType) {
      this.actionType = actionType;
      this.siteEditDialog = true;
      if (actionType == "update") {
        this.$router.push(
          this.$route.path +
            "/" +
            this.selectedSite.id +
            "?action=" +
            actionType
        );
      } else this.$router.push(this.$route.path + "?action=" + actionType);
    },
    selectItem(item) {
      this.SetSelectedSite(item);
      this.GetStoredRequests(item.id);
      this.$router.push(
        "/management/" +
          this.$route.params.type +
          "/" +
          this.appCustomer.id +
          "/sites/" +
          this.selectedSite.id +
          "/storedrequests"
      );
    },
    goToInjectionList(item) {
      this.SetSelectedSite(item);
      this.GetStoredRequests(item.id);
      this.$router.push(
        "/management/" +
          this.$route.params.type +
          "/" +
          this.appCustomer.id +
          "/sites/" +
          this.selectedSite.id +
          "/injections"
      );
    },
    async getCountStoredRequests(site_id) {
      var response = await this.GetStoredRequests(site_id);
      var placements = response.data;
      return placements.length;
    },
    newSite() {
      this.$router.push({
        name: "new_sites_new",
        params: {
          type: this.$route.params.type,
          customer_id: this.appCustomer.id,
        },
      });
    },
    updateSite(item) {
      this.SetSelectedSite(item);
      this.$router.push({
        name: "new_sites_edit",
        params: {
          type: this.$route.params.type,
          customer_id: this.appCustomer.id,
          site_id: this.selectedSite.id,
        },
      });
    },
    csvExport() {
      let csvContent = "data:Gsheet;charset=utf-8,";
      csvContent += [
        Object.keys(this.computedSites[0]).join(";"),
        ...this.computedSites.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    showConfirmationDialog(item) {
      this.item = item;
      this.$router.push(this.$route.path + "?action=confirmArchiving");
      this.confirmActionDialog = true;
      this.confirmationAction = "archive";
    },
  },
};
</script>
<style scoped>
.avatar {
  padding-left: 20px;
  padding-bottom: 23px;
}
.no-shadow,
.no-shadow::before {
  box-shadow: none;
  background-color: transparent !important;
}
.combobox {
  padding-left: 50px;
}
.cnx {
  letter-spacing: 2px;
  font-size: 28px;
  background-color: #dddddd;
  border-radius: 5px;
  padding: 5px;
}
</style>
