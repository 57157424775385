<template>
  <v-container fluid style="background-color: #f9f9fb">
    <v-row>
      <v-col>
        <h2 class="cnx">Set up</h2>
      </v-col>
    </v-row>
    <br /><br /><br />
    <contentDashboards :parent-exists="dataExists" />
  </v-container>
</template>

<script>
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { GetSites } from '@/store/action_types'

  import contentDashboards from './content.vue'

  export default {
    name: 'Dashboards',
    components: { contentDashboards },
    data() {
      return {
        dataExists: false
      }
    },
    computed: {
      ...mapGetters(['sites', 'appCustomer'])
    },
    watch: {
      async appCustomer() {
        await this.GetSites(this.appCustomer.id)
        if (this.sites.length > 0) this.dataExists = true
        else this.dataExists = false
      }
    },
    async created() {
      await this.GetSites(this.appCustomer.id)
      if (this.sites.length > 0) this.dataExists = true
      else this.dataExists = false
    },
    methods: {
      ...mapActions({ GetSites }),
      ...mapMutations([])
    }
  }
</script>

<style scoped>
  .cnx {
    padding-left: 10px;
  }

  .combobox {
    padding-left: 50px;
    width: 370px;
  }
  .notcapital {
    text-transform: none !important;
  }
  .parameters {
    background-color: black;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
</style>
