<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="$emit('hideDialog'), back()"
  >
    <template>
      <v-card style="height: 100%">
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="$emit('hideDialog'), back()"
            >mdi-close</v-icon
          >
        </v-col>
        <v-card-title primary-title style="padding-top: 10px">
          <v-row>
            <v-col cols="12">
              <h3 style="color: black">Bulk upload</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="margin-top: 10px">
          <v-container>
            In order to be efficient and fast in the placement management, you
            can download our template and fill the information required by
            website and by placement into a single file. <br />After, you can
            directly upload this file here and we will automatically update the
            system. <br />
            You can use this feature only if you have already set placement
            names.
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-row>
            <v-col cols="2" />
            <v-col cols="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="20"
                offset-x
                style="width: 100%"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="border-color: black; margin-right: 20px"
                    class="white--text notcapital"
                    color="black"
                    width="250"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-download-outline </v-icon> Download the
                    template
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <download-csv
                          :name="filename + '.csv'"
                          :data="datatoExport"
                        >
                          <v-btn
                            style="
                              border-color: black;
                              margin-right: 10px;
                              margin-left: 5%;
                            "
                            class="black--text notcapital"
                            color="#d3d3d3"
                            width="200"
                            dense
                            @click="checkData"
                          >
                            <v-icon left> mdi-download-outline </v-icon>
                            As CSV file
                          </v-btn>
                        </download-csv>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <!-- <v-divider></v-divider>-->
                  <!--<v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <downloadExcel
                          :name="filename"
                          :data="datatoExport"
                          :worksheet="filename"
                        >
                          <v-btn
                            style="
                              border-color: black;
                              margin-right: 10px;
                              margin-left: 5%;
                            "
                            class="black--text notcapital"
                            color="#d3d3d3"
                            width="200"
                            dense
                            @click="checkData"
                          >
                            <v-icon left> mdi-download-outline </v-icon>
                            As Excel file
                          </v-btn>
                        </downloadExcel>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>-->
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="1" />
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 20px"
                class="white--text notcapital"
                color="black"
                width="260"
                dense
                @click="$emit('hideDialog'), (uploadDialog = true)"
              >
                <v-icon left> mdi-upload-outline </v-icon> Upload the filled
                template
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-row style="padding: 5px">
          <v-col align="left" justify="left" style="margin-left: 1%">
            <span v-if="errors.length > 0">
              <v-alert
                v-for="error in errors"
                :key="error"
                color="#db2727"
                type="error"
                dense
                >{{ error }}</v-alert
              >
            </span>
          </v-col>
        </v-row>
      </v-card>
      <uploadPage
        :dialog="uploadDialog"
        :multiple="false"
        @filesUploaded="processUpload($event)"
        @hideDialog="back(), (uploadDialog = false)"
        @back="$emit('hideUploadDialog'), (uploadDialog = false)"
      />
    </template>
  </v-dialog>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import JsonCSV from 'vue-json-csv'
  import JsonExcel from 'vue-json-excel'
  import uploadPage from './uploadPage.vue'
  Vue.component('downloadCsv', JsonCSV)
  Vue.component('downloadExcel', JsonExcel)
  export default {
    components: { uploadPage },
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        dialog: false,
        site: {
          name: ''
        },
        TitleDialog: this.$t('update_site'),
        nameRules: [(v) => !!v || this.$t('name is required')],
        submitLoading: false,
        errors: [],
        btnText: this.$t('update'),
        datatoExport: [],
        uploading: false,
        uploadDialog: false,
        menu: false
      }
    },
    computed: {
      ...mapGetters([
        'selectedSite',
        'appCustomer',
        'SspList',
        'selectedCustomer'
      ]),
      filename() {
        if (this.$route.params.type == 'fast') return 'web_template'
        return this.$route.params.type + '_template'
      }
    },
    watch: {
      async appCustomer() {
        let response = {}
        if (this.$route.name == 'new_storedrequests')
          response = await axios.get(
            `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/${this.$route.params.site_id}`
          )
        else
          response = await axios.get(
            `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/0`
          )
        this.datatoExport = response.data
      },
      parentDialog() {
        this.dialog = this.parentDialog
      },
      '$route.params.type': async function (val, oldVal) {
        let response = {}

        if (this.$route.name == 'new_storedrequests')
          response = await axios.get(
            `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/${this.$route.params.site_id}`
          )
        else
          response = await axios.get(
            `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/0`
          )
        this.datatoExport = response.data
      }
    },
    async created() {
      this.site.appCustomer = this.appCustomer.id
      this.showBar = false
      let response = {}
      if (this.$route.name == 'new_storedrequests')
        response = await axios.get(
          `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/${this.$route.params.site_id}`
        )
      else
        response = await axios.get(
          `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.type}/0`
        )
      this.datatoExport = response.data
    },
    methods: {
      EditActions() {
        this.TitleDialog = this.$t('update_site')
        this.btnText = this.$t('update')
        this.site = Object.assign({}, this.selectedSite)
      },
      AddActions() {
        this.TitleDialog = this.$t('add_site')
        this.btnText = this.$t('add')
        if (this.site.name != '') this.$refs.form.reset()
        this.site = {
          name: ''
        }
      },
      back() {
        this.$router.push(this.$route.path)
      },
      processUpload(e) {
        console.log(e)
      },
      Emit() {
        this.$emit('submitted', this.site.name, this.actionType)
      },
      goToUploadPage() {
        this.$router.push({
          name: 'upload_page',
          params: {
            type: this.$route.params.type,
            customer_id: this.appCustomer.id
          }
        })
      },
      checkData() {
        this.errors = []
        this.menu = false
        if (this.datatoExport.length == 0) {
          this.errors.push('You have no placements to download')
          setTimeout(() => (this.errors = []), 5000)
        }
      }
    }
  }
</script>
